// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

//web version 9 (modular)
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getStorage, ref } from "firebase/storage";
import { getAuth, 
  setPersistence,
  browserLocalPersistence, 
  browserSessionPersistence, 
  inMemoryPersistence } from "firebase/auth";
//import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyAXPE4uVRl34ye6O4DQUWAYszl4REn8ql0",
  authDomain: "fpdk-5b316.firebaseapp.com",
  databaseURL: "https://fpdk-5b316.firebaseio.com",
  projectId: "fpdk-5b316",
  storageBucket: "fpdk-5b316.appspot.com",
  messagingSenderId: "342971245592",
  appId: "1:342971245592:web:1eaba5530071041be60f6d",
  measurementId: "G-3CPXC5B4R1"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const db = getFirestore();
export const storage = getStorage(firebaseApp);
export const storageRef = ref(storage);
export const auth = getAuth(firebaseApp);

setPersistence(auth, browserLocalPersistence) ///note: enabling persistence, firebase will remember the user's authentication state even after a page reload.

// export const functions = getFunctions(firebaseApp);





// //web version 9 (modular)
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore"
// import { getStorage, ref } from "firebase/storage";
// //import { getFunctions, httpsCallable } from "firebase/functions";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAXPE4uVRl34ye6O4DQUWAYszl4REn8ql0",
//   authDomain: "fpdk-5b316.firebaseapp.com",
//   databaseURL: "https://fpdk-5b316.firebaseio.com",
//   projectId: "fpdk-5b316",
//   storageBucket: "fpdk-5b316.appspot.com",
//   messagingSenderId: "342971245592",
//   appId: "1:342971245592:web:1eaba5530071041be60f6d",
//   measurementId: "G-3CPXC5B4R1"
// };

// // Initialize Firebase
// export const firebaseApp = initializeApp(firebaseConfig);
// //export const analytics = getAnalytics(firebaseApp);
// export const db = getFirestore();
// export const storage = getStorage(firebaseApp);
// export const storageRef = ref(storage);
// // export const functions = getFunctions(firebaseApp);