
import React, { forwardRef, useLayoutEffect, useRef, useEffect, useState, memo, useContext, useCallback, Fragment, useMemo } from 'react';
import banner_sun from '../src/imgs/banner_sun.png'; ///note: see https://stock.adobe.com/search?k=mountain+peak+illustration&asset_id=591793393
import banner_fog from '../src/imgs/banner_fog.png'; 
import '../src/standard-components.css';
import * as generics from './generics';
import produce from "immer"
import ContentEditable from 'react-contenteditable'
import styled from 'styled-components';
import { templatesContext } from './DataProvider';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';


export function Wrapper({ id, name, className, height, display, width, position, style, show, paddingAndMargin, overflow, animate, marginTop, marginBottom, margin, children }) {
  ///
  let classShow
  switch (true) {
    case generics.stringUndefined(show) && generics.stringUndefined(animate):
      classShow = "show"
      break;
    case show && animate:
      classShow = "show fadein-05"
      break;
    case show && !animate:
      classShow = "show"
      break;
    case !show && animate:
      classShow = "hide fadeout-01"
      break;
    case !show && !animate:
      classShow = "hide"
      break;
    default:
    break;
  };

  const dimSizeObj = {
    height: !generics.stringUndefined(height) ? height : "100%", 
    width: !generics.stringUndefined(width) ? width : "100%", 
    }
  const marginObj = {
    // marginTop: !generics.stringUndefined(marginTop) ? marginTop : "0%", 
    // marginBottom: !generics.stringUndefined(marginBottom) ? marginBottom : "0%",  
    } 
  const overflowObj = {overflow: overflow}
  const positionObj = {position: position}
  const displayObj = {display: display}
  const wrapperStyle = {...style, ...dimSizeObj, ...marginObj, ...overflowObj, ...positionObj, ...displayObj}

  return (
    <div 
      id={id}
      className={`
      ${name}-wrapper 
      wrapper
      ${paddingAndMargin}
      ${className}
      ${classShow}
      `}
      style={wrapperStyle}
    >
        {/* <div 
          className={`
          ${name}
          ${className}
          `}
          style={{style}}
        > */}
          { children }
      {/* </div> */}
    </div>
  );
}

export function AspectRatio( { children, wrapperClassName, wrapperMargin, aspectRatio, marginTop, marginLeft, marginFactor, scrollSnapAlign } ) {

  const styleWrapper = {
    paddingTop: `${aspectRatio}%`, /* 16:9 Aspect Ratio */
    marginBottom: wrapperMargin,
    scrollSnapAlign: scrollSnapAlign,
    //position: "relative",
    height: "100%", ///optimise: implement padding instead of height, probably via positon relative and ???. note: at horizontal = true, aspectratio = 0, groupsize = 1, height > 0% ensures image is visible
  }

  const styleWrapped = {
    // marginTop: `calc(${marginTop}% / ${marginFactor})`, ///optimse: test use of padding instead of margin, and no transform
    // marginLeft: `calc(${marginLeft}% / ${marginFactor})`,
    // transform: `translateX(calc(${- marginLeft}% / ${marginFactor}))`,
  }

  return (
    <div 
      className={`aspect-ratio-wrapper ${wrapperClassName}`}
      style={styleWrapper}
      >
      <div className={`
        aspect-ratio-wrapped
      `}
        style={styleWrapped}
      >
         { children }
        </div> 
      </div>
  );
}

export const ButtonWithLogo = ({ width, isOneColumn, disabled, href, buttonHeight, theme, iconScaleFactor, icon, text, parentCallback }) => {
    return (

    <Button
      width={!!width ? width : "100%"}
      height={buttonHeight}
      show={true}
      classTypes={["classical", ""]}
      theme={theme}
      disabled={disabled}
      selectOff={disabled}
      wrapperDisplay={"grid"}
      parentCallback={(e) => parentCallback(e)}
    >
    <a 
      href={href}
      target={"_blank"}
      style={{width: "100%", height: "100%", textDecoration: "none", pointerEvents: `${disabled ? "none" : ""}`}}
    >
      <GridColums
        gridTemplateColumns={`${isOneColumn ? "1fr" : "1fr 1fr"}`}
        gridTemplateRows={"1fr"}
        alignItems={"center"}
        justifyItems={"center"}
        columnGap={"0em"}
        wrapperStyle={{height: "100%", width: "100%"}}
        height={"100%"}
        width={"100%"}

      >
        <span>{text}</span>
        <AspectRatio
          aspectRatio={1}
        >
          <div
            className={"center-by-transform"}
            style={{position: "relative", height: `calc(100% * ${iconScaleFactor})`, width: "100%"}}
          >
          {icon}
          </div>
        </AspectRatio>

        {/* <SvgComponent
          alt="Chat on Facebook Messenger" 
          icon={<>{GlobeLogo}</>}
          width={iconWidth}
        >
        </SvgComponent> */}
        
      </GridColums>
      </a> 
    </Button>

    )
}

export function Button( { wrapperDisplay, overflowOff, selectOff, hoverOff, style, innerRef, show, wrapperClassName, wrapperStyle, position, positions, className, classTypes, paddingAndMargin, minHeight, minWidth, height, width, text, fontSize, theme, isSelected, suffix, message, disabled, children, parentCallback } ) { ///optimise: control of height of button and width

  ///ref
  const buttonRef = useRef(null) ///optimise/risk: use of innerRef and buttonRef...!?

  ///state
  const  
    [entered, setEntered] = useState(false),
    [highlight, setHighlight] = useState(false)

  ///const
  const 
    name = "btn",
    classes = {
      1: { ///overall look and feel
        default: "btn-classical",
        classical: "btn-classical",
      },
      2: { ///additional styling
        default: "btn-all",
        all: "btn-mini",
        all: "btn-all",
        left: "btn-left",
        right: "btn-right",
        customborder: "btn-customborder ",
        min: "btn-min",
        option: "btn-option",
        subtle: "btn-subtle",
        subtleExtra: "btn-subtle-extra",
        subtleSuper: "btn-subtle-super",
        select: "btn-select",
        selectRight: "btn-select-right",
        selectLeft: "btn-select-left",
        link: "btn-link"
      }
    }

  const classes1 = generics.getClassesFromType(classes, 1, classTypes) ///optimise: combine classes1 and classes 2...
  const classes2 = generics.getClassesFromType(classes, 2, classTypes)

  const handleMouseMovement = (entered) => {
      setEntered(entered)
  }

  useEffect(() => {
    const highlight = (isSelected || entered) && !hoverOff
    setHighlight(highlight)
  }, [entered])

  //const highlight = (isSelected || entered) && !hoverOff

  const positionObj = Array.isArray(positions) ?
      generics.getPosition(positions) : 
      {}
  //const heightObj = {height: height}
  const wrapperStyleObj = {...wrapperStyle, ...positionObj}

  const btnText = 
    <span className={`${name}-text`}>
      {text}
    </span>

const isColor = generics.isValidColor(message)

// const PseudoStyle = isColor ? styled.div`
//     &::before {
//       background: ${message};
//       display: block;
//       margin: 10px;
//       width: 50%; 
//       height: 50%; 
//       border-radius: 10%; 
//     }
//   ` :  styled.div``

  const colorStyle = {
    background: `${message}`,
    display: "block",
    margin: "10px",
    width: "50%",
    height: "50%",
    borderRadius: "10%",
  }

  

  return (
    <Wrapper
      name={name} ///optimise - use name in button
      className={`
        ${name}-wrapper
        ${wrapperClassName}
        ${show ? "show fadein-05" : "hide fadeout-05"}
      `}
      paddingAndMargin={paddingAndMargin}
      height={generics.stringUndefined(height) ? "auto" : height}
      width={generics.stringUndefined(width) ? "auto" : width}
      show={true}
      style={wrapperStyleObj}
      display={wrapperDisplay}
      position={Array.isArray(positions) ? "absolute" : position}
    >
  <div ///note: if classname is used => consider to imeplemt in classes
        className={` 
          ${name}
          ${className}
          ${classes1}
          ${classes2}
          ${`font-size-${fontSize}`}
          ${`theme-${theme}`}
        ${disabled ? 
          `btn-disabled` : 
          ``
        }
        ${highlight ? 
          `highlight` :
          "" }
        `}
        style={
          {...style,
          minHeight: minHeight, 
          minWidth: minWidth, 
          height: "100%", ///was height
          width: width, 
          cursor: `${selectOff ? "" : "pointer"}`,
          overflow: overflowOff ? "hidden" : "visible",
          display: "flex", ///note: to center children (text)
          }}
        suffix={suffix}
        ref={innerRef} ///note: ref/innerRef from props not currently implemented/used
        // message={!isColor ? message : "" }
        onMouseEnter={(e) => !disabled && handleMouseMovement(true)}
        onMouseLeave={(e) => !disabled && handleMouseMovement(false)}
        onClick={(e) => !disabled && !selectOff && parentCallback(e)}
      >
        { children } { btnText }
        <span ///optimise/risk: can overflow width, mitigate ...
          className={"btn-message"}
          style={isColor ? colorStyle : null}
          >
            {!isColor ? message : ""}
        </span>
  </div> 
    </Wrapper>
  );
}


export const PreLoader = ( props ) => ///${show ? "fade-in" : "fade-out"}
    <div className={`
        preloader-w
        ${props.className} 
    `} ///note: props.className added via childrenWrapper
    >
        <div className="preloader">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
    </div>
export const MemoPreLoader = memo(PreLoader)

export const BackgroundComponent = ({ children }) => {

  let location = useLocation()

  const [showPrimary, setShowPrimary] = useState(false)

  // useEffect(() => { 
  //   const 
  //     pathname = location.pathname,
  //     pathLength = pathname.split("/").filter(Boolean).length
  //     if (pathLength > 1) {

  //     }
  // }, [location]) 

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    if(window.pageYOffset === 0) {
      setShowPrimary(true)
    }
    else {
      if (showPrimary) {
        setShowPrimary(false)
      }
    }    
  }

  const backgrounds = {
    0: { src: banner_sun, isPrimary: true },
    1: { src: banner_fog, isPrimary: false },
   // 2: { src: banner_fog2, zLayer: 10110, isPrimary: false }
  }

  return (
    Object.entries(backgrounds).map(([k, v], i) => 
      <div className={`banner-c`}
        key={v.src}
      >
          <img
            className={`
              banner 
              ${v.isPrimary ? "banner-primary" : "banner-secondary"}
              ${showPrimary ? "primary--show" : "primary--hide"}
            `}
            draggable={false}
              src={v.src}
          ></img>
          { children }
      </div>
    )
  )
}

export const Sticky = ({ className, offsetSticky, isFade, fadeSticky, children }) => { ///stickyCallback
// https://github.com/yairEO/react-hooks/blob/master/hooks/useDetectSticky.js
/**
 * detects when a (CSS) sticky element changes "sticky" state
 * @param {object} ref optional react ref. if not provided, a new one will be used instead.
// */
// const [isStickyText, setIsStickyText] = useState("bb")

const useDetectSticky = (ref, observerSettings = {threshold: [1]}) => {
  const [isSticky, setIsSticky] = useState(false)
  
  const newRef = useRef()
  ref ||= newRef;
  
   // mount 
  useEffect(()=>{
    const cachedRef = ref.current,
          observer = new IntersectionObserver(
            ([e]) => 
              setIsSticky(
                e.boundingClientRect.bottom <= e.intersectionRect.bottom && ///note: condition to exclude at viewport border 
                e.intersectionRatio < 1
                ),
              {threshold: 1}
          
          )
    observer.observe(cachedRef)
    
    // unmount
    return () => {
      observer.unobserve(cachedRef)
    }
  }, [])

  return [isSticky, ref, setIsSticky];
}

const Header = ({ sticky=false, ...rest }) => {
  const [isSticky, ref, setIsSticky] = useDetectSticky()

  const elementRef = useRef(null);

  useEffect(() => {
    if (!fadeSticky) return
    const elements = Array.from(document.querySelectorAll(`.${className}.sticky--true`));
    //if (elements.length === 1 && i !== 0) return
    if (elements.length > 0) {
      elements.map((element, i) => { ///slice(0, -1)
        elementRef.current = element
        i === 0 ? 
          elementRef.current.classList.add('first-sticky--true') : ///note: subtle impact on animation
          elementRef.current.classList.remove('first-sticky--true');
        i === elements.length - 1 ? 
          elementRef.current.classList.add('last-child--true') :
          elementRef.current.classList.remove('last-child--true')
      })
    }

    const innerText = elements.length > 0 ?
      elements.pop().innerText : ""
      //stickyCallback({ innerText: innerText })

  }, [isSticky]);

  return (
    // <div className={`sticky-w
    //  ${isFade ? "fade-out" : "fade-in"}
    // `}>
    <>
      <div className={`
        sticky-background
        ${isSticky ? " sticky--true" : ""}
        ${isSticky && !isFade ? "fade-in" : "fade-out"}
        `}>
      </div>
      <header 
        className={`
          sticky-c
        `} 
        style={{
          top: "-1px", //!!offsetSticky ? `-${offsetSticky}` : "-1px",
          paddingTop: !!offsetSticky ? offsetSticky : "1px",
        }}
        ref={ref} 
        {...rest}
      >
          <div className={`sticky
                  ${isFade ? "select--false-blur" : ""}
                  ${fadeSticky ? "sticy-fade--true" : ""}
                  ${className} ${isSticky ? " sticky--true" : ""}
          
          `}

            >
              { children(isSticky) }
        </div>
      </header>
    {/* </div> */}
    </>
  )
}
  return (
    <>
      <Header 
        //stickyCallback={({ innerText }) => setIsStickyText(innerText)}
      >
          { children }
      </Header>         
      {/* <StaticHeader headerText={isStickyText}/> */}
    </>
  )
}

// export const StaticHeader = ({ text }) => {
//   return (
//     <div ///   
//     className={`
//       static-header-c 
    
//     `}>
//       <div
//         className={`
//           static-header
//       `}>
//         {/* {text} */}
//       </div>
//     </div>
//   )
// }

export const GalleryHeadline = ({ show, headline, subline }) => {

  //const headlineRef = useRef(null)

  return (
    <Sticky
      fadeSticky={false}
      //stickyCallback={() => console.log("bib")}
    >
      {(isSticky) => (
      <div className={
        `gallery-headline-c
        ${show ? "fade-in delay" : "fade-out"}
        `}
    >
        {/* <div className={`screen`}>

        </div> */}
        <span className={`
          gallery-headline
          ${isSticky ? "hide fade-out" : "fade-in" }
        `}>
          {headline}
        </span>
        <span className={`gallery-headline-subline`}>
          {subline}
        </span>
      </div>
      )}
    </Sticky>
  )
}


export const GridColums = ( { wrapperId, style, id, border, wrapperStyle, gridTemplateColumns, gridTemplateRows, justifyContent, hideScrollBars, justifyItems, alignItems, gridAutoFlow, scrollSnapAlignChildren, name, gap, rowGap, columnGap, height, width, overflow, scrollSnapType, paddingBottom, className, children } ) => {
    ///repeat(auto-fit, minmax(0, min(100%/3, max(100%/${columns})))) ///min and max defines number of elements in rows
    const style_ = {
      ...style,
      gridTemplateColumns: gridTemplateColumns,
      gridTemplateRows: gridTemplateRows,
      justifyContent: justifyContent,
      justifyItems: justifyItems,
      alignItems: alignItems,
      gap: gap,
      rowGap: rowGap,
      columnGap: columnGap,
      height: height,
      width: width,
      overflow: overflow,
      scrollSnapType: scrollSnapType,
      paddingBottom: paddingBottom,
      gridAutoFlow: gridAutoFlow,
      border: border
    }
  
    const heightObj = {height: `100%`} ///note/risk: removed due to the height of fixed and scrollable textelement, impact elsewhere? 
    const wrapperStyleObj = wrapperStyle ///{...wrapperStyle, ...heightObj}  ///optimise/bug/risk: needed?? removed due to height of mediaupload in galleryitem
  
    return (
       <div 
       id={wrapperId}
       className={`
       ${name}-wrapper
       ${className}
        grid-columns-wrapper
        ${hideScrollBars ? "scrollbar-hide" : ""}
      `}
        style={wrapperStyleObj}
       >
        <div className={`
          ${name}
          grid-columns
          scroll-snap-align-children-${scrollSnapAlignChildren}
        `}
          style={style_}
          id={id}
        >
        { children }
       </div>
       </div>
     )
  }
  
  
  export const Symbol = ({ symbol, showControl, parentCallback }) => { ///optimse: combine x-symbol in one
    const show = generics.getValueFromKey(showControl, symbol)
    return (
        <div className={`symbol-wrapper`}>
          <div className={`
            symbol
            input input-square input-radius input-border-default
            ${show ? "show fadein-05 input-hover" : "hide fadeout-05"}
            `}
            onClick={() => show && parentCallback(symbol)}
            >
            <i className={`fas fa-${symbol}`}></i>  
          </div>
        </div>
    );
  }

  export const Checkbox = ({ setup, parentCallback }) => { 

    // const [dataArray, setDataArray] = useState(setup)

    const handleSetIsChecked = ({ id, parameter }) => {
        const data = setup.find((data) => data.id === id);
        data[parameter] = !data[parameter];
        parentCallback({ id, value: data[parameter] })
    }

    const Components = ({ data }) => {

      const [isSubTextShow, setIsSubTextShow] = useState(false)

      return (
        <label 
          className={`
            checkbox-c
            ${isSubTextShow ? "open" : "closed"}
            `}
          >
        <input type="checkbox" checked={data.isChecked}
          onChange={() => void(0)}
        />
        <span className="checkmark"
          onClick={(e) => handleSetIsChecked({ id: data.id, parameter: "isChecked" })}
        ></span>
        <span className="text">{data.text}
          <i className="fa-solid fa-circle-info"
            onClick={() => setIsSubTextShow(!isSubTextShow)}
          ></i>
        </span>
        <span className={`
          sub-text
          ${isSubTextShow ? "fade-in delay" : "fade-out"}
        `}> 
          {data.subText}
        </span>
      </label>
      )
    }

    return (
      <div className={`checkbox-w`}>
        {setup.map((data) =>
          <Components key={data.id} data={data}/>
        )} 
      </div>
    )
  }
  
 export const Input = forwardRef((props, forwardedRef) => {
  return (
      <ContentEditable 
          className={props.className}
          html={props.text}
          innerRef={props.forwardedRef} ///note: props.forwardRef, instead of forwardedRef not used, likely due to composition innerRef, not ref
          placeholder={props.placeholder}
          // suppressContentEditableWarning={true}
          //onBlur={handleBlur} 
          onChange={props.parentCallback} 
      />
  
 )
 })


export const FlexBox = ( { className, children } ) => {

  const style = {
    display: "flex",
    flexWrap: "wrap"
  }

   return (
     <div className={`flexbox-wrapper`}>
      <div className={`
        flexbox
        ${className}
      `}
        style={style}
      >
        { children }
        </div>
      </div>
    )
}

export const ZeroToFullHeight = memo(( { height, classNameOuter, classNameInner, noShow, justShow, animateForwards, animateReverse, children } ) => {
  ///optimise: full-height is set via max-height. not ideal...
  ///note: noshow, justshow, animateforwards, animateeverse instead of simply show => to mitigate animation on re-render

  const durationHeightForwards = 0.8
  const durationHeightReverse = 0.3
  const durationOpacityForwards = 0.5
  const durationOpacityReverse = 0.3
  const delayOpacity = durationHeightForwards - (durationOpacityForwards / 2)

   const Component = useCallback(() => {
    return (
    <div 
    key={"a"} 
    className={`${classNameOuter} zero-to-full-height-outer
      ${noShow ? "zero-to-full-height-outer-noshow" : ""}
      ${justShow ? "zero-to-full-height-outer-justshow" : ""}
      ${animateForwards ? "zero-to-full-height-outer-animation-forwards" : ""}
      ${animateReverse ? "zero-to-full-height-outer-animation-reverse" : ""}
    `}
    style={{
      animationTimingFunction: "ease-in-out", ///note: animationame is heightanimation
      animationDuration: `${animateForwards ? durationHeightForwards : durationHeightReverse}s`,
      animationDirection: `${animateForwards ? "normal" : "reverse"}`,
      animationFillMode: "forwards",
    }}
  >
      <div 
        key={"b"} 
        className={`${classNameInner} zero-to-full-height-inner
        ${justShow ? "zero-to-full-height-inner-justshow" : ""}
        ${animateForwards ? "zero-to-full-height-inner-animation-forwards" : ""}
        ${animateReverse ? "zero-to-full-height-inner-animation-reverse" : ""}
        `}
        style={{
          animationTimingFunction: "ease-in-out", ///note: animationame is opacityanimation
          animationDuration: `${animateForwards ? durationOpacityForwards : durationOpacityReverse}s`,
          animationDirection: `${animateForwards ? "normal" : "reverse"}`,
          animationFillMode: "forwards",
          animationDelay: `${delayOpacity}s`,
          //opacity: "0", ///reduced away
        }}
        >
        { children }
      </div>
    </div>
    )
  },[noShow, justShow, animateForwards, animateReverse])

  return (
   <Component/>
   )
})

export const Edit = ({ identifier, param, label }) => {

  ///context 
  const { templates, handleSetTemplatesData } = useContext(templatesContext)

  const handleEdit = ({}) => { ///edit mode selected => set templates data (=> note: this change at !!label cause useEffect at dataProvider) 
    handleSetTemplatesData({ identifier: identifier, action: "update", activeTemplate: "video", param, label }) //, isUseModal: true })
  }

  return (
    <ButtonWithLogo
      iconScaleFactor={0.5}
      isOneColumn={false}
      className={`edit-container`}
      classTypes={["classical", "btn-customborder"]}
      show={true}
      disabled={false}
      theme={1}
      buttonHeight={"fit-content"}
      width={"auto"}
      icon={<i className="edit fa fa-solid fa-pen-to-square"></i>}
      parentCallback={handleEdit} ///parentCallback({ modalChildName: "multiOptions", isShow: true })}
    >
    </ButtonWithLogo>
  )
}

export const Blanket = ({ isShow }) => {

  ///use
  const history = useHistory();

  return (
    <div className={`
      ${isShow ? "fade-in-animation" : "fade-out"}
        pl-blanket`
      }
      onClick={() => history.goBack()}
    ></div>
  )
}




