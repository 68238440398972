import { apiKeys } from './requestVideos';

export const requestChannelData = async({ channelIdentifier }) => {

    let 
        jsonChannelData  = {},
        apiReady = true

        if (apiReady) {
            const 
                key = apiKeys[1],
                part = "part=snippet&part=brandingSettings&part=contentDetails&part=statistics",
                channelIdentifier_ = channelIdentifier, //.replace(/\s+/g, '').replace(/@/g, ''),
                isName = !channelIdentifier_.startsWith("UC")
            console.log("channelIdentifier_", channelIdentifier_)
            const  /// `https://www.googleapis.com/youtube/v3/channels?${part}&forUsername=${channelIdentifier_}&key=${key}` :
                requests = isName ?
                    `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=${channelIdentifier_}&type=channel&key=${key}` :
                    `https://www.googleapis.com/youtube/v3/channels/?${part}&id=${channelIdentifier_}&key=${key}`,
                response = await fetch(requests)
            if (response.status === 403) {
                apiReady = false
                console.log("apiReady", apiReady)
                //break
            }
            jsonChannelData = await response.json()
            console.log("jsonChannelData", jsonChannelData)
        }

    if (jsonChannelData.hasOwnProperty("items")) { ///channel exist
        const 
            lastChanged = { lastchanged: new Date() },
            channelId = jsonChannelData.items[0].id,
            { title, customUrl, description, publishedAt } = jsonChannelData.items[0].snippet,
            thumbnailUrl = jsonChannelData.items[0].snippet.thumbnails.high.url,
            { subscriberCount, videoCount, viewCount } = jsonChannelData.items[0].statistics,
            startVideoId = jsonChannelData.items[0].brandingSettings.channel.hasOwnProperty("unsubscribedTrailer") ? 
            jsonChannelData.items[0].brandingSettings.channel.unsubscribedTrailer : "",
            modifiedTitle = title.toLowerCase().replaceAll(':', '-')
            ,
            channelData = { 
                staticData: { 
                    ...lastChanged,
                    title: modifiedTitle, ///due to tara styles :-)
                    channelId: channelId, 
                    customUrl: customUrl,
                    publishedAt: publishedAt,
                    template: "channel"
                },
                dynamicData: {
                    ...lastChanged,
                    thumbnailUrl: thumbnailUrl,
                    description: description, ///note: subject to update
                    startVideoId: startVideoId, ///note: subject to update
                },
                stats: {
                    ...lastChanged,
                    subscriberCount: Number(subscriberCount),
                    videoCount: Number(videoCount),
                    viewCount: Number(viewCount),
                },
                attr: {
                    ...lastChanged,
                }
            },
            document = modifiedTitle
        return [ document, channelData ] 
    }
  
}