import React, { Fragment, useContext, forwardRef, useMemo, useState, createRef, useLayoutEffect, useEffect, memo, useRef, useCallback } from 'react';
import '../src/auth.css';
import { Button, Checkbox, Input } from './StandardComponents';
import { getAuthLink, signOut, signInMethods, signInWithEmail, deleteProfile } from './AuthFunctions';
import { updateDocument, readDoc, addDocument, deleteDocument } from './FirebaseFunctions';
import * as generics from './generics';
import produce from "immer"
import { authDataContext, authAPIContext } from './Home2';
import firebase from './Fire';
import * as emailSetups from './emailSetups';


const AuthHeader = ({ modalChild }) => {
    let text = ""
    switch(true) {
        case modalChild === "signUp":
            text = "Sign up to YogaTube.ai"
        break;
        case modalChild === "signIn":
            text = "Sign in to YogaTube.ai"
        break;
        case modalChild === "profile":
            text = "Your profile"
        break;
        default:
    }
    return (
        <div className={`auth-header-c`}>
            <div className={`auth-header`}>
                {text}
            </div>
        </div>
    )
}

const AuthButton = (props) => 
    <Button
        show={props.show}
                wrapperClassName={``}
                classTypes={["classical", "min"]}
                className={`
                    more
            input-padding-3
                `}
        wrapperStyle={{margin: "auto"}}
        width={"auto"}
        disabled={props.disabled}
        theme={1}
        height={"50%"}
        fontSize={1}
        parentCallback={props.parentCallback}
        > {props.text}
    </Button> 
const MemoAuthButton = memo(AuthButton)

const Messages = (props) => 
    <div
        className={`
            messages-c
            ${props.show ? "fade-in" : "fade-out"}
        `}
    >
        {props.messages.map((message) =>
        <span 
            key={message}
            className={`
                messages
                ${props.show ? "fade-in" : "fade-out"}
          `}
        > {message} </span>
        )}
    </div>

const UserStats = (props) => {

    const elements = {
        created: "member since",
        signInCount: "number of times signed in"
    }

    const modify = (k, v) => {
        console.log("vii", v)
        switch (k) {
            case "created":
                return v.toDate()?.toDateString().split(' ').slice(1,).join(' ');
            default:
                return v;
            }
    };

    const component = 
        Object.entries(props.userStats)
            .filter(([k, v]) => generics.keyExist(elements, k))
            .map(([k, v], i) => {
                return (
                    <span 
                        key={k}
                  
                            className={`
                                user-stats
                        `}
                        >
                            {elements[k]}: {modify(k, v)} 
                    </span>
                )
            }
        )

    return ( 
        <div
            className={`
                user-stats-c
                ${props.show ? "fade-in" : "fade-out"}
            `}
        >
            {component}
        </div>
    )
}

// const MemoInput = memo(Input)

export const Auth = (props) => {

        const 
            text = useRef(''), ///note: ref for text (innerHTML)
            ref = useRef(null) ///note: ref for focus

        const 
            swrAuth = useContext(authDataContext),
            setSwrAuth = useContext(authAPIContext)

        const authStates = swrAuth.data

        const 
            [isValidated, setIsValidated] = useState({ isAccount: true, isEmail: false }), ///optimise: rename as not boolean
            [isFullValidated, setIsFullValidated] = useState(false),
            [subsc, setSubsc] = useState({isAccount: true, isEmail: true}),
            [isUpdated, setIsUpdated] = useState(false)

        const checkboxData = [
            { 
                id: "isAccount", ///consider: renaming back to account, instead of isaccount
                text: "Free Membership", 
                subText: "Membership makes you part of the community, and enables you to actively participate at YogaTube.ai. Comming soon!",
                isChecked: subsc.isAccount,
                isSubTextShow: false,
            },
            { 
                id: "isEmail",
                text: "Free Newsletter Subscription", 
                subText: "Get ~montly updates, news about the upcomming launch, and help shape YogaTube.ai",
                isChecked: subsc.isEmail,
                isSubTextShow: false,
            }
            ]

        const handleInputChange = evt => {
            text.current = evt.target.value;

            testIsValidated({ parameter: "isEmail", value: text.current })
        };

        const handlesetSubsc = ({ id, value }) => {

            setSubsc(
                produce((draft) => {
                    draft[id] = value;
                })
            )
        }

        useEffect(() => {
            testIsValidated({ parameter: "isAccount" })
        }, [subsc]);

        const testIsValidated = ({ parameter, value }) => {
        
            let isValidated = false

            switch(true) {
                case parameter === "isEmail":
                    isValidated = generics.validateEmail(value)
                break;
                case parameter === "isAccount":
                    isValidated = generics.valueInObject({ object: subsc, value: true })
                break;
                default:
            }

            handleSetIsValidated({ parameter: parameter, value: isValidated })
        }

        const handleSetIsValidated = ({ parameter, value }) => {
            setIsValidated(
                produce((draft) => {
                draft[parameter] = value;
                })
            )
        }

        useEffect(() => { ///note: setIsFullValidated
            //ref.current.focus();
            const isFullValidated = generics.isEqualValuesInObject({ object: isValidated, value: true })
            setIsFullValidated(isFullValidated)
        }, [isValidated]);

        const handleGetAuthLink = async({ email, isSignUp, subsc }) => {
            handleSetAuthStates({ parameter: "isLinkSend", value: false }) ///note: to reset messages

            await getAuthLink({ email, isSignUp, subsc })

            handleSetAuthStates({ parameter: "isLinkSend", value: true }) 
        }

        const handleUpdateDoc = useCallback(async() => {
            console.log("email bib")
        }, [])

        const handleSignOut = () => {
            signOut()
            handleSetAuthStates({ parameter: "uid", value: "" }) 
        }

        const handleSetAuthStates = ({ parameter, value }) => {
            setSwrAuth(
                produce((draft) => {
                    draft.data[parameter] = value;
                })
            )
        }

        ///if modal child is profile =>
        useEffect(() => { 
            if (props.modalChild === "profile") {
                handleGetUserData()
            }
            return () => { ///note: clean-up => reset user and userStats at modal close (while not impacting authStates uid)
                handleSetAuthStates({ parameter: "user", value: {} }) 
                handleSetAuthStates({ parameter: "userStats", value: {} })
            }
            console.log()
        }, [props.modalChild]);

        ///xxx =>
        useEffect(() => { 
            if (!generics.isObjectEmpty({ obj: authStates.user })) {
                handlesetSubsc({id: "isAccount", value: authStates.user.subsc.isAccount})
                handlesetSubsc({id: "isEmail", value: authStates.user.subsc.isEmail})
            }
        }, [authStates.user]);


        const handleGetUserData = useCallback(async() => {
            const [user, userStats] = await Promise.all(
                [
                    readDoc({ collection: "users", document: authStates.uid }) , 
                    readDoc({ collection: "usersStats", document: authStates.uid }) ///risk: authStates not read/set at event
                ]
                );
                console.log("user", user, userStats)
                handleSetAuthStates({ parameter: "user", value: user })
                handleSetAuthStates({ parameter: "userStats", value: userStats })
        }, [])

        const handleSetIsUpdated = useCallback((isUpdated) => {
            setIsUpdated(isUpdated)

            let timeout;
            timeout = setTimeout(reset, 3000);
            
            function reset() {
                setIsUpdated(false)
            }
        }, [])
    
        // const ///optimise: at use of memo input field still re-renders at isvalidated change (but no visual effect, so OK)
        //     memoText = useMemo(() => text.current),
        //     memoPlaceholder =  useMemo(() => !text.current ? "your email" : "", [text]),
        //     memoHandleInputChange = useMemo(() => handleInputChange, [handleInputChange])

        const placeholder = !text.current ? "your email" : ""

        let textAuth ///optimise: consider specifying at modalchild definition
        switch(true) {
            case props.modalChild === "signUp":
                textAuth = "Sign Up"
            break;
            case props.modalChild === "signIn":
                textAuth = "Sign In"
            break;
            case props.modalChild === "signOut":
                textAuth = "Sign Out"
            break;
            case props.modalChild === "profile":
                textAuth = generics.equalValuesInObject({ obj: subsc, testValue: false }) ? 
                    "Delete Your Profile (so sad!)" : "Update"
            break;
            default:
        }
        
        let component = <></>, messages = ""
            switch(true) {
                case props.modalChild === "signUp":
                    messages = 
                        ["Dear yogi or yogi to be", "Sign up and all sign in is done via email", "Secure, minimal, easy, with no password needed", "Use link in email from YogaTube.ai to complete sign up and sign in", "Ohh, please notice an email sign in link can only be used once."]
                            //authStates.isNewUser ? ///note: authStates.isNewUser currently only set at signInWithEmail resolved, not getAuthLink resolved.
                            // ["Dear new user, sign up and sign in is done via email - secure and no password needed!", "See email to complete sign up and sign in."] : 
                            // ["you are already a member - nice!", "email for sign in is send"]
                    component =  
                        <div className={`auth-c`}>
                            <AuthHeader
                                modalChild={props.modalChild}
                            />
                            <Checkbox ///optimise: mitigate re-render at isvalidated change, as cause subtext to abruptly hide
                                setup={checkboxData}  
                                parentCallback={handlesetSubsc}
                            />
                            <Input ///optimise: prevent use of enter, space etc.
                                className={`email`}
                                text={text.current}
                                placeholder={placeholder}
                                parentCallback={handleInputChange} 
                                forwardedRef={ref}
                            />
                            <MemoAuthButton 
                                show={true}
                                text={textAuth}
                                disabled={!isFullValidated}
                                parentCallback={() => 
                                    handleGetAuthLink({ 
                                        email: text.current, 
                                        isSignUp: true,
                                        subsc: subsc,
                                    })
                                }
                            />
                            <Messages
                                show={!!authStates.isLinkSend}
                                messages={messages}
                            />
                        </div>
                break;
                case props.modalChild === "signIn":
                    messages = ["Dear yogi", "Email for sign in is send."]
                    component = 
                        <div className={`auth-c`}>
                        <AuthHeader
                            modalChild={props.modalChild}
                        />
                        <Input ///optimise: prevent use of enter, space etc.
                            className={`email`}
                            text={text.current}
                            placeholder={placeholder}
                            parentCallback={handleInputChange} 
                            forwardedRef={ref}
                        />
                        <MemoAuthButton 
                            show={true}
                            text={textAuth}
                            disabled={!isFullValidated}
                            parentCallback={() => 
                                handleGetAuthLink({ 
                                    email: text.current, 
                                    isSignUp: false,
                                    subsc: null ///not used for signin/existing users
                                })
                            }
                        />
                        <Messages
                            show={!!authStates.isLinkSend}
                            messages={messages}
                        />
                    </div>
                break;
                case props.modalChild === "signOut":
                    component = 
                        <div className={`auth-c`}>
                            <AuthHeader
                                modalChild={props.modalChild}
                            />
                            <MemoAuthButton 
                                show={true}
                                text={textAuth}
                                disabled={false}
                                parentCallback={() => handleSignOut()}
                            />
                        </div>
                break;
                case props.modalChild === "profile":
                    console.log("profile", authStates)
                    messages = ["Dear yogi", "Email for sign in is send."]
                    component = !generics.isObjectEmpty({ obj: authStates.user }) ?
                        <div className={`auth-c`}>
                        <AuthHeader
                            modalChild={props.modalChild}
                        />
                        <Checkbox ///optimise: mitigate re-render at isvalidated change, as cause subtext to abruptly hide
                            setup={checkboxData}  
                            parentCallback={handlesetSubsc}
                        />
                        <MemoAuthButton 
                            show={true}
                            text={textAuth}
                            disabled={generics.objectsEqual(subsc, authStates.user?.subsc)}
                            parentCallback={async() => { 
                                const 
                                    { email, uid } = authStates.resultUser,
                                    isDeleteProfile = generics.equalValuesInObject({ obj: subsc, testValue: false })
                                if (!isDeleteProfile) {
                                    await updateDocument({ collection: "users", document: authStates.uid, payload: { subsc: subsc }, isIncrement: false })
                                    await handleGetUserData() 
                                    handleSetIsUpdated(true);
                                    const 
                                        emailSetup = emailSetups.emailSetupSubsc({ template: "subscupdate", email, subsc }),
                                        sendEmail = await addDocument({ collection: "mail", document: authStates.uid, payload: emailSetup })
                                } 
                                if (isDeleteProfile) {
                                    await deleteDocument({ collection: "users", document: authStates.uid })
                                    await deleteProfile()
                                    handleSignOut()
                                    handleSetIsUpdated(true);
                                    let timeout = setTimeout(closeModal, 2000);
                                    function closeModal() {
                                        props.setModalCallback()
                                    }
                        
                                    const 
                                        emailSetup = emailSetups.emailSetupSubsc({ template: "profiledeleted", email, subsc }),
                                        sendEmail = await addDocument({ collection: "mail", document: authStates.uid, payload: emailSetup })
                                }
                            }}
                        />
                        <Messages
                            show={isUpdated}
                            messages={generics.equalValuesInObject({ obj: subsc, testValue: false }) ?
                                ["your profile is now deleted"] : ["your subscriptions is updated"]}
                        />
                        <UserStats
                            show={true}
                            userStats={authStates.userStats}
                        ></UserStats>

                    </div>
                    : 
                    <div className={`auth-c`}>
                        <span>Loading your profile...</span>
                    </div>
                break;
                default:
            }
        return (
            <>{component}</>
        )
}
