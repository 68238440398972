import { memo, forwardRef } from 'react'
import { useDrag, useDragLayer } from 'react-dnd'
// const style = {
//   border: '1px dashed gray',
//   backgroundColor: 'white',
//   padding: '0.5rem 1rem',
//   marginRight: '1.5rem',
//   marginBottom: '1.5rem',
//   cursor: 'move',
//   float: 'left',
// }


export const Box = memo(forwardRef(function Box({ draggedItem, type, isDropped, children }, ref) {
  const [{ opacity }, drag, dragPreview] = useDrag(
    () => ({
      type,
      item: { draggedItem },
      ref: ref,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [draggedItem, type],
  )

  // const { item, } = useDragLayer((monitor) => ({
  //   item: monitor.getItem(),
  // }));

  // const offsetWidth = item?.draggedComponent.offsetWidth

  //console.log("offsetwidth", offsetWidth)


  return (
    <div 
      className={"dnd-box"}
      ref={drag}  
      data-testid="box">
      {/* {isDropped ? <s>{name}</s> : name} */}
      { children } 
    </div>
  )
}))
