import React from 'react';
import '../src/footer.css';

const Footer = (props) => {

	return ( 
		<>
		<div className={`footer-c`}>
			<div className={`footer
				${props.show ? "fade-in" : "fade-out"}
			`}>
				<span className={`founded`}>
					{"2023"}
				</span>
				<a 
					className={`contact`}
					href="mailto:contact@yogatube.ai">contact@yogatube.ai
				</a>
			</div>
		</div>
		</>
	) 
}
        
export default Footer;