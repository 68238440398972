import '../src/pl-gallery.css';
import React, { useEffect, useRef, useState, memo, useContext, useCallback, Fragment, useMemo } from 'react';
import ReactPlayer from 'react-player';
import { useImmer } from "use-immer";
import Header from './Header';
import { focusStatesContext, videosDataContext, channelsDataContext, categoriesDataContext, plsDataContext } from './Home2';
import { Link, useLocation } from "react-router-dom";
import { colorsLight } from './colors';
import { categoriesExtraData, typesCategories, youTubeChannelCategories } from './CategoryGalleryData';
import { badges, popularityBadgeStats,  popularityParameters, popularityBadgeInfo, badgeColors } from './PopularityComponents';
import { MemoPreLoader, GalleryHeadline, Sticky, ButtonWithLogo, Blanket } from './StandardComponents';
import * as generics from './generics';
import { AnimatedComponent } from './AnimatedComponent';
import { ItemText } from './ItemTextReduced';
import { createContext as selectorContext, useContextSelector } from 'use-context-selector';
import * as Scroll from 'react-scroll';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Box } from './Box.js'
import { ItemTypes } from './ItemTypes.js'
import { Attrs } from './FilterBox'
import { initialStateOptions } from './StateOptions';

const autoPlsTypes = ["intro", "highlight", "videolist-pl"] ///note: variable used to list those pls that are autogenered or videolist-pl (with implication for styling)

const bestOf = {
  // "yoga with adriene": [
  //   {"30 days yoga journeys (by date)": ["CENTER - A 30 Day Yoga Journey", "MOVE - A 30 Day Yoga Journey", "BREATH - A 30 Day Yoga Journey", "HOME - A 30 Day Yoga Journey", "DEDICATE - A 30 Day Yoga Journey", "YOGA CAMP - 30 Day Home Practice", "30 Days of Yoga"]},
  //   {"notable playlists (by date)": ["BREATH - A 30 Day Yoga Journey", "HOME - A 30 Day Yoga Journey", "DEDICATE - A 30 Day Yoga Journey", "YOGA CAMP - 30 Day Home Practice", "30 Days of Yoga"]},
  //   {"fuck": ["BREATH - A 30 Day Yoga Journey"]},
  //   {"bib": ["CENTER - A 30 Day Yoga Journey", "MOVE - A 30 Day Yoga Journey"]}
  // ]
}

const excludePls = {
//   "yoga with kassandra": [
//     "Day 7 - Sunday Yoga & Meditation", ///included in other pl
//     "Day 6 - Saturday Yoga & Meditation",
//     "Day 5 - Friday Yoga & Meditation",
//     "Day 4 - Thursday Yoga & Meditation",
//     "Day 3 - Wednesday Yoga & Meditation",
//     "Day 2 - Tuesday Yoga & Meditation",
//     "Day 1 - Monday Yoga & Meditation",

//     "15 min Yin Yoga Stretches – Day #30 (RELAXING YOGA BEFORE BEDTIME)",
//     "15 min Yoga for Sleep – Day #29 (YOGA STRETCHES BEFORE BED)",
//     "15 min Yoga Class – Day #28 (EVENING YOGA STRETCHES FOR BEGINNERS)",
//     "15 min Evening Yoga – Day #27 (YOGA FOR SORE MUSCLES)",
//     "15 min Yoga for Flexibility – Day #26 (BEDTIME YIN YOGA CLASS)",
//     "15 min Before Bed Yoga – Day #25 (FULL BODY RELAXING YOGA)",
//     "15 min Sleep Yoga – Day #24 (WIND DOWN YOGA FLOW)",
//     "15 min Evening Yoga Flow – Day #23 (BEDTIME YOGA FULL BODY)",
//     "15 min Yin Yoga for Sleep – Day #22 (YOGA FOR FLEXIBILITY & RELAXATION)",
//     "15 min Bedtime Yoga – Day #21 (YOGA FOR HIPS & LOWER BACK)",
//     "15 min Evening Yoga Class – Day #20 (RELAXING YOGA STRETCHES BEFORE BED)",
//     "15 min Bedtime Yoga Stretches – Day #19 (EVENING YOGA FLOW)",
//     "15 min Evening Yin Yoga – Day #18 (YOGA STRETCHES BEFORE BED)",
//     "15 min Before Bed Yoga – Day #17 (EVENING YOGA FOR BEGINNERS)",
//     "15 min Yoga for Flexibility – Day #16 (BEDTIME YOGA CLASS)",
//     "15 min Evening Yoga Flow – Day #15 (BEFORE BED YOGA)",
//     "15 min Sleep Yoga – Day #14 (FULL BODY RELAXING YIN YOGA)",
//     "15 min Bedtime Yoga – Day #13 (YOGA FOR SLEEP & RELAXATION)",
//     "15 min Yoga for Sleep – Day #12 (YOGA FOR HIPS & LOWER BACK)",
//     "15 min Yoga Stretches – Day #11 (BEDTIME YOGA FULL BODY)",
//     "15 min Yin Yoga Class – Day #10 (EVENING YOGA FOR BEGINNERS)",
//     "15 min Evening Yoga – Day #9 (WIND DOWN YOGA FLOW)",
//     "15 min Yoga for Flexibility – Day #8 (EVENING YOGA FULL BODY)",
//     "15 min Evening Yoga Stretches – Day #7 (BEDTIME YOGA FOR BEGINNERS)",
//     "15 min Sleep Yoga – Day #6 (YIN YOGA FOR SORE MUSCLES)",
//     "15 min Bedtime Yoga – Day #5 (RELAXING YOGA STRETCHES BEFORE BED)",
//     "15 min Yoga for Sleep – Day #4 (EVENING YOGA FLOW)",
//     "15 min Evening Yoga Class – Day #3 (YOGA STRETCHES BEFORE BED)",
//     "15 min Yin Yoga Stretches – Day #2 (EVENING YOGA FOR BEGINNERS)",
//     "15 min Evening Yoga – Day #1 (YOGA FOR FLEXIBILITY AND RELAXATION)",

//     "10 min FULL BODY Intermediate Morning Yoga – Day #30 (FULL BODY MORNING YOGA FLOW)",
//     "10 min Morning Yoga Stretch To ENERGIZE – Day #29 (10 MIN MORNING STRETCH)",
//     "10 min Morning Yoga To Kick Start Your Day – Day #28 (MORNING YOGA FOR ENERGY)",
//     "10 min Morning Yoga For Your BACK – Day #27 (10 MIN BACK STRETCH YOGA)",
//     "10 min SUNRISE FEEL ALIVE Yoga Flow – Day #26 (FEEL ALIVE MORNING STRETCH)",
//     "10 min Morning Yoga For Shoulder Release – Day #25 (SHOULDER STRENGTH & STRETCH)",
//     "10 min Morning Yoga DEEP Stretch – Day #24 (DEEP FULL BODY STRETCH)",
//     "10 min Morning Yoga Stretches For Tired & Tight Legs - Day #23  (TIRED & TIGHT LEGS YOGA)",
//     "10 min Morning Yoga To Start Your Day Off RIGHT! – Day #22 (10 MIN YOGA STRETCH)",
//     "10 min Full Body Flexibility Yoga To WAKE UP – Day #21 (MORNING YOGA FOR FLEXIBILITY)",
//     "10 min Morning Yoga to Feel Your Best! – Day #20 (10 MIN YOGA STRETCHES)",
//     "10 min Morning Yoga For Hips & Lower Back – Day #19 (HIPS & BACK MORNING YOGA)",
//     "10 min Wake-Up Yoga For FLEXIBILITY – Day #18 (10 MIN MORNING YOGA)",
//     "10 min Morning Yoga For Upper Body STRENGTH – Day #17 (10 MIN ARM YOGA)",
//     "10 min Morning Yoga To Stretch & Soothe For Pain Release – Day #16 (STRETCH & SOOTHE)",
//     "10 min SUNRISE Full Body Yoga – Day #15 (FULL BODY MORNING YOGA)",
//     "10 min Yoga For Lower Back Pain Release - Day #14 (LOWER BACK MORNING YOGA STRETCHES)",
//     "10 min Morning Yoga Flow To DETOX & DESTRESS – Day #13 (DESTRESS YOGA)",
//     "10 min COMPLETE Full Body Yoga Stretch – Day #12 ( FULL BODY MORNING YOGA)",
//     "10 min Morning Yoga to ENERGIZE Your Day– Day #11 (ENERGIZING YOGA STRETCH)",
//     "10 min Morning Yoga For Neck & Upper Back Relief – Day #10 (NECK & BACK YOGA STRETCH)",
//     "10 min BEST Morning Yoga Practice – Day #9 (10 MINUTE WAKE UP STRETCH)",
//     "10 min FULL BODY Stretch Wake Up Yoga – Day #8 (FULL BODY MORNING YOGA)",
//     "10 min Morning Yoga For Anxiety & Stress– Day #7 (ANXIETY & STRESS STRETCH)",
//     "10 min SUNRISE Yoga for Energy – Day #6 (REFRESHING MORNING YOGA STRETCH)",
//     "10 min FULL BODY Flow Morning Yoga Practice – Day #5 (FULL BODY STRETCH)",
//     "10 min Morning Yoga For Neck & Shoulder Relief – Day #4 (NECK & SHOULDER STRETCHES)",
//     "10 min Morning Yoga Stretches – Day #3 (10 MIN FULL BODY YOGA)",
//     "10 min WAKE UP Full Body Yoga Stretch – Day #2 (MORNING YOGA STRETCHES)",
//     "10 min Morning Yoga Stretch For BEGINNERS – Day #1 (10 MIN BEGINNER YOGA)"
// ]
}

export const bundlePlaylistsData = [
  { "yoga with kassandra": [
    { 
      isUploaded: true,
      plTitle: "x",
      videos: [
          [
            "x"
          ]
      ]
    },
  ]
  }
]

const generateIntros = ({ categoryName, categoryNameId, channels, pls, imgFile }) => {

  if (
    //!!pls &&
    //!youTubeChannelCategories.includes(categoryNameId) || ///if categoryNameId not a channel (not within youTubeChannelCategories) 
    !channels.find(channel => channel.staticData["category name id"] === categoryNameId) ||
      pls.find(pl => pl.staticData["category name id"].includes(categoryNameId) && 
      !!pl.staticData.plType === "intro"))  ///if intro for that categoryNameId (channel) is not already added
    { 
      return [] ///return [], i.e. no highlightpl(s)
  }

  let newPls = []

    let newPlVideoCollection = []
    const plTitle = `Intro Video`
    const plId = `${categoryNameId}-${plTitle}`
    // videoUrlArray.map((video, index) => {
      const 
        ///youtubeUrl = "https://youtu.be/",
        channelObj = channels.find(channel_ => channel_.staticData["category name id"] === categoryNameId)
      const newVideo =
        { 
          season: 1, 
          episode: 1, //index + 1, 
          videoUrl: channelObj.dynamicData.startVideoId,
        }
      newPlVideoCollection.push(newVideo)
  // })

  const newPl = [{
    staticData: {
      // ...lastChanged,
      plId: plId.toLowerCase().replace(/\s+/g, '_'),
      channelTitle: categoryName, 
      plTitle: plTitle,
      plPublishedAt: "Today",
      plThumbnailUrl: imgFile,
      plSourceId: null,
      plSource: "youtube",
      plType: "intro",
      ["category name id"]: [categoryNameId],
      template: "pl"
    },
    dynamicData: {	
      // ...lastChanged,
      plDesc: "", 
      plVideoCollection: newPlVideoCollection,
      ///["category name ids"]: [],
      plSortId: [1],
    },
    stats: {
      // ...lastChanged,
    },
    attr: {
      categories: { categories: "intros" }
      // ...lastChanged,
    }
  }]
  newPls.push(...newPl)
  return [...newPls]
}

const generateHighlights = ({ categoryName, categoryNameId, channels, pls, videos, imgFile }) => {
  console.log("pls", pls)
  if (
    //!youTubeChannelCategories.includes(categoryNameId) || ///if categoryNameId not a channel (not within youTubeChannelCategories) 
    //!channels.find(channel => channel.staticData["category name id"] === categoryNameId) ||
    pls.find(pl => pl.staticData["category name id"].includes(categoryNameId) && 
    !!pl.staticData.plType === "highlight"))  ///if intro for that categoryNameId (channel) is not already added
    { 
      return [] ///return [], i.e. no highlightpl(s)
  }

  const arrayUniqueByKey = (array, key) => { ///optimise: move to generic...
    let result = [...new Map(array.map(item =>
      [item["staticData"][key], item])).values()];
    return result
  }
  
  const
    years = ["The Last Year", "all"],
    amount = 20

  let highlightPls = []

  const extraCategory = categoriesExtraData.extraCategory.hasOwnProperty(categoryName) ? categoriesExtraData.extraCategory[categoryName] : []

  //youTubeChannelCategories.map((channel) => {
    years.map((year, index) => {
    const channelVideos = videos
      .filter((video) => 
        video.staticData["category name id"].includes(categoryNameId) ||
        generics.flattenedArray(!!video.attr?.categories ? video.attr.categories : []).includes(categoryNameId) ///note/optimise: consider alternatives to use of flattenedArray and attr? when categories not defined ... ///was:["category name ids"].includes(categoryNameId)
        )
      .filter((video) => {
        switch(true) {
          case year === "all":
            return true
          case year === "The Last Year":
            return new Date(video.staticData.publishedAt) >= new Date(new Date().setFullYear(new Date().getFullYear() - 1)) ///note: by year: new Date(video.publishedAt).getFullYear() === year
          default:
        }
      })
    const mostWatched =  channelVideos
      .sort((a, b) => b.stats.viewCount - a.stats.viewCount)
      .slice(0, amount * 2) ///note: * 2 random higher number in case of ducplicates
    const mostLiked = channelVideos
      .sort((a, b) => b.stats.likeCount - a.stats.likeCount)
      .slice(0, 5)
    const mostCommented =  channelVideos
    .sort((a, b) => b.stats.commentCount - a.stats.commentCount)
    .slice(0, 5)
    const mostCombined = [...mostLiked, ...mostCommented, ...mostWatched]
    const mostCombinedUnique = arrayUniqueByKey(mostCombined, "videoId")
    const mostPopular = [...mostCombinedUnique.slice(0, amount)]
    let newPlVideoCollection = []
    const plTitle = year === "all" ? `Most Popular Videos Of All Time` : `Most Popular Videos Of ${year}`
    const plId = `${categoryName}-${plTitle}`
    mostPopular.map((video, index) => {
      const newVideo =
        { 
          season: 1, 
          episode: index + 1, 
          videoUrl: video.staticData.videoId,
        }
      newPlVideoCollection.push(newVideo)
  })

  const newPl = [{
    staticData: {
      // ...lastChanged,
      plId: plId.toLowerCase().replace(/\s+/g, '_'),
      channelTitle: categoryName, 
      plTitle: plTitle,
      plPublishedAt: "Today",
      plThumbnailUrl: imgFile,
      plSourceId: null,
      plSource: "youtube",
      plType: "highlight",
      ["category name id"]: [categoryNameId],
      template: "pl"
    },
    dynamicData: {	
      // ...lastChanged,
      plDesc: year === "all" ? `${categoryName}'s ${mostPopular.length} most popular videos of all time, including most liked, watched, and commented` : `${categoryName}'s ${amount} most popular videos of ${year}, including most liked, watched, commented`, 
      plVideoCollection: newPlVideoCollection,
      ["category name ids"]: [categoryNameId],
      plSortId: [index],
    },
    stats: {
      // ...lastChanged,
    },
    attr: {
      // ...lastChanged,
    }
  }]
  if (!!newPlVideoCollection) { ///note: only add pl if videos exist
    highlightPls.push(...newPl)
  }
})
    const channelVideos = arrayUniqueByKey(videos, "videoId")
      .filter((video) => 
        video.staticData["category name id"].includes(categoryNameId) ||
        generics.flattenedArray(!!video.attr?.categories ? video.attr.categories : []).includes(categoryNameId) ///video.dynamicData["category name ids"].includes(categoryNameId)
        )
      .sort((a, b) => new Date(b.staticData.publishedAt) - new Date(a.staticData.publishedAt))
   
    let newPlVideoCollection = []
    const plTitle = "All Videos"
    const plId = `${categoryName}-${plTitle}`
    channelVideos.map((video, index) => {
      const newVideo =
        { 
          season: 1, 
          episode: index + 1, 
          videoUrl: video.staticData.videoId,
        }
      newPlVideoCollection.push(newVideo)
  })

  const newPl = [{
    staticData: {
      // ...lastChanged,
      plId: plId.toLowerCase().replace(/\s+/g, '_'), 
      channelTitle: categoryName, 
      plTitle: plTitle,
      plPublishedAt: "Today",
      plThumbnailUrl: imgFile,
      plSourceId: null,
      plSource: "youtube",
      plType: "highlight",
      ["category name id"]: [categoryNameId],
      template: "pl"
    },
    dynamicData: {	
      // ...lastChanged,
      plDesc: `All Videos`, 
      plVideoCollection: newPlVideoCollection,
      ["category name ids"]: [],
      plSortId: [2],
    },
    stats: {
      // ...lastChanged,
    },
    attr: {
      // ...lastChanged,
    }
  }]
  if (!!newPlVideoCollection) { ///note: only add pl if videos exist
    highlightPls.splice(1, 0, ...newPl); ///push into position 1
  }
  return [...highlightPls]
}

const generateVideoPls = ({ id, categoryName, categoryNameId, plFocusStates, videos }) => {
  if (!videos) return []

  //console.log("videosmostview", videos.sort((a,b) => new Date (b.stats.viewCount) - new Date (a.stats.viewCount))[0])

  const getVideosFilter = ({ id, video }) => {
    switch(true) {
      case id === "pl-gallery-category":
        return (
          video.staticData["category name id"].includes(categoryNameId) ||
          generics.flattenedArray(!!video.attr?.categories ? video.attr.categories : []).includes(categoryNameId) ///video.dynamicData["category name ids"].includes(categoryNameId)
        )
      break;
      case id === "pl-gallery-user-videos":
        return (
          video.staticData["uploaderType"] === "user"
        )
      case id === "pl-gallery-newest":
        return true
      break;
      default:
        return true
    }
  }

  const getVideoSlice = ({ id }) => {
    switch(true) {
      case id === "pl-gallery-category":
        return 5 ///note: temp value
      break;
      case id === "pl-gallery-newest":
        return 8 ///note/optimise: coordinate with plStatesProvider
      break;
      default:
        return 1000
    }
  }

  const getVideosSort = ({ a, b }) => {
    switch(true) {
      case id === "video-list":
        return 
      break;
      default:
       return new Date (b.staticData.publishedAt) - new Date (a.staticData.publishedAt)
    }
  }

   let newPls = []

  let videos_ = videos
 
   //const extraCategory = categoriesExtraData.extraCategory.hasOwnProperty(channel) ? categoriesExtraData.extraCategory[channel] : []
  if (id === "video-list") {
    videos_ = plFocusStates.elementFocus.dynamicData.plVideoCollection.map((video, i) =>
      videos.find(video_ => video_.staticData.videoId === video.videoUrl)
    )
  }

  console.log("videos_", id, videos_)

  videos_
    .filter(video => video !== undefined)
    .filter(video => getVideosFilter({ id, video }))
    .sort((a,b) => getVideosSort({ a, b }))
    .slice(0, getVideoSlice({ id }))
    .map((video, i) => {
  
     let newPlVideoCollection = []
     const plTitle = video.staticData.title
     const plId = `${video.staticData["category name id"]}-${plTitle}`

       const newVideo =
         { 
           season: 1, 
           episode: id === "video-list" ? i + 1 : 1, 
           videoUrl: video.staticData.videoId, ///was: videoUrl
         }
       newPlVideoCollection.push(newVideo)

   const newPl = {
    staticData: {
      // ...lastChanged,
      plId: plId.toLowerCase().replace(/\s+/g, '_'), 
      channelTitle: categoryName, 
      plTitle: plTitle,
      plPublishedAt: video.staticData.publishedAt,
      plThumbnailUrl: video.staticData.thumbnailUrl,
      plSourceId: null,
      plSource: "youtube",
      plType: "video",
      "category name id": video.staticData["category name id"],
      template: "pl"
    },
    dynamicData: {	
      // ...lastChanged,
      plDesc: video.staticData.description, 
      plVideoCollection: newPlVideoCollection,
      // "category name ids": video.dynamicData["category name ids"],
      plSortId: [i],
    },
    stats: {
      // ...lastChanged,
    },
    attr: {
      ...video.attr
    }
  }
   newPls.push(newPl)
  })

   return [...newPls]
}

const getPlsMap = ({ id, categoryFocusStates, plFocusStates, categories, channels, pls, videos }) => {

  if (!pls) {
    return
  }

  let 
    plsMap = [], 
    videoPls = [],
    imgFile = categoryFocusStates?.elementFocus?.media?.[0].url, ///alternative: categories.find(category => category["category name id"] === categoryFocusStates.idFocus)?.media[0].url
    categoryName = categoryFocusStates?.elementFocus?.["category name"],
    categoryNameId = categoryFocusStates?.elementFocus?.["category name id"]
   
  switch (true) {

    case id === "pl-gallery-invitation":
      const video = [
        {
          "staticData": {
              "channelTitle": "yoga with adriene",
              "privacyStatus": "public",
              "publishedAt": "2013-11-18T03:56:16Z",
              "videoUrl": "https://youtu.be/v7AYKMP6rOE",
              "minutesDuration": 23.75,
              "thumbnailUrl": "https://i.ytimg.com/vi/v7AYKMP6rOE/hqdefault.jpg",
              "embeddable": true,
              "description": "Yoga for Complete Beginners! Adriene welcomes all levels - complete and total beginners to start here! Hop on the mat and start to build the foundation of your own yoga practice with this 20 minute home workout video! Enjoy, stay mindful and find what feels good. \n\n#yogaforbeginners  #freeyogavideos #yogawithadriene\n\n- - - - - - - - - - \n\n❤️ WELCOME to the Yoga With Adriene YouTube channel! Our mission is to connect as many people as possible through high-quality free yoga videos. We welcome all levels, all bodies, all genders, all souls! SUBSCRIBE  to the channel and join our global movement! ❤️\nhttps://www.youtube.com/user/yogawithadriene?sub_confirmation=1\n\n- - - - - - - - - - \n\nYoga With Adriene, LLC recommends that you consult your physician regarding the applicability of any recommendations and follow all safety instructions before beginning any exercise program. When participating in any exercise or exercise program, there is the possibility of physical injury. If you engage in this exercise or exercise program, you agree that you do so at your own risk, are voluntarily participating in these activities, and assume all risk of injury to yourself.",
              "videoId": "v7AYKMP6rOE",
              "madeForKids": false,
              "lastChanged": {
                  "seconds": 1688562883,
                  "nanoseconds": 369000000
              },
              "template": "video",
              "title": "Yoga For Complete Beginners - 20 Minute Home Yoga Workout!",
              "category name id": [
                  "yoga_with_adriene"
              ],
              "iso8601Duration": "PT23M45S"
          },
          "stats": {
              "commentCount": 17267,
              "viewCount": 51033185,
              "favoriteCount": 0,
              "likeCount": 745079,
              "lastchanged": {
                  "seconds": 1688562885,
                  "nanoseconds": 538000000
              }
          },
          "dynamicData": {
              "category name ids": [],
              "lastChanged": {
                  "seconds": 1688562883,
                  "nanoseconds": 369000000
              }
          },
          "attr": {
              "lastChanged": {
                  "seconds": 1688562883,
                  "nanoseconds": 369000000
              }
          }
      }
      ]
      videoPls = generateVideoPls({ id, categoryName: "yoga with adriene", categoryNameId: "yoga_with_adriene", videos: video })
      plsMap = [
        ...[{ staticData: { plType: "introduction-header", position: 0, 
          plTitle: "AND BEFORE YOU GO", 
          plSubtitle: "Yogi, have you seen this video before? It has 51 million views, like if every person in the whole of South Korea saw it. It's by popular Yoga With Adriene, from 2013, and the most watched yoga video in history! And clearly a great video for you to try out. You´re in great company now!"}}],
        ...videoPls, 
      ]
      break
    case id === "pl-gallery-category":
      plsMap = 
        categoryFocusStates.isFocus ? 
        pls.filter(pl => 
          pl.staticData["category name id"].includes(categoryFocusStates.idFocus) ||
          generics.flattenedArray(!!pl.attr?.categories ? pl.attr.categories : []).includes(categoryFocusStates.idFocus)) : ///pl.dynamicData["category name ids"].includes(categoryFocusStates.idFocus)) :
        [] ///note: not mapped at no choice of category. alternatively: [...pls]
   

      //if (plsMap.length === 0) { return []} ///note: to mitigate error at sort if plsMap is empty array

      plsMap.sort((a,b) => new Date (b.staticData.plPublishedAt) - new Date (a.staticData.plPublishedAt))
    
      //   if (plsFromBundle.length > 0) {
      //   const x = currentPls_.splice(5, 0, ...plsFromBundle[0])
      // }
    
      plsMap.filter(( pl ) => excludePls.hasOwnProperty(categoryFocusStates.idFocus) ? ///exclude specified pls
        !excludePls[categoryFocusStates.idFocus].includes(pl.staticData.plTitle) :
        true)
    
      const 
        introPls = generateIntros({ categoryName, categoryNameId, channels, pls, imgFile }),
        channel = channels.find(channel_ => channel_.staticData["category name id"] === categoryNameId),
        background = categories.find(category => category["category name id"] === categoryNameId)?.background?.[0].background ///optimise: multioption save: "string vs. object" in array 
      const infoBox = [ { staticData: {
        plType: "infoBox",
        value: generics.isObject({ obj: channel }) ?  ///note: test if object, including not undefinedo or null
                <ItemText 
                  background={background}
                  data={{ 
                    template: "infobox",
                    "document": "6SDd5IoDObxe8o8bXUsK", 
                    "stats": [
                      {channel: <a target="_blank" href={`https://www.youtube.com/${channel.staticData.customUrl}`}>{channel.staticData.customUrl} by {channel.staticData.title}</a>},
                      {"created": generics.formatDate(new Date(channel.staticData.publishedAt))},
                      {subscribers: generics.getNumeral(channel.stats.subscriberCount)}, 
                      {"numbers of videos": channel.stats.videoCount},
                      {"numbers of views": generics.getNumeral(channel.stats.viewCount)},
                      {"numbers of pls": plsMap.length},
                    ], 
                    "header": "info box", 
                    description: channel.dynamicData.description, 
                    highlights: ["one", "two", "two"]}} ///channel.description
                /> : null
        }}]
    
      const highlightPls = generateHighlights({ categoryName, categoryNameId, channels, pls, videos, imgFile })
    
      videoPls = generateVideoPls({ id, categoryName, categoryNameId, videos })
    
      plsMap = [...introPls, ...infoBox, ...highlightPls, ...plsMap, ...videoPls]
    
      const 
        lengthInfos = introPls.length,
        lengthInfoBox = 1,
        lengthHighlights = highlightPls.length, /// plsMap.filter((pl) => pl.hasOwnProperty("plType") && pl.plType === "highlight").length //number of highlight pls
        extraPlayListTaglines = bestOf.hasOwnProperty(categoryFocusStates.idFocus) ? 
          bestOf[categoryFocusStates.idFocus].map((playListTaglines => Object.values(playListTaglines))).flat().flat() :
          [],
        extraPlaylist = extraPlayListTaglines.map(title => plsMap.find(pl => pl.staticData.plTitle === title)) //plsMap.filter((pl => extraPlayListTaglines.includes(pl.plTitle))) //|| {}).execs || [] ///note || ... to return [] if find return undefined (if extra pl is specified, but pl currently not present in pls at backend)
      plsMap.splice(lengthInfos + lengthHighlights + 1, 0, ...extraPlaylist)
    
      const 
        fixedHeaders = [
          { staticData: { plType: "header", position: 0, plTitle: "intro" }},
          { staticData: { plType: "header", position: lengthInfos + lengthInfoBox + 1, plTitle: "highlights" }},
          { staticData: { 
              plType: "header", 
              position: bestOf.hasOwnProperty(categoryFocusStates.idFocus) ? ///if extra header defined for current category (channel)
              lengthInfos + lengthInfoBox + lengthHighlights + 1 + 1 + ///question: why the extra +1?
                bestOf[categoryFocusStates.idFocus].reduce((acc, obj) => acc + Object.values(obj).flat().length, 0) + ///number of total playlists in extra headers
                bestOf[categoryFocusStates.idFocus].length : ///add number of extra headers
                lengthInfos + lengthInfoBox + lengthHighlights + 1 + 1, ///if no extra headers
              plTitle: `all playlists from youtube (by latest updated)` }}
        ],
        extraHeaders = bestOf.hasOwnProperty(categoryFocusStates.idFocus) ? 
          bestOf[categoryFocusStates.idFocus].map((headerObj, index) => {
            ///optimise: consider checking number of pls for each extra header and only map if pls found 
            return {
              staticData: { 
                plType: "header",
                position: 
                lengthInfos + lengthInfoBox + lengthHighlights + 1 +
                  bestOf[categoryFocusStates.idFocus].slice(0, index).reduce((acc, obj) => acc + Object.values(obj).flat().length, 0) + ///number of total playlists in extra headers, prior to current header (index)
                  bestOf[categoryFocusStates.idFocus].slice(0, index).length, ///add number of extra headers, prior to current header (index)
                plTitle: Object.keys(headerObj)[0]
                }
              }
            })
          : []
    
      let headers = [...fixedHeaders]
      headers.splice(1, 0, ...extraHeaders)
    
    
      if (!generics.arrayEmpty(videoPls)) {
        const header = {
          staticData: { 
            plType: "header",
            position: 
              plsMap.map(pl => pl.staticData.plType).indexOf('video') ///find first instance of plType video
              + headers.length, ///add the number of headers (before addition of said header)
            plTitle: "videos"
            }
          }
        headers.push(header)
      }

      headers.map((header) => plsMap.splice(header.staticData.position, 0, header)) ///note: insert header

      break;
      case id === "video-list":
        videoPls = generateVideoPls({ id, categoryName, categoryNameId, plFocusStates, videos })
        plsMap = [
          // ...[{ staticData: { plType: "header", position: 0, plTitle: "videos added by users" }}], 
          ...videoPls
        ]
        return plsMap
        break
    case id === "pl-gallery-user-videos":
      videoPls = generateVideoPls({ id, categoryName, categoryNameId, videos })
      plsMap = [
        ...[{ staticData: { plType: "header", position: 0, plTitle: "videos added by users" }}], 
        ...videoPls
      ]
      return plsMap
      break
    case id === "pl-gallery-newest": 
  
      plsMap = 
        [...pls]
          .sort((a,b) => new Date (b.staticData.plPublishedAt) - new Date (a.staticData.plPublishedAt))
          .slice(0, 9)
          ///.map(pl => { return {...pl, id: "pl-gallery-newest"} })
        
      videoPls = generateVideoPls({ id, categoryName, categoryNameId, videos })
      plsMap = [
        ...[{ staticData: { plType: "header", position: 0, plTitle: "newest playlists" }}],
        ...plsMap, 
        ...[{ staticData: { plType: "header", position: 0, plTitle: "newest videos" }}], 
        ...videoPls
      ]
    break;
    default: 
  };
  return plsMap
}

const videoElementFromUrl = ({ pathname, plsMap }) => {
  const 
    plId = pathname.split("/")[1],
    season = Number(pathname.split("/")[2].split("_")[0]), ///optimise: clean...
    episode = Number(pathname.split("/")[2].split("_")[1]),
    plElement = plsMap.find(pl => pl.staticData.plId === plId),
    videoElement = !!plElement ? ///optimise: syntax of computing videoelement, dependent on plelement
      plElement.dynamicData.plVideoCollection.find(video => video.season === season && video.episode === episode) :
      null,
    isExist = !!videoElement

    return [plElement, videoElement, isExist]
}


const ReactPlayerComponent = ( props ) => {

if (!props.isRender) {
  return <></>
}

return ( /// ${props.videoFocusStates.actionFocus !== "select" ? "no-pointer-events" : "" }
  <div className={`player-w`}>
    <ReactPlayer
      className={`react-player main-player`}
      url={props.isGallery ? 
        `https://youtu.be/${props.videoFocusStates.idFocus}` :
        `https://youtu.be/${props.videoLocalFocusStates.idFocus}`
        }
      light={false}  
      playing={props.videoLocalFocusStates.actionFocus === "load" || props.videoLocalFocusStates.actionFocus === "play"}
      muted={props.isMuted ? false : true} //{videoChosenX ? false : true}
      
      // volume={0 to 1}
      stopOnUnmount={false}
      //pip={videoChosenX ? true : false}
      onPlay={(e) => {
        const 
          mediaTypeFocus = "video",
          actionFocus = "play",
          elementFocus = props.videoLocalFocusStates.elementFocus, ///note: set af existing value (=> no change)
          idFocus = props.videoLocalFocusStates.idFocus, ///note: set as existing value (=> no change)
          isFocus = props.videoLocalFocusStates.isFocus ///note: set as existing value (=> no change)
        props.setFocusStatesCb({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
      }}
      controls={true} 
      //onEnded={() => props.onVideoEnded()}
    />
  </div>
  )
}
const MemoReactPlayerComponent = memo(ReactPlayerComponent)

const PlDescription = ( props ) => 
    true ? ///props.isRender
      <div className={`pl-description-c`}
        onClick={() => props.setIsShowToggle(false)}
      >
        <span className={`pl-description`}>
          {props.description}
        </span> 
      </div>
      : null
const MemoPlDescription = memo(PlDescription)

const HighlightInfo = ( props ) => 
    props.isRender ?
      <div className={`highlight-info-c`}>
        <span className={`highlight-info-title`}>
          {props.title}
        </span> 
      </div>
      : null

const MemoHighlightInfo = memo(HighlightInfo)

const PlOverlay = ( props ) => 
	<div className={`pl-overlay`} 
	> 
		<span className={`pl-published`}
			style={{background: "white"}} ///props.background
		>
		{props.published}
		</span>
    <span className={`pl-x`}
			style={{background: "white"}} ///props.background
		>
		{"x"}
		</span>
    {props.plType !== "video" ?
		<span
      className={`pl-length`}
			style={{background: "white"}} ///props.background
     >{props.videosLength} <i className={`fas size-a fa-brands fa-youtube`}></i>
     </span> : <span/> 
     }
		<span	
			style={{background: "white"}} ///props.background
		>{"hi"}</span>
	</div>
const MemoPlOverlay = memo(PlOverlay)

const PlImg = ( props ) => { ///${props.isShow ? "fade-out" : "fade-in"}
	
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
      setLoaded(true);
    };
  
  const img = props.default ? 
    <img 
    onLoad={handleImageLoad}
      draggable={false}
      className={`
        pl-img
        `}
      style={{background: props.background}}
      src={props.pl.staticData.plThumbnailUrl} ///todo: implement defaultpreview...
      id={`pl-img-id-${props.pl.staticData.plId}`}
      alt={props.pl.staticData.plTitle}
    ></img> :
    <div className={"element-img-c"}>
      <div className={`yinyang-c`}>
        <div className={"yinyang"}>
        </div>
        <div className={"yinyang-shape"}>
          <img 
            onLoad={handleImageLoad}
            draggable={false}
            className={`
              element-img
              ${loaded ? "fade-in" : "fade-out"}
              `}
            style={{background: props.background}}
            src={props.pl.staticData.plThumbnailUrl} ///todo: implement defaultpreview...
            id={`pl-img-id-${props.pl.staticData.plId}`}
            alt={props.pl.staticData.plTitle}
          ></img>
        </div>
      </div>
    </div>

  return (
  <div className={`
    pl-img-c
    ${props.className} 
	` } ///note: props.className added via childrenWrapper
		>
    { props.isRender ? 
		img : null }
	</div>
    )
}
const MemoPlImg = memo(PlImg)

const WithLink = ( props ) => 
	<Link 
		to={props.to}
		className={"pl-link"}
	>
		{ props.children }
	</Link>
export const MemoWithLink = memo(WithLink)

const ChildrenWrapper = ( props ) =>  ///optimise: consider to combine with children wrapper in home ...
	props.children.map((child, i) => {
		const 
      fade = props.isShow[child.props.id] ? "fade-in" : "fade-out",
      blur = props.hasOwnProperty("isBlur") && props.isBlur[child.props.id] ? "blur" : "",
      top = child.props.id === "link" ? "top" : "", ///optimise: ensure link is on top without this condition here...
			className = `${fade} ${blur} ${top}`

		return (
			<div ///note: implemented initally fragment and use of "childClone = React.cloneElement(child, {className: className})", but the clone alters child, thus causes re-render
        className={`
          child-wrapper
          child-wrapper-${child.props.id}
          ${className}
        `}
        style={{zIndex: `${150-i}`}} ///note: stack first div on top, and so forth
        key={child.props.id}>	
				{ child } 
			</div> ///note: i as key ok, as list is permanent after first mount/render (not changed)
		)
	})
const MemoChildrenWrapper = memo(ChildrenWrapper)

const PlMedia = ( props ) => {

    const 
      isAutoPl = autoPlsTypes.includes(props.pl.staticData.plType),
      date = new Date(props.pl.staticData.plPublishedAt),
      published = props.pl.staticData.plPublishedAt === "Today" ? 
        "Today" : 
        `${generics.monthNames[date.getMonth()]} ${date.getFullYear()}`,
      plCategory = 1, //categoriesExtraData.alias.hasOwnProperty(pl.category[0]) ?
        // categoriesExtraData.alias[pl.category[0]] : 
        // pl.category[0],
      memoIsShow = useMemo(() => props.isShow),
      memoVideoFocusStates = useMemo(() => props.videoFocusStates),
      memoVideoLocalFocusStates = useMemo(() => props.videoLocalFocusStates),
      memoSetFocusStatesCb = useMemo(() => props.setFocusStatesCb)

	return (
    <>
    {props.isShow.pl ? 
    <div className={`
      pl-backlight
      ${props.isShow.pl ? "fade-in" : "fade-out"}
    `}></div>
      : null } 
		<div className={`
      pl-media
      ${props.isShow.pl ? "pl-selected--true" : "pl-selected--false"}
      `}
      style={{background: props.background}}
			onClick={(e) => {
        if (props.isEnableInteraction) {
          const 
            mediaTypeFocus = "pl",
            actionFocus = "select",
            elementFocus = props.pl,
            idFocus = props.pl.staticData.plId,
            isFocus = true
          props.setFocusStatesCb({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
          //props.focusProvider.setFocusObjsCb({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
        }
      }} //props.onPlClick(pl.staticData.plId, 1, 1, i)} // i was passed, but dont seems nesseary ... //props.onPlClick(e, i, pl)} //pl.staticData.plId, pl.title, pl.season, pl.episode, pl.episodeplTitle, pl.category, pl.episodeDescription, pl.Description
			onMouseOver={() => { ///optimse: consider to specify parameters below only once...
				if (props.isEnableInteraction) {
          const 
            mediaTypeFocus = "pl",
            actionFocus = "enter",
            elementFocus = props.pl,
            idFocus = props.pl.staticData.plId,
            isFocus = false
          props.setFocusStatesCb({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
        }
      }}
			onMouseLeave={() => { ///optimse: consider to specify parameters below only once...
				const 
					mediaTypeFocus = "pl",
					actionFocus = "leave",
					elementFocus = props.pl,
					idFocus = props.pl.staticData.plId,
					isFocus = false
				props.setFocusStatesCb({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
			}}
		>
				<MemoChildrenWrapper
          isShow={memoIsShow}
          isBlur={props.isBlur}
        >
          <MemoPlDescription
            id={"description"}
            description={props.pl.dynamicData.plDesc}
            setIsShowToggle={props.setIsShowToggle}
          >
          </MemoPlDescription>
          <MemoWithLink 
            id={"link"}
            to={props.to}
          ></MemoWithLink>
          <MemoHighlightInfo
            id={"title"}
            title={props.pl.staticData.plTitle}
            isRender={props.isRender.title}
            isBlur={props.isBlur.title}
          >
          </MemoHighlightInfo>
          <MemoPlOverlay
            id={"overlay"}
            plType={props.pl.staticData.plType}
            videosLength={props.pl.dynamicData.plVideoCollection.length} ///x-listing
            //background={props.background} 
            published={published} 
            // imgFile={props.imgFile} 
            />
          <MemoPreLoader 
            id={"preLoader"} />
          <MemoPlImg 
            id={"img"}
            isRender={props.isRender.img}
            isBlur={props.isBlur.img}
            default={!isAutoPl}
            pl={props.pl} 
            background={props.background}/> 
          <MemoReactPlayerComponent 
            isGallery={props.isGallery}
            id={"player"}
            isRender={props.isRender.player}
            isMuted={props.isMuted}
            videoFocusStates={memoVideoFocusStates}
            videoLocalFocusStates={memoVideoLocalFocusStates} 
            setFocusStatesCb={memoSetFocusStatesCb}/>
        </MemoChildrenWrapper>
		</div>
    </>
	)
}

const PlBar = ( props ) => {
  if (!props.video) {
    return <></>
  }
  const { favoriteCount, commentCount, likeCount, viewCount } = props.video.stats
  const pularityBadges = popularityBadgeStats({stats: [likeCount, viewCount, commentCount]})
  return (
  <>
      <div className={`pl-channelimg-c`}
        style={{background: props.background}} 
      >
        <img className={`pl-channelimg`}
          src={props.imgFile}
          draggable={false}
          >
        </img>
      </div>
    	<span className={`pl-header-title`}>
      {props.isRender.title ?
			  props.pl.staticData.plTitle : null
      }
      <span className={`pl-header-category`}>
      {props.isShow.channelTitle ?
			  ` By ${props.pl.staticData.channelTitle}` : null
      }
      </span>
		</span>
    {props.isShow.channelTitle ?
    pularityBadges :
    <p></p>
    }
    { props.isRender.infoIcon ?
      <i className={`fa fa-info`}
        aria-hidden="true"
        onClick={() => props.setIsShowToggle(!props.isShowToggle)}
      ></i> : null
    }
    </>
  )
}
const MemoPlBar = memo(PlBar)

const PlInfo = ( props ) => {

  if (!props.video) {
    return <></>
  }

  const { channelTitle, duration, embeddable, madeForKids, privacyStatus, publishedAt, plThumbnailUrl, title, videoId, videoUrl } = props.video.staticData 
  
  let description = "bib", plDescription

  switch(true) {
    case props.videoIsFocus:
    ({ description } = props.video.staticData);
    break;
    case !props.videoIsFocus:
      let { plDesc} = props.pl.dynamicData;
      description = plDesc
    break;
    default:
  }

  console.log("description", props.videoIsFocus)

    return (
      <div className={
        `pl-info
        ${props.isShowToggle ? "fade-in" : "fade-out"}
      `}>
        <p className={"pl-video-description"}>{description}</p>
        <Attrs identifier={videoId} template={"video"} item={props.video}/>
      </div>
    )
}
const MemoPlInfo = memo(PlInfo)

const PlFooter = ({ isShowToggle, setIsShowToggle, children } ) => {

  return (
    <div className={"pl-footer"}
    > { children(isShowToggle, setIsShowToggle) }
    </div>
  )
}
const MemoPlFooter = memo(PlFooter)

export const PlComponent = ({ id, isGallery, pl, imgFile, background, videos, isMuted }) => {

  ///ref
  const plRef = useRef()

  ///from context
  const
    categoryFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "category")),
    plFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "pl")),
    videoFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "video")),
    dlFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list")),
    dlcFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list-creator")),
    setFocusStates = useContextSelector(focusStatesContext, v => v.setFocusStates)

  ///state
  const 
    focusInitialStates = [
      {
          mediaTypeFocus: "pl",
          actionFocus: "",
          elementFocus: {},
          idFocus: "",
          isFocus: false, ///note: isFocus is (currently) true when pl selected, independent of if video is selected in video selector
      },
      {
          mediaTypeFocus: "video",
          actionFocus: "",
          elementFocus: {},
          idFocus: "",
          isFocus: false, ///note: pl select => elementFocus set, (but) isFocus false, video select (in video selector) => isFocus true (implication for pl-info, like description...)
      }
      ],
    [localFocusStates, setLocalFocusStates] = useImmer(focusInitialStates),
    plLocalFocusStates = useMemo(() => generics.getFocusObj({ focusStates: localFocusStates, mediaType: "pl" })),
    videoLocalFocusStates = useMemo(() => generics.getFocusObj({ focusStates: localFocusStates, mediaType: "video" })),
    [isShowToggle, setIsShowToggle] = useState(false),
    plAnimationInitialState = {left: 0, top: 0, isAnimate: false, isNormalDirection: true},
	  [plAnimation, setPlAnimation] = useState(plAnimationInitialState)
    //plAnimationResetState = {left: plAnimation.left, top: plAnimation.top, isAnimate: true, isNormalDirection: false}

    useEffect(() => { ///pl entered or left (via change pl local states) => set video local states
        if (!plLocalFocusStates.actionFocus) { return } ///note: aid in correct play of pls

        if (!isGallery) {
          if (
            plLocalFocusStates.elementFocus.dynamicData.plVideoCollection[0].videoUrl === videoFocusStates.elementFocus.videoUrl ///note: if hovered pl ("video pl") in video-list is already set at focusStates (i.e. video playing globally) => abort set locally (i.e. abort play locally on hover). drawback: localVideoStates not set at hover of season 1 episode 1, when videoStates.isFocous false
            /// && videoFocusStates.isFocus ///note: alternative to ensure set of videoStates of hover of season 1 episode 1...
            ) {
            return
          }
        }

        if (plLocalFocusStates.actionFocus === "enter" || plLocalFocusStates.actionFocus === "leave") {
        
          let actionFocus, isFocus
          switch(true) {
            case plLocalFocusStates.actionFocus === "enter":
              actionFocus = "load"
              isFocus = false;
            break
            case plLocalFocusStates.actionFocus === "leave":
              actionFocus = "pause"
              isFocus = false;
            break;
            default:
          }

          const 
            mediaTypeFocus = "video",
            elementFocus = plLocalFocusStates.elementFocus.dynamicData.plVideoCollection[0], ///optimse: or perhaps better, instead of plVideoCollection[0] use filter season 1 episode 1? 
            idFocus = elementFocus?.videoUrl ///note: ? to mitigate error at no video in category
            handleSetLocalFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
        }
    }, [plLocalFocusStates.actionFocus]); 

	// useEffect(() => { ///direct navigation
	// 	if (!!plFocusStates.idFocus && plFocusStates.idFocus === pl.staticData.plId) {
	// 		if (document.referrer === "") { ///note: direct navigation
	// 		const 
	// 			pathname = location.pathname,
	// 			pathLength = pathname.split("/").filter(Boolean).length
	// 		if (
	// 			!categoryFocusStates.isFocus && pathLength === 2 || ///was: 2
	// 			categoryFocusStates.isFocus && pathLength === 3
	// 			) {
	// 			let { mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus } = plFocusStates
	// 			handleSetLocalFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
	// 				mediaTypeFocus = "video"
	// 				actionFocus = "play"
	// 				elementFocus = videoFocusStates
	// 				idFocus = videoFocusStates.videoUrl
	// 				isFocus = true
	// 			handleSetLocalFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
	// 		}
	// 		}
	// 	}
	// }, [plFocusStates.idFocus]); ///note

    useEffect(() => { ///video load (or pause, causing no sideeffects) (via change in video local states) => set video states
      // if (!plLocalFocusStates.actionFocus) { return }

      if (!isGallery) { ///if !isGallery (fx is video-list) => abort set of (global) states (in effect, at hover, only play video locally)
        return
      }

      if (videoLocalFocusStates.actionFocus === "load" || videoLocalFocusStates.actionFocus === "pause") {
        const 
            mediaTypeFocus = "video",
            actionFocus = videoLocalFocusStates.actionFocus,
            elementFocus = plLocalFocusStates.elementFocus.dynamicData.plVideoCollection[0], ///optimse: or perhaps better, instead of plVideoCollection[0] use filter season 1 episode 1? 
            idFocus = elementFocus?.videoUrl, ///note: ? to mitigate error at no video in category
            isFocus = false
        handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
      }
    }, [videoLocalFocusStates.actionFocus]); 

    useEffect(() => { ///direct load => set local states (=> useEffect for pl actionFocus "select" ...)
      if (plFocusStates.isFocus) {
          if (plFocusStates.idFocus === pl.staticData.plId) {
            if (!plLocalFocusStates.isFocus) {
              handleSetLocalFocusStates( plFocusStates )
              handleSetLocalFocusStates({ ...videoFocusStates, actionFocus: "play" })
            }
          }
      }
    }, [plFocusStates.isFocus])

    useEffect(() => { ///pl selected (via change in pl local states) => set pl animation + pl states + video states (to first video in pl)
      // if (!plLocalFocusStates.actionFocus) { return }

      if (plLocalFocusStates.actionFocus === "select") {
        //plRef.current.style.transformOrigin = 'initial';

        let { mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus } = plLocalFocusStates
   
        if (!id.includes("video-list")) { ///note: if !isGallery => omit set of pl and animation
            const 
              plAnimation = {
                left: plRef.current.getBoundingClientRect().left, 
                top: plRef.current.getBoundingClientRect().top,
                isAnimate: true,
                isNormalDirection: true,
              }
          setPlAnimation(plAnimation)

          //generics.scrollTo({ name: name, containerId: "", offsetY: "0"})

          handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })

          const showFooter = () => {
            setIsShowToggle(true)
            stopFunction()
          }
  
          function stopFunction() {
            clearTimeout(myTimeout);
          }
  
          const myTimeout = setTimeout(showFooter, 1000);
        }

        //if (!videoFocusStates.isFocus) { ///note: criteria !videoFocusStates.isFocus used in case of direct load (state already set)
          // mediaTypeFocus = "video"
          // elementFocus = plLocalFocusStates.elementFocus.dynamicData.plVideoCollection[0] ///optimse: or perhaps better, instead of plVideoCollection[0] use filter season 1 episode 1? 
          // idFocus = elementFocus.videoUrl
          // isFocus = isGallery ? false : true
          // handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
        //}

        if (isMobile) { ///note: if mobile, actionFocus "load" not set via hover => thus set onClick
          handleSetLocalFocusStates({ mediaTypeFocus, actionFocus: "load", elementFocus, idFocus, isFocus })
         }

        if (!isGallery) { ///if !isGallery (fx video-list) => reset local video states (in effect, stop play of video locally)
          setLocalFocusStates( focusInitialStates ) 
          //handleSetLocalFocusStates({ mediaTypeFocus: "video", ...focusInitialStates.find(focus => focus.mediaTypeFocus === "video") }) 
        }
      }

    }, [plLocalFocusStates.actionFocus]); ///note

    useEffect(() => { ///note: change videoFocusStates.isFocus (isFocus becomes true as video is selected in video selector) => set at local state (note: currently no impact, apart from syncronisation, as isVideoFocus is now based on focusStates, not localFocusStates)
      ///handleSetLocalFocusStates({ mediaTypeFocus: "video", isFocus: videoFocusStates.isFocus })
    }, [videoFocusStates.isFocus]); 

    let location = useLocation() ///question: define elsewhere?

    useEffect(() => { ///note: reset local focus states when location is (back to) home or categories
      const 
        pathname = location.pathname,
        pathLength = pathname.split("/").filter(Boolean).length
      if (
        pathLength === 0 || ///note: home path
        pathLength === 1 || ///note: categories path (no category chosen)
        pathLength === 2 && (pathname.includes("xl-") && pathname.includes("cat-")) ///note: xl + category path
        ) {
        setIsShowToggle(false) ///note: reset
        setLocalFocusStates(focusInitialStates) ///note: reset
      }
    }, [location]); 

    useEffect(() => {  ///note: if pl !!actionfocus at change in pl local states (via useeffect (location pathlenght criterias)) => if animated => reset/reverse animation
      console.log("bibberg", plLocalFocusStates)
      const 
        left = plAnimation.left,
        top = plAnimation.top
      if (!plLocalFocusStates.actionFocus) {
        if (plAnimation.isAnimate) {
          const plAnimation = { ///note: used this instead of plAnimationResetState, to encompass new scroll positon at animation, but does not work...
            left: left, 
            top: top,
            isAnimate: true,
            isNormalDirection: false,
          }
          setPlAnimation(plAnimation) //plAnimationResetState
          }
        }
    }, [plLocalFocusStates.actionFocus]); 

    const handleSetLocalFocusStates = ( focusParams ) => { ///optimise: implement only set of !!param via map
 
      if (plLocalFocusStates.actionFocus === "select" && focusParams.mediaTypeFocus === "pl") {
        return
      }

      setLocalFocusStates((draft) => {
        const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === focusParams.mediaTypeFocus);
        Object.entries(focusParams) ///note: set state for each param (passed via function)
          .filter(([param, v]) => param !== "mediaTypeFocus") ///exclude param value from set state
          .map(([param, v], i) => focusElement[param] = v )
      })
    }
    // const handleSetLocalFocusStates = ({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus }) => { ///optimise: implement only set of !!param via map
    //   console.log("handleSetLocalFocusStates", isFocus)
    //   if (plLocalFocusStates.actionFocus === "select" && mediaTypeFocus === "pl") {
    //     return
    //   }
    //   setLocalFocusStates((draft) => {
    //           const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === mediaTypeFocus);

    //           focusElement.actionFocus = actionFocus
    //           focusElement.elementFocus = elementFocus
    //           focusElement.idFocus = idFocus
    //           focusElement.isFocus = isFocus
    //   })
    // }

    const handleSetFocusStates = ( focusParams ) => {
      console.log("focusParams", focusParams)
      setFocusStates((draft) => {
        const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === focusParams.mediaTypeFocus);
        Object.entries(focusParams) ///note: set state for each param (passed via function)
          .filter(([param, v]) => param !== "mediaTypeFocus") ///exclude param value from set state
          .map(([param, v], i) => focusElement[param] = v )
              // const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === mediaTypeFocus);
              // focusElement.actionFocus = actionFocus
              // focusElement.elementFocus = elementFocus
              // focusElement.idFocus = idFocus
              // focusElement.isFocus = isFocus
      })
    }

  const 
    isAutoPl = autoPlsTypes.includes(pl.staticData.plType),
    //background = typesCategories.indexOf(categoryFocusStates.idFocus) !== -1 ?
      // colors[`${typesCategories.indexOf(categoryFocusStates.idFocus) > colors.length ? typesCategories.indexOf(categoryFocusStates.idFocus) % colors.length : typesCategories.indexOf(categoryFocusStates.idFocus)}`] : ///was: colorsLight[Math.floor(Math.random() * (colorsLight.length - 0 + 1)) + 0] ///note: or index
      // colors[`${youTubeChannelCategories.indexOf(categoryFocusStates.idFocus) > colors.length ? youTubeChannelCategories.indexOf(categoryFocusStates.idFocus) % colors.length : youTubeChannelCategories.indexOf(categoryFocusStates.idFocus)}`], //categoriesExtraData.extraCategory[categoryFocusStates.idFocus] !== undefined && colorsLight[typesCategories.indexOf(categoriesExtraData.extraCategory[categoryFocusStates.idFocus][0])]
    mediaIdentifier = isGallery && pl.staticData.plType === "video" ? "vid-" : "pl-", ///optimse: plType for pl includes channel, category and video, a mix of origin and type ... align
    video = useMemo(() => !!videoFocusStates.idFocus && videos.find(video => video.staticData.videoId === videoFocusStates.idFocus)) ///optimse/fix: !!videoFocusStates.idFocus added fastly to mitigate crash at load...

    let to_ = 
      (dlFocusStates.isFocus && !id.includes("diamont-pl-component") ? `/xl-${dlFocusStates.idFocus}` : '') + ///note: if id.includes("diamont-pl-component") => omit xl- from link
      (categoryFocusStates.isFocus ? `/cat-${categoryFocusStates.idFocus}` : '') +
      (isGallery ? `/${mediaIdentifier}${encodeURI(pl.staticData.plId)}` : `/${mediaIdentifier}${encodeURI(plFocusStates.elementFocus.staticData?.plId)}`) + ///note: if isGallery true use pl for link, else (if video-list) use plFocusStates (i.e. do not use pl for link as video-pl)
      (`/lv-${!videoLocalFocusStates.elementFocus.season ? 1 : videoLocalFocusStates.elementFocus.season}_${!videoLocalFocusStates.elementFocus.episode ? 1 : videoLocalFocusStates.elementFocus.episode}`) ///note: ! criteria included as hover of FIRST video in video-list => useffect set of localVideoStates is aborted (to avoid playing locally at hovered) => season and episode is undefined => thus infered/set explicit as season 1 episode 1 ///optimise: consider other solution than infer from undefined);

      //   switch(true) {
      //     case isGallery && !!categoryFocusStates.idFocus:
      //       to_ = !!dlFocusStates.isFocus ? 
      //       `/xl-${dlFocusStates.idFocus}/cat-${categoryFocusStates.idFocus}/${mediaIdentifier}${encodeURI(pl.staticData.plId)}/lv-1_1` :
      //       `/cat-${categoryFocusStates.idFocus}/${mediaIdentifier}${encodeURI(pl.staticData.plId)}/lv-1_1`
      //     break;
      //     case isGallery && !categoryFocusStates.idFocus:
      //       to_ = !!dlFocusStates.isFocus ? 
      //         `xl-${dlFocusStates.idFocus}/${mediaIdentifier}${encodeURI(pl.staticData.plId)}/lv-1_1` :
      //         `/${mediaIdentifier}${encodeURI(pl.staticData.plId)}/lv-1_1`
      //     break;
      //     case !isGallery && !!categoryFocusStates.idFocus && !!dlFocusStates.isFocus:
      //       to_ = !!plFocusStates.isFocus ?
      //         `/cat-${categoryFocusStates.idFocus}/pl-${encodeURI(plFocusStates.elementFocus.staticData.plId)}/lv-${videoLocalFocusStates.elementFocus.season}_${videoLocalFocusStates.elementFocus.episode}` :
      //         "pl-yoga_with_adriene-love_ywa_june_2023-PLui6Eyny-UzwR51P0HS5VIF9o9GRZjUbT/lv-1_1"
      //     break;
      //     case !isGallery && !categoryFocusStates.idFocus && !!dlFocusStates.isFocus:
      //       to_ = !!plFocusStates.isFocus ? 
      //         `/pl-${encodeURI(plFocusStates.elementFocus.staticData.plId)}/lv-${!videoLocalFocusStates.elementFocus.season ? 1 : videoLocalFocusStates.elementFocus.season}_${!videoLocalFocusStates.elementFocus.episode ? 1 : videoLocalFocusStates.elementFocus.episode}` : ///note: ! criteria included as hover of FIRST video in video-list => useffect set of localVideoStates is aborted (to avoid playing locally at hovered) => season and episode is undefined => thus infered/set explicit as season 1 episode 1 ///optimise: consider other solution than infer from undefined...
      //         "pl-yoga_with_adriene-love_ywa_june_2023-PLui6Eyny-UzwR51P0HS5VIF9o9GRZjUbT/lv-1_1"
      //   break;
      //     default:
      // }

  const to = useMemo(() => to_)

  const isShow = useMemo(() => {

		return (
			{
        link: plLocalFocusStates.actionFocus !== "select",
        title: !isGallery || plLocalFocusStates.actionFocus !== "select",
        description: isGallery && isShowToggle && plLocalFocusStates.actionFocus !== "select",
        channelTitle: isGallery && plLocalFocusStates.actionFocus === "select",
        overlay: !isGallery || plLocalFocusStates.actionFocus !== "select", 
				img: videoLocalFocusStates.actionFocus !== "play", 
				preLoader: videoLocalFocusStates.actionFocus === "load", 
				player: videoLocalFocusStates.actionFocus === "play",
				pl: plLocalFocusStates.actionFocus === "select",
        plAnimation: isGallery && plAnimation.isAnimate
			}
		)
	})

  const isRender = useMemo(() => {
		return (
			{
        title: isAutoPl && !plLocalFocusStates.isFocus,
        infoIcon: 
          plLocalFocusStates.actionFocus === "select" || ///if pl selected
          (!isAutoPl && !!pl.dynamicData.plDesc && plLocalFocusStates.actionFocus !== "select"), ///or if no pl selected, not auto pl, and desc exist
				img: true, 
				preLoader: plLocalFocusStates.idFocus === pl.staticData.plId, 
				player: plLocalFocusStates.idFocus === pl.staticData.plId,
			}
		)
	})

  const isBlur = useMemo(() => {
    const isBlur = isGallery && isShowToggle && plLocalFocusStates.actionFocus !== "select"
		return (
			{
        title: isBlur,
				img: isBlur, 
			}
		)
	})


  const 
    plSelected = plLocalFocusStates.actionFocus === "select",
    videoIsFocus = videoFocusStates.isFocus, ///was: videoLocalFocusStates.isFocus
    plNoColumns = 4
  let plWidth
      // isMobile ? "90vw" : 
      // isGallery ? `${((100 - (dlcFocusStates.isFocus ? 20 : 0) - 2*4 - (plNoColumns - 1) * 2) * 1) / plNoColumns }vw` : ///note: 3 equals number of colums, and the substration of multiplum of x is due to gap-column ///note/risk: calc of plWidth not working if plNoColumns is 1 (divide by 0) ///note: if width is not specified explicit it will be 100% of parent (OK), but missing explicit animation start width at select will result in glitchy animation (NOT OK), thus => explicit width.
      // "auto" ///note: not gallery, incl list 


  switch(true) {
    case isMobile:
      plWidth = "90vw";
    break;
    case id === "diamont-pl-component":
      plWidth = `100%`
    break;
    case isGallery:
      plWidth = `${((100 - (dlcFocusStates.isFocus ? 20 : 0) - 2*4 - (plNoColumns - 1) * 2) * 1) / plNoColumns }vw`  ///note: 3 equals number of colums, and the substration of multiplum of x is due to gap-column ///note/risk: calc of plWidth not working if plNoColumns is 1 (divide by 0) ///note: if width is not specified explicit it will be 100% of parent (OK), but missing explicit animation start width at select will result in glitchy animation (NOT OK), thus => explicit width.
    break;
    default:
  }
    

  let listCounter = ""
  switch(true) {
    case id === "video-selector-pl":
      listCounter = !videoIsFocus ? '\u25B6' : ""
    break;
    case id === "video-list":
      console.log("toto", pl.dynamicData.plVideoCollection?.[0]?.videoUrl === videoFocusStates?.elementFocus?.videoUrl, pl.dynamicData.plVideoCollection?.[0]?.videoUrl, videoFocusStates?.elementFocus?.videoUrl, pl.dynamicData.plVideoCollection[0]?.episode)
      listCounter = pl.dynamicData.plVideoCollection?.[0]?.videoUrl === videoFocusStates?.elementFocus?.videoUrl ? '\u25B6' : pl.dynamicData.plVideoCollection[0]?.episode
    break;
    default:
  }

  return (
    <>
    <div 
      className={`
        pl-c
        ${isShow.pl ? "select--true" : ""}
    `}
    >
      <AnimatedComponent ///  ${isShow.pl ? "pl--selected" : "pl--selected-no"}
        className={`
          pl
          ${isShow.pl ? "select--true" : ""}
          ${!isShow.pl && plFocusStates.actionFocus === "select" && id.includes("gallery") ? "select--false-blur" : ""}
          ${isAutoPl ? "pl-auto" : ""}
          ${autoPlsTypes.includes(pl.staticData.plType) && !plLocalFocusStates.isFocus ? "footer--hide" : "footer--show"}
        `}
        isShow={isShow.plAnimation}
        isReducedWidth={dlcFocusStates.isFocus}
        initialWidth={plWidth}
        animation={plAnimation}
        listCounter={listCounter}
        animationResetCallback={() => setPlAnimation(plAnimationInitialState)}
        style={{
          background: isGallery && (isAutoPl || isShow.pl) ? background : "", /// `linear-gradient(to right, rgb($color-b), rgb($color-b))`, ///`rgba(${background}, 0)`, ///note: change `rgba(..)` to `transparent` to not keep footer gradient-color at deselect
          width: plWidth,
          //transform: isShow.pl ? `translate(-${plAnimation.left}px, -${plAnimation.top}px` : ""
        }}
        ref={plRef} 
      >
        {/* <div className={`
          pl
          ${isShow.pl ? "bib" : ""}
        `} 
          onAnimationEnd={(e) => {
            console.log("animation name", e.nativeEvent.animationName);
          }}
          style={{
            background: background,
            transform: isShow.pl ? `translate(-${plAnimation.left}px, -${plAnimation.top}px` : ""
          }}
          ref={plRef} ///note: ref used to calc plAnimation for translateX
        > */}
          <PlMedia
            // imgFile={imgFile}
            isGallery={isGallery}
            background={isAutoPl ? background : "#1a1a1a"}
            pl={pl}
            isShow={isShow}
            isRender={isRender}
            isBlur={isBlur}
            isEnableInteraction={!isShowToggle}
            videoFocusStates={videoFocusStates}
            videoLocalFocusStates={videoLocalFocusStates}
            setFocusStatesCb={handleSetLocalFocusStates}
            isShowToggle={isShowToggle}
            setIsShowToggle={setIsShowToggle}
            to={to}
            isMuted={isMuted}
          />
          <MemoPlFooter
            isShowToggle={isShowToggle}
            setIsShowToggle={setIsShowToggle}
          > 
            {(isShowToggle, setIsShowToggle) => ( ///see https://stackoverflow.com/questions/32370994/how-to-pass-props-to-this-props-children Calling children as a function
              <>
                <MemoPlBar
                  pl={pl}
                  imgFile={imgFile}
                  background={background} 
                  isRender={{title: !isRender.title, infoIcon: isRender.infoIcon}}
                  isShow={isShow}
                  video={video}
                  isShowToggle={isShowToggle}
                  setIsShowToggle={setIsShowToggle}
                />
                {isShow.pl && 
                <MemoPlInfo
                  pl={pl}
                  video={video}
                  videoIsFocus={videoIsFocus}
                  isShowToggle={isShowToggle}
                />
                }
              </>
              )}
          </MemoPlFooter>
        {/* </div> */}
      </AnimatedComponent>
    </div>
    { plSelected ? ///note: only render blanket at plSelected to mitigate impact on drag preview image
      <Blanket isShow={plSelected}/> 
      : null
    }
    </>
  )
}

const GalleryComponents = (props) => {

  const plRefs = useRef([]);

  // let location = useLocation() ///question: define elsewhere?
  // const setFocusStates = useContextSelector(focusStatesContext, v => v.setFocusStates)

  // const handleSetFocusStates = ({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus }) => {
  //   setFocusStates((draft) => {
  //           const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === mediaTypeFocus);
  //           focusElement.actionFocus = actionFocus
  //           focusElement.elementFocus = elementFocus
  //           focusElement.idFocus = idFocus
  //           focusElement.isFocus = isFocus
  //   })
  // }

  // const stickyCallback = useCallback(({ innerText }) => {
  //   //setStaticHeaderText(innerText)
  // }, [])

//   useEffect(() => { ///question: place useeffect elsewhere?
//     if (document.referrer === "") { ///note: direct navigation
//       const 
//         pathname = location.pathname,
//         pathLength = pathname.split("/").filter(Boolean).length
//       if ( pathLength === 2
//         // !categoryFocus.isFocus && pathLength === 2 ||
//         // categoryFocus.isFocus && pathLength === 3
//         ) {
//         const [plElement, videoElement, isExist] = videoElementFromUrl({ pathname, plsMap: props.plsMap })
//         if (isExist) {
//           let 
//               mediaTypeFocus = "pl",
//               actionFocus = "select",
//               elementFocus = plElement,
//               idFocus = plElement.staticData.plId,
//               isFocus = true
//           handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
//               mediaTypeFocus = "video"
//               actionFocus = "play"
//               elementFocus = videoElement
//               idFocus = elementFocus.videoUrl
//               isFocus = true
//           handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
//         } else {
//           ///consider: => url to / 
//         }
//       }
//     }
//   }, [props.plsMap]); ///note

  return (
    [...props.plsMap]
      .map((pl, i) => {
        let component = <></>
          switch(true) {
            case pl.staticData.plType === "infoBox": ///optimise, use of plType or category ... should be plType
              component =
                <div
                  className={"infobox"}
                >
                  {pl.staticData.value}
                </div>
              break;
            case pl.staticData.plType === "introduction-header":
              component = 
                <>
                  <div className={"pl-gallery-header-c"}>
                  <h1 className={"pl-gallery-header"}>
                    {pl.staticData.plTitle}
                  </h1>

                </div>
                <span className={`pl-gallery-subheader`}>
                  {pl.staticData.plSubtitle}
                  </span>
                </>
              break
            case pl.staticData.plType === "header":
              component = 
                <Sticky 
                  isFade={props.plSelected}
                  offsetSticky={"8vh"} 
                  fadeSticky={true}
                  className={`sticky-pl-gallery-header`}
                  // stickyCallback={stickyCallback}
                >
                  {(isSticky) => (
                    <>
                  <div className={"pl-gallery-header-c"}>
                    <h1 className={`pl-gallery-header`}
                    
                    >
                      {pl.staticData.plTitle}
                    </h1>
                  </div>
                  <span className={
                        `pl-gallery-subheader
                        ${isSticky ? "fade-out" : "fade-in"
                        }`
                        }>
                    {pl.staticData.plSubtitle}
                    </span>
                  </>
                )}
          </Sticky>
 
              break;

            default:
                const 
                  categoryImg = props.categories.find(category => category["category name id"] === pl.staticData["category name id"]?.[0])?.media[0].url,
                  // colors = colorsLight({isRadial: false}),
                  background = props.categories.find(category => category["category name id"] === pl.staticData["category name id"]?.[0])?.background?.[0].background ///optimise: multioption save: "string vs. object" in array 

                component = 
                <Box
                  ref={el => plRefs.current[i] = el}
                  draggedItem={pl}
                  type={ItemTypes.PL}
                  isDropped={false}
                  key={i}
                >
                  <PlComponent 
                      id={props.id}
                      isGallery={!props.id.includes("video-list")} ///note: implemented to distingush between gallery and list (fx diamont-list)
                      pl={{...pl, id: props.id}} ///note: id (or gId) added here to pl to capture gallery (a simpler alternative than implementing "gallery" at focusStates) => update: not used currently ...was: currently this id is used to apply "blanket" only once, namely at gallery that holds selected pl. optimise: currently need to be specified explicit for each use of PlComponent (to ensure blanket behaviour)
                      background={background}
                      imgFile={categoryImg}
                      videos={props.videos}
                      isMuted={props.isMuted}
                  />
                </Box>
          }
        return (
          <Fragment
            key={`${pl.staticData.plTitle}-${i}`}
          >
            {component}
          </Fragment>
        )
      })
  )
}

const Gallery = ({ id, isReducedWidth, isMuted, plSelected, plSelectedInGallery, children }) =>  {

  console.log("Pl Gallery")

  ///ref
  const plGalleryRef = useRef()

  ///from context
  //const categoryFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "category"))

  // useEffect(() => { ///control of scroll
  // if (!plGalleryRef.current) {
  //   return
  // }
  // const element = plGalleryRef.current
  // if (plFocusStates.actionFocus === "select") {
  //   //element.addEventListener('wheel', generics.preventScroll, {passive: false});
  // }
  // else { ///pl not selected => enable scroll
  //   element.removeEventListener('wheel', generics.preventScroll, {passive: false});
  // }
  //   return () => { ///cleanup
  //     element.removeEventListener('wheel', generics.preventScroll, {passive: false});
  //   };
  // },[plFocusStates.actionFocus])

          return ( 
              <>
                <div 
                  className={`
                    gallery-w
                  `}
                  style={{marginLeft: `${isReducedWidth ? "20vw" : "0vw"}`}}
                >
                  {/* <Header
                    isSearchField={false}
                    isMutedControl={true}
                    isMuted={isMuted}
                    isBackground={false}
                  /> */}
                  {/* <ChannelInfoComponent/> */}
                  {/* <BackgroundComponent pos={1} crop={true}/>
                  <BackgroundComponent pos={2} crop={true}/> */}
                  {/* <div 
                      className={`gallery-w`}
                  > */}
                    {/* <GalleryHeadline
                      show={plFocusStates.actionFocus !== "select"}
                      headline={!!categoryFocusStates.idFocus ? categoryFocusStates.idFocus : "yoga"}
                      subline={"playlists"}
                    /> */}
                    <div 
                        className={`
                        ${id.includes("gallery") ? "pl-gallery" : ""}
                        ${id}
                        ${plSelected ? "a-pl-selected" : ""}
                        `}
                        id={id}
                        ref={plGalleryRef}
                        // style={{zIndex: plSelectedInGallery ? 1000000 : 0}}
                        >
                        { children(isMuted) }
                    </div> 
                  </div> 
      
              </> 

          )
      }
//  }
 const MemoGallery = memo(Gallery)

export const PlGallery = ({ id, isMuted }) => {

  ///from context
  const 
    swrCategories = useContext(categoriesDataContext),
    swrChannels = useContext(channelsDataContext),
    swrPls = useContext(plsDataContext), 
    swrVideos = useContext(videosDataContext),
    categoryFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "category")),
    dlcFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list-creator")),
    plFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "pl"))

      if (swrPls.isError) return <p></p>; 
      if (swrPls.isLoading) return <p></p>;
        else {  /// if (swrPls.data?.length === 0) return <p></p>; ///note: !swrPls.data used instead of swrPls.isLoading, due to "pagination implementation" of useSWRImmutable with multiple calls via key isLoaded => multiple calls result in multiple isLoading, independent whether !!data, thus !data is utilised here instead ///alternative: if (swrPls.isLoading) return <p></p>;
          const
            categories = swrCategories.data, ///risk - implement check?
            channels = swrChannels.data, ///risk - implement check?
            pls = swrPls.data, //props.swr.data,
            videos = swrVideos?.data ? swrVideos.data : [], 
            plsMap = getPlsMap({ id, categoryFocusStates, plFocusStates, categories, channels, pls, videos }), ///note: plsMap is a modifiction of pls, with headers and related pls, highlights, excluded pls and dummyelements (to ensure correct ui in grid)
            plSelected = plFocusStates.actionFocus === "select", ///note: any pl (in any gallery) selected
            plSelectedInGallery = plFocusStates.elementFocus.id === id ///note: selected pl in (this) gallery
            console.log("iddd", plFocusStates, plFocusStates.elementFocus.id, id, plFocusStates.elementFocus.id === id)
            return (

              <MemoGallery
                id={id}
                isReducedWidth={dlcFocusStates.isFocus && id.includes("gallery")}
                isMuted={isMuted}
                plSelected={plSelected}
                plSelectedInGallery={plSelectedInGallery}
              >     
                {(isMuted) => (
                  <>
                    {/* { !!channel ?
                    <ItemText
                      data={{ "document": "6SDd5IoDObxe8o8bXUsK", "template": "attractions", description: channel.description, highlights: ["one", "two", "two"]}}
                  /> : null } */}
                  {/* {plSelected ? */}
                  {/* <div className={`
                    ${plSelectedInGallery ? "fade-in" : "fade-out"}
                    pl-blanket`
                  }
                  
                    onClick={() => history.goBack()}
                  ></div> */}
                  {/* : null} */}
                  {/* <Blanket isShow={plSelectedInGallery}/> */}
                    <GalleryComponents
                      //imgFile={imgFile}
                      id={id}
                      plSelected={plSelected}
                      categories={categories}
                      channels={channels}
                      plsMap={plsMap}
                      videos={videos}
                      isMuted={isMuted}
                    /> 
                  </>
                )}
              </MemoGallery>   
            )
      } 
  }
  export const MemoPlGallery = memo(PlGallery)
