export const emailSetupSubsc = ({ email, template, subsc }) => {
    return {
        to: [email],
        // from: 'YogaTube.ai <contact@YogaTube.ai>',
        // message: {
        // subject: 'Hello from Firebase!',
        // text: 'This is the plaintext section of the email body.',
        // html: 'This is the <code>HTML</code> section of the email body.',
        // },
        template: {
            name: template,
            data: {
                subscriptions: `
                    <p
                        style="
                            font-weight: 400;
                            font-size: 15px;
                            margin: 10px;
                            "
                    > 
                    <table cellpadding="0px" cellspacing="10pz" border="0">
                        <tr>
                            <td align="center" valign="middle" bgcolor=${subsc.isAccount ? "#007bff" : "#808080"} width="25" height="25" style="font-size: 15px; color: #fff; line-height: 1;">
                                ${subsc.isAccount ? "\u2713" : ""}
                            </td>
                            <td align="left" valign="middle" bgcolor="" height="25" style="font-size: 15px; color: white; line-height: 1; opacity: ${subsc.isAccount ? 1 : 0.3}">
                                Free Membership
                            </td>
                        </tr>
                        <tr>
                            <td align="center" valign="middle" bgcolor=${subsc.isEmail ? "#007bff" : "#808080"} width="25" height="25" style="font-size: 15px; color: #fff; line-height: 1;">
                                ${subsc.isEmail ? "\u2713" : ""}
                            </td>
                            <td align="left" valign="middle" bgcolor="" height="25" style="font-size: 15px; color: white; line-height: 1; opacity: ${subsc.isEmail ? 1 : 0.3}">
                                Free Newsletter
                            </td>
                        </tr>
                    </table>
                    </p>   
                    `,
            },
        }
    }   
}    