import './app.css';
import './styling-standards.css';
import './preview-images.css';
import React, { useState, useEffect, useCallback,  memo, useMemo, useRef, useContext } from 'react';
import { Wrapper, ScrollBoxPair } from './StandardComponents';
import * as generics from './generics';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import arrayMove from 'array-move'
import { trashIcon, handPointerIcon } from './Icons'
import Video from './Video'
import ContentEditable from 'react-contenteditable'
import produce from "immer";
///import { itemElementsExtraContext } from './NewArtwork';
import { contentRestrictions, contentEditablePaste, contentEditableKeyDown } from './ItemForm'
import { initialStateOptions, usePrevious} from './StateOptions';
import ReactPlayer from 'react-player'

export const PreviewImages = ( { fileListArray, maxWidth, parentCallback, setTextCallback } ) => { ///optimise: rename to mediapreview
  
  ///ref 
  const contentEditableRef = useRef([]);
  
  ///state
  const [items, setItems] = useState(fileListArray)
  //const [groupSize, setGroupSize] = useState(1)
  // const prevItems = usePrevious(items)
  ///const
  const name = `preview-images`
  ///onsort => update items => parentcallback to update itemelements
  useEffect(() => {
    if (items !== fileListArray) { ///note: if ... to mitigate callback at no change. note: this shallow comparison tested OK for usecase
      parentCallback({ label: "media", items })
    }
  }, [items]);

  ///on sort end
  const onSortEnd = (oldIndex, newIndex) => {
    setItems((array) => { 
      let newArray = array
      if (newIndex === mapArray.length - 1) { ///if newindex is last index (bin)
        newArray = array.filter((media, index) => index !== oldIndex) ///exclude dragged to bin file (having oldindex)
      } 
        return arrayMove(newArray, oldIndex, newIndex)
    })
    //let mediaText = mediaText ///[...itemElementsExtra.mediaText]
    // let newMediaText 
    // if (newIndex === mapArray.length - 1) { 
    //   newMediaText = [...newMediaText].filter((media, index) => index !== oldIndex)
    // } 
    //   else {
    //   newMediaText = arrayMove(mediaText, oldIndex, newIndex)
    // }
    // const 
    //   parameter = "mediaText", 
    //   action = "update-array",
    //   value = newMediaText  
    //   setTextCallback(parameter, value, action)
  }

  const TextComponent = ({ text, index }) => {
    const 
      label = "mediaText", 
      action = "update-text"
    return (
      <ContentEditable ///optimise: combine all contenteditable into one
        html={text} 
        placeholder={"add text"}
        innerRef={(el) => (contentEditableRef.current[index] = el)}
        onKeyDown={(e) => contentEditableKeyDown(e, label, contentEditableRef.current, index)}
        onPaste={(e) => {
          const anchorOffset = document.getSelection()["anchorOffset"]
          const value = contentEditablePaste(e, label, contentEditableRef.current, index, anchorOffset) ///if text is not restricted => return text, else empty string
          value !== "" && setTextCallback({ label, value, index }) ///action?? ///if text not empty string => set item elements
          }}
        ///note: onchange not used => to ensure correct focus/blur at custom keydown events
        onBlur={() => {
          const value = contentEditableRef.current[index].innerHTML
          setTextCallback({ label, value, index }) //action??
          }}
      />
    )
  }

  const ImageComponent = ({item, index}) => {
    const url = item.url !== "" ? ///if url
      item.url : ///use url
      URL.createObjectURL(item.file) ///else create
    const mediaText = item.mediaText
    return (
      <div>
        <SortableKnob>
          <img 
            draggable={false}
            //className={`center-by-transform`}
            style={{zIndex: "100", maxHeight: "100%", maxWidth: "100%", position: "relative"}} 
            src={url} 
            alt={generics.isFile(item) ? item.name : ""}
          />
        </SortableKnob>
      <TextComponent
        index={index}
        text={mediaText}
      />
      </div>
    )
  }

  const VideoComponent = ({item, index}) => {
    const url = item.url
    const mediaText = item.mediaText
    return (
      <div
      style={{maxHeight: "100%", maxWidth: "100%", position: "relative"}} 
      >      
        <SortableKnob
        className={"color-4"}
        >
        <div style={{width: "2em", height: "2em", position: "absolute", right: "-5%", bottom: "-5%", zIndex: "100"}}>
        { handPointerIcon }
        </div>
        </SortableKnob>
      <Video
        videoUrl={url}
        mediaId={index}
      ></Video>
      <TextComponent
        index={index}
        text={mediaText}
      />
      </div>
    )
  }

  const TrashComponent =                   
  <span 
    className={`
    color-4
    `}
    style={{lineHeight: "10vw"}}
    >
      { trashIcon }
  </span>

  const 
    bin = ["bin"], 
    mapArray = items.length > 0 ? [...items, ...bin] : [],
    component =
      mapArray.map((item, index) => {
        const isTrash = index === mapArray.length - 1
        let component_ = null
        switch (true) {
          //case (generics.isFile(item) || item.mediaType === "image"):   
          case item.mediaType === "image":
            component_ = ImageComponent({item, index});
            break;
          case item.mediaType === "video":  
            component_ = VideoComponent({item, index});
          break;
          case isTrash:
            component_ = TrashComponent;
            break;
          default: 
          break;
        };
            return (    
              <div
                key={index} 
                index={isTrash ? null : index} 
                counter={isTrash ? null : index + 1}
              >
                <SortableItem key={index}>
                  { component_ }
                </SortableItem> 
              </div>
            )
          }) 
        return (
          <Wrapper 
            name={name}
            width={maxWidth}
            height={"auto"}
          >
            <SortableList  
              className={`${name}-list`} 
              style={{padding: `${items.length > 0 ? "1vw" : ""}`}}
              onSortEnd={onSortEnd} 
              allowDrag
              draggedItemClassName="dragged"
            >
              { component }
            </SortableList>
          </Wrapper>
        )
      }
export const MemoPreviewImages = memo(PreviewImages)
