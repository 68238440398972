///next: add animatedcomponent to program-c, perhaps
import '../src/animated-component.css';
import { forwardRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import React from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

 ///AnimatedComponent enables addition/removal of annimations with dynamic settings, without dependencies (like styled components). annimations itself enables onAnimation events (compared to simply transform via css)
export const AnimatedComponent = forwardRef(( props, ref ) => {
 
const 
  [animationName, setAnimationName] = useState(""),
  [animationStyle, setAnimationStyle] = useState({}),
  [isAtStartPos, setIsAtStartPos] = useState(true),
  [fixedStyle, setFixedStyle] = useState({})

useEffect(() => {
  if (props.animation.isAnimate) {

    if (!props.animation.isNormalDirection) {
      handleFixedStyle()
    }
  }

  handleAnimationStyle()
}, [props.animation]);

const handleAnimationStyle = () => {

  let animationName = `program--select-${Math.round(Math.random() * 1000)}`;
  setAnimationName(animationName)

  const style = props.animation.isAnimate ? 
    {
      position: "absolute",
      animationName: animationName,
      animationTimingFunction: "easy-in-out",
      animationDuration: props.animation.isNormalDirection ? "1s" : "0.3s",
      animationDelay: "0s",
      animationIterationCount: 1,
      animationDirection: props.animation.isNormalDirection ? "normal" : "reverse",
      animationFillMode: "forwards",
      transformOrigin: '0% 0%',
      zIndex: 1000, ///note: to stack on top of blanket and other pl
    } :
    {
      animationName: "",
    }
    setAnimationStyle(style)
}

useEffect(() => {
  if (props.animation.isAnimate) {
    console.log("animation", props.animation)
    animation()
  }
}, [animationStyle]);

const handleAnimationEnd = () => {
  if (props.animation.isNormalDirection) {
    setIsAtStartPos(false)
  }
  if (!props.animation.isNormalDirection) {
   props.animationResetCallback()
  }
}

  useEffect(() => {
    if (!isAtStartPos) {
      //handleFixedStyle()
    }
  }, [isAtStartPos]);

const handleFixedStyle = () => {
  // const width = isMobile ? "100vw" : "80vw"
  const style = props.animation.isNormalDirection ? 
    {
        //transform: `translate(-${props.animation.left}px, -${props.animation.top}px)`,
        position: `fixed`,
        top:`${props.animation.top}px`, ///`${props.animation.top}px`
        // width: width
    } :
    {
      // transform: `translate(-${props.animation.left}px, -${0}px)`,
     // position: `relative`,
      //top: `${0}px`
      //top: `${0}px` ///`${props.animation.top}px`
    }
    setFixedStyle(style)
}

 const addStylesheetRules = (rules) => {
    const styleEl = document.createElement("style");
  
    ref.current.appendChild(styleEl) ///document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;
    styleSheet.insertRule(rules, 0);
  }

  const animation = () => {

    const 
      width = isMobile ? 
        "100vw" : 
        `calc(70vw - ${props.isReducedWidth ? '20vw' : '0vw'})`,
      marginLeft = isMobile ? "0vw" : `${props.isReducedWidth ? "25vw" : "5vw"}`,
      marginTop = isMobile ? "0vw" : "5vw",
      scale = 1

    let keyframes = `
        @-webkit-keyframes ${animationName} {
            0% {
                -webkit-transform: translate(0px, 0px);
            } 
            100% {
              -webkit-transform: translate(-${props.animation.left * 1/scale}px, -${props.animation.top * 1/scale}px);
              border-radius: 0;
              width: ${width};
              margin-top: ${marginTop};
              margin-left: ${marginLeft}; 
            }
        }`;
      // 
      // -webkit-transform: scale(${scale}) translate(-${props.animation.left * 1/scale}px, -${props.animation.top * 1/scale}px);
      //   50% {
      //     -webkit-transform:translate(-${props.animation.left}px, -${props.animation.top}px);
      //     width: ${props.initialWidth};
      // } 
    addStylesheetRules(keyframes);

    // setAnimationName(animationName)
  }

    // let fixedStyle = 
    //   animationEndedAtSelected ? ///fixed video at animation end at selected
    //     {
    //     transform: `translate(-${props.animation.left}px, -${0}px)`,
    //     position: `fixed`,
    //     top: `${props.animation.top}px`
    //     } : 
    //   {
    //     //transform: `translate(-${props.animation.left}px, -${0}px)`,
    //     position: `relative`,
    //     //top: `${props.animation.top}px`
    //   } 

    const combinedStyle = {...props.style, ...animationStyle, ...fixedStyle }

    let animateClassName
    switch(true) {
      case props.animation.isAnimate && props.animation.isNormalDirection:
        animateClassName = "pl-animate-normal"
      break;
      case props.animation.isAnimate && !props.animation.isNormalDirection:
        animateClassName = "pl-animate-reverse"
      break;
      default:
  }

    return (
      <div /// ${animateClassName}
        className={` 
          ${props.className}
          ${animateClassName}
        `}
        style={combinedStyle}
        ref={ref}
        listCounter={props.listCounter} 
        onAnimationEnd={() => handleAnimationEnd()}
      > 
        { props.children }
      </div>
    );
  })
