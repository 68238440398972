import React from 'react';
import ReactDOM from 'react-dom/client'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import history from './History';

// function usePageViews() {
//   let location = useLocation();
//   React.useEffect(() => {
//     ga.send(["pageview", location.pathname]);
//   }, [location]);
// }

// export default function Routing() {
//   // usePageViews();
//   return (
//     <Router>  
//       <Switch>
//         <Route
//           exact path="/" 
//           component={App} 
//         />
//         <Route
//           path="/:id" 
//           component={App}  
//         />
//       </Switch>
//     </Router>
//   )
// }

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

if (rootElement.hasChildNodes()) {
  root.hydrate( ///implemented due to prerender ///https://www.npmjs.com/package/react-spa-prerender
    <Router>
      <App />
    </Router>);
} else {
  root.render( 
    <DndProvider backend={HTML5Backend}>
    <Router>
        <App />
    </Router>
    </DndProvider>
    );
}

// ReactDOM.render(
//   <Router>
//     <App />
//   </Router>, 
// rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
