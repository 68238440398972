import './app.css';
import './styling-standards.css';
import './multi-option.css';
import { useState, useEffect, useRef, useContext, useCallback, memo } from 'react';
import { showContext } from './Home2'; ///itemElementsContext
///import { CloseSymbol } from './ComponentStandards';
// import { templates } from './ItemForm';
import produce from "immer";
import * as generics from './generics';
import { GridColums, ZeroToFullHeight, Button } from './StandardComponents';
import { usePrevious} from './StateOptions';
import { templatesDataContext } from './Home2';

import { templatesContext } from './DataProvider';
///notes:
///multioptionbutton: 
///=> setshow: 1) show modal and 2) modalchild equal to multioption, including data options
///multioptions:
///=> multioption component, including options
///=> useeffect at load: handleoption with preselected options from "itemelements"
///=> handle option: prepare add/remove with limit => setshow add/remove option
///app: 
///=> handle modal, including action for modal controls 1) accept => set "itemelements" 2) minus => setshow reset for modalchild 3) close => reset
export const MultiOptions = ({ action, templatesData, symbol, setTemplatesDataCallback, selectedDataCallback }) => { ///optimise: combine filterbox and multioption


  ///context
  //const { show, setShow } = useContext(showContext);
  // const { templatesData, setTemplatesData } = useContext(templatesDataContext);

  ///const based on context

  const { templates } = useContext(templatesContext)

  const [selectedData, setSelectedData] = useState(templatesData[action].values[templatesData[action].activeTemplate][templatesData[action].label])
  const [options, setOptions] = useState([])


  // const toggleMultiOptionsShow = (label) => { //////optimise: duplicaete function in itemform. reduce
  //   const ///reset parameters
  //     component = "modal", 
  //     action = "show", showValue = false,
  //     child = "modalChild", childValue = null
  //   const ///reset parameters
  //     modalChild = "multiOptions",
  //     parameter = "data", dataValue = []

  //   setShow(
	// 		produce((draft) => { 
  //       draft[component][action] = showValue
  //       draft[component][child] = childValue
  //       draft[modalChild][parameter] = dataValue
	// 		})
	// 	);
  // }

  ///on load => add preselected options to selected options
  // useEffect(() => {
  //   const preSelectedOptions = show.multiOptions.preSelectedOptions
  //   preSelectedOptions.map(option =>
  //     handleOption(Object.values(option)[0])
  //   )
  // }, []);

  // const handleSetTemplatesData = ({trigger, label, index, value, startUpload, reset }) => { ///optimise: move function up and combine in one in newartwork?

  //   if (templatesData.temp.startUpload) { ///if startupload is true => abort. implemented to mitigate reset of startupload (to false) due to rerender and call of handleitemtemplatesdata
  //     return
  //   }
  //   console.log("handleSetTemplatesData multioptions", label, value)
  //   const tempObj = { ///optimise: make specification implicit via map
  //     document: null, ///note: no document for a new item
  //     action: "new", 
  //     template: activeTemplate, 
  //     label: label, 
  //     index: index, 
  //     value: value, 
  //     startUpload: false, 
  //     reset: false 
  //     } 
  //     ///action = "new"
  //     ///tempObj = { action: action, label: label, value: value, index: index, startUpload: false} ///optimise: make specification implicit via map
  //   setTemplatesData(
  //     produce((draft) => { 
  //       draft.temp = tempObj
  //     })
  //   );
  // }

  const symbolRef = useRef(symbol);

  useEffect(() => {
    symbolRef.current = symbol;
  }, [symbol]);

  useEffect(() => {
    if (symbol === "minus") {
      const action = "option-remove-all"
      handleSetSelectedData(action)
    }
  }, [symbol]);

  const stateRef = useRef(selectedData);

  useEffect(() => {
    stateRef.current = selectedData;
    selectedDataCallback({ data: selectedData })
  }, [selectedData]);

  useEffect(() => {
    const cleanup = () => {
      if ( symbolRef.current === "check") {
        let value
        switch (templates[templatesData[action].activeTemplate][templatesData[action].label].type) {
          case "multiOptions":
            value = stateRef.current
            break
          case "textualObjects":
            const 
              existingData = templatesData[action].values[templatesData[action].activeTemplate][templatesData[action].label],
              newData = stateRef.current
                .filter((option) => !!Object.values(option)[0]) ///note: to mitgate mapping additional obj with keys as ""
                .map((option) => { return { [Object.values(option)]: ""} } ) ///note: option to key with value ""
            value = [...existingData, ...newData]
            break
          default:
          }

        setTemplatesDataCallback({ action, label: templatesData[action].label, value: value, startUpload: action === "update" ? true : false })
      }
      if (symbolRef.current === "times" && action === "update") { ///note: if action "update: at close modal => reset
        setTemplatesDataCallback({ action, reset: true }) ///setTemplatesDataCallback({ action, reset: true })
      }
    };

    return cleanup;
  }, []);

  useEffect(() => { ///at mount => set options
    if (!!templatesData[action].label) {
      handleSetOptions()
    }
  }, []);

//   useEffect(() => {
//     if (!!templatesData.update.label) {
//       handleSetOptions({ action: "update" })
//     }
// }, [templatesData.update.label]);

  const handleSetOptions = () => {
    let options
    switch (templates[templatesData[action].activeTemplate][templatesData[action].label].type) {
      case "multiOptions":
        options = templates[templatesData[action].activeTemplate][templatesData[action].label].hasOwnProperty("options") ?
        templates[templatesData[action].activeTemplate][templatesData[action].label].options : []
        break
      case "textualObjects":

        options = generics.compareArraysAndExcludeDuplicates(templatesData[action].values[templatesData[action].activeTemplate][templatesData[action].label].map((obj) => Object.keys(obj)[0]), templates[templatesData[action].activeTemplate][templatesData[action].label].options)
        break
      default:
    }
    setOptions(options)
  }

  ///const
  const theme = 0

  ///select and deselect option
  const handleOption = (option) => { ///optimise: consider to cleanup variables ...
    //const parameter = ["multiOptions"]["dataSelected"]

    const optionSelected = generics.valueExistInArrayOfObjectsViaKey(selectedData, templatesData[action].label, option)
    const singleSelection = templates[templatesData[action].activeTemplate][templatesData[action].label].limit === 1
    const atLimit = selectedData.length >= templates[templatesData[action].activeTemplate][templatesData[action].label].limit
    let action_ ///optimise: naming of action "new" and "update" vs. this action ... update naming
    switch (true) {
      case !singleSelection && !optionSelected && !atLimit ||
        singleSelection && !atLimit:
        action_ = "option-add"
        break;
      case singleSelection && atLimit:
        action_ = "option-remove-all-add"
        break;
      case singleSelection && optionSelected ||
        !singleSelection && optionSelected:
        action_ = "option-remove"
        break;
      default:
      break;
    };

    // const action = optionSelected ? "option-remove" : "option-add";
    // if (action === "option-add" && show.multiOptions.dataSelected.length >= multiOptionsLimit[templatesData[action].label] ) { ///limit on number of options selected
    //   return
    // }
    const index = optionSelected ? generics.indexOfObjectInArray(selectedData, templatesData[action].label, option) : null
    const optionObj={}
    optionObj[templatesData[action].label] = option

    handleSetSelectedData(action_, optionObj, index)
  }

	const handleSetSelectedData = useCallback((action, value, index) => {  
    setSelectedData(
      produce((draft) => {
        switch (action) {
          case "option-add":
            draft.push(value);
            break;
          case "option-remove":
            draft.splice(index, 1);
            break;
          case "option-remove-all-add":
            draft.splice(0, draft.length, value);
            break;
          case "option-remove-all":
            draft.splice(0, draft.length);
            break;
          default:
          break;
        };
      })
    )
	},[]);

  const Category = () => {
    let text
    switch (true) {
      case templates[templatesData[action].activeTemplate][templatesData[action].label].limit === 1:
        text = "select 1 option"
        break;
      case templates[templatesData[action].activeTemplate][templatesData[action].label].limit > 1:
          text = `select up to ${templates[templatesData[action].activeTemplate][templatesData[action].label].limit} options`
        break;
        case templates[templatesData[action].activeTemplate][templatesData[action].label].limit === undefined:
          text = "select options"
        break;
      default:
        break;
    } 
    return (
      <GridColums
        height={`100%`}
        className={"filter-box-categories multi-option-categories"}
        gridTemplateColumns={`repeat(auto-fit, minmax(0, min(100%/${1}, max(100%/${1}))))`} 
      >
          <div 
            className={`filter-box-category center-by-grid`} ///${category === templatesData[action].label ? `theme-background-${theme === 0 ? 1 : 0} theme-color-${theme === 0 ? 1 : 0}` : ""}`}
            // onClick={() => handleCategory(category)}
          >
            <span className={"filter-box-category-text"}
            >
               <span className={"filter-box-category-text uppercase"}>{`${templatesData[action].label}:`}</span>
               <span className={"filter-box-category-text lowercase"}>{` ${text}`}</span>
            </span>
          </div>   
      </GridColums>
    )
  }

  const Options = useCallback(() => {
    return (
      <div className={"filter-box-filters multi-options-option padding-top-3 flex-centered"}>
        {options.map((option) => {
          const 
            isSelected = generics.valueExistInArrayOfObjectsViaKey(selectedData, templatesData[action].label, option),
            isColor = generics.isValidColor(option)
          let text
          switch (true) {
            case 
              !isColor:
              text = option
              break;
            case isColor && !isSelected:
              text = <span>&nbsp;</span> ///note: insert space to ensure homogenous height
              break;
            case isColor && isSelected:
              text = <i class="fa-solid fa-check"></i> ///note: if selected => checkmark
              break;
            default:
            break;
          };
          return (
            <Button
              show={true}
              key={option}
              classTypes={["classical", "option"]}
              height={"fit-content"}
              className={`
                filter-box-filter
              ${isSelected ?
                "theme-1" : "theme-1" }
              `}
              fontSize={1}
              // height={"auto"}
              // width={"100%"}
              isSelected={isSelected}
              parentCallback={() => handleOption(option)}
              style={{background: isColor ? option : ""}}
            >
              {/* <span className={`filter-box-filter-item-count 
              text-gradient-1
              input-radius input-padding-2
              `}
              >
              </span> */}
              <span className={"filter-box-filter-text"}
              >
                {text}
              </span>
            </Button> 
            )
          }
        )}
      </div>
    )
  }, [options, selectedData]) ///question: ///was itemElements[templatesData[action].label], include something instead?

  // const FiltersRemove = () => {
  //   const
  //     parameter = "filters",
  //     action = "filters-remove"
  //   return (
  //     <div className={`
  //       filters-remove-wrapper
  //       ${refine.filters.length > 0 ? "show fadein-05" : "hide fadeout-05"}
  //     `}>
  //       <div className={`
  //         filters-remove
  //         input input-radius input-padding-2
  //         theme input-border-1 theme-color-${theme}
  //       `}>
  //         <i className="fas fa-filter filters-remove-icon"
  //           onClick={() => handleSetRefine(parameter, action)}
  //         ></i>
  //       </div>
  //     </div>
  //   )
  // }

  // const FilterBoxButtons = () => {
  //   return (
  //     <div className={"filter-box-buttons-wrapper"}>
  //       <GridColums
  //         className={"filter-box-buttons"}
  //         gridTemplateColumns={`2em fit-content(50%)`} 
  //         justifyContent={"end"}
  //       >
  //         {/* <FiltersRemove/> */}
  //         <FilterModeToggle/>
  //       </GridColums>
  //     </div>
  //   )
  // }

  const Box = memo(() => {

    const BoxContent = useCallback(() => {
      return (
        <div className={`multi-option-wrapper`}>
        <GridColums
          className={`multi-options`} ///optimise: scrollability of options is ensured via class multi-options and flex-centered with explicit height (random value). but priduces a larger empty space below. optimise
          gridTemplateRows={"5vh fit-content(100%)"}
          overflow={"scroll"}
        >
        {/* <div className={`
            multi-options vertical-gridbox 
            ${show ? "show fadein-05" : "hide fadeout-01"}
            `}
            > */}
            <Category/>
            <Options/>
            {/* <FilterBoxButtons/> */}
        {/* </div> */}
        </GridColums>
      </div>
      )
    }, [])

    return (
      <BoxContent/>
    )
  })
	
  return (
      <div className={`multi-option-prompt-wrapper`}>
          <Box/>
      </div>
  ); 
}


// export const MultiOptions = ({ templatesData, setTemplatesData, show, setShow }) => { ///optimise: combine filterbox and multioption

//   ///context
//   //const { show, setShow } = useContext(showContext);
//   // const { templatesData, setTemplatesData } = useContext(templatesDataContext);

//   ///const based on context
// 	const activeTemplate = templatesData[action].activeTemplate

//   console.log("MultiOptions", show, templatesData)


//   // const toggleMultiOptionsShow = (label) => { //////optimise: duplicaete function in itemform. reduce
//   //   const ///reset parameters
//   //     component = "modal", 
//   //     action = "show", showValue = false,
//   //     child = "modalChild", childValue = null
//   //   const ///reset parameters
//   //     modalChild = "multiOptions",
//   //     parameter = "data", dataValue = []

//   //   setShow(
// 	// 		produce((draft) => { 
//   //       draft[component][action] = showValue
//   //       draft[component][child] = childValue
//   //       draft[modalChild][parameter] = dataValue
// 	// 		})
// 	// 	);
//   // }

//   ///on load => add preselected options to selected options
//   useEffect(() => {
//     const preSelectedOptions = show.multiOptions.preSelectedOptions
//     preSelectedOptions.map(option =>
//       handleOption(Object.values(option)[0])
//     )
//   }, []);

//   ///const
//   const templatesData[action].label = show["multiOptions"]["label"]
//   const options = show["multiOptions"]["data"][templatesData[action].label]
//   const theme = 0

//   ///select and deselect option
//   const handleOption = (option) => { ///optimise: consider to cleanup variables ...
//     const parameter = ["multiOptions"]["dataSelected"]
//     const optionSelected = generics.valueExistInArrayOfObjectsViaKey(show["multiOptions"]["dataSelected"], templatesData[action].label, option)
//     const singleSelection = templates[activeTemplate][templatesData[action].label].limit === 1
//     const atLimit = show.multiOptions.dataSelected.length >= templates[activeTemplate][templatesData[action].label].limit
//     let action
//     switch (true) {
//       case !singleSelection && !optionSelected && !atLimit ||
//         singleSelection && !atLimit:
//         action = "option-add"
//         break;
//       case singleSelection && atLimit:
//         action = "option-remove-all-add"
//         break;
//       case singleSelection && optionSelected ||
//         !singleSelection && optionSelected:
//         action = "option-remove"
//         break;
//       default:
//       break;
//     };

//     // const action = optionSelected ? "option-remove" : "option-add";
//     // if (action === "option-add" && show.multiOptions.dataSelected.length >= multiOptionsLimit[templatesData[action].label] ) { ///limit on number of options selected
//     //   return
//     // }
//     const index = optionSelected ? generics.indexOfObjectInArray(show["multiOptions"]["dataSelected"], templatesData[action].label, option) : null
//     const optionObj={}
//     optionObj[templatesData[action].label] = option
//     handleSetShow(parameter, action, optionObj, index)
//   }

// 	const handleSetShow = useCallback((parameter, action, value, index) => {  
//     //console.log(parameter, action, value)
//     setShow(
//       produce((draft) => {
//         let item = draft["multiOptions"]["dataSelected"];
//         switch (action) {
//           case "option-add":
//             item.push(value);
//             break;
//           case "option-remove":
//             item.splice(index, 1);
//             break;
//           case "option-remove-all-add":
//             draft["multiOptions"]["dataSelected"] = [value];
//             break;
//           default:
//           break;
//         };
//       })
//     )
// 	},[]);

//   const Category = () => {
//     let text
//     switch (true) {
//       case templates[activeTemplate][templatesData[action].label].limit === 1:
//         text = "select 1 option"
//         break;
//       case templates[activeTemplate][templatesData[action].label].limit > 1:
//           text = `select up to ${templates[activeTemplate][templatesData[action].label].limit} options`
//         break;
//         case templates[activeTemplate][templatesData[action].label].limit === undefined:
//           text = "select options"
//         break;
//       default:
//         break;
//     } 
//     return (
//       <GridColums
//         height={`100%`}
//         className={"filter-box-categories multi-option-categories"}
//         gridTemplateColumns={`repeat(auto-fit, minmax(0, min(100%/${1}, max(100%/${1}))))`} 
//       >
//           <div 
//             className={`filter-box-category center-by-grid`} ///${category === templatesData[action].label ? `theme-background-${theme === 0 ? 1 : 0} theme-color-${theme === 0 ? 1 : 0}` : ""}`}
//             // onClick={() => handleCategory(category)}
//           >
//             <span className={"filter-box-category-text"}
//             >
//                <span className={"filter-box-category-text uppercase"}>{`${templatesData[action].label}:`}</span>
//                <span className={"filter-box-category-text lowercase"}>{` ${text}`}</span>
//             </span>
//           </div>   
//       </GridColums>
//     )
//   }

//   const Options = useCallback(() => {
//     const parameter = "options"
//     return (
//       <div className={"filter-box-filters multi-options-option padding-top-3 flex-centered"}>
//         {options.map((option) => {
//           const isSelected = generics.valueExistInArrayOfObjectsViaKey(show.multiOptions.dataSelected, templatesData[action].label, option)
//           return (
//             <Button
//               show={true}
//               key={option}
//               classTypes={["classical", "min"]}
//               className={`
//                 filter-box-filter 
//               ${isSelected ?
//                 "theme-0" : "" }
//               `}
//               fontSize={1}
//               // height={"auto"}
//               // width={"100%"}
//               isSelected={isSelected}
//               parentCallback={() => handleOption(option)}
//             >
//               {/* <span className={`filter-box-filter-item-count 
//               text-gradient-1
//               input-radius input-padding-2
//               `}
//               >
//               </span> */}
//               <span className={"filter-box-filter-text"}
//               >
//                 {option}
//               </span>
//             </Button> 
//             )
//           }
//         )}
//       </div>
//     )
//   }, [show.multiOptions]) ///question: ///was itemElements[templatesData[action].label], include something instead?

//   // const FiltersRemove = () => {
//   //   const
//   //     parameter = "filters",
//   //     action = "filters-remove"
//   //   return (
//   //     <div className={`
//   //       filters-remove-wrapper
//   //       ${refine.filters.length > 0 ? "show fadein-05" : "hide fadeout-05"}
//   //     `}>
//   //       <div className={`
//   //         filters-remove
//   //         input input-radius input-padding-2
//   //         theme input-border-1 theme-color-${theme}
//   //       `}>
//   //         <i className="fas fa-filter filters-remove-icon"
//   //           onClick={() => handleSetRefine(parameter, action)}
//   //         ></i>
//   //       </div>
//   //     </div>
//   //   )
//   // }

//   // const FilterBoxButtons = () => {
//   //   return (
//   //     <div className={"filter-box-buttons-wrapper"}>
//   //       <GridColums
//   //         className={"filter-box-buttons"}
//   //         gridTemplateColumns={`2em fit-content(50%)`} 
//   //         justifyContent={"end"}
//   //       >
//   //         {/* <FiltersRemove/> */}
//   //         <FilterModeToggle/>
//   //       </GridColums>
//   //     </div>
//   //   )
//   // }

//   const Box = memo(() => {

//     const BoxContent = useCallback(() => {
//       return (
//         <div className={`multi-option-wrapper`}>
//         <GridColums
//           className={`multi-options`} ///optimise: scrollability of options is ensured via class multi-options and flex-centered with explicit height (random value). but priduces a larger empty space below. optimise
//           gridTemplateRows={"5vh fit-content(100%)"}
//           overflow={"scroll"}
//         >
//         {/* <div className={`
//             multi-options vertical-gridbox 
//             ${show ? "show fadein-05" : "hide fadeout-01"}
//             `}
//             > */}
//             <Category/>
//             <Options/>
//             {/* <FilterBoxButtons/> */}
//         {/* </div> */}
//         </GridColums>
//       </div>
//       )
//     }, [show.modal])

//     return (
//       <BoxContent/>
//     )
//   })
	
//   return (
//       <div className={`multi-option-prompt-wrapper`}>
//           <Box/>
//       </div>
//   ); 
// }

const X = 
  <Button
  show={true}
  key={""}
  classTypes={["classical", "option"]}
  height={"fit-content"}
  className={`
    filter-box-filter
  `}
  fontSize={1}
  isSelected={false}
  // parentCallback={() => handleOption(option)}
  style={{background: "red"}}
  />


export const MultiOptionButton = ({ show, setShow, className, disabled, requester, payloadObjectName, payloadInnerPathViaArray, preSelectedOptions, document, label, classTypes, optionCountText, multiOptionsValues, text, children, parentCallback}) => {

  //const { show, setShow } = useContext(showContext)

  ///interaction: handle multioption 
  const toggleMultiOptionsShow = () => { ///optimise: move most to modal in app
    console.log("modalfun")
    const 
      component = "modal", //label
      action = "show", showValue = true,
      child = "modalChild", 
      childValue = "multiOptions"
    const 
      modalChild = "multiOptions",
      parameter = "label", labelValue = label,
      data = "data", dataValue = { [label]: multiOptionsValues }
    setShow(
      produce((draft) => { 
        draft[component][action] = showValue
        draft[component][child] = childValue
        draft[component]["requester"] = requester
        draft[modalChild][parameter] = labelValue
        draft[modalChild][data] = dataValue
        draft[modalChild]["payloadObjectName"] = payloadObjectName
        draft[modalChild]["payloadInnerPathViaArray"] = payloadInnerPathViaArray ///note: can be used with immer
        draft[modalChild]["preSelectedOptions"] = preSelectedOptions
        draft[modalChild]["document"] = document
      })
    );
  }

  const isColor = generics.isValidColor(optionCountText)

  return (
    <Button
      disabled={disabled}
      selectOff={disabled}
      hoverOff={disabled}
      show={true}
      wrapperClassName={`multi-option-button-wrapper`}
      className={`${className} multi-option-button`}
      classTypes={classTypes}
      theme={1}
      height={"100%"}
      //minHeight={`inherit`}
      //minWidth={minWidthButton}
      fontSize={1}
      message={optionCountText}
      parentCallback={() => parentCallback()} ///was before reduced away temproray:  !disabled && toggleMultiOptionsShow(label) || disabled && parentCallback()}
  >
    { text } { children }
  </Button> 
  )
}


//   ///state
//   const category = "a"
//   ///const prevRefine = usePrevious(refine.show.filterbox) 

//   ///select and deselect option
//   const handleOption = (option) => { ///optimise: consider to cleanup variables ...
//     const parameter = "childData"
//     const optionSelected = generics.valueExistInArrayOfObjectsViaKey(modal[parameter], category, option)
//     const action = optionSelected ? "option-remove" : "option-add";
//     const index = optionSelected ? generics.indexOfObjectInArray(modal[parameter], category, option) : null
//     const optionObj={}
//     optionObj[category] = option
//     handleSetShow(parameter, action, optionObj, index)
//   }

// 	const handleSetShow = useCallback((parameter, action, value, index) => {  
//     console.log(parameter, action, value)
//     setShow(
//       produce((draft) => {
//         let item = draft[parameter];
//         switch (action) {
//           case "option-add":
//             item.push(value);
//             break;
//           case "option-remove":
//             item.splice(index, 1);
//             break;
//           default:
//           break;
//         };
//       })
//     )
// 	},[]);

//   const Categories = () => {
//     return (
//       <GridColums
//         className={"filter-box-categories"}
//         gridTemplateColumns={`repeat(auto-fit, minmax(0, min(100%/${1}, max(100%/${1}))))`} 
//       >
//         {category.map((category) => 
//           <div 
//             key={category}
//             className={`filter-box-category center-by-grid ${category === templatesData[action].label ? `theme-background-${theme === 0 ? 1 : 0} theme-color-${theme === 0 ? 1 : 0}` : ""}`}
//             onClick={() => handleCategory(category)}
//           >
//             <span className={"filter-box-category-text"}
//             >
//               {category}
//             </span>
//           </div>   
//         )}
//       </GridColums>
//     )
//   }

//   const Options = useCallback(() => {
//     const parameter = "options"
//     return (
//       <div className={"filter-box-filters padding-1 flex-left"}>
//         {filterOptions.map((filter) => {
//           return (
//             <div 
//             key={option}
//               className={`filter-box-filter 
//               ${generics.valueExistInArrayOfObjectsViaKey(refine[parameter], templatesData[action].label, filter) ? 
//                 `fadein-05 theme-background-${theme === 0 ? 1 : 0} theme-color-${theme === 0 ? 1 : 0}` : ``}
//               theme theme-border-${theme}
//               input input-padding-2 input-margin-2 input-radius`}
//               onClick={() => handleOption(option)}
//             >
//               <span className={`filter-box-filter-item-count 
//               text-gradient-1
//               input-radius input-padding-2
//               `}
//               >
//               </span>
//               <span className={"filter-box-filter-text"}
//               >
//                 {option}
//               </span>
//             </div> 
//             )
//           }
//         )}
//       </div>
//     )
//   }, [show.modal.childData])

//   const FiltersRemove = () => {
//     const
//       parameter = "filters",
//       action = "filters-remove"
//     return (
//       <div className={`
//         filters-remove-wrapper
//         ${refine.filters.length > 0 ? "show fadein-05" : "hide fadeout-05"}
//       `}>
//         <div className={`
//           filters-remove
//           input input-radius input-padding-2
//           theme input-border-1 theme-color-${theme}
//         `}>
//           <i className="fas fa-filter filters-remove-icon"
//             onClick={() => handleSetRefine(parameter, action)}
//           ></i>
//         </div>
//       </div>
//     )
//   }

//   // const FilterBoxButtons = () => {
//   //   return (
//   //     <div className={"filter-box-buttons-wrapper"}>
//   //       <GridColums
//   //         className={"filter-box-buttons"}
//   //         gridTemplateColumns={`2em fit-content(50%)`} 
//   //         justifyContent={"end"}
//   //       >
//   //         {/* <FiltersRemove/> */}
//   //         <FilterModeToggle/>
//   //       </GridColums>
//   //     </div>
//   //   )
//   // }

//   const Box = memo(() => {

//     const BoxContent = useCallback(() => {
//       return (
//         <div className={`filter-box-wrapper`}>
//         <div className={`
//             filter-box vertical-gridbox 
//             theme theme-border-${theme} theme-color-${theme} input-radius
//             ${show ? "show fadein-05" : "hide fadeout-01"}
//             `}>
//             <Categories/>
//             <Options/>
//             {/* <FilterBoxButtons/> */}
//         </div>
//       </div>
//       )
//     }, [show.modal])

//     return (
//       <BoxContent/>
//     )
//   })

//   return (
//     <Box/>
//   );

// export default memo(FilterBox)