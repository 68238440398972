import { categoriesExtraData } from './CategoryGalleryData';

export const createProgramFromBundle = ({ programs, programTitle, bundle }) => {

      const newProgramVideoCollection = []
      let counter = 1
      const mapNewProgramVideoCollection = [...programs]
        .filter((program => bundle.videos.includes(program.programTagline) && program.programTitle === programTitle))
        .reverse()
        .map((program) => 
          program.programVideoCollection.map((video) => {
            newProgramVideoCollection.push({
              season: 1,
              episode: counter,
              videoUrl: video.videoUrl,

            })
            counter++
          })
        )

    const 
        channel = programTitle.toLowerCase(),
        extraCategory = categoriesExtraData.extraCategory.hasOwnProperty(channel) ? categoriesExtraData.extraCategory[channel] : []

        //newProgramVideoCollection.push(Object.values(...program.programVideoCollection)))
      const program = {
        // previewImgUrl: require(`../src/imgs/${channel}.png`),
        thumbnailUrl: programs.find(program => program.programTagline === bundle.videos[0]).thumbnailUrl,
        programCollectionId: bundle.programTagline.toLowerCase().replace(/\s+/g, '_'),
        category: [channel, ...extraCategory],
        sortId: programs.find(program => program.programTagline === bundle.videos[0]).sortId, // bundlePlaylistsData[this.props.currentCategory][index].videos[0]).sortId,
        programTitle: programTitle,
        programTagline: bundle.programTagline,
        programDescriptionShort: "",
        programDescriptionLong: "", 
        publishedAt: programs.find(program => program.programTagline === bundle.videos[0]).publishedAt, //this.props.currentPrograms.find(program => program.programTagline === bundlePlaylistsData[this.props.currentCategory][index].videos[0]).publishedAt,
        programVideoCollection: newProgramVideoCollection,
        type: "bundle"
      }

      return program
    }

    // bundlePlaylistsData.map( (bundleObj) => 
    //     Object.values(bundleObj).map( (bundles) => 
    //         bundles.map( (bundle) => {
    //             console.log("bundle", bundle)
    //         if (!bundle.isUploaded) {
    //             console.log("bundle not uploaded")
    //             const 
    //                 programTitle = Object.keys(bundleObj)[0]
    //                 const program = mapProgramFromBundle({ programs, programTitle, bundle })
    //             return program
    //         }
    //         })
    //     )
    //)

    // const programsFromBundleExist = bundlePlaylistsData.hasOwnProperty(this.props.currentCategory)
    // if (programsFromBundleExist) {
    //   mapProgramsFromBundle()
    // }
//}