//https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCX32D3gKXENrhOXdZjWWtMA&maxResults=2&order=date&type=video&key=[YOUR_API_KEY]
//https://youtube.googleapis.com/youtube/v3/videos?part=statistics&id=tIJrHBIUkYM&id=tIJrHBIUkYM&key=[YOUR_API_KEY] ///+ contentdetails?? => https://developers.google.com/youtube/v3/docs/videos#resource 

export const apiKeys =
[
	"AIzaSyDVRyd2eZzXy8sgPAlxGAg1tIS5Me636ig",
	"AIzaSyAy1OfrBnjSMn0V5ENtYtBAqtIQMx0N5Dc" ///yoga curated api
]

export const requestVideos = async({ channelName, channelId, requestAll }) => {

    let 
		combinedVideoList = [],
		apiReady = true,
		firstLoop = true,
		isMoreData = false,
		nextPageToken = ""

    const 
        part = "snippet",
        type = "video", ///or playlist
        maxResults = requestAll ? 50 : 5

    do {
        if (apiReady) {

            let 
				isFetched = false,
            	counter = 0,
				response

        		while (!isFetched && counter < apiKeys.length) { 

        			const requests = firstLoop ?
						`https://youtube.googleapis.com/youtube/v3/search?part=${part}&channelId=${channelId}&maxResults=${maxResults}&order=date&type=${type}&key=${apiKeys[counter]}` :
						`https://youtube.googleapis.com/youtube/v3/search?part=${part}&channelId=${channelId}&maxResults=${maxResults}&order=date&type=${type}&key=${apiKeys[counter]}&pageToken=${nextPageToken}`
        
					console.log("attempt fetch with api key index:", counter)
        			
					response = await fetch(requests)

					if (response.status === 403) {
						apiReady = false
						console.log("api index", counter, "ready:", apiReady)
					} else {
						apiReady = true
						isFetched = true
						console.log("isFetched:", isFetched, ", via api index:", counter)
					}
        			counter++
        		}

		if (!apiReady) {
			console.log("apiReady:", apiReady, "nextPageToken:", nextPageToken)
			break
		}

        let videoList = await response.json()
        //console.log("videoList:", videoList)
        combinedVideoList = isMoreData ?
          {...combinedVideoList, ...combinedVideoList.items.push(...videoList.items)}
          : videoList

        isMoreData = videoList.hasOwnProperty("nextPageToken")
        nextPageToken = isMoreData ? 
            videoList.nextPageToken : ""
        
        firstLoop = false
        }
	}
	while ((isMoreData || firstLoop) && requestAll)

	const responseVideos = combinedVideoList

	console.log("request videos at ", channelName, " returns ", responseVideos, " with items ", responseVideos.items)
	return responseVideos

		// const mapNewProgram = ({ playListItems }) => {

		// 	const index = i + playListIndex ///note: +playListIndex to offset corretly
	  
		// 	  let newProgram = {}
		// 	  let newProgramVideoCollection = []
		// 	  playListItems.items.map((video, index) => {
		// 		  const 
		// 			  youtubeUrl = "https://youtu.be/",
		// 			  thumbnailUrl = playListItems.items[i].snippet.hasOwnProperty('thumbnails') && playListItems.items[0].snippet.thumbnails.hasOwnProperty('high') && playListItems.items[0].snippet.thumbnails.high.hasOwnProperty('url') ? playListItems.items[0].snippet.thumbnails.high.url : "",
		// 			  newVideo =
		// 				  {
		// 					  videoUrl: `${youtubeUrl}${video.snippet.resourceId.videoId}`,	
		// 					  episode: index + 1,  ///note: or use .position ///optimise: episode not offset in realtion to non-included (private) videos, consider to mitigate
		// 					  season: 1,
		// 				  }
	  
		// 		  if (thumbnailUrl !== "") {
		// 			  newProgramVideoCollection.push(newVideo) ///if no thumbnail video is (probably) private => not included
		// 		  }
		// 	  })
	  
		// 	  const 
		// 		  channelTitle = playListItems.items[0].snippet.channelTitle.toLowerCase(),
		// 		  dateArray = playListItems["items"].map(e => new Date(e["snippet"]["publishedAt"])),
		// 		  maxDate = new Date(Math.max(...playListItems["items"].map(e => new Date(e["snippet"]["publishedAt"])))),
		// 		  indexMaxDate = dateArray.map(Number).indexOf(+maxDate), ///note: serilisation for correct comparison according to https://stackoverflow.com/questions/27450867/how-to-correctly-use-javascript-indexof-in-a-date-array
		// 		  publishedAt = playListItems["items"][indexMaxDate].snippet.publishedAt, ///note: publishedat is maxdate for a video in that playlist
		// 		  thumbnailUrl = playListItems.items[0].snippet.hasOwnProperty('thumbnails') && playListItems.items[0].snippet.thumbnails.hasOwnProperty('high') && playListItems.items[0].snippet.thumbnails.high.hasOwnProperty('url') ? playListItems.items[0].snippet.thumbnails.high.url : "",
		// 		  tagLine = playLists.find(playList => playList.snippet.title === playListsTitles[index]).snippet.title, //playListsEssentials.find(playList => playList.playListId === playListItems.items[0].snippet.playlistId).title,
		// 		  description = playLists.find(playList => playList.snippet.title === playListsTitles[index]).snippet.description, //playListsEssentials.find(playList => playList.playListId === playListItems.items[0].snippet.playlistId).description,
		// 		  sortId = [100 + i],
		// 		  extraCategories = categoriesData.extraCategory[channelTitle],
		// 		  category = [channelTitle, ...extraCategories] //.join(",")
	  
		// 	  return newProgram = {
		// 		  programCollectionId: `${channelTitle.toLowerCase().replaceAll(' ', '_')}-${tagLine.toLowerCase().replaceAll(' ', '_')}`,
		// 		  programTitle: channelTitle, 
		// 		  programTagline: tagLine, 	
		// 		  programDescriptionShort: description,
		// 		  programDescriptionLong: description,
		// 		  programVideoCollection: newProgramVideoCollection,
		// 		  publishedAt: publishedAt,
		// 		  thumbnailUrl: thumbnailUrl,
		// 		  previewImg: channelTitle,
		// 		  category: category,
		// 		  sortId: sortId,
		// 	  }
		//   }
	  
		//   const program = mapNewProgram({ playListItems })
		//   programs.push(program)

    // jsonVideoList.sort((a,b) => { ///sort playlists after newest published video in each playlist
    //   return (
    //     new Date(Math.max(...b["items"].map(e => new Date(e["snippet"]["publishedAt"])))) - 
    //     new Date(Math.max(...a["items"].map(e => new Date(e["snippet"]["publishedAt"]))))
    //   )
    // //}
    // //)
    // })
}