
import React, { useState, useEffect } from 'react'
import '../src/upload-new-programs.css';
import firebase from './Fire';
//import { db } from '../src/Fire';
import { pivotPrograms as newPrograms } from '../src/programs/programs'; //import {newPrograms} from './NewPrograms';
import { playListToExcel } from '../src/programs/playListToExcel';
import { requestChannelData } from './requestChannelData';
import { requestPlaylists } from './requestPlaylists';
import { requestPlaylistItemsCollection } from './requestPlaylistItemsCollection';
import { requestVideos } from './requestVideos';
import { requestCaption } from './requestCaption';
import * as generics from './generics';
import { createProgramFromBundle } from './createBundleProgram';
import { bundlePlaylistsData } from './ProgramGallery';

// import imgToBase64 from 'img-to-base64';
import { login } from './AuthFunctions';
import { getCollection, getDocument, getDocIds, addDocument, getPrograms, getChannels, getChannelsNames, uploadChannelData, preparePlaylistDoc, addProgram, uploadPlaylists, getPlaylists, uploadVideos, getVideosForChannel, uploadPrograms, getProgramsForChannel, prepareDoc, getRecentDoc, updateVideos, writeBatch } from './FirebaseFunctions';
import { requestVideoStats } from './requestVideoStats';
import { createPls } from './createPls';
import { createVideo } from './createVideo';
import { createVideos } from './createVideos';
import { requestVideo } from './requestVideo';
import { requestYouTube } from './requestYouTube';

import { getAllByAltText } from '@testing-library/react';

const UploadNewPrograms = () => { 

  const 
    [password, setPassword] = useState(""),
    [email, setEmail] = useState(""),
    [channelIdentifier, setChannelIdentifier] = useState(""), ///note: channel-title or channel-id
    [categories, setCategories] = useState([]),
    [channels, setChannels] = useState([]),
    [requestAll, setRequestAll] = useState(false),
    [updateChannelsSelectedChannels, setUpdateChannelsSelectedChannels] = useState([]),
    [selectedChannels, setSelectedChannels] = useState([]),
    [playlists, setPlaylists] = useState([]),
    [playlistsChannels, setPlaylistsChannels] = useState([]),
    [playlistsTitles, setPlaylistsTitles] = useState([]),
    [channelNamePlaylistsItems, setChannelNamePlaylistsItems] = useState([]),
    [videoId, setVideoId] = useState("")


  useEffect(() => {
    if (channelIdentifier === "") {
      const fetchChannels = async () => {
        const channels = await getCollection({ collection: "channels" })///getChannels({collection: "channels"})
        console.log("load chanels", channels)
        setChannels(channels.data)
      }

      fetchChannels()
        .catch(console.error);
    }
  }, [channelIdentifier]);

  useEffect(() => {
    if (channelIdentifier === "") {
      const fetchCategories = async () => {
        const categories = await getCollection({ collection: "categoriesx" })///getChannels({collection: "channels"})
        setCategories(categories.data)
      }

      fetchCategories()
        .catch(console.error);
    }
  }, [channelIdentifier]);

  useEffect(() => {
    const fetchChannels = async () => {
      const channelNames = await getDocIds({ collection: "pls" }) ///was: getChannelsNames({ collection: "playlists" })
      setPlaylistsChannels(channelNames)
     }
    fetchChannels()
      .catch(console.error);
  }, [playlists]);



  const handleUploadVideos = async ({ channelName, videos_ }) => {
    const videoMinYear = videos_.reduce(function (pre, cur) {
      return Date.parse(pre.publishedAt) > Date.parse(cur.publishedAt) ? cur : pre;
      })
    const videoYears = [],
    startYear = new Date(videoMinYear.publishedAt).getFullYear(),
    currentYear = new Date().getFullYear(),
    yearBundleSize = 1 ///note: yearbundle with default value 3 was created to limit number of documents (pooling). but disabled (with size of 1) in favor of reducing complexity (of read/write, in particular update)
    for (let i = startYear; i <= currentYear; i += yearBundleSize){
      videoYears.push(i);
    }
    videoYears
      .map(async (year) => {
        const 
          videos = videos_.filter((video) => 
            new Date(video.publishedAt).getFullYear() >= year &&
            new Date(video.publishedAt).getFullYear() < year + yearBundleSize
            ),
          document = channelName,
          document_ = `${document} ${year}${yearBundleSize === 1 ? "" : `-${year + yearBundleSize - 1}`}`,
          yearMatrix = Array.from(Array(yearBundleSize)).map((year_, index) => year + index) // [year, year + 1, year + 2]
          if (videos.length > 0) {
            console.log(document_, document, videos, yearMatrix)
            await uploadVideos({ document_, document, videos, yearMatrix })
          }
      }) 
  }

  // const handleAddVideoStats = async({ channelName, uniqueVideos }) => {
  //   const 
  //     videoIds = uniqueVideos
  //       .filter((video) => !video.statisticsDate) ///note: add filter to select for last updated date older than x
  //       .map((video) => video.videoId)
  //   if (videoIds.length === 0) {
  //     console.log("all statistics up to date, videos returned with no change")
  //     return uniqueVideos
  //   }
  //   const videoStats = await requestVideoStats({ videoIds })
  //   let itemObj, snippet, stats, status, contentDetails
  //   let videos_ = []
  //   uniqueVideos.map( (video) => { ///with statistics, contentdetails, and status
  //       const statExist = !(typeof videoStats.items.find(item => item.id === video.videoId) === 'undefined')
  //       if (statExist) {
  //           itemObj = videoStats.items.find(item => item.id === video.videoId)
  //           const { description } = itemObj.snippet
  //           snippet = {description: description}
  //           const { commentCount, favoriteCount, likeCount, viewCount } = itemObj.statistics
  //           stats = {commentCount: Number(commentCount), favoriteCount: Number(favoriteCount), likeCount: Number(likeCount), viewCount: Number(viewCount)}
  //           const { embeddable, madeForKids, privacyStatus } = itemObj.status
  //           status = {embeddable: embeddable, madeForKids: madeForKids, privacyStatus: privacyStatus}
  //           const { duration } = itemObj.contentDetails
  //           contentDetails = {duration: duration}
  //         }
  //       const video_ = statExist ?
  //           {...video, ...snippet, ...stats, ...status, ...contentDetails, statisticsDate: new Date()} :
  //           {...video}
  //       videos_.push(video_)
  //   })

  //   JSON.stringify(videos_, function (key, value) {return (value === undefined) ? "" : value}); ///instance of fieldvalue undefined replaced with ""

  //   console.log("videos with stat is prepared at ", channelName, " as ", videos_)
  //   return videos_
  // }
  const handleAddVideoStats = async({ channelName, uniqueVideos }) => {
    const 
      videoIds = uniqueVideos
        .map((video) => video.staticData.videoId)
    if (videoIds.length === 0) {
      console.log("all statistics up to date, videos returned with no change")
      return uniqueVideos
    }
    const videoStats = await requestVideoStats({ videoIds })
    let itemObj, snippet, stats, status, durations
    let videos_ = []
    const lastChanged = { lastchanged: new Date() }
    uniqueVideos.map( (video) => { ///with statistics, contentdetails, and status
        const statExist = !(typeof videoStats.items.find(item => item.id === video.staticData.videoId) === 'undefined')
        if (statExist) {
            itemObj = videoStats.items.find(item => item.id === video.staticData.videoId)
            const { description, channelId } = itemObj.snippet
            snippet = {description: description, channelId: channelId }
            const { commentCount, favoriteCount, likeCount, viewCount } = itemObj.statistics
            stats = { commentCount: Number(commentCount), favoriteCount: Number(favoriteCount), likeCount: Number(likeCount), viewCount: Number(viewCount) }
            const { embeddable, madeForKids, privacyStatus } = itemObj.status
            status = {embeddable: embeddable, madeForKids: madeForKids, privacyStatus: privacyStatus}
            const { duration } = itemObj.contentDetails
            const minutesDuration = generics.iso8601DurationToMinutes(duration)
            durations = { iso8601Duration: duration, minutesDuration: minutesDuration }
          }
        const video_ = statExist ?
            {
                ...video, 
                staticData: { ...video.staticData, ...snippet, ...status, ...durations},
                stats: { ...lastChanged, ...stats },
              // data: { ...lastChanged, ...video, ...snippet, ...status, ...durations}, 
              // stats: { ...lastChanged, ...stats },
              // attr: { ...lastChanged }
            } :
            { 
              ...video, 
              staticData: {  ...video.staticData, ...snippet, ...status, ...durations}
              // data: { ...lastChanged, ...video},
              // attr: { ...lastChanged }
            }
        videos_.push(video_)
    })

    JSON.stringify(videos_, function (key, value) {return (value === undefined) ? "" : value}); ///instance of fieldvalue undefined replaced with ""

    console.log("videos with stat is prepared at ", channelName, " as ", videos_)
    return videos_
  }

const handleAddPlaylists = async ({ channelName, responsePlaylists }) => {
    //await prepareDoc({ collection: "playlists", document }) ///create or - if does indeed exist - update (root) doc for channel
    const existingPlaylists = await getDocument({ collection: "playlists", document: channelName }), /// getPlaylists({ channelName }),
      combinedPlaylists = [...existingPlaylists, ...responsePlaylists],
      playlists = [...new Map(combinedPlaylists.map(playlist => [playlist.id, playlist])).values()]
    await writeBatch({ collection: "playlists", dataArray: playlists, docIdAttr: "id" }) ///await addDocument({ collection: "playlists", document: document, payload: playlists}) ///uploadPlaylists({ document, playlists })
    return playlists
}

// const handleAddPls = async ({ channelName, playlists, responsePlaylistItemsCollection }) => {
//   const
//     document = channelName,
//     existingPrograms = await getProgramsForChannel({ document }),
//     createdPrograms = await createPls({ channelName, playlists, responsePlaylistItemsCollection }),
//     combinedPrograms = [...existingPrograms, ...createdPrograms], ///note: preference is createdprogram
//     programs = [...new Map(combinedPrograms.map(program => [program.playlistId, program])).values()]
//   await uploadPrograms({ document, programs })  
// }
const handleAddPls = async ({ channelName, categoryNameId, playlists, responsePlaylistItemsCollection }) => {
  console.log("ppp", channelName, playlists, responsePlaylistItemsCollection)
  const
    //document = channelName,
    existingPrograms = [], //await getProgramsForChannel({ document }),
    createdPrograms = await createPls({ channelName, categoryNameId, playlists, responsePlaylistItemsCollection }),
    combinedPrograms = [...existingPrograms, ...createdPrograms] ///note: preference is createdprogram
    console.log("combinedPrograms", combinedPrograms)
    const programs = [...new Map(combinedPrograms.map(program => [program.staticData.plId, program])).values()]
    console.log("combinedPrograms", programs)
  await writeBatch({ collection: "pls", dataArray: programs, docIdAttr: "staticData.plSourceId" }) ///was: await uploadPrograms({ document, programs })  
}

const handleAddVideos = async ({ channelName, categoryNameId, responseVideos }) => {
  // const document = channelName
  // await prepareDoc({ collection: "videoCollection", document }) ///create or - if does indeed exist - update (root) doc for channel
  const
    existingVideos = [], //await getVideosForChannel({ document }),
    createdVideos = await createVideos({ channelName, categoryNameId, responseVideos }),
    combinedVideos = [...createdVideos, ...existingVideos],
    uniqueVideos = [...new Map(combinedVideos.map(video => [video.staticData.videoId, video])).values()],
    videos_ = await handleAddVideoStats({ channelName, uniqueVideos })
    videos_.sort((a,b) => new Date(b.staticData.publishedAt) - new Date(a.staticData.publishedAt))
    console.log("crevideos", createdVideos, videos_)
  //await handleUploadVideos({ channelName, videos_ })
  await writeBatch({ collection: "videos", dataArray: videos_, docIdAttr: "staticData.videoId" })
  //await uploadVideos({ document_, document, videos, yearMatrix })
}

const getAll = async ({ isSelection, selection, requestAll }) => {
  channels
    .filter((channel) => isSelection ? selection.includes(channel.staticData.title) : true) ///note: if isselection true, filter for only selected channels
    .map( async (channel) => {
      const 
        channelName = channel.staticData.title,
        categoryNameId = categories.find((category) => category["category name"] === channelName)["category name id"], ///optimise: better way?
        channelId = channel.staticData.channelId,
        responsePlaylists_ = await requestYouTube({ resource: "playlists", channelName, channelId, requestAll }),
        responsePlaylists = responsePlaylists_.items,
        playlists = await handleAddPlaylists({ channelName, responsePlaylists })
      const
        playlistsTitles = playlists.map((playlist) => playlist.snippet.title),
        playlistTitle = playlistsTitles[0],
        responsePlaylistItemsCollection = await requestPlaylistItemsCollection({ channelName, playlists, playlistsTitles, playlistTitle })
        console.log("responsePlaylistItemsCollection", responsePlaylistItemsCollection)
      await handleAddPls({ channelName, categoryNameId, playlists, responsePlaylistItemsCollection })
      const responseVideos = await requestYouTube({ resource: "videos", channelName, channelId, requestAll })
      await handleAddVideos({ channelName, categoryNameId, responseVideos })
  })
}

    // const handleUploadNewPrograms = () => {

    //   const db = firebase.firestore();
    //   //let previewImgUrl = ''
    //   let programInfoCollection = []

    //   const programs = newPrograms.pivotPrograms

    //   const uploadPreviewImgAndCollectProgramInfo = programs.map(program => {

    //     const previewImgUrl = program.thumbnailUrl
    //     collectProgramInfo(program, previewImgUrl)

    //     let previewImg = require(`../src/imgs/${program.previewImg}.png`)
    //     let filename = program.title.toLowerCase().replace(/ /g,"_");

    //     return new Promise((resolve) => {
    //     // Create blob from local file reference
    //     fetch(previewImg)
    //     .then((response) => {
    //       return response.blob()
    //     })
    //     .then((blob) => {
    //       // Create a root reference
    //       const storageRef = firebase.storage().ref();
    //       const file = blob 
    //       const metadata = {
    //         contentType: 'img/png'
    //       };
    //       const uploadTask = storageRef.child('programimgs/' + filename).put(file, metadata);

    //         // Upload status
    //         uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    //         function(snapshot) {
    //           // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //           var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //           console.log('Upload is ' + progress + '% done');
    //           switch (snapshot.state) {
    //             case firebase.storage.TaskState.PAUSED: // or 'paused'
    //               console.log('Upload is paused');
    //               break;
    //             case firebase.storage.TaskState.RUNNING: // or 'running'
    //               console.log('Upload is running');
    //               break;
    //             default:
    //           }
    //         }, function(error) {

    //         switch (error.code) {
    //           case 'storage/unauthorized':
    //             // User doesn't have permission to access the object
    //             break;

    //           case 'storage/canceled':
    //             // User canceled the upload
    //             break;

    //           case 'storage/unknown':
    //             // Unknown error occurred, inspect error.serverResponse
    //             break;
    //           default:
    //         }
    //       }, function() {
    //         // Upload completed successfully, now we can get the download URL
    //         uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
    //           previewImgUrl = downloadURL;
    //           collectProgramInfo(program, previewImgUrl)
    //           return resolve();
    //         });
    //       });
    //     });
    //   })
    // })

    // function collectProgramInfo(program, previewImgUrl) {
    //   console.log(program.sortId)
    //   let programInfo = {
    //     previewImgUrl: previewImgUrl,
    //     // videoUrl: program.videoUrl,
    //     programCollectionId: program.programCollectionId,
    //     //focus: program.focus,
    //     publishedAt: program.publishedAt,
    //     sortId: program.sortId.toString().split(","),
    //     category: program.category.split(","),
    //     title: program.programTitle,
    //     tagLine: program.programTagline,
    //     programDescriptionShort: program.hasOwnProperty("programDescriptionShort") ? program.programDescriptionShort : "",
    //     programDescriptionLong: program.hasOwnProperty("programDescriptionLong") ? program.programDescriptionLong : "",
    //     backgroundPreviewImg: "2deg;rgba(255, 237, 75, 1);20%;rgba(253, 205, 59, 1);60%"  //"linear-gradient(2deg, #ffed4b 20%, #fdcd3b 60%)",
    //     //backgroundPreviewImg: program.backgroundPreviewImg,
    //     ////textAlign: program.textAlign,
    //     ////spanRight: program.spanRight,
    //     ////spanLeft: program.spanLeft,
    //     // season: program.season,
    //     // episode: program.episode,
    //     // episodeTitle: program.episodeTitle,
    //   }

    //   programInfoCollection.push(programInfo)
    // }

    //   Promise.all(uploadPreviewImgAndCollectProgramInfo).then(() => {
    //     uploadProgramInfo()
    //   })

    //   function uploadProgramInfo() {
    //     // db.collection("programs").add({

    //       db.collection("programs").doc("zf7HLaEW7NPYSE3ghQyW").get().then(function(doc) {
    //         if (doc.exists) {
    //             let existingProgramInfo = doc.get("programInfoCollection") //[...doc.data()]
    //             uploadAllProgramInfo(existingProgramInfo)
                
    //         } else {
    //             // doc.data() will be undefined in this case
    //             console.log("No such document!");
    //         }
    //     }).catch(function(error) {
    //         console.log("Error getting document:", error);
    //     });

    //     function uploadAllProgramInfo(existingProgramInfo) {

    //       //programInfoCollection = typeof existingProgramInfo !== undefined ? programInfoCollection : existingProgramInfo.concat(programInfoCollection);
    //       console.log("programInfoCollection", programInfoCollection)
    //       programInfoCollection = existingProgramInfo.concat(programInfoCollection);

    //       db.collection("programs").doc("zf7HLaEW7NPYSE3ghQyW").set({
    //         programInfoCollection
    //       })
    //       .then(function(docRef) {
    //         console.log("Document successfully updated");
    //       })
    //       .catch(function(error) {
    //         console.error("Error adding document: ", error);
    //       })
    //     }
    //   }
    // }
    
        return ( 
          <div className={"upload-interface-c"}>
            <div className={"item-upload item-upload--show"}>
              {/* <button
                onClick={() => handleUploadNewPrograms()}
              >upload</button> */}
            </div>  
            <div className={"item-upload item-upload--show"}>
              
            <form
              onSubmit={ async (e) => {
                e.preventDefault(); // prevent page refresh
                login({ email, password })
                setPassword("")
                setEmail("")
            }}
            >
              <label>login: </label>
              <br></br>
              <input type="text" placeholder="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              ></input>
              <input type="text" placeholder="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              ></input>
              <input type="submit" value="Submit"></input>
            </form>

            <br></br>

            <form
              onSubmit={ async (e) => {
                e.preventDefault(); // prevent page refresh
                const categoryNameId = channelIdentifier.startsWith("UC") ?
                  categories.find((category) => category["categoryId"] === channelIdentifier) :
                  categories.find((category) => category["category name"] === channelIdentifier)
                  ,
                [document, channelData] = await requestChannelData({ channelIdentifier })
                await addDocument({ collection: "channels", document: document, payload: channelData }) ///await uploadChannelData({ document, channelData })

                setChannelIdentifier('');
            }}
            >
              <label>request and upload channel info: </label>
              <br></br>
              <input type="text" placeholder="@channel-name or channel-id"
                value={channelIdentifier}
                onChange={e => setChannelIdentifier(e.target.value)}
              ></input>
              <input type="submit" value="Submit"></input>
            </form>

            <br></br>

            <button
                onClick={ async (e) => {
                  console.log(channels)
                  channels  
                    .map( async (channel) => {
                      const channelIdentifier = channel.staticData.channelId
                      const [document, channelData] = await requestChannelData({ channelIdentifier })
                      await addDocument({ collection: "channels", document: document, payload: channelData }) ///await uploadChannelData({ document, channelData })
                    })
                }}
              >update (rewrite) all channels
            </button>

            <br></br>
            <br></br>

            <form
              onSubmit={(e) => {
                e.preventDefault()
                channels  
                    .filter((channel) => updateChannelsSelectedChannels.includes(channel.staticData.title)) ///note: if isselection true, filter for only selected channels
                    .map( async (channel) => {
                      const channelIdentifier = channel.staticData.channelId
                      const [document, channelData] = await requestChannelData({ channelIdentifier })
                      await addDocument({ collection: "channels", document: document, payload: channelData })///await uploadChannelData({ document, channelData })
                    })
              }} // prevent page refresh
            >
              <select multiple
                onChange={(e) => {
                  const 
                    selection = Array.from(e.target.selectedOptions, option => option.value);
                    setUpdateChannelsSelectedChannels(selection)         
                }}
              >
                {channels.map((channel, index) => 
                  <option key={channel.staticData.title} value={channel.staticData.title}
                  >{index}  {channel.staticData.title} 
                  </option>
                )}
              </select>
              <input 
              type="submit" value="update (rewrite) specific channels"></input>
            </form>

            <label>request playlist and playlisteitems to create programs + request videos and statistics to create videos: </label>
            <br></br>
            <label>request all data: </label>
            <select 
                onChange={(e) => {
                  const requestAll = e.target.value
                  setRequestAll(requestAll)         
                }}
              >
                  <option key={false}>false</option>
                  <option key={true}>true</option>
              </select>
            
            <br></br>

            <button
                onClick={ async (e) => {
                  const 
                    isSelection = false,
                    selection = [] ///note: not read
                  getAll({ isSelection, selection, requestAll })
                }}
              >get for all channels
            </button>

            <br></br>
            <br></br>

            <button
                onClick={ async (e) => {
                  const videoId = "OfJl_jhJcWw"
                  const transcript = requestCaption({ videoId })
                  console.log("transcript", transcript)
                }}
              >get video transcript
            </button>

            <br></br>
            <br></br>

            <form
              onSubmit={(e) => {
                e.preventDefault()
                const 
                  isSelection = true,
                  selection = selectedChannels
                getAll({ isSelection, selection, requestAll })
              }} // prevent page refresh
            >
              <select multiple
                onChange={(e) => {
                  const 
                    selection = Array.from(e.target.selectedOptions, option => option.value);
                    setSelectedChannels(selection)         
                }}
              >
                {channels.map((channel, index) => 
                  <option key={channel.staticData.title} value={channel.staticData.title}
                  >{index}  {channel.staticData.title} 
                  </option>
                )}
              </select>
              <input 
              type="submit" value="get for selected channels"></input>
            </form>

            <br></br>

            <label>create bundle program </label>
            <button 
                onClick={ async (e) => {
                  const 
                    programs = await getPrograms()

                    bundlePlaylistsData.map(async (bundleObj) => 
                    Object.values(bundleObj).map(async (bundles) => 
                        bundles.map( (bundle) => {
                          if (!bundle.isUploaded) {
                            const 
                                programTitle = Object.keys(bundleObj)[0]
                                const program = createProgramFromBundle({ programs, programTitle, bundle }),
                                document = programTitle
                                addProgram({ document, program })
                        }
                        })
                    )
                )
                }}
              >create
            </button>

            <br></br>
            <br></br>

            <form 
              onSubmit={ async (e) => {
                e.preventDefault(); // prevent page refresh
                const 
                  responseVideo = await requestVideo({ videoId }), ///note: example: mgVHZnn1WP0
                  categoryNameIds = ["test"],
                  video = await createVideo({ responseVideo, categoryNameIds })
                  const payload = Object.assign({}, ...video);
                  console.log("cratevideo", responseVideo, payload)
                setVideoId('');
            }}
            >
              <label>get video by id: </label>
              <input type="text" placeholder="vidio id"
                value={videoId}
                onChange={e => setVideoId(e.target.value)}
              ></input>
              <input type="submit" value="Submit"></input>
            </form>


              {/* <button
                onClick={() => playListToExcel()}
              >playList</button> */}
          </div>  
        </div>  
      );
    }
  
 
export default UploadNewPrograms