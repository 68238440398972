import '../src/dnd-dropzone.css';
import { memo, useEffect, useCallback, useState, useContext } from 'react'
import { useDrop, DropTarget } from 'react-dnd'
import { Category } from './CategoryGallery2';
import { PlComponent } from './PlGallery';
import { useLongPress } from 'use-long-press';
import { focusStatesContext, videosDataContext, channelsDataContext, categoriesDataContext, plsDataContext } from './Home2';

// const style = {
//   height: '12rem',
//   width: '12rem',
//   marginRight: '1.5rem',
//   marginBottom: '1.5rem',
//   color: 'white',
//   padding: '1rem',
//   textAlign: 'center',
//   fontSize: '1rem',
//   lineHeight: 'normal',
//   float: 'left',
// }
export const Dustbin = memo(function Dustbin({
  accept,
  lastDroppedItem,
  onDrop,
  isShow,
}) {
  
  const [collectedProps, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      hoverItemType: monitor.getItemType() ///note: only at hover, not at/after drop...
    })
  })

  const { isOver, canDrop, hoverItemType } = collectedProps

  const swrVideos = useContext(videosDataContext)
  const videos = swrVideos?.data ? swrVideos.data : []

  const [isLongPressed, setIsLongPressed] = useState(false)

  const isActive = isOver && canDrop

  let backgroundColor = 'rgba(255, 255, 255, 0.1)'
  if (isActive) {
    backgroundColor = '#92c891'
  } else if (canDrop) {
    backgroundColor = '#fbe58b'
  }

  const Component = ({ withLink }) => {
    
    const itemType = lastDroppedItem.draggedItem?.type ? ///note/optimise: preferently component is determined from itemtype (as in itemtypes.js), but not succesful in retriving (at/after drop) => thus component type is based on reading the content diretly... (minor risk at changes to data structure) 
      lastDroppedItem.draggedItem.type : ///optimise: use of type not template ...
      lastDroppedItem.draggedItem.staticData.template
    switch (true) {
      case itemType === "category":
        return <Category
          category={lastDroppedItem.draggedItem}
          subscriberText={""}
          withLink={withLink} 
        /> 
        break;
      case itemType === "pl":

        return <PlComponent 
            id={"diamont-pl-component"}
            isGallery={true}
            pl={{...lastDroppedItem.draggedItem, id: "diamont-pl-component"}}
            // background={background}
            // imgFile={categoryImg}
            videos={videos}
            isMuted={true}
        />
        break;
      case itemType === "video":
        break;
      default:
        <></>
      }
  }

  const longPressCallback = useCallback(() => {
    setIsLongPressed(true);
  }, []);

  const pressFunc = useLongPress(true ? longPressCallback : null, {
    onFinish: (event, meta) => {
      setIsLongPressed(false);
    },
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 100,
    // captureEvent: true,
    // cancelOnMovement: false,
    // cancelOutsideElement: true,
    // detect: LongPressEventType.Pointer
  });

  return (
    <div 
      className={"dnd-dropzone"}
      ref={drop} 
      style={{
        // ...style, 
        width: lastDroppedItem ? "100%" : "100%", ///isActive ? "110%" : "30%",
        //paddingBottom: lastDroppedItem ? "0" : isActive ? "110%" : "30%"
      // padding: isActive ? "4em" : "0em"
        }} 
      data-testid="dustbin">
        {isActive
          ? '' ///Release to drop
          : '' ///`This dustbin accepts: ${accept.join(', ')}`
        }

        {lastDroppedItem && (
          <div className={"list-counter-container"}>
              <div className={"list-counter-background"}></div>
              <div className={"list-counter"}
                listCounter={1}
                {...pressFunc()} 
              >
                <Component
                  withLink={!isLongPressed}
              />
              </div>
            </div>
        // <p>Last dropped: {JSON.stringify(lastDroppedItem)}</p>
        )}
            <div  
              className={`
                dnd-dropzone-mark
                ${isShow ? "mark-fade-in" : "mark-fade-out"}
                `}
              style={{
                width: lastDroppedItem ? "100%" : isActive ? "100%" : "30%",
                backgroundColor: lastDroppedItem ? "" : backgroundColor,
                paddingBottom: lastDroppedItem ? "0" : isActive ? "100%" : "30%"
              }} 
            >
              <i className={`
                fa fa-solid fa-clone
                ${isActive ? "fade-out" : "fade-in"}
                `}></i>
            </div>
    </div>
  )
})
