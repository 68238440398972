import './app.css';
import './styling-standards.css';
import './video.css';
import { useState, useEffect, useContext, memo } from 'react';
import ReactPlayer from 'react-player';
import { PreLoader } from './StandardComponents';
///import { mediaIdContext, videoPlayContext } from './MoreMedia'; ///reduced away
import * as generics from './generics';

function Video( { videoUrl, mediaId, light } ) {

  ///const { mediaId_a, setMediaId_a } = useContext(mediaIdContext); ///reduced away
  ///const { videoPlay, setVideoPlay } = useContext(videoPlayContext);
  const [readyPreLoader, setReadyPreLoader] = useState(false)

  const video_a = true ///generics.stringsAreIdentical(mediaId, mediaId_a) ///reduced away

  const handleMouseEvent = (e, mediaId) => {
    // const entered = e.type === "mouseenter" ///temp removed due to no mediaidcontext
    // const mediaId_ = entered ? mediaId : "" ///temp removed due to no mediaidcontext
    // setMediaId_a(mediaId_) ///case false => mouseleaves ///temp removed due to no mediaidcontext

    // ///setReadyPreLoader(true) ///optimse: not implemented as not able to turn off...
  }

  const onVideoStart = () => { ///note: unexpected behavoir: not called upon secondary entry
    ///setVideoPlay(true) ///reduced away
    setReadyPreLoader(false)
  }

  const onVideoPause = () => {
  }

  const onVideoEnded = () => {
  }
	
  return (
	  <div className={"video-wrapper"}>
      {/* <PreLoader
        show={!readyPreLoader}
      ></PreLoader> */}
      <div className={"video"}
      >
        <ReactPlayer
            className='react-player'
            url={videoUrl}
            width='100%'
            height='100%'
            light={light}
            //style={{borderRadius: "50px"}}
            //playIcon={kvadratisk}
            playing={false}
            muted={true} //{videoChosen ? false : true}
            // onStart={() => props.onPlayStart()}
            // volume={0 to 1}
            //pip={this.props.pipActivated ? true : false}
            onStart={(e) => onVideoStart(e)}
            onPause={(e) => onVideoPause(e)}

            // onEnded={() => props.onMediaEnded()}
            controls={false} //{videoChosen ? true : false}
            //onProgress={(loaded) => console.log(loaded)}
            //onDuration={(loaded) => console.log(loaded)}
            onEnded={() => onVideoEnded()}
            onMouseEnter={(e) => handleMouseEvent(e, mediaId)}
            onMouseLeave={(e) => handleMouseEvent(e, mediaId)}
          />
      </div>
    </div>
  );;
}

export default memo(Video)