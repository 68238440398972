import '../src/popularity-components.css';

const
    viewBadge = ({ size, color }) =>
        <span className={`fa-stack ${size}`}>
            <></> 
            {/* <i class="icon-stack-outer fa-solid fa-certificate fa-stack-2x"></i> */}
            <i 
                style={{background: color, WebkitBackgroundClip: "text", color: "transparent"}} 
                className="icon-stack-inner fa-solid fa-eye fa-stack-1x">
            </i> 
        </span>,
    likeBadge = ({ size, color }) =>
        <span className={`fa-stack ${size}`}>
            <></> 
            {/* <i class="icon-stack-outer fa-solid fa-certificate fa-stack-2x"></i> */}
            <i 
                style={{background: color, WebkitBackgroundClip: "text", color: "transparent"}} 
                className="icon-stack-inner fa-solid fa-thumbs-up fa-stack-1x">
            </i> 
        </span>,
    commentBadge = ({ size, color }) =>
        <span className={`fa-stack ${size}`}>
            <></> 
            {/* <i class="icon-stack-outer fa-solid fa-certificate fa-stack-2x"></i> */}
            <i 
                style={{background: color, WebkitBackgroundClip: "text", color: "transparent"}} 
                className="icon-stack-inner fa-solid fa-comments fa-stack-1x">
            </i> 
        </span>
export const badges = [likeBadge({size: "fa-2x"}), viewBadge({size: "fa-2x"}), commentBadge({size: "fa-2x"})]
export const popularityParameters = ["likeCount", "viewCount", "commentCount"]
const badgeColors = [ ///https://codepen.io/ElJefe/pen/aprLdG
        "linear-gradient(-45deg, #BF953F, #FCF6BA, #bc8a27, #FBF5B7, #AA771C, #BF953F, #FCF6BA, #bc8a27, #FBF5B7, #AA771C, #BF953F, #FCF6BA, #bc8a27, #FBF5B7, #AA771C, #FCF6BA, #bc8a27, #FBF5B7, #AA771C)",
        "linear-gradient(-45deg, #B1B1B1, #fbfbfb, #CFCFCF, #B1B1B1, #fbfbfb, #CFCFCF, #B1B1B1, #fbfbfb, #CFCFCF, #B1B1B1, #fbfbfb, #CFCFCF, #B1B1B1, #fbfbfb, #CFCFCF, #B1B1B1, #fbfbfb, #CFCFCF, #B1B1B1, #fbfbfb, #CFCFCF)", 
        "linear-gradient(-45deg, #db6d0e, #ffb575, #db6d0e, #ffb575, #db6d0e, #db6d0e, #ffb575, #db6d0e, #ffb575, #db6d0e, #db6d0e, #ffb575, #db6d0e, #ffb575, #db6d0e, #ffb575, #db6d0e, #ffb575, #db6d0e)"
    ]
export const popularityBadgeInfo = 
    <div className={"popularity-badge-info"}>
        {likeBadge({color: badgeColors[0]})}
        {viewBadge({color: badgeColors[0]})} 
        {commentBadge({color: badgeColors[0]})} 
        {<span className={"text"}>most liked</span>} 
        {<span className={"text"}>most viewed</span>} 
        {<span className={"text"}>most commented</span>} 
    </div>

export const popularityBadgeStats = ({ stats }) => {
    return (
    <div className={"popularity-badge-stats"}>
        {<span className={"text"}>{stats[0]}</span>} 
        {likeBadge({color: badgeColors[0]})}
        {<span className={"text"}>{stats[1]}</span>} 
        {viewBadge({color: badgeColors[0]})} 
        {<span className={"text"}>{stats[2]}</span>} 
        {commentBadge({color: badgeColors[0]})} 
    </div>
    )
}
		
        
    const getBadgeComponents = (index, i_) => { //popularityParameters.map((parameter) => {
        const color = badgeColors[i_]
        return  {
                ...badges[index], ///copy everything from badges[index]
                props: { ///override the props property
                    ...badges[index].props, ///copy everything from bagdes[index].props
                    children: [ ///override the children property
                        ...badges[index].props.children
                            .map((child, i) => {
                                return i === 1 ?
                                    {
                                        ...child, 
                                        props: {
                                            ...child.props, 
                                            ["style"]: { 
                                                background: color,
                                                WebkitBackgroundClip: "text",
                                                color: "transparent"
                                            },
                                            key: {index, i_, i}
                                        }
                                    } : 
                                    { 
                                        ...child, 
                                        key: {index, i_, i}
                                    }
                            })
                    ]
                }
            }
        }
    
    const getPopularityVideosSubset = ({ categoryFocus, videos, plFocus }) => {
        const 
            isVideosSubset = plFocus.staticData.plTitle.includes("Most Popular Videos Of The Last"), ///note: if program fullfills critiera => popularcompoents computed and shown for only that program (i.e. videos in that program)
            plFocusUrls = plFocus.dynamicData.plVideoCollection.map(video => video.videoUrl) ///note: vidoes (via urls) in selected program (i.e. part of plFocus) 
            const popularityVideosSubset = isVideosSubset ?
                [...videos]
                    .filter(video => video.staticData["category name id"].includes(categoryFocus)) ///note: videos in given category (channel)
                    .filter(video => plFocusUrls.includes(video.staticData.videoId)) 
                    : ///note: only videos (via urls) that is part of selected program (i.e. part of plFocus)
                [...videos]
                    .filter(video => video.staticData["category name id"].includes(categoryFocus)) ///note: videos in given category (channel)
        return popularityVideosSubset
    }            
    
    const getPopularVideos = ({ categoryFocus, videos, plFocus }) => 
        popularityParameters.map((parameter) => {
            // const 
            //     videoCollection = [...videos],
            //     isFilterVideoCollection = plFocus. //props.activeProgramTagline.includes("Most Popular Videos Of The Last")
            // let filteredVideoCollection = [] //videoCollection.filter(video => currentProgramUrls.includes(video.videoUrl))
            //     if (isFilterVideoCollection) {
            //         const currentProgramUrls = props.programCollectionId !== "" && props.programs.find(program => program.programCollectionId === props.programCollectionId).plVideoCollection.map(video => video.videoUrl)
            //         filteredVideoCollection = videoCollection.filter(video => currentProgramUrls.includes(video.videoUrl))
            //     }
            // const popularVideoCollection = isFilterVideoCollection ?
            //     filteredVideoCollection : videoCollection
            const popularVideos = getPopularityVideosSubset({ categoryFocus, videos, plFocus })
                return {
                    [parameter]: popularVideos
                        //.filter(video => video.channelTitle === props.activeCategory)
                        .sort((a, b) => b.stats[parameter] - a.stats[parameter])
                        .slice(0, 3)
                        .map(video => video.staticData.videoId)
                }
        })
    

    export const getPopularityComponents = ({ swrVideos, categoryFocus, videos, plFocus, videoUrl }) => {
        if (swrVideos.isError || swrVideos.isLoading) { ///if videos (incl statistics) not downloaded
            return null
        }
        let bagdeComponents = []
        const popularVideos = getPopularVideos({ categoryFocus, videos, plFocus })
        popularVideos.map((parameterObj, index) =>
            Object.values(parameterObj).map((videoUrls) =>
                videoUrls.map((videoUrl_, i_) => {
                    if (videoUrl === videoUrl_) {
                        const bagdeComponent = getBadgeComponents(index, i_) 
                        bagdeComponents.push(bagdeComponent)
                    }
                })
            )
        )
        return bagdeComponents
    }