import React, { Component, Fragment, useMemo } from 'react';
import ReactPlayer from 'react-player';
//import '../src/program-gallery.css';
import {
  //BrowserRouter as Router,
  Link
} from "react-router-dom";
import { colorsLight } from './colors';
//import { programTitles } from './programs/yoga-with-adriene';
import { categories, categoriesExtraData, stringInArray, typesCategories, youTubeChannelCategories } from './CategoryGalleryData';
import { badges, popularityParameters } from './PopularityComponents';
import { useCallback } from 'react';
import { focusElementsContext } from './Home2';
// import { Thumbnails } from 'youtubei';

const bestOf = {
  "yoga with adriene": [
    {"30 days yoga journeys (by date)": ["CENTER - A 30 Day Yoga Journey", "MOVE - A 30 Day Yoga Journey", "BREATH - A 30 Day Yoga Journey", "HOME - A 30 Day Yoga Journey", "DEDICATE - A 30 Day Yoga Journey", "YOGA CAMP - 30 Day Home Practice", "30 Days of Yoga"]},
    {"notable playlists (by date)": ["BREATH - A 30 Day Yoga Journey", "HOME - A 30 Day Yoga Journey", "DEDICATE - A 30 Day Yoga Journey", "YOGA CAMP - 30 Day Home Practice", "30 Days of Yoga"]}
  ]
}

const excludePlaylists = {
  "yoga with kassandra": [
    "Day 7 - Sunday Yoga & Meditation", ///included in other program
    "Day 6 - Saturday Yoga & Meditation",
    "Day 5 - Friday Yoga & Meditation",
    "Day 4 - Thursday Yoga & Meditation",
    "Day 3 - Wednesday Yoga & Meditation",
    "Day 2 - Tuesday Yoga & Meditation",
    "Day 1 - Monday Yoga & Meditation",

    "15 min Yin Yoga Stretches – Day #30 (RELAXING YOGA BEFORE BEDTIME)",
    "15 min Yoga for Sleep – Day #29 (YOGA STRETCHES BEFORE BED)",
    "15 min Yoga Class – Day #28 (EVENING YOGA STRETCHES FOR BEGINNERS)",
    "15 min Evening Yoga – Day #27 (YOGA FOR SORE MUSCLES)",
    "15 min Yoga for Flexibility – Day #26 (BEDTIME YIN YOGA CLASS)",
    "15 min Before Bed Yoga – Day #25 (FULL BODY RELAXING YOGA)",
    "15 min Sleep Yoga – Day #24 (WIND DOWN YOGA FLOW)",
    "15 min Evening Yoga Flow – Day #23 (BEDTIME YOGA FULL BODY)",
    "15 min Yin Yoga for Sleep – Day #22 (YOGA FOR FLEXIBILITY & RELAXATION)",
    "15 min Bedtime Yoga – Day #21 (YOGA FOR HIPS & LOWER BACK)",
    "15 min Evening Yoga Class – Day #20 (RELAXING YOGA STRETCHES BEFORE BED)",
    "15 min Bedtime Yoga Stretches – Day #19 (EVENING YOGA FLOW)",
    "15 min Evening Yin Yoga – Day #18 (YOGA STRETCHES BEFORE BED)",
    "15 min Before Bed Yoga – Day #17 (EVENING YOGA FOR BEGINNERS)",
    "15 min Yoga for Flexibility – Day #16 (BEDTIME YOGA CLASS)",
    "15 min Evening Yoga Flow – Day #15 (BEFORE BED YOGA)",
    "15 min Sleep Yoga – Day #14 (FULL BODY RELAXING YIN YOGA)",
    "15 min Bedtime Yoga – Day #13 (YOGA FOR SLEEP & RELAXATION)",
    "15 min Yoga for Sleep – Day #12 (YOGA FOR HIPS & LOWER BACK)",
    "15 min Yoga Stretches – Day #11 (BEDTIME YOGA FULL BODY)",
    "15 min Yin Yoga Class – Day #10 (EVENING YOGA FOR BEGINNERS)",
    "15 min Evening Yoga – Day #9 (WIND DOWN YOGA FLOW)",
    "15 min Yoga for Flexibility – Day #8 (EVENING YOGA FULL BODY)",
    "15 min Evening Yoga Stretches – Day #7 (BEDTIME YOGA FOR BEGINNERS)",
    "15 min Sleep Yoga – Day #6 (YIN YOGA FOR SORE MUSCLES)",
    "15 min Bedtime Yoga – Day #5 (RELAXING YOGA STRETCHES BEFORE BED)",
    "15 min Yoga for Sleep – Day #4 (EVENING YOGA FLOW)",
    "15 min Evening Yoga Class – Day #3 (YOGA STRETCHES BEFORE BED)",
    "15 min Yin Yoga Stretches – Day #2 (EVENING YOGA FOR BEGINNERS)",
    "15 min Evening Yoga – Day #1 (YOGA FOR FLEXIBILITY AND RELAXATION)",

    "10 min FULL BODY Intermediate Morning Yoga – Day #30 (FULL BODY MORNING YOGA FLOW)",
    "10 min Morning Yoga Stretch To ENERGIZE – Day #29 (10 MIN MORNING STRETCH)",
    "10 min Morning Yoga To Kick Start Your Day – Day #28 (MORNING YOGA FOR ENERGY)",
    "10 min Morning Yoga For Your BACK – Day #27 (10 MIN BACK STRETCH YOGA)",
    "10 min SUNRISE FEEL ALIVE Yoga Flow – Day #26 (FEEL ALIVE MORNING STRETCH)",
    "10 min Morning Yoga For Shoulder Release – Day #25 (SHOULDER STRENGTH & STRETCH)",
    "10 min Morning Yoga DEEP Stretch – Day #24 (DEEP FULL BODY STRETCH)",
    "10 min Morning Yoga Stretches For Tired & Tight Legs - Day #23  (TIRED & TIGHT LEGS YOGA)",
    "10 min Morning Yoga To Start Your Day Off RIGHT! – Day #22 (10 MIN YOGA STRETCH)",
    "10 min Full Body Flexibility Yoga To WAKE UP – Day #21 (MORNING YOGA FOR FLEXIBILITY)",
    "10 min Morning Yoga to Feel Your Best! – Day #20 (10 MIN YOGA STRETCHES)",
    "10 min Morning Yoga For Hips & Lower Back – Day #19 (HIPS & BACK MORNING YOGA)",
    "10 min Wake-Up Yoga For FLEXIBILITY – Day #18 (10 MIN MORNING YOGA)",
    "10 min Morning Yoga For Upper Body STRENGTH – Day #17 (10 MIN ARM YOGA)",
    "10 min Morning Yoga To Stretch & Soothe For Pain Release – Day #16 (STRETCH & SOOTHE)",
    "10 min SUNRISE Full Body Yoga – Day #15 (FULL BODY MORNING YOGA)",
    "10 min Yoga For Lower Back Pain Release - Day #14 (LOWER BACK MORNING YOGA STRETCHES)",
    "10 min Morning Yoga Flow To DETOX & DESTRESS – Day #13 (DESTRESS YOGA)",
    "10 min COMPLETE Full Body Yoga Stretch – Day #12 ( FULL BODY MORNING YOGA)",
    "10 min Morning Yoga to ENERGIZE Your Day– Day #11 (ENERGIZING YOGA STRETCH)",
    "10 min Morning Yoga For Neck & Upper Back Relief – Day #10 (NECK & BACK YOGA STRETCH)",
    "10 min BEST Morning Yoga Practice – Day #9 (10 MINUTE WAKE UP STRETCH)",
    "10 min FULL BODY Stretch Wake Up Yoga – Day #8 (FULL BODY MORNING YOGA)",
    "10 min Morning Yoga For Anxiety & Stress– Day #7 (ANXIETY & STRESS STRETCH)",
    "10 min SUNRISE Yoga for Energy – Day #6 (REFRESHING MORNING YOGA STRETCH)",
    "10 min FULL BODY Flow Morning Yoga Practice – Day #5 (FULL BODY STRETCH)",
    "10 min Morning Yoga For Neck & Shoulder Relief – Day #4 (NECK & SHOULDER STRETCHES)",
    "10 min Morning Yoga Stretches – Day #3 (10 MIN FULL BODY YOGA)",
    "10 min WAKE UP Full Body Yoga Stretch – Day #2 (MORNING YOGA STRETCHES)",
    "10 min Morning Yoga Stretch For BEGINNERS – Day #1 (10 MIN BEGINNER YOGA)"
]
}

export const bundlePlaylistsData = [
  { "yoga with kassandra": [
    { 
      isUploaded: true,
      programTagline: "x",
      videos: [
          [
            "x"
          ]
      ]
    },
  ]
  }
]


//props.currentPrograms.filter((program => bundlePlaylists[props.currentCategory].includes(program.programTagline)))

const ProgramGallery = (props) => {
console.log("fuc")
  // constructor(props) {
  //   super(props);
  //   // this.handleimgChange = handleimgChange.bind(this)
  //   this.state = {
  //     // imgLoadCounter: 0,
  //     // programimgsLoaded: false,
  //     // categoryimgsLoaded: false,
  //     // redirect: false,
  //     // categoryUrl: null,
  //   }
  // }


  // handleRouting = (e) => {
  //   // const mainGalleryELementSelectedId = Math.round(e.target.id)
  //   // const programQueryText = props.categoryGalleryData[mainGalleryELementSelectedId-1].category
  //   // this.setState({redirect: true, categoryUrl: programQueryText});
  // }



  // handleimgChange = () => {
  //   this.setState({imgLoadCounter: this.state.imgLoadCounter + 1})
  //   (this.state.imgLoadCounter)
  //   let noOfPrograms = props.programs.filter(program => program.category.includes(props.programQueryText)).length
  //   if (noOfPrograms === this.state.imgLoadCounter) {
  //     console.log("laoded", noOfPrograms)
  //   }
  // };




    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // if (this.state.redirect) {
    //   const categoryUrl = "/" + this.state.categoryUrl
    //   return <Redirect push to={`${categoryUrl}`} />;
    // }

    // let programimgLoadCounter = 0
    // let noOfPrograms = props.programs.filter(program => program.category.includes(props.programQueryText)).length
    // this.handleProgramimgChange = () => {
    //   programimgLoadCounter = programimgLoadCounter + 1
    //   if (noOfPrograms === programimgLoadCounter) {
    //     this.setState({programimgsLoaded: true});
    //     programimgLoadCounter = 0
    //   }
    // }

    // let categoryimgLoadCounter = 0
    // let noOfCategories = props.categoryGalleryData.length
    //  this.handleCategoryimgChange = () => {
    //   categoryimgLoadCounter = categoryimgLoadCounter + 1
    //   if (noOfCategories === categoryimgLoadCounter) {
    //     this.setState({categoryimgsLoaded: true});
    //     categoryimgLoadCounter = 0
    //   }
    // }

    //const programGallerySlideUp = props.gallery === "category" && props.videoChosen === false && props.searchText === "" && props.programimgsLoaded
    // const recommendedProgramCollectionIds = props.currentRecommenderId !== 0 ? props.recommendationsList.filter(recommendation => recommendation.individualId === props.currentRecommenderId).map(recommendation => recommendation.programCollectionId) : 0
    // const recommendedPrograms = props.currentRecommenderId !== 0 ? props.programs.filter(program => recommendedProgramCollectionIds.includes(program.programCollectionId)) : null

    // const matchAllPrograms =  props.programs.filter(program => program.category.some(category => category.toLowerCase().includes(props.programQueryText)))
    // // const programQueryText = props.programQueryText === "" :
    // const matchAllProgramsInCategory = props.searchText === "" ? 
    //   props.programs.filter(program => program.category.some(category => category.toLowerCase() === props.currentCategory)) : 
    //   props.programs.filter(program => program.category.some(category => category.toLowerCase() === props.currentCategory)).filter(program => program.category.some(category => category.toLowerCase().includes(props.searchText)))

    // const currentPrograms = props.currentCategory !== "" ? matchAllProgramsInCategory : matchAllPrograms

    // const programCollectionIds = props.programs.map(program => program.programCollectionId)
    // const randomProgramCollectionId = programCollectionIds[Math.floor(Math.random() * programCollectionIds.length)];
    // const randomProgramSuggestion = props.programs.filter(program => program.programCollectionId === randomProgramCollectionId)



    
    const PopularityComponent = ({ videoUrl }) => {
      const 
        isInVideoCollection = !(typeof props.videoCollection.find(video => video.videoUrl === videoUrl) === 'undefined'),
        activeVideo = isInVideoCollection ? props.videoCollection.find(video => video.videoUrl === videoUrl) : ""
      return (
        <div className={`
          program-popularity-c
          ${props.videoDescriptionsShow ? "program-popularity-show" : "program-popularity-hide"}
          `}>
          {popularityParameters.map((parameter, index) => 
            <span 
              key={`${parameter}-${index}`}
              className={`program-popularity-span`}>
              {badges[index]}~{activeVideo[parameter]}
            </span>
          )}
        </div>
      ) 
    }

    const background =
      typesCategories.indexOf(props.currentCategory) !== -1 ?
        colorsLight[`${typesCategories.indexOf(props.currentCategory) > colorsLight.length ? typesCategories.indexOf(props.currentCategory) % colorsLight.length : typesCategories.indexOf(props.currentCategory)}`] : ///was: colorsLight[Math.floor(Math.random() * (colorsLight.length - 0 + 1)) + 0] ///note: or index
        colorsLight[`${youTubeChannelCategories.indexOf(props.currentCategory) > colorsLight.length ? youTubeChannelCategories.indexOf(props.currentCategory) % colorsLight.length : youTubeChannelCategories.indexOf(props.currentCategory)}`] //categoriesExtraData.extraCategory[props.currentCategory] !== undefined && colorsLight[typesCategories.indexOf(categoriesExtraData.extraCategory[props.currentCategory][0])]

    //const x = headers.map((header) => console.log(dummyElements(header.position)))
    let currentPrograms_ = [...props.programs] //.filter(program => program.category.includes(focusElementsContext))] ///// [...props.currentPrograms]

    currentPrograms_.sort((a,b) => new Date (b.publishedAt) - new Date (a.publishedAt))
  //   if (programsFromBundle.length > 0) {
  //   const x = currentPrograms_.splice(5, 0, ...programsFromBundle[0])
  // }

    let currentPrograms = excludePlaylists.hasOwnProperty(props.currentCategory) ?
      currentPrograms_.filter(( program ) => !excludePlaylists[props.currentCategory].includes(program.programTagline)) :
      currentPrograms_

    // let remove = []
    // remove = splice(myArray.findIndex(item => item.field === "cStatus"))

    const lengthHighlights = currentPrograms.filter((program) => program.hasOwnProperty("type") && program.type === "highlight").length //number of highlight programs
    const extraPlayListTaglines = bestOf.hasOwnProperty(props.currentCategory) ? 
      bestOf[props.currentCategory].map((playListTaglines => Object.values(playListTaglines))).flat().flat() :
      []
    const extraPlaylist = currentPrograms.filter((program => extraPlayListTaglines.includes(program.programTagline))) //|| {}).execs || [] ///note || ... to return [] if find return undefined (if extra program is specified, but program currently not present in programs at backend)
   
   if (currentPrograms.length > 0) {

      currentPrograms.splice(lengthHighlights, 0, ...extraPlaylist)

      const dummyElement = { category: "dummy", headerTitle: "dummy"}

      const fixedHeaders = [
        { category: "header", position: 0, headerTitle: "highlights" },
        { category: "header", 
            position: bestOf.hasOwnProperty(props.currentCategory) ? ///if extra header defined for current category (channel)
              lengthHighlights + 1 +
              bestOf[props.currentCategory].reduce((acc, obj) => acc + Object.values(obj).flat().length, 0) + ///number of total playlists in extra headers
              bestOf[props.currentCategory].length : ///add number of extra headers
              lengthHighlights + 1, ///if no extra headers
            headerTitle: `all playlists from youtube (by latest updated)` }
      ]

      const extraHeaders = bestOf.hasOwnProperty(props.currentCategory) ? 
        bestOf[props.currentCategory].map((headerObj, index) => {
          ///optimise: consider checking number of programs for each extra header and only map if programs found 
          return {
            category: "header",
            position: 
              lengthHighlights + 1 +
              bestOf[props.currentCategory].slice(0, index).reduce((acc, obj) => acc + Object.values(obj).flat().length, 0) + ///number of total playlists in extra headers, prior to current header (index)
              bestOf[props.currentCategory].slice(0, index).length, ///add number of extra headers, prior to current header (index)
            headerTitle: Object.keys(headerObj)[0]
            }
          })
        : []

      let headers =  [...fixedHeaders]
      headers.splice(1, 0, ...extraHeaders)

      const dummyElements = (amount) => Array(amount).fill(dummyElement)
      headers.map((header) => currentPrograms.splice(header.position, 0, header)) ///note: insert header
      headers.map((header, index) => {
        const prevHeaderIndex = index > 0 && currentPrograms.findIndex(program => program.headerTitle === headers[index - 1].headerTitle) 
        const headerIndex = currentPrograms.findIndex(program => program.headerTitle === header.headerTitle) 
        const trailingElementIndex = headerIndex - 1
        const indexDiff = trailingElementIndex - prevHeaderIndex
        const mapDummy = indexDiff % 3 > 0
        const dummyAmount = 3 - indexDiff % 3
        if (mapDummy) {
        currentPrograms.splice(trailingElementIndex + 1, 0, ...dummyElements(dummyAmount)) //note: insert dummyelements, if needed
        }
      })
   }

      // headers.map((header) => console.log(header.headerTitle, header.position, header.position % 3)) //note: insert dummyelements, if needed
    //const categoryGalleryCollection =  currentPrograms
    // props.programs.filter(program => program.category.some(category => category.includes(props.programQueryText))) :
    // props.programs.filter(program => program.programCollectionId === 2)
  //   let x = []
  // const u = [...currentPrograms].map((program, i) => i > 10 && i < 41 && x.push(program.programTagline))
  //   console.log(x)

    const CategoryGalleryData = useCallback(() =>
     [...currentPrograms]
      .map((program, i) => {
        const
          date = new Date(program.publishedAt),
          published = program.publishedAt === "Today" ? 
            "Today" : 
            `${monthNames[date.getMonth()]} ${date.getFullYear()}`,
          defaultPreviewUrl = "",
          programCategory = categoriesExtraData.alias.hasOwnProperty(program.programTitle) ?
            categoriesExtraData.alias[program.programTitle] : 
            program.programTitle
          
        let component = <></>
          switch(true) {
            case program.category === "header":
              component =
                <div
                  className={"program-gallery-header"}
                  style={{display: `${props.videoChosen ? "none" : ""}`}}
                >
                  {program.headerTitle}
                </div>
              break;
            case program.category === "dummy":
              component = 
                <div
                  className={"program-gallery-dummy"}
                  style={{display: `${props.videoChosen ? "none" : ""}`}}
                >
                </div>
              break;
            default:
              component = 
                <div className={`element-w
              ${Math.ceil((i + 1) / 5) * 5 === props.hoverFirstElementInRow && "scaledown"}
              ${props.activeVideoUrlIndex !== i && props.videoChosen && "element-w-video-chosen-not"}
              ${props.activeVideoUrlIndex === i && props.videoChosen && "element-w-video-chosen"}
              ${props.activeVideoUrlIndex === i && props.videoChosen === false && "element-w-hover"}
              ${props.activeVideoUrlIndex !== i && props.videoChosen && "element-w-chosen-not"}
              //{currentPrograms.length === 0 && props.activeVideoUrlIndex !== i && "element-w-focus"}
            `}
                key={`${program.programCollectionId}-${i}`}
                onMouseLeave={(e) => props.elementHoverOn && props.onElementTaglineHover(e, i)}
              >
  
                <div className={`element-c
                ${props.activeVideoUrlIndex === i && props.videoChosen && "element-c-noborder"}
              `}
                  // style={{textAlign: program.textAlign}}
                  style={{ textAlign: "left" }}
                >
                  {/* <>
                <div className={"element-left"}
                ></div>
                <div className={"element-right"}></div>
              </> */}
                  {props.videoChosen === false &&
                    <>
                      <span className={`element-c-title-span
                      ${props.activeVideoUrlIndex === i && "element-c-title-span-hide"} 
                      ${props.activeVideoUrlIndex === null && props.activeTaglineIndex === i && "element-c-title-span-hide"}
                    `}
                        style={{
                          // left: program.spanLeft, 
                          //right: program.spanRight, 
                          //color: program.backgroundPreviewImg.substring(program.backgroundPreviewImg.indexOf("rgba"), program.backgroundPreviewImg.indexOf(")"))
                        }}
                        published={published}
                      >
                        {program.programTagline}
                        {/* {<br></br>}
                      {program.category.toString()}
                      {<br></br>}
                      {program.sortId.toString()} */}
                      </span>
                      <div className={`element-c-tagline
                      ${props.activeVideoUrlIndex !== null && "element-c-tagline-fade"} 
                      ${props.activeTaglineIndex === i && "element-c-tagline-nopointerx"}
                    `}
                        onClick={(e) => props.elementHoverOn && props.onElementTaglineHover(e, i)}
                      //onMouseLeave={(e) => props.elementHoverOn && props.onElementTaglineHover(e, i)}
  
                      >
                        <span className={`element-c-tagline-span
                        ${props.activeVideoUrlIndex === i && "element-c-tagline-span-hide"}
                      `}
                        // style={{left: program.spanLeft, right: program.spanRight}}
                        >
                          {programCategory}
                          {/* {program.programDescriptionShort !== "" ? 
                          <i 
                            className={`fa fa-info
                              ${props.activeVideoUrlIndex === null && props.activeTaglineIndex === i && "fa fa-info--focus"}
                            `}
                            aria-hidden="true"
                            style={{
                              color: "white",
                             //color: program.backgroundPreviewImg.substring(program.backgroundPreviewImg.indexOf("rgba"), program.backgroundPreviewImg.indexOf(")"))
                            }}
                          ></i> : null} */}
                        </span>
  
                        {/* {program.title !== 0 ? program.title : null}            */}
                        {true ?  //program.programDescription !== ""
                          <i
                            className={`fa fa-info
                              ${props.activeVideoUrlIndex === null && props.activeTaglineIndex === i && "fa fa-info--focus"}
                            `}
                            aria-hidden="true"
                          ></i> : null}
  
  
                      </div>
  
                      <div className={`element-c-information
                      ${(props.activeVideoUrlIndex === null && props.activeTaglineIndex === i) ? "element-c-information-show" : "element-c-information-hide"}
                    `}
                      // onClick={(e) => props.onElementClick(e, i)}
                      >
                        <span className={"element-c-information-span"}
                          // style={{left: program.spanLeft, right: program.spanRight}}
                          onClick={(e) => props.elementHoverOn && props.onElementTaglineHover(e, i)}
                        >
                          {program.programDescriptionShort}
                        </span>
                      </div>
                      <div className={`element-c-amount
                      ${props.activeTaglineIndex === i && "element-c-tagline-nopointerx"}
                      ${props.activeTaglineIndex === i && "element-c-tagline-fade"} 
                    `}
                      >
                        <span className={`element-c-amount-span
                      ${props.activeTaglineIndex === i && "element-c-tagline-span-hide"}
                       ${props.activeVideoUrlIndex === i && "element-c-tagline-span-hide"}
                       `}
                        >
                          {program.programVideoCollection.length}
                          {/* {props.videoCollection.filter((video) => video.programCollectionId === program.programCollectionId).length} */}
                        </span>
                      </div>
                      <div className={`element-c-information-fade-top-c
  
                      `}
                      >
                        <div className={`element-c-information-fade-top
                          ${(props.activeVideoUrlIndex === null && props.activeTaglineIndex === i) ? "element-c-information-show" : "element-c-information-hide"}
                      `}
                        //style={{background: `linear-gradient(${program.backgroundPreviewImg.split(";")[0]},${program.backgroundPreviewImg.split(";")[1]}${program.backgroundPreviewImg.split(";")[2]},${program.backgroundPreviewImg.split(";")[3]}${program.backgroundPreviewImg.split(";")[4]})`}}
                        // style={{background: program.backgroundPreviewImg.substring(program.backgroundPreviewImg.indexOf("rgba"), program.backgroundPreviewImg.indexOf(")"))}}
                        //style={{background: `linear-gradient(${program.backgroundPreviewImg.split(";")[3]}20%,transparent 95%`}}
                        >
                        </div>
                      </div>
                    </>
                  }
                  <div className={`element-background
                  ${props.activeVideoUrlIndex === i ? "element-background-hover" : "element-background-nothover"}
                `}
                    style={{
                      background: background,
                      //filter: `hue-rotate(${Math.floor(Math.random() * ((i * 10) - 0 + 1)) + 0}deg)`
                    }}
                  //style={{background: colorsLight[categories.indexOf(props.currentCategory)]}} 
                  // style={{background: colorsLight[`${i > colorsLight.length ? i % colorsLight.length : i}`]}} //was: style={{background: `linear-gradient(${program.backgroundPreviewImg.split(";")[0]},${program.backgroundPreviewImg.split(";")[1]}${program.backgroundPreviewImg.split(";")[2]},${program.backgroundPreviewImg.split(";")[3]}${program.backgroundPreviewImg.split(";")[4]})`}}
                  // style={{background: program.backgroundPreviewImg}}
                  // style={{background: props.mainGalleryElementHoverId !== null && props.categoryGalleryData[props.mainGalleryElementHoverId].background}}               
                  >
                  </div>
  
                  {props.activeVideoUrlIndex === i && props.videoStartedId === null &&
                    <div className={`loading-w loading-w--left
                  //{program.spanLeft !== null && "loading-w--left"}
                  //{program.spanRight !== null && "loading-w--right"}
                  `}
                    >
                      <div className="preloader">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                      </div>
                    </div>
                  }
                  <Link to={`/${encodeURI(program.programCollectionId.toLowerCase().replace(/ /g, "_"))}/1-1`} //program.title.normalize('NFKD').replace(/[\u0300-\u036F]/g, '').toLowerCase().replace(/ /g,"_").replace(/%/g,"")}/1-1`} //}/1-1`}
                    //to={`/${encodeURIComponent(program.title)}/1-1`}
                    style={{ textDecoration: 'none', height: props.videoChosen ? "0%" : "80%", width: props.videoChosen ? "0%" : "100%", position: "absolute", bottom: "0", zIndex: "100" }}
                    onClick={() => props.onProgramClick(program.programCollectionId, 1, 1, i)} // i was passed, but dont seems nesseary ... //props.onProgramClick(e, i, program)} //program.programCollectionId, program.title, program.season, program.episode, program.episodeTitle, program.category, program.episodeDescription, program.programDescription
                    onMouseEnter={(e) => props.activeTaglineIndex !== i && props.elementHoverOn && props.onElementHover(e, i, program.programCollectionId)}
                    onMouseOver={(e) => props.activeTaglineIndex !== i && props.elementHoverOn && props.onElementHover(e, i, program.programCollectionId)}
                    onMouseLeave={(e) => props.activeTaglineIndex !== i && props.elementHoverOn && props.onElementHover(e, i, program.programCollectionId)}
                    onLoad={() => props.onProgramimgChange()}
                    onError={() => props.onProgramimgChange()}
  
  
                  >
                    <div className={"element-img-c"}>
                      <div className={`yinyang-c"}
                ${props.activeVideoUrlIndex !== null && "preview-img-fade"}
                ${props.activeTaglineIndex === i && "preview-img-fademore"}
                ${props.videoStartedId === props.activeVideoUrlIndex && props.activeVideoUrlIndex === i && "preview-img-fadeout"
                        }`}
                      >
                        <div className={"yinyang"}>
                        </div>
                        <div className={"yinyang-shape"}>
  
                          <img className={`element-img 
                  ${props.activeVideoUrlIndex !== null && "preview-img-fade"}
                  //{props.activeVideoUrlIndex === i && "preview-img-fadeout"}
                  ${props.videoStartedId === props.activeVideoUrlIndex && props.activeVideoUrlIndex === i && "preview-img-fadeout"}
                  ${props.activeTaglineIndex === i && "preview-img-fademore"}
                  ${props.videoChosen && "preview-img-nopointerevents"}
                  ${props.activeVideoUrlIndex === i && "preview-img-hovered"}
                  ${program.spanLeft !== null ? "preview-img-origin-right" : "preview-img-origin-left"}
                  `}
                            //style={{backgroundimg: `url(${program.previewImgUrl})`}}
                            key={program.programCollectionId}
                            src={program.thumbnailUrl !== "" ? program.thumbnailUrl : defaultPreviewUrl}
                            //videoUrl={program.videoUrl}
                            id={`program-img-id-${program.programCollectionId}`}
                            alt={program.category}
                          // style={{background: program.backgroundPreviewImg}}
                          // onClick={() => props.onProgramClick(program.programCollectionId, 1, 1, i)} // i was passed, but dont seems nesseary ... //props.onProgramClick(e, i, program)} //program.programCollectionId, program.title, program.season, program.episode, program.episodeTitle, program.category, program.episodeDescription, program.programDescription
                          // onMouseEnter={(e) => props.activeTaglineIndex !== i && props.elementHoverOn && props.onElementHover(e, i, program.programCollectionId)}
                          // onMouseOver={(e) => props.activeTaglineIndex !== i && props.elementHoverOn && props.onElementHover(e, i, program.programCollectionId)}
                          // onMouseLeave={(e) => props.activeTaglineIndex !== i && props.elementHoverOn && props.onElementHover(e, i, program.programCollectionId)}
                          // onLoad={() => props.onProgramimgChange()}
                          // onError={() => props.onProgramimgChange()}
                          // onError={this.handleimgChange}
                          // onClick={(e) => props.onGalleryElementEnter(e)
                          ></img>
                        </div>
                      </div>
                    </div>
  
                  </Link>
                  <div className={`player-w
                  ${props.videoStartedId === props.activeVideoUrlIndex ? "player-fadein" : "player-fadeout"}`}>
                    {props.activeVideoUrlIndex !== null && props.activeVideoUrlIndex === i &&
                      <ReactPlayer
                        className='react-player'
                        url={props.activeVideoUrl}
                        width='100%'
                        height='100%'
                        light={false}
                        // playIcon={kvadratisk}
                        playing={true}
                        muted={props.audioOff ? true : false} //{props.videoChosen ? false : true}
                        // onStart={() => props.onPlayStart()}
                        // volume={0 to 1}
                        stopOnUnmount={false}
                        pip={props.videoChosen ? true : false}
                        onStart={(e) => props.onVideoStart(e, i)}
                        // onEnded={() => props.onMediaEnded()}
                        controls={true} //{props.videoChosen ? true : false}
                        //onProgress={(loaded) => console.log(loaded)}
                        //onDuration={(loaded) => console.log(loaded)}
                        onEnded={() => props.onVideoEnded()}
                      />
                    }
                  </div>
                </div>
  
                {props.videoChosen && props.activeVideoUrlIndex === i &&
                  <>
                    {/* <div className={`element-c-video-category`}>
                    
                </div> */}
                    <div className={`element-c-video-information`}>
                      {/* {props.activeCategory.map((category) => 
                  <span className={"tags"}>{category}</span>
                  )} */}
                      {/* <span className={"title"}>{props.activeTitle}</span> */}
                      {/* <span className={"season-episode"}>{"S" + props.activeSeason + ":E" + props.activeEpisode}</span> */}
                      {/* <span className={"episode-title"}>{props.activeEpisodeTitle}</span>
                  <span className={"episode-description"}>{props.activeProgramDescription}</span>
                  <span className={"episode-description"}>{props.activeEpisodeDescription}</span> */}
  
                      {/* <div className='row'>
                    <div className='column'>
                      <div className='blue-column'>
                      <span className={"title"}>{props.activeTitle}</span>
                      <span className={"episode-description"}>{props.activeProgramDescription}</span>
                      </div>
                    </div>
                    <div className='column'>
                      <div className='green-column'>
                      <span className={"episode-title"}>{props.activeEpisodeTitle}</span>
                      <span className={"episode-description"}>{props.activeEpisodeDescription}</span>
                      </div>
                    </div>
                  </div> */}
  
                      <div className="grid-c">
                        <div className="grid-item episode-header">
                          <span className={"title"}>{props.activeTitle + ":"}</span>
                          {/* <i className="fa fa-align-left" aria-hidden="true"></i> */}
                          <span className={"episode-title"}>{props.activeEpisodeTitle}</span>
                          <i className="fas fa-stream" aria-hidden="true"
                            onClick={() => props.onVideoDescriptionsToogleClicked()}
                          ></i>
                            <PopularityComponent
                              videoUrl={props.activeVideoUrl}
                            ></PopularityComponent>
                        </div>
                        {/* <div class="grid-item">
                      </div> */}
                        <div className="grid-item">
                          <div className={`video-descriptions
                          ${props.videoDescriptionsShow ? "video-descriptions-show" : "video-descriptions-hide"}
                        `}>
                            <span className={"episode-description"}>{props.activeEpisodeDescription}</span>
                            <br></br>
                            <span className={"title"}>{`You are watching: `}</span>
                            <span className={"episode-title"}>{props.activeProgramTagline}</span>
                            <span className={"episode-description"}>{props.activeProgramDescriptionLong}</span>
                          </div>
                        </div>
                        {/* <div class="grid-item">
                       
                      </div> */}
                      </div>
                    </div>
                  </>
                }
  
              </div>

          }

        return (
          <Fragment
            key={`${program.category}-${i}`}
          >
            {component}
          </Fragment>
        )
      }), [props.gallery, props.activeVideoUrl, props.programCollectionId, props.onVideoStart, props.currentPrograms, props.videoChosen, props.activeVideo])

    // const noOfCurrentPrograms = props.programs.filter(program => program.category.some(category => category.includes(props.programQueryText))).length
    // const noOfGhostElements = props.noOfProgramsInRow - (noOfCurrentPrograms % props.noOfProgramsInRow)

    // const ghostElements = Array(noOfGhostElements).fill().map((i, index) =>
    //   <div className={`element-w
    //       ${Math.ceil((i + 1) / 5) * 5 === props.hoverFirstElementInRow && "scaledown"}
    //       ${props.activeVideoUrlIndex !== i && props.videoChosen && "element-w-video-chosen-not"}
    //       ${props.activeVideoUrlIndex === i && props.videoChosen && "element-w-video-chosen"}
    //       ${props.activeVideoUrlIndex === i && props.videoChosen === false && "element-w-hover"}
    //       ${props.activeVideoUrlIndex !== i && props.videoChosen && "element-w-chosen-not"}
    //     `}
    //     key={index}
    //   >
    //   </div>
    // )

    const programSuggestionInfo = ["Din søgning gav 0 resultater.", "Søg igen.", "Eller måske frister dette program?"]
    const programSuggestionInfoElements = Array(2).fill().map((i, index) =>
      <div className={`element-w
          ${Math.ceil((i + 1) / 5) * 5 === props.hoverFirstElementInRow && "scaledown"}
          ${props.activeVideoUrlIndex !== i && props.videoChosen && "element-w-video-chosen-not"}
          ${props.activeVideoUrlIndex === i && props.videoChosen && "element-w-video-chosen"}
          ${props.activeVideoUrlIndex === i && props.videoChosen === false && "element-w-hover"}
          ${props.activeVideoUrlIndex !== i && props.videoChosen && "element-w-chosen-not"}
        `}>
        {index === 1 &&
          <div className={`element-suggestion-info`}>
            {programSuggestionInfo.map((info, i) =>
              <span className={`element-suggestion-info-span`}
                key={i}
              >
                {info}
              </span>
            )}
          </div>
        }
      </div>
    )

    // const programGallerySlideUp = props.gallery === "category" && props.videoChosen === false && props.searchText === "" && props.programimgsLoaded
    //const programGallerySlideUp = (props.categoryGalleryData.filter(categoryGallery => categoryGallery.category === props.programQueryText).length > 0 && props.programimgsLoaded) || props.videoChosen //props.gallery === "category" && props.videoChosen === false && props.searchText === "" && props.programimgsLoaded
    const programGallerySlideUp =
      true ||
      (props.currentCategory === "" && props.searchText !== "") ||
      (props.currentCategory !== "" && props.programimgsLoaded) ||
      (props.currentCategory !== "" && props.searchText !== "") ||
      props.videoChosen //props.gallery === "category" && props.videoChosen === false && props.searchText === "" && props.programimgsLoaded


    const programGalleryShow = props.gallery === "category" || props.searchText !== ""
    return (
      <>
        {/* //included to mitigate that randomProgramSuggestion is updated after load of page (before categoryh is chosen), and fires onLoad and counter of imgs, so its become wrong. More robust implementation probablty needed */}
        <div className={`gallery program-gallery
          ${programGallerySlideUp ? "program-gallery--slideup" : "program-gallery--low"}
          ${programGalleryShow ? "program-gallery--show" : "program-gallery--hide"}
          ${props.randomProgramActive ? "program-gallery--align-top" : "program-gallery--align-center"} 
          ${props.videoChosen ? "translate-more gallery--left" : "gallery--centered"} 
          ${props.gallerycShow ? "show" : "hide"}
          ${props.videoChosen ? "gallery-fullwidth" : "gallery-narrow"}
          //{props.gallery === "category" ? "category-gallery-show" : ""}` //
        }>
          {props.randomProgramActive && programSuggestionInfoElements}
          <CategoryGalleryData></CategoryGalleryData>
          {/* {ghostElements} */}
        </div>
        {/* } */}
      </>
    )
  }


export default ProgramGallery;