import algoliasearch from 'algoliasearch';

///connect and authenticate with your Algolia app
const client = algoliasearch('AGNXRGPJK8', 'ddf45f2bb3f48bd4613a0bf9a80adcc6')

///index
const index = client.initIndex('videos')
// const record = { objectID: 1, name: 'test_record' }
// index.saveObject(record).wait()

export const search = ({ }) => {
///search the index and print the results
  index
    .search('yoga')
    .then(({ hits }) => console.log(hits))
}