import { auth } from './Fire';
import { deleteUser, signInWithEmailLink, signInWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink } from "firebase/auth";

// const auth = firebaseApp.auth();

export function login({ email, password }) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      alert("signed in")
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
    });
  }
  
  const actionCodeSettings = ({ isSignUp, subsc }) => {
    const settings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: isSignUp ? 
      `http://localhost:3000/?isaccountsubsc=${subsc.isAccount}&&isemailsubsc=${subsc.isEmail}` :
      `http://localhost:3000`, ///production: https://www.yogatube.ai  ///development: http://localhost:3000
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    //dynamicLinkDomain: 'localhost:3000/finishSignUp?', 
    }
    return settings
  };

export const signOut = async() => {
await auth.signOut().then(function() {
    console.log('Signed Out');
  }, function(error) {
    console.error('Sign Out Error', error);
  });
}

// export const signInMethods = ({ email }) => {
//     // After asking the user for their email.
//     firebase.auth().fetchSignInMethodsForEmail(email)
//     .then((signInMethods) => {
//         // This returns the same array as fetchProvidersForEmail but for email
//         // provider identified by 'password' string, signInMethods would contain 2
//         // different strings:
//         // 'emailLink' if the user previously signed in with an email/link
//         // 'password' if the user has a password.
//         // A user could have both.
//         if (signInMethods.indexOf(
//                 firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1) {
//                 // User can sign in with email/password.
//                 getAuthLink({ email }) 
//                 return
//         }
//         if (signInMethods.indexOf(
//                 firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1) {
//                 getAuthLink({ email }) 
//                 return
//         // User can sign in with email/link.
//         }
//         else {
//             getAuthLink({ email }) 
//             return
//         }
//     })
//     .catch((error) => {
//         // Some error occurred, you can inspect the code: error.code
//     });
// }
  
export const getAuthLink = async({ email, isSignUp, subsc }) => {
  await sendSignInLinkToEmail(auth, email, actionCodeSettings({ isSignUp, subsc }))
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
    window.localStorage.setItem('emailForSignIn', email);
}

  // Confirm the link is a sign-in with email link.
export const signInWithEmail = async() => {
  
  // Confirm the link is a sign-in with email link.
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
    }
    // The client SDK will parse the code from the link for you.
    const result = await signInWithEmailLink(auth, email, window.location.href)
      //.then((result) => {
        // Clear email from storage.

        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      //})
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
      window.localStorage.removeItem('emailForSignIn');
      return result.user
  }
    // if (auth().isSignInWithEmailLink(window.location.href)) {
    //     // Additional state parameters can also be passed via URL.
    //     // This can be used to continue the user's intended action before triggering
    //     // the sign-in operation.
    //     // Get the email if available. This should be available if the user completes
    //     // the flow on the same device where they started it.
    //     var email = window.localStorage.getItem('emailForSignIn');
    //     if (!email) {
    //     // User opened the link on a different device. To prevent session fixation
    //     // attacks, ask the user to provide the associated email again. For example:
    //     email = window.prompt('Please provide your email for confirmation');
    //     }
    //     // The client SDK will parse the code from the link for you.
    //     const result = await auth().signInWithEmailLink(email, window.location.href)
    //     // .then((result) => {
    //     //     // Clear email from storage.
    //     //     console.log("signed up and logged in")
    //     //     window.localStorage.removeItem('emailForSignIn');
    //     //     // You can access the new user via result.user
    //     //     // Additional user info profile not available via:
    //     //     // result.additionalUserInfo.profile == null
    //     //     // You can check if the user is new or existing:
    //     //     // result.additionalUserInfo.isNewUser
    //     // })
    //     .catch((error) => {
    //         console.log("error", error)
    //         // Some error occurred, you can inspect the code: error.code
    //         // Common errors could be invalid email and invalid or expired OTPs.
    //     });
    //         // Clear email from storage.
    //     console.log("signed up and logged in", result)
    //     window.localStorage.removeItem('emailForSignIn');
    //     return result.user
    //         // You can access the new user via result.user
    //         // Additional user info profile not available via:
    //         // result.additionalUserInfo.profile == null
    //         // You can check if the user is new or existing:
    //         // result.additionalUserInfo.isNewUser

    // }
}


export const deleteProfile = async() => {
  const user = auth.currentUser;
  deleteUser(user)
  .catch((error) => {
  });
  return
}

  auth.onAuthStateChanged((user) => {
    if (user) {
      var uid = user.uid;
      console.log("signed in ", uid)
      //signOut()
    } else {
      console.log("signed out ", uid)
    }
  });




  