import React, { useEffect, useContext, useRef, useCallback, memo, createContext as reactCreateContext, useContext as reactUseContext, Fragment, useState, useMemo, Dispatch, SetStateAction } from 'react';
import { categoryGroupsDataContext, categoriesDataContext, videosAPIContext, videosDataContext, plsDataContext } from './Home2'
import * as generics from './generics';
import { produce } from "immer";
import { TextualArray, templet, templatesType, templatesNames, handleMediaAddition } from './ItemForm';
import { pesoIcon, burstIcon, clockIcon, personWalkingIcon, lightbulb, proSign } from './Icons';
import * as FirebaseFunctions from './FirebaseFunctions';
import { colorsLight, attrColors } from './colors';

export const templatesContext = reactCreateContext([,() => {}])

export const DataProvider = (props) => {

    const 
      swrCategoryGroups = useContext(categoryGroupsDataContext),
      swrCategories = useContext(categoriesDataContext),
      swrVideos = useContext(videosDataContext),
      { handleSetSWRUpdate } = reactUseContext(videosAPIContext),
      swrPls =  useContext(plsDataContext)

    const colors = colorsLight({ isRadial : false})

    const isDbTemplatesContent = swrCategoryGroups?.data && swrCategories?.data  ///note: is data loaded

    const dbTemplatesContent = { ///note: specification of selection choices based on data (from backend)
        "channel category group names": isDbTemplatesContent ? swrCategoryGroups.data.filter(categoryGroup => categoryGroup["is channels?"]).map(categoryGroup => categoryGroup["category group name"]) : [],
        "non-channel category group names": isDbTemplatesContent ? swrCategoryGroups.data.filter(categoryGroup => !categoryGroup["is channels?"]).map(categoryGroup => categoryGroup["category group name"]) : [],
        "categories": isDbTemplatesContent ? swrCategories.data.filter(category => category["template"] !== "channel").map(category => category["category name id"]) : [] ///alternative template === "category", but if additional templates is added, the current statement is probably true (only template "channel" is excluded, i.e. those videos are already in db, no need to add video manually)
    }
    const
      audienceAttrs = ["seniors", "children", "pregnant"], 
      focusAttrs = ["morning routines", "bedtime relaxation", "stress relief at work", "yoga for athletes", "DIY", "equipment"],
      equipmentAttrs = ["mat", "block", "strap", "bolster", "blanket", "wheel", "meditation cushion", "yoga ball", "resistance bands", "yoga socks", "foam roller", "eye pillow", "wall"],
      ambianceAttrs = ["nature sounds"]

    const 
        templateParam = [
        "type", ///string
        "value", ///incl. default value and input value(s)
        "visible",
        "path",
        "filter", ///boolean
        "optional", ///boolean (optional or mandatory)
        "mapping", ///0: "view 0 default, 1: view 0 more, 2: view 1
        "className", ///like font-size-x and no-translate
        "vertical", ///vertical or horizontal arrangement of text (relevant for mapping of arrays)
        "isfixed", ///at more-view fixed (at top) or scrollable (below)
        "shortcut", ///at more-view if scrollable shortcut true => included in scrolltomenu. elements not mapped 
        "placeholder", ///string or array
        "options", ///array - applies to: multistate, multioptions
        "limit", ///integer - applies to: multioptions
        "color" ///applies to: multioptions
        ],
        templatesSetup = { ///note: mapwidgets unique for widgets
          "category group": {
            collection: "category groups",
            documentNamePath: "category group name",
            isGallery: false,  
            mapWidgetNames: ["category group"],
            color: "#DFDD6C",
            headerContent: "Live Events & Promotions! Find the place to be and your crowd now!"
          },
          "channel": {
            collection: "categoriesx",
            documentNamePath: "category name",
            isGallery: false,  
            mapWidgetNames: ["category group"],
            color: "#DFDD6C",
            headerContent: "Live Events & Promotions! Find the place to be and your crowd now!"
          },
          "category": {
            collection: "categoriesx",
            documentNamePath: "category name",
            isGallery: false,  
            mapWidgetNames: ["category group"],
            color: "#DFDD6C",
            headerContent: "Live Events & Promotions! Find the place to be and your crowd now!"
          },
          "video": {
            collection: "videosInput",
            documentNamePath: "media[0].videoId",
            isGallery: false,  
            mapWidgetNames: ["live"],
            color: "#DFDD6C",
            headerContent: "Live Events & Promotions! Find the place to be and your crowd now!"
          },
          "diamont-list": {
            collection: "lists",
            documentNamePath: "",
            isGallery: false,  
            mapWidgetNames: ["live"],
            color: "#DFDD6C",
            headerContent: "Live Events & Promotions! Find the place to be and your crowd now!"
          },
          "special events": {
            isGallery: false,
            mapWidgetNames: ["special events"],
            color: "#FE8D6F",
            headerContent: "xxx"
          },
          "weekly events": {
            isGallery: false,
            mapWidgetNames: ["monday", "tuesday"],
            color: "#FDC453",
            headerContent: "yyy"
          },
          "attractions": {
            isGallery: true,
            color: "#5ab6ca",
            headerContent: "xxx"
          },
          "experiences": {
            isGallery: true,
            color: "#F886A8",
            headerContent: "xxx"
          },
          "stays": {
            isGallery: true,
            color: "#7676d9",
            headerContent: "xxx"
          },
          "consume": {
            isGallery: true,
            color: "black",
            headerContent: "xxx"
          },
        },
        templatesContentSetup = { ///risk: attributes not in data-set will result in error in itemtext - fix!
    "category group": {
        "category group name":       ["textual", "", true, "", true, true, [true, true, true], "font-size-2", false, false, [false, null], `existing groups: ${[...dbTemplatesContent["channel category group names"], ...dbTemplatesContent["non-channel category group names"]]}`],
        "header title":              ["textual", "", true, "", true, true, [true, true, true], "font-size-2", false, false, [false, null], ""],
        "is channels?":              ["multiState", false, true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", [true, false]],
        "is show?":                  ["multiState", true, true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", [true, false]],
        "is show catchphrase?":      ["multiState", true, true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", [true, false]],
        "is show extra categories?": ["multiState", true, true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", [true, false]],
    },
    "channel": {
      "category group name":        ["multiState", "", true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", dbTemplatesContent["channel category group names"]],
      channelId:                    ["textual", "", true, true, "", true, [true, true, true], "font-size-2", false, false, [false, null], "use crome extension YouTube Channel IDfier"],
      "category name":              ["textual", "", true, true, "", false, [true, true, true], "font-size-2", false, false, [false, null], "name of the channel"],
      "category name id":           ["textual", "", true, true, "", false, [true, true, true], "font-size-2", false, false, [false, null], "category name id will appear in the url. default is you type the same as category name..."],
      alias:                        ["textual", "", true, true, "", true, [true, true, true], "font-size-2", false, false, [false, null]],
      catchphrase:                  ["textual", "", true, true, "", true, [true, true, true], "font-size-2", false, false, [false, null]],
      "extra categories":           ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
      tags:                         ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
      "is show?":                   ["multiState", true, true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", [true, false]],
      media:                        ["media", [], true, false, "", false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
      video:                        ["video", "", true, false, "", true, [true, true, true], "font-size-1", false, false, [true, null]],
      background:                   ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", colorsLight, 1],
  },
    "category": {
        "category group name":      ["multiState", "", true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", dbTemplatesContent["non-channel category group names"]],
        "category name":            ["textual", "", true, "", true, false, [true, true, true], "font-size-2", false, false, [false, null]],
        "category name id":         ["textual", "", true, "", true, false, [true, true, true], "font-size-2", false, false, [false, null], "this id will appear in the url. default is you type the same as category name, but perhaps not?"],
        alias:                      ["textual", "", true, "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
        catchphrase:                ["textual", "", true, "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
        "extra categories":         ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
        tags:                       ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", Array.from(Array(400).keys()), 1],
        "is show?":                 ["multiState", true, true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null], "", [true, false]],
        media:                      ["media", [], true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
        video:                      ["video", "", true, "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
        background:                 ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", colorsLight, 1],
    },
    "video": {
        media:                      ["media", [], true, "", false, false, [true, true, true], "font-size-1", false, false, [true, null]], ///note: if file => is set to dummy value ///{id: 0, mediaType: "", file: [], url: "", mediaText: ""}
        video:                      ["video", "", true, "", false, true, [true, true, true], "font-size-1", false, false, [true, null]],
        categories:                 ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", dbTemplatesContent.categories, 5],
        audience:                   ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", audienceAttrs, 3, attrColors[0]],
        focus:                      ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", focusAttrs, 10, attrColors[1]],
        ambiance:                   ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", ambianceAttrs, 3,  attrColors[2]],
        equipment:                  ["multiOptions", [], true, "", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", equipmentAttrs, 10, attrColors[3]],
        access:                     ["multiState", "private", true, "", false, false, [true, true, true], "font-size-1", false,  false, [true, null], "", ["public", "private"]]
    },
    "diamont-list": {
      name:                         ["textual", "", true, "dynamicData", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
      data:                         ["", [], false, "", true, true, [true, true, true], "font-size-2", false, false, [false, null]],
      audience:                     ["multiOptions", [], true, "attr", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", audienceAttrs, 3, attrColors[0]],
      focus:                        ["multiOptions", [], true, "attr", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", focusAttrs, 10, attrColors[1]],
      ambiance:                     ["multiOptions", [], true, "attr", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", ambianceAttrs, 3,  attrColors[2]],
      equipment:                    ["multiOptions", [], true, "attr", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", equipmentAttrs, 10, attrColors[3]],
      access:                       ["multiState", "private", true, "dynamicData", false, false, [true, true, true], "font-size-1", false,  false, [true, null], "", ["public", "private"]],
      background:                   ["multiOptions", [], true, "dynamicData", true, true, [true, true, true], "font-size-1", false, false,[false, null], "", colorsLight, 1],
  },
  infobox: {
        header:                     ["textual", "", true, "", true, true, [true, true, true], "font-size-3", false, true, [false, null]],
        stats:                      ["textualObjects", [], true, "", true, false, [false, true, false], "font-size-1", false, false, [true, "stats"], "", [""], 1],
        description:                ["textual", "", true, "", true, false, [false, true, false], "font-size-1", true, false, [true, "description"]],
        highlights:                 ["textualArray", [], true, "", true, false, [false, true, false], "font-size-1", true, false, [true, "highlights"]], 
    },
        },
        statesTemplates = generics.objMapTemplates(templatesContentSetup, templateParam),
        templatesValue = generics.objMapTemplatesX(templatesContentSetup, templateParam, "value")
      const initialStateTemplatesData = {
        temp: {},
        new:    { isUseModal: false, values: templatesValue, activeTemplate: Object.keys(templatesContentSetup)[0], label: "", startUpload: false },
        update: { isUseModal: false, values: templatesValue, activeTemplate: "", label: "", startUpload: false, identifier: "", param: "" }, ///note: identifier, fx videoId or plId, is chosen to identify entity instead of document (documentId)
        show: { activeTemplates: [templatesNames[0]] } 
       } ///optimise/risk: activeTemplate cant be "" (reset to ""), but at reset it will now be potentially wrong (ie. activeTemplate[0]). consider to solve. it should not be a problem, because before read for a purpose its set again, correctly ... right?
      const 
        [templates, setTemplates] = useState(statesTemplates),
        [templatesData, setTemplatesData] = useState(initialStateTemplatesData),
        [prevData, setPrevData] = useState(null) ///note: capture stateData value (at change !!templatesData.update.label) => utilised if document update failure

        const handleSetTemplatesData = useCallback(( templatesParam ) => { ///optimise: move to DataProvider? ///trigger, template, label, index, value, startUpload, reset, isUseModal

          // if (templatesData.temp.startUpload) { ///if startupload is true => abort. implemented to mitigate reset of startupload (to false) due to rerender and call of handleitemtemplatesdata
          //   return
          // }
     
        
          //const templatesParam = { ...templatesParam_, action: "new" }

            setTemplatesData(
              produce((draft) => { 
                  draft.temp = templatesParam
              })
            )
        }, [templatesData.new.activeTemplate, templatesData.update.activeTemplate]) //, [templatesData.new.activeTemplate])

        const templatesDataValue = useMemo(
          () => ({ templates, handleSetTemplatesData }), ///note: templatesDataValue (currently) holds a mix consisting of "templates" and "handleSetTemplatesData"
          [templatesData]
        );

        useEffect(() => {
            setTemplates(statesTemplates)
        }, [swrCategoryGroups.data, swrCategories.data]);
    //   const initialValueShow = {  ///optimise: move individual values to specific components? 
    //     modal: { show: false, modalChild: null }, ///question/optimise: move requester to multioptions?
    //     multiOptions: { data: [], label: "", requester: "", action: "", dataSelected: [], payloadObjectName: "", payloadInnerPathViaArray: [], preSelectedOptions: [], document: "" }, ///note: payload/value location is ensured either via 1) payloadInnerPathViaArray or 2) document, label
    //     dimensionsOptions: { data: [], label: "", dataSelected: [] }, ///optimise: look into use... used?
    //     textualArray: { data: [], document: "", label: "", child: <></>, startUpload: false },
    //     textualObjects: { data: [], document: "", label: "", child: <></>, startUpload: false },
    //     openingHours: { data: [], document: "", label: "", child: <></>, startUpload: false }
    //   } 
    //   const [show, setShow] = useState(initialValueShow) ///consider: to rename to modal or similar
    //   const showValue = useMemo(
    //     () => ({ show, setShow }), 
    //     [show]
    //   );

      
  ///reset of modal, included after set of items (via modal/multioptions)
//   useEffect(() => { ///modal reset in case of itemform => => modal check/accept change => set templatesdata.new => useeffect =>
//     setShow(initialValueShow)
// }, [templatesData.new]); 

// useEffect(() => { ///modal reset in case of itemtext => modal check/accept change => update at backend => set of items (client-side) => useeffect =>
//   if (show.modal.requester === "itemText") {
//     setShow(initialValueShow)
//   }
// }, [items]);

// useEffect(() => { ///modal reset in case of itemtext => modal check/accept change => update at backend => set of items (client-side) => useeffect =>
//   if (show.modal.modalChild === "textualArray" || show.modal.modalChild === "textualObjects" || show.modal.modalChild === "openingHours") {
//     setShow(
//       produce((draft) => {
//         draft.multiOptions = initialValueShow.multiOptions
//       })
//     )
//   }
// }, [show.modal.modalChild]);

///toogle body scroll on modal open/close
// useEffect(() => {
//   show.modal.show ? 
//     document.body.classList.add('modal-open') : 
//     document.body.classList.remove('modal-open')
// }, [show.modal.show]);
///change at templatesdata temp => handle settemplatesdata 




useEffect(() => {
  const { action, label, value, index, reset, activeTemplate } = templatesData.temp

  // if (generics.stringUndefined(reset)) { ///at load => no reset set => abort
  //   return
  // }
  const template = !!activeTemplate ? activeTemplate : templatesData[action]?.activeTemplate ///!!templatesData.temp.activeTemplate ? templatesData.temp.activeTemplate : templatesData.new.activeTemplate

  // const 
  //   template = templatesData[action].activeTemplate ///!!templatesData.temp.activeTemplate ? templatesData.temp.activeTemplate : templatesData.new.activeTemplate
    // isColumns = 
    //   generics.keyExist(templates[template], label) && ///note: keyexits due to use of label mediatext not in template (at this nesting-level)
    //   generics.isArrayLengthAbove(templates[template][label].value, 0) 
    //   console.log("isColumns", isColumns)
  setTemplatesData( 
    produce((draft) => { 

      Object.entries(templatesData.temp) ///note: set state for each param (passed via function)
        .filter(([param, v]) => param !== "value") ///exclude param value from set state
        .map(([param, v], i) => draft[action][param] = v )
  
      // if (!!document) {///was: (action === "update" && reset === false && templatesData.update.document !== document) { ///note: not set for action new as no document
      //   draft[action].document = document 
      // }

      ///note/question: add condition to only run code if not template change ...
        switch (true) {
          case reset === true:
            if (action === "update") {
              draft[action] = initialStateTemplatesData[action] 
            } else { ///note: action "new"
              draft[action].values[template] = initialStateTemplatesData[action].values[template] ///note: only reset activetemplate (if input in other template(s) it remains (no reset), i.e. work on another new item can continue
              draft[action].startUpload = initialStateTemplatesData[action].startUpload
            }
            break;
          case generics.stringUndefined(value): ///if no value => abort (from here)
            break;
          case label === "media_is_video":
            const 
              stateMedia = [],
              parameter = "url",
              mediaType = "video",
              mediaArray = handleMediaAddition(stateMedia, value, parameter, mediaType)
            draft[action].values[template]["video"] = "";
            draft[action].values[template]["media"].push(mediaArray[0]) ///[0] to get obj in array
            break;
          case label === "mediaText":
            draft[action].values[template]["media"][index]["mediaText"] = value
            break;
          // case templates[template][label]?.type === "textualObjects":
          //   draft[action].values[template][label] = value
                //// show.modal.modalChild === "multiOptions" ?
                ////   draft[action].values[template][label].push(value) :
                ////   draft[action].values[template][label] = value
            // break;
          // case isColumns:
          //   draft[action].values[template][label][index] = value
          //   break;
          // case !isColumns:
 
            //break;
          default:
            draft[action].values[template][label] = value
          break;
        };
    })
  );
}, [templatesData.temp]);

useEffect(() => { ///note: (=> edit mode) => templatesData.update.label => set state to open modal and set (existing) stateData (note: stateData can be passed/set instead via Edit, but the choice is to isolate behaviour..)
  if (!!templatesData.update.label) {
    let stateData
    switch (true) {
      case templatesData.update.activeTemplate === "video":
        const 
          video = swrVideos?.data.find(video => video.staticData.videoId === templatesData.update.identifier)
        //param = generics.getPath({ obj: video, property: templatesData.update.label })
        stateData = video[templatesData.update.param][templatesData.update.label] 
        break;
      case templatesData.update.activeTemplate === "pl":
        const 
          pl = swrPls?.data.find(pl => pl.staticData.videoId === templatesData.update.identifier)
          ///...etc
        break;
      default:
      break;
    };
 
    setPrevData(stateData)

    handleSetTemplatesData({ action: "update", isUseModal: true, label: templatesData.update.label, value: stateData }) ///note: set preveiously: param: templatesData.update.param
  }
}, [templatesData.update.label]);

  ///selected upload => media uploaded and set (incl startupload true) => upload item
  useEffect(() => {
      if (templatesData.new.startUpload) {
      // if (generics.arrayEmpty(media)) {
      //   return ///if media change due to reset abort. optimise/bug: if removed, circuluar call. also, can not upload text without media. fix
      // }
      // if (!templatesData.new.startUpload) {
      //   return
      // }
      // const mediaObj = {media: media}
      // let { image, ...item } = templatesData.new.values[activeTemplate] ///note: removes image from itemelements ///optimise: specify parameters to upload in variable and use here
      // item = { ... item, ... mediaObj }
      let { video, ...payload } = templatesData.new.values[templatesData.new.activeTemplate] ///note: removes video from payload ///optimise: specify parameters to upload in variable and use here
      
      const template = templatesData.new.activeTemplate

      let payload_ = {};
      (template === "video" || template === "pl" || template === "diamont-list") ? ///note: add template at relevant path ///optimise: consider aligning path/nesting for all templates..
        payload_ = { ...payload, ["staticData"]: { ["template"]: template } } : ///note: add path staticData with template value-key
        payload_ = { ...payload, template }
    
      //const payload_ = { ...payload, template } ///note: add template to payload
 
      let payload__ = {}

      const mapPath = (v, k, payload__) => { ///note: construct nested payload according to paths in templates
        if (
          !!templates[templatesData.new.activeTemplate]?.[k] && ///note: if label exist in template
          !!templates[templatesData.new.activeTemplate][k].path ///note: and if path is defined (if !!..path)
          ) { 
          const path = templates[templatesData.new.activeTemplate][k].path; ///note: get path
          payload__[path] = { ...payload__[path], [k]: v }; ///note: spread existing value-key pair and add new value-key pair at path
        } else { 
          payload__[k] = v; ///else: simply add value-key
        }
      }

      generics.objMap(payload_, (v, k) => mapPath(v, k, payload__), false) ///get nested payload (payload__)

      const
        collection = templatesSetup[templatesData.new.activeTemplate].collection,
        document = generics.getValueViaPath({ obj: payload, path: templatesSetup[templatesData.new.activeTemplate].documentNamePath})

      FirebaseFunctions.addDocument({ collection: collection, document: document, payload: payload__, isAddUid: true })
      //NewArtwork_w.WriteArtwork(payload, templatesData.new.activeTemplate) ///risk: ensure resetComponent only set
      resetComponent({ action: "new" })
    }
  }, [templatesData.new.startUpload]); 

    //edit => change in values => startUpload true ///note: alternatively set startUpload (directly) at "cleanup" at "Modal" or useEffect [modal.symbol] "check" at "Modal"
    // useEffect(() => {
    //   if (!!templatesData.update.label) {
    //     handleSetTemplatesData({ action: "update", startUpload: true })
    //   }
    // }, [templatesData.update.values]); 

    //edit => upload changes =>
    useEffect(() => {
      if (templatesData.update.startUpload) {
        handeUpdate()
      }
    }, [templatesData.update.startUpload]); 

  const handeUpdate = async() => { ///note: for an instant user experience - at low risk/impact - the update pattern is: update UI, update backend => at failure revert and inform (in contrast to update at backend => update UI) ///optimise: include for action "new"...
    let collection, document, param, payload_
    switch(true) {
      case templatesData.update.activeTemplate === "video":
        collection = "videos"
        document = templatesData.update.identifier
        param = templatesData.update.param
        payload_ = { [param]: { [templatesData.update.label]: templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label] } }
      break;
      case templatesData.update.activeTemplate === "pl":
        ///etc
      break;
      default:
    }

    handleSetSWRUpdate({ value: templatesData.update.values[templatesData.update.activeTemplate][templatesData.update.label], param: templatesData.update.param, label: templatesData.update.label, identifier: templatesData.update.identifier })
    
    handleSetTemplatesData({ action: "update", reset: true })

    const isSucess = await FirebaseFunctions.updateDocument({ collection: collection, document: document, payload: payload_, isIncrement: false, param: param })

    if (!isSucess) { ///note: if update failure => revert to prevData
      handleSetSWRUpdate({ value: prevData, param: templatesData.update.param, label: templatesData.update.label, identifier: templatesData.update.identifier })
      ///optimise/todo: inform user!
    }

    setPrevData(null) ///reset prevData
  }

  ///item uploaded => reset component
  const resetComponent = ({ action }) => {
    handleSetTemplatesData({ action, reset: true })
    //resetState()
    ///resetInput()
  }

    // if (swrCategories.isLoading || swrCategories.isError) 
    //     return <>{props.children(templatesData, setTemplatesData)}</>;

    // const Provider = ({ children }) => {  ///question/optimise: use this format for templates context?
    //     const [swr, setSwr] = useState({ data: undefined, isLoading: true, isError: undefined });
    //     return (
    //         <channelsDataContext.Provider value={swr}>
    //             <channelsAPIContext.Provider value={setSwr}>
    //                 { children }
    //             </channelsAPIContext.Provider>
    //         </channelsDataContext.Provider>
    //     );
    // }
    
    return (
        <templatesContext.Provider value={templatesDataValue}> 
            {props.children(templatesData, setTemplatesData, handleSetTemplatesData)}
        </templatesContext.Provider>
    )
}