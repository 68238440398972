import { useRef, useEffect, useCallback, useContext} from 'react';
import produce from "immer";
import { showContext } from './App';

//use: const prevItems = usePrevious(items), console.log(prevItems)
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const initialStateOptions = [
  null,
  [],
  false,
  true
]
