import './app.css';
import './styling-standards.css';
import './filter-box.css';
import { useState, useEffect, useMemo, useContext, useCallback, memo} from 'react';
import { refineContext, showContext, itemsContext, itemsRefinedContext, themeContext, templatesDataContext } from './App';
import produce from "immer";
import { multiOptionsValues, templatesType, templatesNames, templatesValue, templates, templatesSetup, templatesFilterableAttr } from './ItemForm';
import { itemsRefinedCountFilterIE } from './GalleryRefined';
import * as generics from './generics';
import { GridColums, ZeroToFullHeight, SquareIcon, Button, Wrapper, FlexBox, Edit } from './StandardComponents';
import { usePrevious} from './StateOptions';
//import DognutChart from './DognutChart';
//import GirlPearl from './GirlPearl';
//import { DimensionsOptions, DimensionsOptionsLight, dimensionsInitial } from './DimensionsOptions';
import { xMarkIcon } from './Icons';
import { templatesContext } from './DataProvider';

function FilterBox( { templatesData }) {
  ///context
  // const { refine, setRefine } = useContext(refineContext);
  // const { theme, setTheme } = useContext(themeContext);
  // const { items, setItems } = useContext(itemsContext);
  // const { itemsRefined, setItemsRefined } = useContext(itemsRefinedContext);
  // const { templatesData, setTemplatesData } = useContext(templatesDataContext);
  // const { show, setShow } = useContext(showContext);

    ///state
    const [rawItems, setRawItems] = useState({
      values: [], 
      download: {
        active: {
          templateNames: [],
          isFinalised: true, 
        },
        overall: {
          status: generics.mapArrayToObjectWithKeysAndSetValue(templatesNames, false)
        }
      }
    })
    const rawItemsValue = useMemo(
      () => ({ rawItems, setRawItems }), 
      [rawItems]
    );
    const [items, setItems] = useState([])
    const itemsValue = useMemo(
      () => ({ items, setItems }), 
      [items]
    );
    const [itemsRefined, setItemsRefined] = useState(items)
    const itemsRefinedValue = useMemo(
      () => ({ itemsRefined, setItemsRefined }), 
      [itemsRefined]
    );
    // const initialStateTemplatesData = {
    //   new: { values: templatesValue, activeTemplate: templatesNames[0], startUpload: false },
    //   update: { values: templatesValue, activeTemplate: "", label: "", document: "", startUpload: false }
    //  } ///optimise/risk: activeTemplate cant be "" (reset to ""), but at reset it will now be potentially wrong (ie. activeTemplate[0]). consider to solve. it should not be a problem, because before read for a purpose its set again, correctly ... right?
    // const [templatesData, setTemplatesData] = useState({ 
    //   temp: {}, ///note: temp is implemented to allow component to initiate set templatesdata locally (shorthand), while the setstate of new and update is done centrally (here) based on temp changes (detected via useeffect)
    //   new: initialStateTemplatesData.new,
    //   update: initialStateTemplatesData.update, //{ values: templatesValue, activeTemplate: "", label: "", document: "", startUpload: false }, ///note: it seems better to pass along template, than set and read activetemplate here... ///note: this centralised utilisiation of activetemplate (or renamed to template), document and label in update => only able to handle a single update at a time fx media OR textualarray via modal, i.e. update of these element must be finalised or aborted before initiation of new update 
    //   show: { activeTemplates: [templatesNames[0]] } 
    // }) 
    // const templatesDataValue = useMemo(
    //   () => ({ templatesData, setTemplatesData }), 
    //   [templatesData]
    // );
    // const [userUid, setUserUid] = useState(initialStateOptions[0])
    // const userUidValue = useMemo(
    //   () => ({ userUid, setUserUid }), 
    //   [userUid]
    // );
    const [theme, setTheme] = useState({ theme: 1, view: 0 })
    const themeValue = useMemo(
      () => ({ theme, setTheme }), 
      [theme]
    );
    const [refine, setRefine] = useState({
      gallery: {itemsPrRow: 4 },
      lookup: "", category: "all", show: { filterbox: true },
      listLength: { gallery: {...{multiple: 6}, ...generics.objMap(templates, (x) => 6, false) }},
      filterOptions: [], filterMode: true, filters: generics.objMap(templates, (x) => [], false), activeFilterAttr: generics.objMap(templates, (x) => Object.keys(x)[0], false),  ///note: show filterbox null used as default, due to zero-to-full-height animation (prevent animation on render). optimise: rename filters to filtersSelected. note: filterMode set to false="exlude mode"
      //dimensions: dimensionsInitial, // { height: 0, width: 0, height_o: 0, width_o: 0, heightInterval: 0, widthInterval: 0, forceGalleyRefined: false}
      documentMore: { document: "", directLoad: true, documentIndex: null, documentIndexs: [] }, ///optimise: strickly documentmore is not dependent on refine criteria, but is does accept what information is rendered ...
      sort: ""
    }) 
    const refineValue = useMemo(
      () => ({ refine, setRefine }), 
      [refine]
    );
    const [urlInfo, setUrlInfo] = useState({location: {pathname: "", state: {document: ""}}, params: {}}) //, directload: {artwork: false, documentParms: ""} })
    const urlInfoValue = useMemo(
      () => ({ urlInfo, setUrlInfo }), 
      [urlInfo]
    );
    const initialValueShow = {  ///optimise: move individual values to specific components? 
      modal: { show: false, modalChild: null }, ///question/optimise: move requester to multioptions?
      multiOptions: { data: [], label: "", requester: "", action: "", dataSelected: [], payloadObjectName: "", payloadInnerPathViaArray: [], preSelectedOptions: [], document: "" }, ///note: payload/value location is ensured either via 1) payloadInnerPathViaArray or 2) document, label
      dimensionsOptions: { data: [], label: "", dataSelected: [] }, ///optimise: look into use... used?
      textualArray: { data: [], document: "", label: "", child: <></>, startUpload: false },
      textualObjects: { data: [], document: "", label: "", child: <></>, startUpload: false },
      openingHours: { data: [], document: "", label: "", child: <></>, startUpload: false }
    } 
    const [show, setShow] = useState(initialValueShow) ///consider: to rename to modal or similar
    const showValue = useMemo(
      () => ({ show, setShow }), 
      [show]
    );

  //const [category_a, setCategory_a] = useState("artist") ///optimise: no hardcoding, but filtercategories[0] not working
  const [filterOptionsByCategory, setFilterOptionsByCategory] = useState([])

  ///const via context
  const 
    activeTemplates = templatesData.show.activeTemplates,
    activeTemplate = templatesData.show.activeTemplates[templatesData.show.activeTemplates.length - 1], ///was: activeTemplate = templatesData.update.activeTemplate
    category_a = refine.activeFilterAttr[activeTemplate]
    const filterCategories = Object.keys(Object.assign({}, 
      ...refine.filterOptions
        //.filter((filterObj) => !generics.arrayEmpty(Object.values(filterObj)[0])) ///note: exclude category, if no filter(s). optimise: simplify, use generics functions ///optimise: this was implemented to map only categories with actual filters, but currently it get filterdata from itemsrefined not items (so must must be updated)
    ))
    //.sort(); ///const

  ///const
  const prevRefine = usePrevious(refine.show.filterbox) 
  //const categories = refine["category"] === "all" ? ["artist", "title", "description", "tags"] : [refine["category"]] ///optimise: copied from galleryrefined. import instead.
  ///load and interaction (category selected) => get filteroptions by category
  useEffect(() => {
    let filterOptionsByCategoryWDuplicates = generics.valueByKeyInArrayOfObjects(refine.filterOptions, category_a) ///retrieve all filters from item data, including dupliates. risk: filter are retrieved from item data. if item data is paginated, all filters should be retrieved independely
    if (templatesType[activeTemplate][category_a] === "multiOptions") { ///was: (generics.keyExist(multiOptionsValues, category_a)) { ///if array
      let filtersAll = []
      filterOptionsByCategoryWDuplicates.map((array) => { /// => array of objects
        const filters = array.map(a => a[category_a]) ///filters in a commaseperated array [x, y, ..]
        filtersAll.push(...filters) ///merge filtersall and filters
      })
      filterOptionsByCategoryWDuplicates = filtersAll //.sort()
    }
    const filterOptionsByCategory = [...new Set(filterOptionsByCategoryWDuplicates)]
    setFilterOptionsByCategory(filterOptionsByCategory)
  }, [refine.filterOptions, category_a]);
	
  const handleCategory = (category) => {
    //setCategory_a(category)
    const 
      parameter = "activeFilterAttr",
      action = "filter-attr-set",
      value = category,
      index = 0, ///note: not used
      activeTemplate_ = activeTemplate
    handleSetRefine(parameter, action, value, index, activeTemplate_)
  }

  ///on category change =>
	useEffect(() => {
    if (category_a === "dimensions") {
      toggleModalShow()
    }
  }, [refine.activeFilterAttr]);

  ///on activetemplate change =>
	useEffect(() => {
    const show = templatesSetup[activeTemplate].isGallery
    const delayFunc = () => toogleShowFilterBox({ show })
    setTimeout(delayFunc, 50); ///optimise: timerfunc??
    //toogleShowFilterBox({show})
  }, [activeTemplate]);

    ///toogle show filterbox ///note: copied from refine.jsx
    const toogleShowFilterBox = ({ show }) => {
      const parameter = "show" ///optimise: combine serefine with handlesetrefine
      const target = "filterbox"
      setRefine(
        produce((draft) => {
          const item = draft
          item[parameter][target] = show;
        })
      );
    }

  ///on template (maincategory) change =>
	// useEffect(() => {
  //   const category = Object.keys(templates[activeTemplate]).sort()[0] ///first category in filterbox
  //   //handleCategory(category)
  // }, [templatesData.show.activeTemplates]);

  ///toogle modal with dimensions options
  const toggleModalShow = () => {
    const 
      component = "modal", //label
      action = "show", showValue = true,
      child = "modalChild", childValue = "dimensionsOptions"
    const 
      modalChild = "dimensionsOptions",
      parameter = "label", labelValue = "dimensions",
      data = "data", dataValue = { dimension: [] }
    setShow(
      produce((draft) => { 
        draft[component][action] = showValue
        draft[component][child] = childValue
        draft[modalChild][parameter] = labelValue
        draft[modalChild][data] = dataValue
      })
    );
  }

  ///select and deselect filter
  const handleFilter = (filter, category, activeTemplate_) => { ///optimise: consider to cleanup variables ...
    const parameter = "filters"
    const filterMode = refine.filterMode
    const filterSelected = generics.valueExistInArrayOfObjectsViaKey(refine[parameter][activeTemplate_], category, filter)
    let action
    switch (true) {
      case filterMode && !filterSelected:
        action = "filter-add"
        break;
      case filterMode && filterSelected ||
        !filterMode && filterSelected:
        action = "filter-remove"
        break;
      case !filterMode && !filterSelected:
        action = "filter-remove-all-add"
        break;
      default:
      break;
    };
    if (category === "remove") {
      action = "filters-remove"
    }
    const index = filterSelected ? generics.indexOfObjectInArray(refine[parameter][activeTemplate_], category, filter) : null
    const filterObj={}
    filterObj[category_a] = filter
    handleSetRefine(parameter, action, filterObj, index, activeTemplate_)
  }

  ///filter count pr. filter
  const itemsRefinedCountFilterIE = (filter, category_a, filterSelected) => { ///optimise: copied from galleryrefiend, combine ...

    // const filterCategories = Object.keys(Object.assign({}, ...refine.filterOptions)) //.sort() ///const
    // const filtersByCategoryPlus = filterCategories.map(category => { ///optimise: move to generic
    //   const refineFilters = [...refine.filters[activeTemplate]]
    //   const filterObj = { [category_a]: filter };
    //   const refineFiltersPlus = refineFilters.concat([filterObj])
    //   //console.log(refineFilters, refineFiltersPlus)
    //   let filters = {
    //   [category]: refineFiltersPlus
    //     .filter((filter => filter[category] !== undefined))
    //     .map((filter => filter[category])) 
    //   }
    //   return filters
    // })
  
    // const filtersByCategoryPlusNonEmpty = filtersByCategoryPlus.filter(object => Object.values(object)[0].length > 0) ///object including only categories where filters have been selected

    // const itemsRefinedModified = 
    //   items
    //     .filter(item => item.template === activeTemplate) ///note: include only items where template equal activetemplate
    //     .filter(item => { 
    //       const check = ///check for each category with a filter selected that item option is identical to at least one filter in that category, output a array with a boolean pr. non-empty category
    //         filtersByCategoryPlusNonEmpty ///array with objects, each object a category with given filters: [{artist: ['francisco toledo', 'frida kahlo']}, {title: ...}]
    //           .map(object => { // map over each object/category: artist: ['francisco toledo', 'frida kahlo']
    //             const category = Object.keys(object) ///array: ['artist']
    //             const itemContentPrCategory = templatesType[activeTemplate][category] === "multiOptions" ? ///was: generics.keyExist(multiOptionsValues, category) ? ///array with item content for the given category
    //               item[category].map(a => a[category]) : ///array: ['francisco toledo']
    //               [item[category]] ///array: ['tag', 'tagit']
    //             const filtersSelectedPrCategory = generics.valueByKeyInArrayOfObjects(filtersByCategoryPlusNonEmpty, category) ///array: ['francisco toledo', 'frida kahlo']
    //             const itemOptionEqualASelectedFilterPrCategory = itemContentPrCategory.map((itemOption) => { ///array with boolean; true if a item option is included in the selected filters
    //               return generics.stringInArray(filtersSelectedPrCategory, itemOption)  
    //             })
    //             return generics.stringInArray(itemOptionEqualASelectedFilterPrCategory, true) ///array with boolean; true if true found just once, else false: [true]
    //           })
    //       const include = 
    //       // refine.filterMode ? 
    //       // generics.stringInArray(check, true) : ///array with boolean; true if value true found just once 
    //         generics.valuesIdenticalInArray(check, true) ///array with boolean; true if all values are true
    //       if ( include ) { ///was: above valuesIdenticalInArray
    //         return item
    //       }
    //     })

    const organiseFilters = (filterObjPrTemplate) => { ///organises filters as template.category.filterarray
      //console.log("filterObjPrTemplate", filterObjPrTemplate)
      let filterCategories = [] ///note: filtercategories (pr. template)
      Object.values(filterObjPrTemplate) ///collect filtercategories pr. template via map
        .map((v) => {
          Object.entries(v).map(([k_, v_]) => {
            if (!generics.stringInArray(filterCategories, k_)) {
              filterCategories.push(k_)
              }
          })
        })
        return filterCategories.map((category) => { ///map each filtercategory, and popuplate array with filters
          const valueArray = []
          Object.values(filterObjPrTemplate)
            .map((v) => {
              Object.entries(v).map(([k_, v_]) => {
                if (k_ === category) {
                  valueArray.push(v_)
                }
              })
            })
        return { [category]: valueArray}
      })
    }
  
    const activeAttr = [...new Set(activeTemplates.map((activeTemplate => templatesFilterableAttr[activeTemplate])).flat())] ///was: activeTemplate].sort()
    const categories = refine["category"] === "all" ? activeAttr : [refine["category"]]
    const filterPlusObj = { [category_a]: filter };

    const filterIncludedCase = [true, false]
    const [itemsRefinedFilterIncluded, itemsRefinedFilterExcluded] = filterIncludedCase.map((includedCase) => {

      const filtersModified = Object.fromEntries(Object.entries({...refine.filters}).map(([template_, v]) => {
        let splicedArray = [...v]
        splicedArray.splice(splicedArray.findIndex(obj => obj[category_a] === filter), 1)
        const newValue = template_ === activeTemplate ? 
          includedCase ? ///if filter is selected
              filterSelected ? v : [...v, ...[filterPlusObj]] : ///add filter obj, if not selected/added by user
              filterSelected ? splicedArray : v ///remove filter obj
          : v  
        return [template_, newValue]
      }))

      const organisedFilters = generics.objMap(filtersModified, organiseFilters, false)
      
      let itemsRefinedModified = []
      itemsRefinedModified = 
        items
          //.filter( ( { publish } ) => !userPresent ? (publish || !publish) : publish )
          .filter(( { template } ) => generics.stringInArray(activeTemplates, template) )
          //.filter( ( { document } ) => directLoadArtwork ? generics.stringsAreIdentical(document, documentParams, false) : document !== null) ///at directloadartwork => only load specific item
          .filter(item => {
            const templateFilterableAttr = templatesFilterableAttr[item.template] ///filterable attributes for that item via template
            if (generics.arrayContainsValueInArray(templateFilterableAttr, categories)) { ///if item attr is included in search categories, return item if match
              return generics.partialMatchFromStart(refine["lookup"], generics.valuesToStringViaKeys(item, categories, " "))
            } else {
              return item ///if item attr is not included in search categories, return item (no filtering)
            }
            }) ///search: join relevant information and filter for matches in joined information
          .filter(item => { ///filtering
            if (generics.arrayEmpty(organisedFilters[item.template])) { ///if no filters selected for item template => return item (no filtering)
              return item
            }
            // if (generics.objectIsEmpty(organisedFilters)) {///was: (filtersByCategoryPlusNonEmpty.length === 0) { ///case: no filter selected (fx on load)
            //   return item
            // }
            const check = ///check for each category with a filter selected that item option is identical to at least one filter in that category, output a array with a boolean pr. non-empty category
            organisedFilters[item.template] ///was: filtersByCategoryPlusNonEmpty ///array with objects, each object a category with given filters: [{artist: ['francisco toledo', 'frida kahlo']}, {title: ...}]
                .map(object => { // map over each object/category: artist: ['francisco toledo', 'frida kahlo']
                  const category = Object.keys(object) ///array: ['artist']
                  const itemContentPrCategory = templatesType[activeTemplates[0]][category] === "multiOptions" ? ///was: generics.keyExist(multiOptionsValues, category) ? ///array with item content for the given category
                    item[category].map(a => a[category]) : ///array: ['tag', 'tagit'] 
                    [item[category]] ///array: ['francisco toledo']
                  const filtersSelectedPrCategory = generics.valueByKeyInArrayOfObjects(organisedFilters[item.template], category) ///array: ['francisco toledo', 'frida kahlo']
                  const itemOptionEqualASelectedFilterPrCategory = itemContentPrCategory.map((itemOption) => { ///array with boolean; true if a item option is included in the selected filters
                    return generics.stringInArray(filtersSelectedPrCategory, itemOption)  
                  })
                  return generics.stringInArray(itemOptionEqualASelectedFilterPrCategory, true) ///array with boolean; true if true found just once, else false: [true]
                })
            const include = 
              // refine.filterMode ? 
              // generics.stringInArray(check, true) : ///array with boolean; true if value true found just once 
              generics.valuesIdenticalInArray(check, true) ///array with boolean; true if all values are true
            if ( include ) {
              return item
            }
          })
          return itemsRefinedModified
        })

        const result = [itemsRefinedFilterIncluded.length, itemsRefinedFilterExcluded.length] //[itemsRefinedModified.length, itemsRefinedPrFilter.length]

        //console.log("itemsRefinedModified", filter, filterIncludedCase, refine.filters, filtersModified, itemsRefinedModified.length)
      
        //.map(item => item)
        let itemsRefinedPrFilter = []
        itemsRefinedPrFilter = templatesType[activeTemplate][category_a] === "multiOptions" ///was: generics.keyExist(multiOptionsValues, category_a) 
          ?
          itemsRefined
            .filter(item => item.template === activeTemplate)
            .filter(item => generics.stringInArray(item[category_a].map(a => a[category_a]), filter)) 
          : 
          itemsRefined
            .filter(item => item.template === activeTemplate)
            .filter(item => item[category_a] === filter) 
        
        
        //const filtersPrCategory = generics.valueByKeyInArrayOfObjects(filtersByCategoryPlusNonEmpty, category_a)
       // const filterSelected = filtersPrCategory.filter(filter_ => filter_ === filter).length === 2
        // const itemCountPrFilter_ = filterSelected ? itemsRefinedPrFilter.length  : itemsRefinedModified.length ///itemsRefinedCountFilterIE renamed to itemCountPrFilter
        // const itemsRefinedCount = itemsRefined
        //   .filter(item => item.template === activeTemplate).length
        // const anyFilterInCategorySelected = true //filtersPrCategory > 1
        // const result = anyFilterInCategorySelected && !filterSelected ?
        // itemCountPrFilter_ - itemsRefinedCount
        //   : itemCountPrFilter_
       
        // const itemsCountPrFilter = itemsRefinedPrFilter.length
        // const itemCountChangeIfAdded = itemCountPrFilter_ - itemsRefinedCount
        // //console.log("filter and length", filter, itemsCountPrFilter, itemCountChangeIfAdded)
        // const result_ = [itemsCountPrFilter, itemCountChangeIfAdded]
        //console.log("filtersPrCategory", filtersPrCategory, itemsRefinedCount)
        return result
  }

  const itemsCountPrFilterFunc = (filter, category_a) => {
    let itemsRefinedPrFilter = []
    itemsRefinedPrFilter = templatesType[activeTemplate][category_a] === "multiOptions" ///was: generics.keyExist(multiOptionsValues, category_a) 
      ?
      itemsRefined
        .filter(item => item.template === activeTemplate)
        .filter(item => generics.stringInArray(item[category_a].map(a => a[category_a]), filter)) 
      : 
      itemsRefined
        .filter(item => item.template === activeTemplate)
        .filter(item => item[category_a] === filter) 
    const itemsRefinedCountPrFilter = itemsRefinedPrFilter.length
    return itemsRefinedCountPrFilter
  }

	const handleSetRefine = (parameter, action, value, index, activeTemplate_) => { 
    setRefine(
      produce((draft) => {
        let item = draft[parameter];
        switch (action) {
          case "filter-attr-set":
            item[activeTemplate_] = value
            break
          case "filter-add":
            item[activeTemplate_].push(value);
            break;
          case "filter-remove":
            item[activeTemplate_].splice(index, 1);
            break;
          case "filtermode-toggle":
            draft[parameter] = !item;
            break;
          case "filters-remove":
            draft[parameter] = generics.objMap(templates, (x) => [], false);
            //draft["dimensions"] = dimensionsInitial
            break;
          case "filter-remove-all-add":
            draft[parameter][activeTemplate] = [value];
            //draft["dimensions"] = dimensionsInitial ///bug: correct?
            break;
          default:
          break;
        };
      })
    )
	};

  const Categories = useCallback(() => {
    return (
      <GridColums ///optimise: implement scrollbox with group => scroll group not single categories
        className={"filter-box-categories"}
        //gridTemplateColumns={`repeat(auto-fit, minmax(0, min(100%/${3}, max(100%/${filterCategories.length}))))`} 
        gridTemplateColumns={`repeat(${filterCategories.length}, minmax(10%, 100%)`} ///note/optimise/risk: min 10% can be too narrow
        gridTemplateRows={`2.5em`}
        scrollSnapType={"x mandatory"}
        scrollSnapAlignChildren={"start"}
        overflow={"scroll"}
        height={"100%"}
        hideScrollBars={true}
      >
        {filterCategories
          .map((category) => 
            <div 
              key={category}
              className={`
                filter-box-category 
                center-by-grid 
              ${category === category_a ? 
                `theme-${theme.theme === 0 ? 0 : 0}` : ///note: color keept dark
                ""
                }
                `
              }
              ref={(el) => { ///note: ref used to enable use of !important
                if (el) {
                  category === category_a ?
                    el.style.setProperty('background-color', templatesSetup[activeTemplate].color, 'important') :
                    el.style.setProperty('background-color', "transparent", 'important')
                } 
              }}
              onClick={() => handleCategory(category)}
            >
                <Button
                  show={true}
                  classTypes={["classical", ""]}
                  className={`
                    vertical-center-by-transform
                  `}
                  theme={category === category_a ? "" : theme.theme}
                  fontSize={1}
                  height={"100%"}
                >
                  <span className={`filter-box-category-text`}>
                    {category}
                  </span>
              </Button>
            </div>   
        )}
      </GridColums>
    )
  },[theme.theme, refine.filters, refine.filterOptions, category_a])

  const Filters = useCallback(() => {
    const parameter = "filters"

    let selectedFilters_ = ///optimise: computed in selectedfilters and recomputed. only compute once and setstate or similar?
    templatesData.show.activeTemplates
      .map((activeTemplate_) => 
        refine.filters[activeTemplate_]
      )
      .flat()
    const anyFilterSelected = selectedFilters_.length > 0
    //console.log("anyFilterSelected", selectedFilters_, anyFilterSelected)

    //const excludeFiltersAtSearch = refine.lookup !== "" && generics.stringInArray(categories, category_a)
    const dimensionsComponent = <></> ///reduced away
                                // <DimensionsOptionsLight
                                //   parentCallback={toggleModalShow}
                                // />
    const defaultComponent = 
      <div className={"filter-box-filters padding-1 flex-left"}>
          {filterOptionsByCategory
          //.filter((x) => console.log("filter", x))
          //.filter(filter => generics.partialMatchFromStart(refine["lookup"], filter)) ///search: join relevant information and filter for matches in joined information ///copied from galleryrefined ///optimise - state only once
          .map((filter) => {
            const filterSelected = generics.valueExistInArrayOfObjectsViaKey(refine[parameter][activeTemplate], category_a, filter)
            //console.log("filter", filter, )
            //console.log(filter, filterOptionsByCategory)
            itemsRefined
            .filter(( item ) => { return true ///filter dimensions (height and width) 
              ///includeagain
              // const { dimensions_g_cm } = refine.dimensions.dim 
              // const { dimensionsInterval_cm } = refine.dimensions.int
              // const height = Number(dimensions_g_cm.height) ///optimise: generic.round should return number, so number() is obsolute
              // const width = Number(dimensions_g_cm.width)
              // const heightInterval = Number(dimensionsInterval_cm.height) //refine.dimensions.heightInterval
              // const widthInterval = Number(dimensionsInterval_cm.width) //refine.dimensions.widthInterval
              // const itemHeight = item.dimensions[0]
              // const itemWidth = item.dimensions[1]
              // if (height === 0 && width === 0) { ///case: no filter selected
              //   return item
              // }
              //   return (
              //     itemHeight >= height - heightInterval &&
              //     itemHeight <= height + heightInterval &&
              //     itemWidth >= width - widthInterval && 
              //     itemWidth <= width + widthInterval 
              //   )
              })

              let itemsForFilter = []
              
              items
                .filter(item => item.template === activeTemplate) ///note: include only items where template equal activetemplate
                itemsForFilter = templatesType[activeTemplate][category_a] === "multiOptions" ///was: generics.keyExist(multiOptionsValues, category_a) 
                  ?
                  items.filter(item => 
                    Array.isArray(item[category_a]) && 
                    generics.stringInArray(item[category_a].map(obj => obj[category_a]), filter)) 
                  : 
                  items.filter(item => item[category_a] === filter) // \uf03e`
                const itemsPrFilterWDimensions = itemsForFilter
              const [itemsRefinedCountFilterIncluded, itemsRefinedCountFilterExcluded] = itemsRefinedCountFilterIE(filter, category_a, filterSelected, true)

              const itemsRefinedCountPrFilter = itemsCountPrFilterFunc(filter, category_a)
              
              //const [itemsCountPrFilterIncluded, itemCountChangeIfAdded] = itemsRefinedCountFilterIE(items, refine, filter, category_a, filterSelected, false)
              const itemsCount = itemsPrFilterWDimensions.filter(item => item.template === activeTemplate).length             
              //const filterCountPercentage = Math.floor(100 * filterCountAdd / itemsCount)
              
              //console.log(filter, itemsCountPrFilterExcluded, itemCountChangeIfExcluded, itemsCountPrFilterIncluded, itemCountChangeIfAdded)

              const itemsRefinedCountFilterDifference = itemsRefinedCountFilterIncluded - itemsRefinedCountFilterExcluded
              let itemCountTextVariable
              switch (true) {
                case refine.filterMode && filterSelected: 
                  itemCountTextVariable = itemsRefinedCountFilterDifference > 0 ? -itemsRefinedCountFilterDifference : `+${-itemsRefinedCountFilterDifference}`
                  break;
                case refine.filterMode && !filterSelected:
                  itemCountTextVariable = `${itemsRefinedCountFilterDifference > 0 ? `+${itemsRefinedCountFilterDifference}` : itemsRefinedCountFilterDifference}`
                  break;
                case !refine.filterMode:
                  itemCountTextVariable = `${itemsCount}`
                  break;
                default:
                break;
              };
              const itemsRefinedCountChangeFilterText =  
              <div>
                <span className={`filter-box-filter-item-filter-count-add bold`}>
                  {`${itemCountTextVariable}`} 
                </span> 
              </div>             
              const itemCountPrFilter =  
                <div>
                  <span className={`filter-box-filter-item-filter-count-add`}>
                    {/* {`${refine.filterMode ? 
                      `${filterSelected ?  
                          itemsCount : 
                          `${anyFilterSelected ? 
                            itemsCount - filterCountAdd :
                            itemsCount }`}
                              of ${itemsCount}` :
                      `${filterCountAdd} of ${itemsCount}`                       {`${itemCountTextVariable} of ${itemsCount} ${itemCountChangeIfAdded}`}
                      }`} */}
                      {`${itemsRefinedCountPrFilter} of ${itemsCount}`}
                    

                    {/* {`${refine.filterMode ? 
                      `${anyFilterSelected ?  
                          itemsCount - filterCountAdd : 
                          `${filterSelected ? 
                            itemsCount :
                            itemsCount }`}
                              of ${itemsCount}` :
                      `${filterCountAdd} of ${itemsCount}`
                      }`} */}
                  </span> 
                  {/* <SquareIcon
                      className={""}
                      width={"1em"}
                      border={false}
                      overflow={"hidden"}
                      display={"inline-block"}
                  >
                    <GirlPearl scale={0.1}></GirlPearl>
                  </SquareIcon>
                  <span className={`filter-box-filter-item-count`}>
                    {` of ${itemsCount}`}
                  </span>  */}
                </div>   
            return (
              <Button
                show={true}
                key={filter}
                classTypes={["classical", "subtle"]} ///bug: remove flickering of filter on select 
                className={`
                  filter-box-filter 
                ${itemsRefinedCountFilterIncluded - itemsRefinedCountFilterExcluded === 0 && refine.filterMode ? 
                  "filter-smaller" : 
                  ""}
                `}
                wrapperStyle={{margin: "0.5vw"}}
                fontSize={1}
                theme={theme.theme} 
                isSelected={filterSelected ? true : false}
                parentCallback={() => handleFilter(filter, category_a, activeTemplate)}
              >
                  <GridColums
                    //key={filter}
                    className={`filter-box-filter-content`}
                    gridTemplateColumns={`1fr`} 
                    gridTemplateRows={`1.5fr 2fr 1fr`} //{`5fr 2fr 1fr`}  //{`0.8fr 1fr 0.3fr`} 
                    //justifyContent={"center"}
                    alignItems={"end"}
                    //justifyItems={"bottom"}
                    height={`100%`}
                    width={`100%`}
                  >
                    <span className={"filter-box-filter-context-text"}>
                      {filter}
                    </span>
                    {itemCountPrFilter}
                    {itemsRefinedCountChangeFilterText} 
                    {/* <span className={`
                      filter-box-filter-context-text"
                      ${filterSelected ? "show fadein-01" : "hide fadeout-05"}
                      `}> 
                      {xMarkIcon}
                    </span> */}
                
                    {/* <DognutChart
                      className={`
                        dognut-chart
                        ${generics.valueExistInArrayOfObjectsViaKey(refine[parameter], category_a, filter) ? 
                        `theme-color-${theme.theme === 0 ? 1 : 0}` : ``}
                        `}
                      width={`3.5vw`} ///optimise: coordinate with filter-box-filter min-width
                      values={{filterCountAdd: filterCountAdd, itemsCount: itemsCount, filterCountPercentage: filterCountPercentage}}
                    /> */}
                  
                  </GridColums>
                  
                  {/* <span className={`filter-box-filter-item-count 
                  text-gradient-1
                  input-radius input-padding-2
                  `}
                  >
                    {`${filterCountAdd} / ${itemsCount}`}
                  </span> */}

             
              </Button>
              )
            }
          )}
          </div>
  
      let component
      switch (true) {
        case category_a === "dimensions":
          component = dimensionsComponent
          break;
        default:
          component = defaultComponent
        break;
      };

    return (
      <div className={"filter-box-filters-wrapper"}>
        <FilterBoxButtons/>
        {component}
      </div>
    )
  }, [theme.theme, refine.filterMode, filterOptionsByCategory, refine.filters, refine.filterMode, refine.filterOptions, category_a]) ///show? obsolute: refine.filters, refine.filterMode, refine.filterOptions, category_a, 

  // const FilterModeToggle = useCallback(() => {
  //   const 
  //     parameter = "filterMode",
  //     action = "filtermode-toggle",
  //     filterMode = refine.filterMode ? "include" : "exclude",
  //     text = `filter-mode: ${filterMode}`
  //   return (
  //     <span className={`
  //       filter-box-filter-mode-toggle-text 
  //       font-size-0
  //       input input-hover input-padding-2 input-radius input-border-1`}
  //       onClick={() => handleSetRefine(parameter, action)}
  //     >
  //       {text}
  //     </span>
  //   )
  // },[refine.filterMode])

  const FilterModeToggle = useCallback(() => {
    const 
      parameter = "filterMode",
      action = "filtermode-toggle",
      filterMode = refine.filterMode ? "multiple" : "single",
      text = `selection mode: ${filterMode}`
    return (
      <Button
        show={true}
        classTypes={["classical", "subtleSuper"]}
        fontSize={0}
        theme={theme.theme}
        height={"100%"}
        text={text}
        parentCallback={() => handleSetRefine(parameter, action)}
      >
      </Button>
    )
  },[refine.filterMode, theme.theme])

  const FiltersRemove = () => {
    const 
      show = refine.filters.length > 0 || refine.dimensions.resetReady,
      parameter = "filters",
      action = "filters-remove"
    return (
      <div className={`
        filters-remove-wrapper
        ${show ? "show fadein-05" : "hide fadeout-05"}
      `}>
          <Button
            show={true}
            classTypes={["classical", "all"]}
            className={`filters-remove`}
            theme={`theme-${theme.theme}`}
            fontSize={1}
          >
      
              <i className="fas fa-filter filters-remove-icon"
                onClick={() => handleSetRefine(parameter, action)}
              ></i>
    
            </Button>
      </div>
    )
  }

  const FilterBoxButtons = () => {
    return (
      <div className={"filter-box-buttons-wrapper"}>
        <GridColums
          className={"filter-box-buttons"}
          gridTemplateColumns={`fit-content(50%) fit-content(50%)`}
          //columnGap={`0.5vw`}
          justifyContent={"end"}
          alignItems={"end"}
       
        >
          {/* <FiltersRemove/> */}
          <FilterModeToggle/>
        </GridColums>
      </div>
    )
  }

  const SelectedFilters = useCallback(() => {
    
    let includeRemoveAll = false
    let selectedFilters_ = ///note: only computed to determine includeremoveall ///optimise: alterntive to determine includeremoveall?
      templatesData.show.activeTemplates
        .map((activeTemplate_) => 
          refine.filters[activeTemplate_]
        )
        .flat()
    if (selectedFilters_.length > 1) {
      includeRemoveAll = true
    }

    const selectedFilters =
     templatesData.show.activeTemplates
      .map((activeTemplate_, i) => {
        const filters = 
          includeRemoveAll && 
          templatesData.show.activeTemplates.length - 1 === i ? ///note: map only removeall at last index (one intance)
            [...refine.filters[activeTemplate_]].concat([{ remove: "ALL" }]) :
            [...refine.filters[activeTemplate_]]
          return filters.map((filterObj) => {
            const filter = Object.values(filterObj)[0]
            const category = Object.keys(filterObj)[0]
              return (
                  <Button
                    show={true}
                    key={filter}
                    classTypes={["classical", "all"]} ///bug: remove flickering of filter on select 
                    className={`
                      filter-box-filter 
                    `}
                    wrapperStyle={{margin: "0.5vw"}}
                    fontSize={1}
                    theme={filter === "ALL" ? theme.theme : 1} ///optimise: filter "all" not white when theme black??
                    isSelected={true}
                    innerRef={(el) => { ///note: ref used to enable use of !important
                      if (el) {
                        if (filter === "ALL") {
                        }
                        else {
                        el.style.setProperty('background-color', templatesSetup[activeTemplate_].color, 'important') 
                        el.style.setProperty('border-color', templatesSetup[activeTemplate_].color, 'important') 
                        }
                      } 
                    }}
                    parentCallback={() => handleFilter(filter, category, activeTemplate_)}
                  >
                    <GridColums
                      //key={filter}
                      className={`filter-box-filter-content`}
                      gridTemplateColumns={`1fr`} 
                      gridTemplateRows={`5fr 1fr`}  //{`0.8fr 1fr 0.3fr`} 
                      //justifyContent={"center"}
                      alignItems={"center"}
                      justifyItems={"center"}
                      height={`100%`}
                      width={`100%`}
                    >
                      <span className={"filter-box-filter-context-text"}
                      >
                        {filter}{xMarkIcon}
                      </span> 
                    </GridColums>
                  </Button>
              )
        })
      }
    )
    
    return (
      // <Wrapper
      //   name={`selected-filters`}
      // >
      <Wrapper
        name={`selected-filters`}
        marginTop={`1vh`}
      >
        <FlexBox
          className={"selected-filters"} 
   
        > {selectedFilters}
        </FlexBox>
      </Wrapper>
      // </Wrapper>
    )
  }, [refine.filters, category_a, templatesData.show.activeTemplates])

  const prevTemplateIsGallery = usePrevious(templatesSetup[activeTemplate].isGallery) 
  const Box = memo(() => {

    const BoxWrapper = useCallback(({ children }) => {
      return (
        <ZeroToFullHeight 
          noShow={false} ///{!refine.show.filterbox} ///reduced away
          justShow={true} ///refine.show.filterbox && prevRefine} ///reduced away
          animateForwards={true} //{refine.show.filterbox && !prevRefine}
          animateReverse={false} //prevRefine && !refine.show.filterbox}
        > { children }
        </ZeroToFullHeight>
      )
    },[refine.show.filterbox])
    // const BoxWrapper = useCallback(({ children }) => {
    //   console.log("templatesSetup", activeTemplate, templatesSetup[activeTemplate].isGallery)
  
    //   return (
    //     <ZeroToFullHeight 
    //       noShow={!templatesSetup[activeTemplate].isGallery}
    //       justShow={true}
    //       animateForwards={!prevTemplateIsGallery && templatesSetup[activeTemplate].isGallery}
    //       animateReverse={prevTemplateIsGallery && !templatesSetup[activeTemplate].isGallery}
    //     > { children }
    //     </ZeroToFullHeight>
    //   )
    // },[activeTemplate])

    const BoxContent = useCallback(() => { 
      return (
        <div className={`
          filter-box-wrapper
          padding-top-2
        `}>
        <div className={` 
          filter-box
          vertical-gridbox 
          btn-like
          theme-${theme.theme}
        ${true ? ///refined away, was: refine.show.filterbox
          "show fadein-05" : 
          "hide fadeout-01"}
        `}
        ref={(el) => { ///note: ref used to enable use of !important
          if (el) {
            el.style.setProperty('border-color', templatesSetup[activeTemplate].color, 'important')
            el.style.setProperty('border-width', '2px' , 'important')
          } 
        }}
        >
            <Categories/>
            <Filters/>
        </div>
      </div>
      )
    }, [refine.filters, category_a])

    return (
      <BoxWrapper
        // show={show}
        ///height={"50vh"} ///not implemented/no explicit dynamic height
      >
        <BoxContent/>
        <SelectedFilters/>
      </BoxWrapper>
    )
  })

  return (
    <Box/>
  );
}

export default memo(FilterBox)

export const Attrs = ({ identifier, template, item }) => { ///note: consider to move outside filterbox...

  const { templates } = useContext(templatesContext)

  const {lastChanged, ...attrs } = item.attr

  console.log("attrs", item, attrs)
  // const categories = item.staticData["category name id"]

  let templatesAttrs = {}
  
  Object.entries(templates[template])
    .filter(([k, v]) => v.type === "multiOptions" && v.mapping[0]) 
    .map(([k, v]) => templatesAttrs[k] = []) //{ return { [k]: [] } })

      // let includeRemoveAll = false
      // let selectedFilters_ = ///note: only computed to determine includeremoveall ///optimise: alterntive to determine includeremoveall?
      //   templatesData.show.activeTemplates
      //     .map((activeTemplate_) => 
      //       refine.filters[activeTemplate_]
      //     )
      //     .flat()
      // if (selectedFilters_.length > 1) {
      //   includeRemoveAll = true
      // }
  const combinedAttrs = generics.combineObjects({obj1: attrs, obj2: templatesAttrs })

  //console.log("categories", categories)

  const selectedFilters =
  // templatesData.show.activeTemplates
  // Object.fromEntries(
    Object.entries(combinedAttrs)
      .map(
      ([k, v], i) => {
        if (v.length < 1) {
          return (
            <>
            <div
              className={"attrs-no-attr"}
              style={{color: templates["video"][k]?.color}}
            >({k})</div>
            <Edit 
              identifier={identifier}
              label={k}
              param={"attr"}
              // stateData={attrs[category]}
            />
            <div
              style={{
                  display: "block",
                  width: "100%",
                  height: "0",
                  position: "relative"
                }}
            ></div> </>
          )
        }
        const attr = k
        return v.map((obj, i) => {
          const filter = Object.values(obj)[0]
          const category = Object.keys(obj)[0]
            return (
              <>
                <Button
                  show={true}
                  key={filter}
                  classTypes={["classical", "all"]} ///bug: remove flickering of filter on select 
                  className={`
                    filter-box-filter 
                  `}
                  wrapperStyle={{margin: "0.5em"}}
                  style={{padding: "0.5em"}}
                  fontSize={0}
                  height={"auto"}
                  theme={1} ///optimise: filter "all" not white when theme black??
                  isSelected={true}
                  innerRef={(el) => { ///note: ref used to enable use of !important
                    if (el) {
                      if (filter === "ALL") {
                      }
                      else {
                      el.style.setProperty('background', templates["video"][category]?.color, 'important') 
                      el.style.setProperty('border-color', templates["video"][category]?.color, 'important') 
                      }
                    } 
                  }}
                  parentCallback={() => console.log("bib")}
                >
                  <GridColums
                    //key={filter}
                    className={`filter-box-filter-content`}
                    gridTemplateColumns={`1fr`} 
                    gridTemplateRows={`0.5fr 1fr`}  //{`0.8fr 1fr 0.3fr`} 
                    //justifyContent={"center"}
                    alignItems={"center"}
                    justifyItems={"center"}
                    height={`100%`}
                    width={`100%`}
                    style={{lineHeight: "1.3"}}
                  >
                    <span className={"attr-attr"}
                    > 
                      {category}
                    </span> 
                    <span className={"filter-box-filter-context-text"}
                    > 
                      {filter}
                    </span> 
                  </GridColums>
                </Button> 
                  {i === v.length - 1 ? ///note: seperate row for each attr
                    <>
                    <Edit 
                      identifier={identifier}
                      label={category}
                      param={"attr"}
                      // stateData={attrs[category]}
                    />
                    <div
                      style={{
                          display: "block",
                          width: "100%",
                          height: "0",
                          position: "relative"
                        }}
                    ></div> </> :
                    <></>
                  }
              </>
            )
      })
      })
  // )

      return (
     
        <Wrapper
          name={`selected-filters`}
          width={"auto"}
          height={"auto"}
        >
        <Wrapper
          name={`selected-filters`}
          marginTop={`1vh`}
        >
          <FlexBox
            className={"selected-filters"} 

          > {selectedFilters}
          </FlexBox>
        </Wrapper>
        </Wrapper>
      )
}