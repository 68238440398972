import './app.css';
import './styling-standards.css';
import './x-list.css';
import { useState, useEffect, useContext, useRef } from 'react';
import { Wrapper } from './StandardComponents';
import { dlsDataContext, videosDataContext, focusStatesContext } from './Home2';
import { Category } from './CategoryGallery2';
import { PlComponent } from './PlGallery';
import { MemoWithLink } from './PlGallery';
import * as generics from './generics';
import { createContext as selectorContext, useContextSelector } from 'use-context-selector';
import { Box } from './Box.js'
import { ItemTypes } from './ItemTypes.js'
import { useLocation } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const PlOverlay = ( props ) => 
	<div className={`pl-overlay`} 
	> 
		<span className={`pl-published`}
			style={{background: "white"}} ///props.background
		>
		{props.published}
		</span>
    <span className={`pl-x`}
			style={{background: "white"}} ///props.background
		>
		{"x"}
		</span>
    {props.plType !== "video" ?
		<span
      className={`pl-length`}
			style={{background: "white"}} ///props.background
     >{props.videosLength} <i className={`fas size-a fa-brands fa-youtube`}></i>
     </span> : <span/> 
     }
		<span	
			style={{background: "white"}} ///props.background
		>{"hi"}</span>
	</div>

export const XListComponents = ({ id, isMuted }) => {

    ///ref
    const xlRefs = useRef([]);

    ///location
    let location = useLocation()

    useEffect(() => {
        const 
            pathname = location.pathname,
            pathLength = pathname.split("/").filter(Boolean).length
        if (pathname.includes("xl-") && pathLength === 1) {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
          });
        }
    }, [location])


    ///context
    const 
        swrDls = useContext(dlsDataContext),
        swrVideos = useContext(videosDataContext),
        dlFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list")),
        dlcFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list-creator")),
        setFocusStates = useContextSelector(focusStatesContext, v => v.setFocusStates)

    const handleSetFocusStates = ( focusParams ) => {
        setFocusStates((draft) => {
            const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === focusParams.mediaTypeFocus);
            Object.entries(focusParams) ///note: set state for each param (passed via function)
            .filter(([param, v]) => param !== "mediaTypeFocus") ///exclude param value from set state
            .map(([param, v], i) => focusElement[param] = v )
            })
        }
	
    const name = "x-lists"

    if (swrDls.isError) return <p></p>; 
    if (swrDls.isLoading) return <p></p>;
      else { 

        const videos = swrVideos?.data ? swrVideos.data : []

        const Component = ({ xItem, i }) => {

            console.log("xItemX", xItem)
    
            const itemType = "template" in xItem ?
                xItem.template :
                xItem.staticData.template ///optimise: use of type not template ...
                
            switch (true) {
              case itemType === "category" || itemType === "channel":
                return (
                    <Box
                        ref={el => xlRefs.current[i] = el}
                        draggedItem={xItem}
                        type={ItemTypes.CATEGORY}
                        isDropped={false}
                        key={i}
                    >
                        <Category
                        category={xItem}
                        subscriberText={""}
                        withLink={true} 
                        />
                    </Box>
                )
                break;
              case itemType === "pl":
        
                return (
                    <Box
                        ref={el => xlRefs.current[i] = el}
                        draggedItem={xItem}
                        type={ItemTypes.PL}
                        isDropped={false}
                        key={i}
                    >
                        <PlComponent 
                            id={"x-pl-component"}
                            isGallery={true}
                            pl={{...xItem, id: "x-pl-component"}}
                            // background={background}
                            // imgFile={categoryImg}
                            videos={videos}
                            isMuted={isMuted}
                        />
                    </Box>
                )
                break;
              case itemType === "video":
                break;
              default:
                <></>
              }
          }
    
        const XLists = ({}) => {

            const dlcFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list-creator"))

            const plNoColumns = 4
            let plWidth

            switch(true) {
              case isMobile:
                plWidth = "90vw";
              break;
              default:
                plWidth = `${((100 - (dlcFocusStates.isFocus ? 20 : 0) - 2*4 - (plNoColumns - 1) * 2) * 1) / plNoColumns }vw`  ///note: 3 equals number of colums, and the substration of multiplum of x is due to gap-column ///note/risk: calc of plWidth not working if plNoColumns is 1 (divide by 0) ///note: if width is not specified explicit it will be 100% of parent (OK), but missing explicit animation start width at select will result in glitchy animation (NOT OK), thus => explicit width.
              break;
            }

            return swrDls.data.map((xList) => {

                console.log("xList", xList)

                const x = xList.data.length

                const   
                    date = xList.staticData.publishedAt.toDate(), //.toDateString()
                    published = `${generics.monthNames[date.getMonth()]} ${date.getFullYear()}`,
                    to = `/xl-${xList.staticData.id}`,
                    background = xList.dynamicData.hasOwnProperty("background") ? 
                        xList.dynamicData?.background?.[0].background :
                        "lightblue"


                return (
                    <div className={`x-list-wrapper`}>
                        <div className={`x-list-container`}>
                            {/* <PlComponent 
                                id={"x-list-item"}
                                isGallery={false} ///note: implemented to distingush between gallery and list (fx diamont-list)
                                pl={{...xList, id: "x-list-item"}} ///note: id (or gId) added here to pl to capture gallery (a simpler alternative than implementing "gallery" at focusStates) => currently this id is used to apply "blanket" only once, namely at gallery that holds selected pl
                                background={""}
                                imgFile={""}
                                videos={videos}
                                isMuted={isMuted}
                            /> */}
                            <div className={`x-list`}
                                onClick={(e) => {
                                    if (true) {
                                        const 
                                        mediaTypeFocus = "diamont-list",
                                        actionFocus = "select",
                                        elementFocus = xList,
                                        idFocus = xList.staticData.id,
                                        isFocus = true
                                    handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                                        //props.focusProvider.setFocusObjsCb({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                                    }
                                }}
                                style={{background: background}}
                            >
                                <MemoWithLink 
                                    id={"link"}
                                    to={to}
                                ></MemoWithLink>
                                <div className={`x-list-description-c`}>
                                    <span className={`x-list-description`}>
                                    {xList.dynamicData.name}
                                    </span> 
                                </div>
                                <PlOverlay
                                    id={"overlay"}
                                    plType={"pl"}
                                    videosLength={1} ///x-listing
                                    //background={props.background} 
                                    published={published} 
                                    // imgFile={props.imgFile} 
                                />
                            </div>
                        </div>
                    </div>
                )
                }
            )
        }

        const Components = ({}) => {
            if (!dlFocusStates.isFocus) { return <></>}
            console.log("swrdlsdata", swrDls.data, dlFocusStates.idFocus)
            return (
                swrDls.data
                    .filter((xList) => xList.staticData.id === dlFocusStates.idFocus)
                    .map((xList, i) => 
                        xList.data.map((xItem) =>
                            <div className={`x-list-item`}>
                                <Component
                                    xItem={xItem}
                                    i={i}
                                />
                                <span>Bibbber</span>
                            </div>
                        )
                )
            )
        }
        
            
          return (  
                <Wrapper
                name={name}
                style={{marginLeft: `${dlcFocusStates.isFocus ? "20vw" : "0vw"}`}}  
                >
                    <div className={`${name}`}>
                        {id === "x-lists" ? 
                        <XLists/>
                        :
                        <Components/>}
                    </div>
                </Wrapper>
          )
    } 
}