import '../src/category-gallery.css';
import React, { Fragment, useLayoutEffect, useState, useRef, useEffect, useContext, memo, useCallback, useMemo } from 'react';
import { Link } from "react-router-dom";
import { categoryGroupsDataContext, categoriesDataContext, channelsDataContext, focusStatesContext } from './Home2';
import { colorsLight } from './colors';
import * as generics from './generics';
import { Sticky } from './StandardComponents';
import { createContext as selectorContext, useContextSelector } from 'use-context-selector';
import { useLocation } from "react-router-dom";
import { Box } from './Box.js'
import { ItemTypes } from './ItemTypes.js'


import { useDrag } from 'react-dnd'
// import { ItemTypes } from './DnDItemTypes'

const ConditionalLink = ({ key, children, condition, ...props }) => {
  return condition ? 
  <Link {...props}>{children}</Link> : <> { children } </>
}

// const FocusControl = ({ children, ...props }) => {

//     const { focusStates, setFocusStates } = useContext(focusElementsContext)

//     const onsetFocusElements = ({ }) => {
//         console.log(props)
//         const { mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus } = props
//         setFocusStates((draft) => {
//                 const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === mediaTypeFocus);
//                 focusElement.actionFocus = actionFocus
//                 focusElement.elementFocus = elementFocus
//                 focusElement.idFocus = idFocus
//                 focusElement.isFocus = isFocus
//         })
//     }

//     return (
//         <div
//             handleSetFocusStates={onsetFocusElements}
//         >
//             { children }
//         </div>
//     )
// }

const getCategoriesMap = ({ categoryGroups, categories }) => {
    ///note: consider to implemnet sortId for each (main)category, to control order of map 
    let categoriesMap = []
    categoryGroups
        .filter(categoryGroup => categoryGroup["is show?"])
        .map(categoryGroup => {
            categoriesMap.push({ type: "header", "category name": categoryGroup["header title"] }) ///question: only push header if isMap, as here, or in addition, only if at least one category is also isMap?
            categories 
                .filter(category => category["category group name"] === categoryGroup["category group name"])
                .filter(category => category["is show?"])
                .map(category => 
                    categoriesMap.push(
                        { 
                            ...category, 
                            type: "category", 
                            "is channels?": categoryGroup["is channels?"], 
                            "is show extra categories?": categoryGroup["is show extra categories?"], 
                            "is show catchphrase?": categoryGroup["is show catchphrase?"]
                        }
                    ))
        })

  return categoriesMap
}

const ImageComponent = ({ withLink, obj }) => {

    ///context
    const setFocusStates = useContextSelector(focusStatesContext, v => v.setFocusStates)
      
    ///states
    const [loaded, setLoaded] = useState(false);
    
    ///functions
    const handleImageLoad = () => {
        setLoaded(true);
      };

    const handleSetFocusStates = ({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus }) => {
        setFocusStates((draft) => {
                const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === mediaTypeFocus);
                focusElement.actionFocus = actionFocus
                focusElement.elementFocus = elementFocus
                focusElement.idFocus = idFocus
                focusElement.isFocus = isFocus
        })
      }

    return (
        <img
            onLoad={handleImageLoad}
             className={`
                category-img
                ${loaded ? "fade-in" : "fade-out"}
                `}
            // src={!obj.img ? 
            //     "data:img/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" : 
            //     obj.img
            // }
            src={obj.media[0].url} ///{require(`../src/imgs/categories/${obj["category name"]}.png`)}
            draggable={false}
            alt={obj.category}
            onClick={(e) => {
                const ///optimse: consider to specify parameters below only once...
                    mediaTypeFocus = "category",
                    actionFocus = "select",
                    elementFocus = obj,
                    idFocus = obj["category id"],
                    isFocus = true
           withLink && handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
            }}
        ></img>
        )
    }

// export default function Card({ children }) {
//     const [{ isDragging }, dragRef] = useDrag(
//         () => ({
//         type: ItemTypes.CARD,
//         //item: { children },
//         collect: (monitor) => ({
//             opacity: monitor.isDragging() ? 0.5 : 1
//         })
//         }),
//         []
//     )
//     return (
//         <div ref={dragRef} style={{ opacity: isDragging ? 0.5 : 1 }}>
//             { children }
//         </div>
//     )
//     }

export const Category = ({ category, subscriberText, withLink }) => {

    ///context
    const dlFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list"))

    const background = !!category.background?.[0] ? ///optimise: multioption save: "string vs. object" in array ///colors[i % colors.length] 
        category.background?.[0].background :
        "white" ///fallback background

    const to = !!dlFocusStates.isFocus ? 
        `/xl-${dlFocusStates.idFocus}/cat-${category["category name id"]}` :
        `/cat-${category["category name id"]}`
    return (
        <div className={`category-w`}>
            <div className={`category-c`}>
                <div className={`category-header-w`}>
                    <div className={`category-header-c`}>  {/* note: ///superfluous class, but relevant if styling from w is removed (changed), see also scss */}
                        <span className={`category`}> 
                            {category["category name"]}
                        </span>  
                        <span className={`extra-categories`}> 
                            {category["is show extra categories?"] ? category["is show extra categories?"] : ""}
                        </span> 
                        <span className={`subscribers`}> 
                            {subscriberText}
                        </span>  
                    </div>

                    <span className={"category-catchphrase"}>{category["is show catchphrase?"] ? category["catchphrase"] : ""}</span>       
                </div>
                <div className={"category-media-c"}>
                    <div className={`category-background`}
                        style={{background: background}} 
                    >
                    </div>
                    <ConditionalLink 
                        // key={category.id}
                        condition={withLink} 
                        to={to}
                    >
                    <ImageComponent 
                        withLink={withLink} 
                        obj={category}
                    />
                    </ConditionalLink>
                </div>
            </div>
        </div> 
    )
}


export const CategoryGallery2 = (props) => {

    ///refs
    const categoryRefs = useRef([]);

    console.log("CategoryGallery2")

    const CategoriesGalleryComponent = memo(({ categoryGroups, categories, channels, stickyCallback  }) => { 

    const setFocusStates = useContextSelector(focusStatesContext, v => v.setFocusStates)

    let location = useLocation()

    const handleSetFocusStates = ({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus }) => {
        setFocusStates((draft) => {
                const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === mediaTypeFocus);
                focusElement.actionFocus = actionFocus
                focusElement.elementFocus = elementFocus
                focusElement.idFocus = idFocus
                focusElement.isFocus = isFocus
        })
      }


    //   useEffect(() => { ///direct load => set category
    //     const 
    //       pathname = location.pathname,
    //       pathLength = pathname.split("/").filter(Boolean).length
    //       console.log("location pathname", location.pathname, pathLength)
    //     if (
    //         pathLength > 0 && ///(pathLength === 1 || pathLength === 2) {
    //         pathname.split("/")[1].startsWith("cat-")
    //         ) { 
            
    //         const categoryId = pathname.split("/")[1].replace(/cat-/g, '').replace(/\//g, '')
    //             // categoryId = pathLength === 1 ?
    //             //     pathname.split("/")[1].replace(/\//g, '') :
    //             //     pathname.split("/")[1].replace(/\//g, '') ///.split("-")[0] ///experiment/risk: this action at pathLength === 2, consider removing
    //         console.log("location", categoryId, categoryId)
    //         const 
    //             mediaTypeFocus = "category",
    //             actionFocus = "select",
    //             elementFocus = categoriesMap.find(category => encodeURI(category["category name"].toLowerCase().replace(/ /g, "_")) === categoryId)
    //         if (!!elementFocus) {
    //             const idFocus = elementFocus["category name"],
    //             isFocus = true
    //             console.log("obj", mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus)
    //             handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
    //         }
    //     }
    //   }, [location]); 

      const categoriesMap = getCategoriesMap({ categoryGroups, categories })
      ///colors = colorsLight({isRadial: true}) 

        return (
            categoriesMap.map((category, i) => {
                
                const withLink = true 
                const subscriberCount = category["category group name"] === "channels" ? 
                    channels.find(channel => channel.staticData["category name id"] === category["category name id"])?.stats.subscriberCount : ///note ?. to mitigate error if channel data does not exist
                    ""
                const subscriberCountFormatted = generics.getNumeral(subscriberCount)
                const subscriberText = subscriberCount > 0 ?
                    `${subscriberCountFormatted} subscribers` : ""
                //const extraCategory = Array.isArray(category.extraCategory) ? category.extraCategory.join(" ") : ""


                // typesCategories.indexOf(category.categoryName) !== -1 ?
                //     colors[`${typesCategories.indexOf(category.categoryName) > colors.length ? typesCategories.indexOf(category.categoryName) % colors.length : typesCategories.indexOf(category.categoryName)}`] : ///was: colorsLight[Math.floor(Math.random() * (colorsLight.length - 0 + 1)) + 0] ///note: or index
                //     colors[`${youTubeChannelCategories.indexOf(category.categoryName) > colors.length ? youTubeChannelCategories.indexOf(category.categoryName) % colors.length : youTubeChannelCategories.indexOf(category.categoryName)}`] //categoriesExtraData.extraCategory[category.category] !== undefined && colorsLight[typesCategories.indexOf(categoriesExtraData.extraCategory[category.category][0])]

                let component = <></>
                switch(true) {
                    case category.type === "header":
                        component =
                        <Sticky 
                            offsetSticky={"8vh"} 
                            fadeSticky={true}
                            className={"sticky-category-gallery-header"}
                            stickyCallback={stickyCallback}
                        >
                        {(isSticky) => (
                            <div className={"category-gallery-header-c"}
                            >
                            <h1 className={"category-gallery-header"}>
                                {category["category name"]}
                            </h1>
                            <span className={
                                `category-gallery-subheader
                                ${isSticky ? "fade-out" : "fade-in"
                                }`
                                }>
                            {category.plSubtitle}
                            </span>
                         </div> 
                        )}
                        </Sticky>
                    break;
                    // case category.id === "dummy":
                    //     component =
                    //         <div className={"category-dummy"}/>
                    // break;
                    case category.type === "category":

                        component = 
                        <Box
                            ref={el => categoryRefs.current[i] = el}
                            draggedItem={category}
                            type={ItemTypes.CATEGORY}
                            isDropped={false}
                            key={i}
                        >
                            <Category
                                category={category}
                                subscriberText={subscriberText}
                                withLink={withLink}
                            />
                        </Box>
                        //  </Card>
                            // <div className={`category-w`}
                        
                            // >
                            //     {/* <div className={`category-c`}
                            //     > */}
                            //     <div className={`category-c`}>
                            //         <div className={`category-header-w`}>
                            //             <div className={`category-header-c`}>  {/* note: ///superfluous class, but relevant if styling from w is removed (changed), see also scss */}
                            //                 <span className={`category`}> 
                            //                     {category["category name"]}
                            //                 </span>  
                            //                 <span className={`extra-categories`}> 
                            //                     {category["is show extra categories?"] ? category["extra categories"] : ""}
                            //                 </span> 
                            //                 <span className={`subscribers`}> 
                            //                     {subscriberText}
                            //                 </span>  
                            //             </div>
            
                            //             <span className={"category-catchphrase"}>{category["is show catchphrase?"] ? category["catchphrase"] : ""}</span>       
                            //         </div>
                            //         <div className={"category-media-c"}>
                            //             <div className={`category-background`}
                            //                 style={{background: background}} 
                            //             >
                            //             </div>
                            //             <ConditionalLink 
                            //                 // key={category.id}
                            //                 condition={withLink} 
                            //                 to={`/cat-${category["category name id"]}`}
                            //             >
                            //             <ImageComponent 
                            //                 withLink={withLink} 
                            //                 obj={category}
                            //             />
                            //             </ConditionalLink>
                            //         </div>
                            //     </div>
                            //     {/* </div>  */}
                            // </div> 
                    break
                    default:
                }
            
                return (
                    <Fragment key={`${category.category}-${i}`}>
                        {component}
                    </Fragment>
                )

            })
        )
    })

    const Gallery = ({ children }) =>  { 
        
        const 
            swrCategoryGroups = useContext(categoryGroupsDataContext),
            swrCategories = useContext(categoriesDataContext),
            swrChannels = useContext(channelsDataContext),
            dlcFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list-creator"))

        //const [staticHeaderText, setStaticHeaderText] = useState("x")

        const stickyCallback = useCallback(({ innerText }) => {
           // setStaticHeaderText(innerText)
        }, [])
   
        if (swrCategoryGroups.isError || swrChannels.isError || swrCategories.isError) return <p></p>;
        if (!swrCategoryGroups.data || !swrChannels.data || !swrCategories.data ) return <p className={"loading"}>Loading...</p>; ///note: !x.data used instead of x.isLoading, due to "pagination implementation" of useSWRImmutable with multiple calls via key isLoaded => multiple calls result in multiple isLoading true, independent whether !!data, thus !data is utilised here instead ///alternative: if (swrCategoryGroups.isLoading || swrChannels.isLoading || swrCategories.isLoading) return <p className={"loading"}>Loading...</p>;
            else {
                return ( 
                    <>
                    <div 
                      className={`gallery-w`}
                      style={{marginLeft: `${dlcFocusStates.isFocus ? "20vw" : "0vw"}`}}
                    >   
                        {/* <StaticHeader
                            text={staticHeaderText}
                        /> */}
                        {/* <GalleryHeadline
                            show={true}
                            // headline={"yoga"}
                            // subline={"categories"}
                        /> */}
                        <div ///optimise: gallery should only render once...
                            className={`
                                category-gallery
                                `}
                            >
                            <CategoriesGalleryComponent
                                categoryGroups={swrCategoryGroups.data}
                                categories={swrCategories.data}
                                channels={swrChannels.data}
                                stickyCallback={stickyCallback}
                            />
                            { children }
                        </div> 
                        {/* <iframe ref={iframeRef} id="myIframe" src="https://www.self.com/story/best-youtube-yoga-channels"></iframe> */}
                    </div>
                    </> 
                )
            }
       }

    return <Gallery/>
    // }
}
