import * as Scroll from 'react-scroll';
import { Link as scrollLink, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import numeral  from 'numeral';

export const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

export const getUriId = ( input ) => { return encodeURI(input.replace(/ /g, "_").trim()) }

export const extractYouTubeVideoId = ({ url }) => {
  const numCharacters = 11
  return url.slice(-numCharacters);
  // const regex = /(?:\?|&)v=([^&#]+)/;
  // const match = url.match(regex);
  // if (match && match[1]) {
  //   console.log("extract",  match[1])
  //   return match[1];
  // } else {
  //   console.log("extract fuck", url)
  //   return "";
  // }
}

export const isValidColor = (color) => {
  var e = document.getElementById('divValidColor');
  if (!e) {
      e = document.createElement('div');
      e.id = 'divValidColor';
  }
  e.style.background = '';
  e.style.background = color;
  var tmpcolor = e.style.background;
  if (tmpcolor.length == 0) {
      return false;
  }
  return true;
}

export const getElementsFromPath = ({ pathname }) => {
      
  const  pathArray = pathname.split("/").map(element => element.replace(/\//g, "_")) ///pathArray seperated by and without "/" ///pathLength = pathname.split("/").filter(Boolean).length
    
  let [xlPath, categoryPath, plPath, vidPath, lvPath] = 
      ["xl-", "cat-", "pl-", "vid-", "lv-"]
        .map((identifier) =>
          !!pathArray.find(path => path.startsWith(identifier)) ?
          {[identifier]: pathArray.find(path => path.startsWith(identifier)).replace(/^(xl-|cat-|pl-|vid-|lv-)/, "")} : 
          {[identifier]: ""} 
          )
  plPath = !!vidPath["vid-"] ? vidPath : plPath ///note: func returns: plPath = !!vidPath["vid-"] ? vidPath : plPath (OK as plPath and vidPath never occuring simultaneously (note: vidPath is just a subtype of plPath)
  return  [xlPath, categoryPath, plPath, lvPath]
}

export const getFocusObj = ({ focusStates, mediaType }) => {
    return focusStates.find(focusObj => focusObj.mediaTypeFocus === mediaType) 
}

export const capitalize = (string) => 
    string.charAt(0).toUpperCase() + string.slice(1)

export const scrollTo = ({ name, containerId, offsetY }) => {
    console.log("programRef scroll", name, containerId)
    return scroller.scrollTo(name, {
        duration: 300,
        delay: 500, ///note: coordinate with animation of program (transform)
        smooth: true,
        containerId: containerId,
        offset: offsetY, 
        ignoreCancelEvents: true
      })
}

export const replaceFunc = ({ input, oldValue, newValue }) => { ///input array or string ///optimise: consider to handle object(s) as well
  const output = Array.isArray(input) ?
    input.map(item => item.replace(new RegExp(oldValue, 'g'), newValue)) :
    input.replace(new RegExp(oldValue, 'g'), newValue)
  return output
}

export const preventScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
}



// ///object
// export const getValueFromKey = (object, key) => { 
//     return object[key]; ///note: key not exist => returns undefined
// }

// ///string
// export const stringUndefined = (string) => { ///optimise: renmae to isundefined, as argument is not only string
//     return string === undefined
//   }

//   ///classes - by type
// export const getClassesFromType = (classes, level, classTypes) => {
//   classTypes = Array.isArray(classTypes) ? classTypes : [] ///is classType specified in component ///optimise: combine with classTypeIsExplicit
//   const classTypeIsExplicit = classTypes.length >= level ///is classType specified in component
//   const classTypeExist = keyExist(classes[level], classTypes[level - 1]) ///specified 
//   const classType = classTypeIsExplicit && classTypeExist ?
//     classTypes[level - 1] :
//     "default"
//   const result = classes[level][classType] 
//   return result
// }

// ///get position - for styling
// export const getPosition = (posArray) => { // min and max included 
//   const 
//     positions = ["top", "bottom", "left", "right"],
//     posObj = {},
//     mapPos = positions.map((position) => {
//       const posValue = stringInArray(posArray, position) ? "0" : null
//       let posPair = {
//         [position]: posValue
//       };
//       if (posValue !== null) {
//         Object.assign(posObj, posPair);
//       }
//     }),
//     posAbsoluteObj = {position: "absolute"}
//     Object.assign(posObj, posAbsoluteObj);
    
//   return posObj
// }

// ///object
// export const keyExist = (object, key) => { 
//   return object[key] !== undefined ? true : false
// }

// ///array
// export const stringInArray = (array, string) => {
//   if (array === undefined || string === undefined) {
//     return false
//   }
//   const boolean = array.some(element => element.toString().toLowerCase() === string.toString().toLowerCase())
//   return boolean
// }

export const iso8601DurationToMinutes = (duration) => {
  const matches = duration.match(/PT(\d+)M(\d+)S/);
  if (matches) {
    const 
      minutes = parseInt(matches[1]),
      seconds = parseInt(matches[2]).toFixed(2)
    return minutes + seconds / 60
  }
  else {
    return 0
  }
}
 

export const getNumeral = (number) => {
 const result = number > 1000000 ?
    numeral(number).format('0.0a') : 
    numeral(number).format('0a')
  return result 
}

export const formatDate = (date) => {
  const options = { month: 'short', year: 'numeric' }; ///day: '2-digit', 
  return date.toLocaleDateString('en-US', options);
}

export const validateEmail = (email) => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

///objects
export const valueInObject = ({ object, value }) => {
  return Object.values(object).includes(value);
}

///objects
export const isEqualValuesInObject = ({ object, value }) => {
  return Object.values(object).every(item => item === value)
}

///objects
export const objectMap = ({ obj, fn }) =>
    Object.fromEntries(
      Object.entries(obj).map(
        ([k, v], i) => [k, fn(k, v, i)]
      )
    )

///objects
export const isObjectEmpty = ({ obj }) => {
    const isEmpty = 
      typeof obj === 'object' && ///isobject
      obj !== null && ///isobject(not undefined or null)
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
  return isEmpty
}

///objects
export const isObject = ({ obj }) => {
  const isObject = 
    typeof obj === 'object' && ///is object
    obj !== null ///is object (not undefined or null)
  return isObject
}

// ///objects
// export const objectsEqual = (o1, o2) => {
//   return Object.keys(o1).length === Object.keys(o2).length 
//     && Object.keys(o1).every(p => o1[p] === o2[p]);
// }


// export const objectToFilteredArrayViaStringInKey = ({ object, string }) => {
//   return Object.entries(object).filter(([k, v]) => {
//     return k.includes(string);
// });
// }

// export const objectFilteredViaStringsInKey = ({ object, stringArray, isIncludedMode }) => {
//   const resultObj = {};
//   // get all the keys from the object
//   const getAllKeys = Object.keys(object);
//   stringArray.forEach((item) => {
//     // looping through first object 
//      getAllKeys.forEach((key) => {
//       if (isIncludedMode) {
//         // using index of to check if the object key name have a matched string
//         if (key.indexOf(item) !== -1) {
//            resultObj[key] = object[key];
//         } 
//       } else {
//         if (key.indexOf(item) === -1) {
//            resultObj[key] = object[key];
//         }
//       }
//     })
//   })
//   console.log("resultObj", resultObj)
//   return resultObj
// }







///copied from simple-easy-gallery

///se Links.jsx for ConditionalLinkWrapper

///component
export const mapText = (textArray, display, delimiter) => { ///optimised: not used. also, include dynamic classes
  
  const content = Array.isArray(textArray) ?
    textArray.map((text, i) =>
      <span 
        key={i}
        style={{display: display}}
      >
        {text} {delimiter}
      </span>
    ) 
    :  null
  return (
    <div>{content}</div>
  );
}

///classes - by type
export const getClassesFromType = (classes, level, classTypes) => {
  classTypes = Array.isArray(classTypes) ? classTypes : [] ///is classType specified in component ///optimise: combine with classTypeIsExplicit
  const classTypeIsExplicit = classTypes.length >= level ///is classType specified in component
  const classTypeExist = keyExist(classes[level], classTypes[level - 1]) ///specified 
  const classType = classTypeIsExplicit && classTypeExist ?
    classTypes[level - 1] :
    "default"
  const result = classes[level][classType] 
  return result
}

///number
export const roundHalf = (num) => {
  return Math.round( num * 2) / 2;
}

///number
export const cmToLn = (num) => {
  return Math.round( num ) / 2.54;
}

///number
export const round = (input, digits) => {
  // let multiplier = Math.pow(10, precision || 0);
  // const x = Math.round(value * multiplier) / multiplier
  let rounded = Math.pow(10, digits);
  return (Math.round(input * rounded) / rounded).toFixed(digits);
}

///number 
export const checkRemainder = (x, y, checkRemainder) => {
  const value = checkRemainder ? 0 : !0
  const result = x % y === value
  return result;
}

///
export const addStr = (str, index, stringToAdd, spacer) => {
  return str.substring(0, index) + spacer + stringToAdd + spacer + str.substring(index, str.length);
}

///string
export const stringsAreIdentical = (string1, string2, toLowerCase) => { ///optimise: if neeeded, include more than two strings as input
  const areiIdentical = toLowerCase ? 
    string1.toLowerCase() === string2.toLowerCase() :
    string1 === string2
  return areiIdentical ///bolean
}

///string
export const removeHtmlTypeStrings = (string) => {
  const cleanedString = string.replace(/<\/?[^>]+(>|$)/g, "") 
  return cleanedString
}

///string
export const isString = (input) => {
  const isString = typeof input === 'string' || input instanceof String ? true : false
  return isString
}


///string
export const stringIncludes = (string, searchString) => {
  const result = isString(string) && isString(searchString) ? string.includes(searchString) : false
  return result //string.includes(searchString)
}

///string
export const partialMatchFromStart = (string, target) => { ///note: case-insensitive
  const lookup = string.replace(/&nbsp;/g,' ').trim().toLowerCase() ///trim spaces, including non-breaking spaces
  return new RegExp("\\b" + lookup).test(target.toLowerCase()) ///returns true if lookup match start of a word in target
}

///string
export const removeLinebreaks = (string) => {
  const cleanedString = string.replace(/\n/g, " ")
  return cleanedString
}

///string
export const stringEmpty = (string) => {
  return string === ""
}

///string
export const stringUndefined = (string) => { ///optimise: renmae to isundefined, as argument is not only string
  return string === undefined
}

///string
export const isOnlyNumber = (exp) => { ///not used. Check okay
  const reg = /^[0-9]+|[\b]+$/; 
  if (exp === '' || reg.test(exp)) {
    return exp
  }
  return ""
}

///string
export const isAllowedGeneral = (e) => {
  switch (e.keyCode) {
    case 13: return false // Enter
    break;
    default:
  }
  return true
}

///string
export const isNumber = (string) => {
   return typeof string === 'number' && isFinite(string);
}

///string - input key
export const inputKeyIsNumber = (e) => {
  switch (e.metaKey) {
    case true: return true; ///including command a = select all on mac. optimise: other browsers? mitigate command + x => failure.
    break;
    default:
  }
  switch (e.keyCode) {
    case 8: return true  // Backspace
    case 9: return false  // Tab
    case 13: return false // Enter
    case 32: return false //space
    case 37: return true // Left
    case 38: return true // Up
    case 39: return true // Right
    case 40: return true // Down
    break;
    default:
    var regex = new RegExp("^[0-9]");
    var key = e.key;
    if (!regex.test(key)) {
      return false;
    } else {
      return true
    }
}

  // const reg = /[0-9Backspace\b]/; ///optimise: disallow Enter/newline
  // if (exp === '' || reg.test(exp)) {
  //   console.log(true)
  //   return true
  // }
  // return false
}

///string
export const returnInputOrOutput = (input, output) => {
  return output === undefined ? input : output
}

///string
export const stringToUrl = (string) => {
  return encodeURI(string.replace(/ /g, "_").toLowerCase());
}

///string => array
export const splitMulti = (str, tokens) => {
  var tempChar = "###" //the first token as a temporary join character
  for(var i = 1; i < tokens.length; i++){
      str = str.split(tokens[i]).join(`${tempChar}${tokens[i]}`); ///note: ${tokens[i]} added to keep label
  }
  str = str.split(tempChar);
  return str;
}

///array

export const arrayExcludeIfInArray = (array, exludeArray) => {
  return array.filter( ( el ) => !exludeArray.includes( el ) );
}

///array
export const arrayUniqueByKey = (array, key) => {
  let result = [...new Map(array.map(item =>
    [item[key], item])).values()];
  return result
}

function getUniqueObjectsByKey(objects, key) {
  const uniqueObjects = Array.from(new Set(objects.map(obj => obj[key])));
  return uniqueObjects.map(value => objects.find(obj => obj[key] === value));
}

///array
export const makeArrayViaRange = (startIndex, lastIndex) => {
  return Array.from({length: lastIndex - startIndex}, (v, k) => k+startIndex+1); 
}



///array
export const arrayContainsValueInArray = (arr1, arr2) => {
  const found = arr1.some(r=> arr2.includes(r))
  return found
}


///array
export const arrayUniqueByPath = (array, path) => {
  let result = [...new Map(array.map(item => 
    [readViaArrayWithPath(item, path), item])).values()];
  return result
}

///array
export const arrayDuplicatesByKey = (array, key) => {
  const duplicateKeys = array
    .map(v => v[key])
    .filter((v, i, vIds) => vIds.indexOf(v) !== i)
  const duplicates = array
    .filter(obj => duplicateKeys.includes(obj[key]));
  return duplicates
}

///array
export const arrayDuplicatesByPath = (array, path) => {
  const duplicateKeys = array
    .map(v => readViaArrayWithPath(v, path))
    .filter((v, i, vIds) => vIds.indexOf(v) !== i)
  const duplicates = array
    .filter(obj => duplicateKeys.includes(readViaArrayWithPath(obj, path)));
  return duplicates
}

///array
export const isArrayLengthAbove = (array, length) => {
  return Array.isArray(array) && array.length > length
}

///array
export const eachArrayNotEmpty = (arrays) => {
  const isNotEmpty = arrays.map((array => array.length > 0)).every(v => v === true);
  return isNotEmpty
}

export const insertAt = (array, index, ...elements) => {
  array.splice(index, 0, ...elements);
  return array
}

export const addToArrayByPeriod = (array, period, ...elements) => {
  let newArray = [...array]
  const extraLength = Math.ceil(array.length / period)
  const mapArray = Array(array.length + extraLength).fill(null)
  //let counter = 0
  //const x = [""].concat(array)
  mapArray.map((l, i) => {
    const isPeriod = checkRemainder(i + 1, period, true) && i !== 0 
    isPeriod && newArray.splice(i, 0, ...elements)
    //counter = counter + b ? 1 : 0
   }
  )
  return newArray
}

///array
export const xxx = (arr1, arr2) => {
  const found = arr1.some(r=> arr2.includes(r))
  return found
}

export const addToArrayByPeriodExtra = (array, period, defaultElement, indexes) => {
  let newArray = [...array]
  const extraLengthPeriodElements = Math.floor((array.length) / (period - 1))
  const extraLengthFullRow = (period - 1) - ((array.length + extraLengthPeriodElements) % period)
  const mapArray = Array(array.length + extraLengthPeriodElements + extraLengthFullRow).fill(null)
  mapArray.map((l, i) => {
    const 
      isPeriod = checkRemainder(i + 1, period, true) ///note && i !== 0 must be obsolute as i + 1 is checked
      isPeriod && newArray.splice(i, 0, defaultElement)
      if (i + 1 > array.length + extraLengthPeriodElements) {
        newArray.splice(i, 0, "placefiller")
      }
   }
  )

  indexes.map((index, i) => {
    const 
      documentMoreElement = [...newArray].splice(index, 1),
      insertIndex = (Math.ceil((index + 1) / period) * period) - 1 
      newArray.splice(insertIndex, 1, ...documentMoreElement)
   }
  )

  // let counter = 0
  // const x = [""].concat(array)
  // let indexInIndexes = null
  // mapArray.map((l, i) => {
  //   const 
  //     isPeriod = checkRemainder(i + 1, period, true), ///note && i !== 0 must be obsolute as i + 1 is checked
  //     indexRange = isPeriod && makeArrayViaRange(i - period, period)
  //     //console.log(isPeriod, indexRange)
  //     if (indexes.indexOf(i) !== -1) { ///if document with index i in array is selected (i in indexes)
  //       indexInIndexes = indexes.indexOf(i) ///get index of document in array
  //     }
  //     if (isPeriod && indexRange.indexOf(indexes[indexInIndexes]) === -1) { ///reset (at period if -1)
  //       indexInIndexes = null
  //     }
  //     // const indexInIndexesAtPeriod = isPeriod && indexInIndexes
  //     // console.log("indexInIndexesAtPeriod", indexInIndexesAtPeriod)
  //     const documentMoreElement = [...array].splice(indexes[indexInIndexes], 1), // isPeriod && !stringUndefined(indexes[indexInIndexesAtPeriod]) && [...array].splice(indexes[indexInIndexesAtPeriod], 1),
  //     element = !stringUndefined(indexes[indexInIndexes]) ?
  //       [`documentMoreElement + ${documentMoreElement[0].document}`] : 
  //       [defaultElement]
  //     console.log(i, documentMoreElement)
  //   isPeriod && newArray.splice(i, 0, ...element)
  //   //counter = counter + b ? 1 : 0
  //  }
  // )
  return newArray
}

///array
export const valuesIdenticalInArray = (array, value) => {
  return array.every(v => v === value);
}

///array
export const filteredSortedArrayOfArrays = async(array, includedElements) => {
    let response = //(await Promise.all(array.map(async element => await element)))
        array
        .filter(([a,]) => includedElements.includes(a))
        .sort(([a,],[b,]) => includedElements.indexOf(a) - includedElements.indexOf(b));
    return await response
}

///array
export const isEqualArrays = (a, b) => { ///optimise: used specially with document, not generics, update ...
  a = a.map(item => item.document)
  b = b.map(item => item.document)
  return JSON.stringify(a) === JSON.stringify(b)
}

export const arraysIsEqual = (a, b) => { ///optimise: use this or isequalarrays??
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}

///array
export const stringInArray = (array, string) => {
  if (array === undefined || string === undefined) {
    return false
  }
  const boolean = array.some(element => element.toString().toLowerCase() === string.toString().toLowerCase())
  return boolean
}

///array
export const trimArrayOfArrays = (array) => {
  const result = array.map(element => {
    const trimmedArray = element.map(subelement => {
      if (typeof subelement === 'string') {
        return subelement.trim();
      }
    })
    return trimmedArray;
  });
  return result
}

///array - check if functionality OK, see also stringinarray
export const findStringInArray = (array, string) => { ///not currently used
  return array.find(str => str === string);
}

///array
export const lastIndex = (array, index) => { ///not currently used
  return array.length - 1 === index
}

///array
export const arrayEmpty = (array) => {
  return Array.isArray(array) && array.length === 0
}

///array
export const indexInArray = (array, string) => { ///not currently used
  const index = array.findIndex(element => {
    return element.toString().toLowerCase() === string.toString().toLowerCase();
  });
  return index
}

///array
export const zip = (a1, a2) => {
  return a1.map((x, i) => [x + a2[i]]); ///seperator is "space"
}

///array
export const replaceAtIndexReturnArray = (array, index, string) => {
  const arr = [...array]
  arr.splice(index, 1, string) ///optimise: include errorhandling (no index)
  return arr ///note: return array.splice() returns an array containing the removed items (if any).
}

///array to string
export const arrayToString = (array, seperator) => {
  const newArray = array.map((text, i) => {
    const mapSeperator = i < array.length - 1 ? true : false
    return (
    `${text}${mapSeperator ? seperator : ""}`
    )
  })
  const string = newArray.join(" ")
  return string
}

///array: strings => array => array of arrays
export const splitStringsInArray = (array, seperator) => { 
  const newArray = []
  array.map((element) => {
    const subarray = element.split(seperator)
    newArray.push(subarray)
  })
  return newArray
}

///array => array with objects
export const mapArrayIntoArrayWithObjectsAndSetValueViaKey = ( array, obj, key, mediaType ) => { ///optimise: consider to explicit pass [id], not hard-coded
  let arrayWithObjects = []
  array.map((element, index) => {
    const nameId = mediaType === "image" ? element.name : element ///note: usage of nameid based on mediatype makes the function non-generic
    const newObj = { ...obj, [key]: element, ["nameId"]: nameId, id: index }
    arrayWithObjects.push(newObj)
  })
  return arrayWithObjects
}

///array => array with objects
export const mapArrayToObjectWithKeysAndSetValue = ( array, value ) => { 
  let arrayWithObjects = []
  array.map((key, index) => {
    const newObj = { [key]: value }
    arrayWithObjects.push(newObj)
  })
  arrayWithObjects = Object.assign(...arrayWithObjects)
  return arrayWithObjects
}

///array => array with objects
export const groupArrayElementsByFrequency = (array, groupSize) => { 
  let 
    grouped = [],
    group = [],
    counter = 0
  array.map((element, index) => {
    group.push(element)
    const pushGroup = 
      checkRemainder(index + 1, groupSize, true) ||
      index + 1 === array.length ///last group of elements (where remainder is not zero)
    if ( pushGroup ) {
      const groupObj = { [counter]: group }
      grouped.push(groupObj)
      group = []
      counter++
    }
  })
  return grouped ///output: array holding a object pr. group
}

///array of arrays
export const findStringInArrayOfArrays = (array, str) => { ///not currently used
  return array.find(t => { return t.find(i => i === str)});
}

///array of arrays => object { x , y , ... }
export const objectFromArrayOfArrays = (array) => {
  const object = Object.assign(...array.map(([k, v]) => ({ [k]: v })));
  return object
}

///object to array
export const outerObjectToArray = (object) => {
  const array = Object.values(object)
  return array
}


///array of objects
export const valueExistInArrayOfObjectsViaKey = (array, key, value) => {
  const exist = array.findIndex(object => object[key] === value) //array.find(x => x.name === value)
  return exist !== -1
}

///array of objects
export const valueExistInArrayOfObjectsViaPath = (array, path, value) => {
  const exist = array.findIndex(obj => readViaArrayWithPath(obj, path) === value) //array.find(x => x.name === value)
  return exist !== -1
}

///array of objects
export const indexOfObjectInArray = (array, key, value) => {
  return array.findIndex(i => i[key] === value);
}

///array of objects
export const valueByKeyInArrayOfObjects = (array, key) => {
    let value = []
    array.find((object) => {
    ({ [key]: value } = object);
      return value
    })
    return value;
}

///array of objects - get array of all property in object array
export const arrayOfObjectGetValuesByKey = (array, key) => { 
  return array.map((a) => a[key]).filter(e => e); ///note/risk: filter e=>e removes [undefined, null, 0, false] is this desired in all usecases?
}

///array of objects
export const valueByIndexAndKeyInArrayOfObjects = (array, index, key) => { ///optimise: include errorhandling
  const value = array[index][key]
  return value;
}

///array of objects - merge
export const mergeArrayWithObjectsIntoObject = (array) => { 
  return Object.assign(...array);
}



///array of objects
export const arrayOfObjectsForKeyIsEqual = (arr1, arr2, key) => {
  if (arr1 === undefined || arr2 === undefined ) {
    return false
  }
  const diffArray = arr2.filter((obj1) => !arr1.some((obj2) => obj1[key] === obj2[key] ))
  if (diffArray.length === 0) {
    return true
  }
  return false
}

///objects
export const equalValuesInObject = ({ obj, testValue }) => {
  return Object.values(obj).every(value => value === testValue);
}

///objects
export const objectsEqual = (o1, o2) => {
  return Object.keys(o1).length === Object.keys(o2).length 
    && Object.keys(o1).every(p => o1[p] === o2[p]);
}

///objects
export const objectsEqualDeep = (o1, o2) => { ///note: not currently used
  return typeof o1 === 'object' && Object.keys(o1).length > 0 
      ? Object.keys(o1).length === Object.keys(o2).length 
          && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
      : o1 === o2;
}

///array of objects
export const arrayOfObjectsIsEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) { return } ///check if is array(s), if not return
  if (arr1.length !== arr2.length) { return false } ///note: check if lenght is (not) equal, if not equal return false
  let checkArray = []
  arr1.map((obj1, index) => {
    const obj2 = arr2[index]
    const objIsEqual = objectsEqual(obj1, obj2)
    checkArray.push(objIsEqual)
  })  
  const arraysIsEqual = valuesIdenticalInArray(checkArray, true)

  return arraysIsEqual
}

///array of objects
export const arrayOfObjectsSortIsEqual = (arr1, arr2, key) => { ///optimise: consider to combine with arrayOfObjectsForKeyIsEqual
  if (arr1 === undefined || arr2 === undefined ) {
    return false
  }
  const diffArray = arr2.filter((obj1, index) => !(arr1[index][key] === obj1[key]) )
  if (diffArray.length === 0) {
    return true
  }
  return false
}

export const compareArraysAndExcludeDuplicates = (array1, array2) => {
  // Create a set to store the unique objects from array1
  const uniqueObjects = new Set(array1);
  // Filter array2 to exclude objects that are present in array1
  const filteredArray = array2.filter(obj => !uniqueObjects.has(obj));
  return filteredArray;
}

///object
export const objectIsEmpty = (object) => {
  return Object.keys(object).length === 0;
}

///object
export const getValueFromKey = (object, key) => { 
    return object[key]; ///note: key not exist => returns undefined
}

///object
export const getKeyFromValue = (object, value) => { 
  return Object.keys(object).find(key => object[key] === value);
}

///object
export const getIndexViaKeyInObject = (object, key) => { 
  return Object.keys(object).indexOf(key) ;
}

///object
export const entityExist = (data, entity, value) => { //array of objects (no nesting)
  const key = entity
  return data.some(
    object => object[key].toLowerCase() === value.toLowerCase()); //returns boolean
}

///object
export const valueExistInObject = (object, value) => { 
  const exists = Object.keys(object).some((k) => {
    return object[k] === value;
  });
}

///object
export const keyExist = (object, key) => { 
  return object[key] !== undefined ? true : false
}

///object - FIX!!!
export const allKeysGotValues = (object) => { //object (no nesting)
  const x = Object.values(object).every((v) => console.log("v", v))
  return x
}

export const removeKeysFromObjViaVal = (object, func) => { 
  return Object.fromEntries(Object.entries(object).filter(([_, v]) => func(v)));
}

///object
export const verify = (objectTemplet, objectData, objectOptional) => { ///note: can handle string (including number), array of lenght 1, array of lenght > 0 (no further nesting)
  let verifyObject = {...objectTemplet}
  const x = Object.keys(objectTemplet).map((key, index) => { ///optimise/consider - ensure itentical sorting of objects, to mitigate not in sync. needed?
    const valueTemplet = objectTemplet[key].value
    const valueData = objectData[key]
    const valueOptional = objectOptional[key].optional
    let verified
     switch (true) {
      // case stringInArray(optional, key): ///case templet = [] => multioptions ///consider: to include lenght 1, i.e. [""]. done
      // verified = true
      // break
      case valueOptional: ///case value is optional => automatic verification
        verified = true
      break
      case Array.isArray(valueTemplet) && valueTemplet.length <= 1: ///case templet = [] => multioptions ///consider: to include lenght 1, i.e. [""]. done
        verified = valueData.length !== 0
        break
      case Array.isArray(valueTemplet) && valueTemplet.length > 1: ///case templet = ["", "" ...] => fx dimensions
        const booleanCheck = valueData.map(value => value !== "")
        verified = valuesIdenticalInArray(booleanCheck, true) 
        break
      case typeof valueData === 'string': ///includes number by default
        verified = valueData !== ""
      break
      case typeof valueData === 'boolean':
        verified = true
      break
      default:
        verified = valueOptional
      break
    }
    verifyObject[key] = verified
  })
  return verifyObject
}

// ///object
// export const isObject = (entity) => { 
//   return typeof entity == 'object';
// }

///object
export const containsAnyObject = (array) => { 
  return array.some(a => typeof a == 'object');
}

export const readViaArrayWithPath = ( obj, path ) => { ///note: path is an array ["a", "b", "c"] ///from https://gist.github.com/fawwaz/b037a105e41fa8ed7292b324abb07f42
  let o = obj;
  let p
  for(p of path) {
    o = o[p];
  }
  return o;
}

///object
export function convertToDotNotation(obj, parentKey = '', result = {}, excludeKeys = []) {
  for (const key in obj) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          if (!excludeKeys.includes(key)) {
              convertToDotNotation(obj[key], parentKey + key + '.', result, excludeKeys);
          } else {
              result[parentKey + key] = obj[key];
          }
      } else {
          result[parentKey + key] = obj[key];
      }
  }
  return result;
}

///object
export const flattenedArray = ( arrayOfObjects ) => arrayOfObjects.flatMap((obj) => Object.values(obj)); 

///object
export function combineObjects({ obj1, obj2 }) {
  const combinedObject = {};

  // Combine keys from both objects
  const allKeys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])];

  allKeys.forEach(key => {
      combinedObject[key] = [...(obj1[key] || []), ...(obj2[key] || [])];
  });

  return combinedObject;
}

///object
export const writeViaArrayWithPath = (obj, keys, v) => { ///note: obj can be new object {} ///from https://gist.github.com/fawwaz/b037a105e41fa8ed7292b324abb07f42
  if (keys.length === 0) {
    return v;
  }
  if (keys.length === 1) {
    obj[keys[0]] = v;
  } else {
    const [key, ...remainingKeys] = keys;
    const nextKey = remainingKeys[0];
    const nextRemainingKeys = remainingKeys.slice(1);

    if (typeof nextKey === "number") {
      // create array
      if (!obj[key]) {
        obj[key] = [];
      }

      // Fill empty index with empty object
      if (obj[key].length < nextKey + 1) {
        const delta = nextKey + 1 - obj[key].length;
        for (let i = 0; i < delta; i++) {
          obj[key].push({});
        }
      }

      // recursively write the object
      obj[key][nextKey] = writeViaArrayWithPath(obj[key][nextKey], nextRemainingKeys, v);
    } else {
      // recursively write the object
      obj[key] = writeViaArrayWithPath(
        typeof obj[key] === "undefined" ? {} : obj[key],
        remainingKeys,
        v
      );
    }
  }

  return obj;
};

export const getValueViaPath = ({ obj, path }) => { /// fx obj: {media[ {url: xx},{}, ... ]}
  return path.split(/\[|\]\.?/g).reduce((obj, key) => obj[key], obj);
}

export const getDeepValueViaPath = ({ obj, path }) => {
  const keys = path.split('.');
  let value = obj;

  for (let key of keys) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return undefined;
    }
  }

  return value;
}
// export const getDeepValueViaPath = ({ obj, path }) => {

//   const 
//     firstDelimiter = ".",
//     secondDelimiter = /\[[^\]]+\]/g,
//     keys = path.split(firstDelimiter).map(item => item.split(secondDelimiter));
//   let value = obj;

//   for (let key of keys) {
//     // if (value && typeof value === 'object' || && key in value) {
//       value = value[key];
//     // } else {
//     //   return undefined;
//     // }
//   }
//   return value;
// }

export const composableWriteViaArrayWithPath = (path, value) => (object) => writeViaArrayWithPath(object, path, value);
  // const output = compose(
  //   composableWrite(['anotherKey',0],'value 1'),
  //   composableWrite(['anotherKey',1],'value 2'),
  // )(objectToModify)

///object map: transform values in nested object
export const objMap = (obj, func, nested) => {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v], i) => 
      [k, (v === Object(v) && nested) ? objMap(v, func) : func(v, k, i)] ///was: [k, v === Object(v) ? objMap(v, func) : func(v, k)]
    )
  );
}

///templet-setup obj with array info each mapped to object via templet-param ///note: implemented to avoid the need for repeating param-keys in templets note: the includeparam functionality is currently not used
export const objMapTemplates = (obj, templetParam, includeParam) => { ///note: includeparam is type array
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => { ///note: for each templet v
      const value = Object.fromEntries( ///note: object from array entry-pair of key and value
        Object.entries(v).map(([k_, v_]) => {
          const value_ = Object.assign(... ///note: flatten object (objects => object)
            v_
              .map((vx, i) => ({ [templetParam[i]]: v_[i] }) ) 
              .filter((vx, i) => { ///filter param
                if (stringUndefined(includeParam)) { ///if includeparam not specified => no filter
                  return true 
                } 
                else { 
                  if (stringInArray(includeParam, templetParam[i])) { ///include only param in includeparam
                    return true
                  }
                }
              })
            )
          const entry_ = [k_, value_]
          return entry_
        })
      );        
      const entry = [k, value]
      return entry
    })
  );
}

export const objWithObjectsMapKeysToArrayIfValue = (obj, value) => {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => { ///note: for each templet v
      const val = 
        Object.entries(v)
          .filter(([k_, v_]) => v_ === value)
          .map(([k_, v_]) => {
            return k_
          }) 
      const entry = [k, val]
      return entry
    })
  );
}

export const objMapTemplatesX = (obj, templetParam, param) => { ///note: param is type string
  const paramPosition = indexInArray(templetParam, param)
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => { ///note: for each templet v
      const value = Object.fromEntries( ///note: object from array entry-pair of key and value
        Object.entries(v).map(([k_, v_]) => {
          const val = [k_, v_[paramPosition]]
          return val
        })
      );        
      const entry = [k, value]
      return entry
    })
  );
}

export const toFlatPropertyMap = (obj, keySeparator = '.') => { ///note: not currently used, but handy
  const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
    for(const [key, value] of Object.entries(obj)){
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key;
      if(value && typeof value === 'object'){
        flattenRecursive(value, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
}


///object - values to a string via specifid keys
export const valuesToStringViaKeys = (object, keys, seperator) => {
  const result = 
    keys
      .map(key => {
        const entity = Array.isArray(object[key]) ?
          arrayOfObjectGetValuesByKey(object[key], key) ///filters are objects in an array
          :
          object[key] ///filter is a string
          return entity
      })
      .filter(v => v)
      .join(seperator)
  return result

  //return keys.map(key => object[key]).filter(v => v).join(seperator);
}

///object
export const getPath = ({ obj, property }) => {
 
  return Object.keys(obj).find((param) => {
    return obj[param].hasOwnProperty(property);
  });
}

//other
export const getSortIdInput = async(array, index) => {
  ///return [prevIndex, nextIndex, addedSeconds]
  switch (true) {
    case index === 0: //firstIndex
      return [null, 1, 1]
    case index === array.length - 1: //lastIndex
      return [array.length - 2, null, -1]
    default:
      return [index - 1, index + 1, 0] ////lastIndex
  }
}

///random interval
export const randomIntFromInterval = (min, max) => { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

///execute function
export const executeFunctionByName = (functionName, context, arguement) => {
  const args = Array.prototype.slice.call(arguement, 2);
  const namespaces = functionName.split(".");
  const func = namespaces.pop();
  for(let i = 0; i < namespaces.length; i++) {
    context = context[namespaces[i]];
  }
  return context[func].apply(context, args);
}

///get position - for styling
export const getPosition = (posArray) => { // min and max included 
  const 
    positions = ["top", "bottom", "left", "right"],
    posObj = {},
    mapPos = positions.map((position) => {
      const posValue = stringInArray(posArray, position) ? "0" : null
      let posPair = {
        [position]: posValue
      };
      if (posValue !== null) {
        Object.assign(posObj, posPair);
      }
    }),
    posAbsoluteObj = {position: "absolute"}
    Object.assign(posObj, posAbsoluteObj);
    
  return posObj
}

///sort
export const sortDate = (a, b, parameter) => { ///question/risk: use todate instead??
  if(a[parameter] > b[parameter]) return 1;
  if(a[parameter] < b[parameter]) return -1;
  return 0;
}

export const sortLowToHigh = (a, b, parameter) => {
const 
  an = Number(a[parameter]),
  bn = Number(b[parameter])
if(an > bn) return 1;
if(an < bn) return -1;
return 0;
}

export const sortHighToLow = (a, b, parameter) => {
const 
  an = Number(a[parameter]),
  bn = Number(b[parameter])
if(an < bn) return 1;
if(an > bn) return -1;
return 0;
}

export const sortAbsending = (a, b, parameter) => {
  let fa = a[parameter].toLowerCase(), 
      fb = b[parameter].toLowerCase();

  if (fa < fb) { ///note: sort descending order via reverse of operator
      return -1;
  }
  if (fa > fb) { ///note: sort descending order via reverse of operator
      return 1;
  }
  return 0;
}

export const shuffleArray = (array) => {
  const newArray = [...array]
  for(let i = newArray.length - 1; i >= 1; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // 0 <= j <= i
    let temp = newArray[j];
    newArray[j] = newArray[i];
    newArray[i] = temp;
  }
  return newArray
}

export const isFile = input => 'File' in window && input instanceof File;

export const isBlob = input => 'Blob' in window && input instanceof Blob;

export const validUrl = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const isImgUrl = (url) => {
  if (typeof url !== 'string') {
    return false;
  }
  return (url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gmi) !== null);
}

///blur
export const Blur = (currentRef) => {
  currentRef.blur();
}

///focus
export const Focus = ({currentRef}) => {
  currentRef.focus();
}

///paste
export const getPasteText = (e) => {
  const text = e.clipboardData.getData('text')
  return text
}

///templet

export const findPathsToKey = (options) => {
  let results = [];

  (function findKey({
    key,
    obj,
    pathToKey,
  }) {
    const oldPath = `${pathToKey ? pathToKey + "." : ""}`;
    if (obj.hasOwnProperty(key)) {
      results.push(`${oldPath}${key}`);
      return;
    }

    if (obj !== null && typeof obj === "object" && !Array.isArray(obj)) {
      for (const k in obj) {
        if (obj.hasOwnProperty(k)) {
          if (Array.isArray(obj[k])) {
            for (let j = 0; j < obj[k].length; j++) {
              findKey({
                obj: obj[k][j],
                key,
                pathToKey: `${oldPath}${k}[${j}]`,
              });
            }
          }

          if (obj[k] !== null && typeof obj[k] === "object") {
            findKey({
              obj: obj[k],
              key,
              pathToKey: `${oldPath}${k}`,
            });
          }
        }
      }
    }
  })(options);

  return results;
}

function findPath (obj, name, val, currentPath) {
  currentPath = currentPath || ''

  let matchingPath

  if (!obj || typeof obj !== 'object') return

  if (obj[name] === val) return `${currentPath}['${name}']`

  for (const key of Object.keys(obj)) {
    if (key === name && obj[key] === val) {
      matchingPath = currentPath
    } else {
      matchingPath = findPath(obj[key], name, val, `${currentPath}['${key}']`)
    }

    if (matchingPath) break
  }

  return matchingPath
}

const treeData = [{
  id: 1,
  children: [{
    id: 2
  }]
}, {
  id: 3,
  children: [{
    id: 4,
    children: [{
      id: 5
    }]
  }]
}] ///console.log(findPath (treeData, 'id', 5))

export const getObjectProperty = (object, path) => { ///console.log(getObjectProperty(data, 'user.username'));
  if (object == null) { // null or undefined
    return object;
  }
  const parts = path.split('.');
for (let i = 0; i < parts.length; ++i) {
      if (object == null) { // null or undefined
        return undefined;
      }
      const key = parts[i];
    object = object[key];
  }
  return object;
};

///check this: https://gist.github.com/fawwaz/b037a105e41fa8ed7292b324abb07f42