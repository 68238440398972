import { apiKeys } from './requestVideos';
import { getSubtitles } from 'youtube-captions-scraper'; ///run in firefox with https://addons.mozilla.org/en-US/firefox/addon/cors-everywhere/
//import { google } from 'googleapis'; 



export const requestCaption = async({ videoId }) => {

    // ES6 / TypeScript

    getSubtitles({
        videoID: videoId, // youtube video id
        lang: 'en' // default: `en`
        }).then(captions => {
        console.log("captions", captions);
        })
       

    // let apiReady = true, isFetched, counter

    //     const query = `https://www.googleapis.com/youtube/v3/captions?part=id,snippet&videoId=${videoId}&key=${apiKeys[0]}`

    //     const request = await fetch(query)

    //     const response = await request.json();

    //     if (response.status === 403) {
    //         apiReady = false
    //         console.log("api index", counter, "ready:", apiReady)
    //     } else {
    //         apiReady = true
    //         isFetched = true
    //         console.log("isFetched:", isFetched, ", via api index:", counter)
    //     }

    //     console.log(response)
    //     const captions = response.items;

    //      // Step 3: Choose the desired caption track
    //      const selectedCaption = captions.find(caption => caption.snippet.language === 'en'); 
    //      const captionTrackId = selectedCaption.id;
     

    //     // //auth 
    //     // // Load the YouTube Data API client library
    //     // google.youtube('v3');

    //     // // Access the gapi object through the google namespace
    //     // const gapi = google.gapi;

    //     // // Initialize the client and make the API request
    //     // function init() {
    //     //     gapi.client.init({
    //     //     apiKey: apiKeys[0],
    //     //     discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'],
    //     //     }).then(() => {
    //     //     // Call the API to retrieve captions
    //     //     return gapi.client.youtube.captions.list({
    //     //         part: 'snippet',
    //     //         videoId: videoId,
    //     //     });
    //     //     }).then((response) => {
    //     //     // Process the response
    //     //     const captions = response.result.items;
    //     //     // Do something with the captions
    //     //     console.log(captions);
    //     //     }).catch((error) => {
    //     //     console.error('Error:', error);
    //     //     });
    //     // }
    //     // init()

    //     // const auth = new google.auth.GoogleAuth({
    //     //     // Specify the required scopes
    //     //     scopes: ['https://www.googleapis.com/auth/youtube.force-ssl'],
    //     // });
          
    //     // const authClient = await auth.getClient();
        
    //     // Fetch the access token
    //     const accessToken = 1 //await authClient.getAccessToken();

    //      // Step 4: Retrieve the transcript
    //      const transcriptResponse = await fetch(`https://www.googleapis.com/youtube/v3/captions/${captionTrackId}?key=${accessToken}`);
    //      const transcriptData = await transcriptResponse.json();
    //      const transcript = transcriptData;

    // return transcript
}