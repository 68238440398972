import { colorsLight } from './colors';

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const typesCategories =  shuffle(["ashtanga", "vinyasa", "hatha", "yin", "iyengar", "kundalini", "hot", "aerial", "prenatal", "power", "acro"])
export const youTubeChannelCategories = [
  "yoga with adriene", 
  // "psychetruth", 
  "boho beautiful yoga", 
  // "yoga with kassandra", 
  // "sarahbethyoga", 
  // "abiola akanni", "move with nicole", "shilpa's yoga", "kinoyoga", "brettlarkinyoga", "breatheandflow", "ventuno yoga", "yoga with bird", "jessica richburg", "tara stiles - yoga that feels like you", "candace cabrera tavino", "yoga with tim", "yoginimelbourne", "ekhartyoga", "youaligned", "travis eliot", "the bare female", "live fertile", "yoga with roberta", "yoga with zelinda", "ann swanson wellness", "faith hunter", "sarah wes",
  // "arianna elizabeth",
  // "seanviguefitness",
  // "yoga practice videos - yoga vidya",
  // "body positive yoga",
  // "geethanjali - yoga",
  "suzie ray",
  // "marlingyoga",
  // "yoga with amit",
  // "purple valley ashtanga yoga"

]

export const categories = [ ...youTubeChannelCategories, ...typesCategories]
export const categoriesExtraData = { 
  catchphrases: {
    ashtanga: "tradional repetition",
    vinyasa: "breathing flow into athletic style yoga",
    hatha: "balance opposing forces",
    yin: "finding comfort in discomfort ",
    iyengar: "methodical alignment", //props
    kundalini: "fire up your spine, chant, sing and awaken", 
    hot: "heated sequences",
    prenatal: "for moms to be",
    power: "flow with power", 
    restorative: "take it easy", //props
    aerial: "float in the air", 
    acro: "it takes two",
    "yoga with adriene": "down to earth, quirky, with a dog",
    // "psychetruth": "bib",
    // "boho beautiful yoga": "boho beautiful yoga",
    // "yoga with kassandra": "yoga with kassandra",
    // "sarahbethyoga": "sarah beth yoga",
    // "crystal nardico": ["crystal nardico"],
    // "abiola akanni": ["abiola akanni"],
    // "move with nicole": ["abiola akanni"],
    // "shilpa's yoga": ["shilpa's yoga"],
    // "kinoyoga": [ "kino yoga"],
    // "brettlarkinyoga": ["brettlarkinyoga"],
    // "breathe and flow": ["breathe and flow"],
    // "ventuno yoga": ["ventuno yoga"],
    // "yoga with bird": [ "yoga with bird"],
    // "jessica richburg": ["jessica richburg"],
    // "tara styles": ["tara styles"],
    // "yoga with candace": ["yoga with candace"],
    // "yoga with tim": ["yoga with tim"],
    // "yoginimelbourne": ["yoginimelbourne"],
    // "ekhartyoga": ["ekhartyoga"],
    // "youaligned": ["youaligned"],
    // "travis eliot": ["travis eliot"],
    // "the bare female": ["the bare female"]
  },
  extraCategory: {
    vinyasa: ["flow"],
    hot: ["bikram"],
    //"yoga with adriene": ["vinyasa", "hatha", "kundalini"], ///ok
    // "psychetruth": ["various"],
    // "boho beautiful yoga": ["yin", "hatha", "ashtanga"], ///ok
    // "yoga with kassandra": ["yin"],
    // "sarahbethyoga": ["yin"],
    // "crystal nardico": ["acro"],
    // "shilpa's yoga": ["x"],
    // "kinoyoga": ["x"],
    // "brettlarkinyoga": ["vinyasa"], ///ok
    // "breathe and flow": ["vinyasa", "hatha", "yin"], ///ok //+ restorative yoga, prenatal yoga, animal flow 
    // "yoga with bird": ["hatha", "yin", "vinyasa", "power"],
    // "jessica richburg": ["jessica richburg"],
    // "tara styles": ["tai chi"],
    // "yoga by candace": ["x"],
    // "yoga with tim": ["x"],
    // "yoginimelbourne": ["x"],
    // "ekhartyoga": ["x"],
    // "youaligned": ["x"],
    // "travis eliot": ["x"],
    // "the bare female": ["x"],
    // "live fertile": ["x"],
    // "yoga with roberta": ["x"],
    // "yoga with zelinda": ["x"],
    // "ann swanson wellness": ["x"],
    // "faith hunter": ["x"],
    // "sarah wes": ["x"],
    // "arianna elizabeth": ["x"],
    // "sean vigue fitness": ["x"],
    // "yoga practice videos - yoga vidya": ["x"],
    // "body positive yoga": ["x"],
    // "suzie ray": ["x"],
    // "marlingyoga": ["x"],
    // "yoga with amit": ["x"],
    // "purple valley ashtanga yoga": ["x"]

  },
  alias: { 
  "sarahbethyoga": "sarah beth yoga",
  "kinoyoga": "kino yoga",
  "brettlarkinyoga": "brett larkin yoga",
  "yoginimelbourne": "yogini melbourne",
  "ekhartyoga": "ekhart yoga",
  "youaligned": "you aligned",
  "breatheandflow": "breathe and flow",
  "marlingyoga": "marling yoga",
  "candace cabrera tavino": "yoga by candace",
  "seanviguefitness": "seanvigue fitness",
  "yoga practice videos - yoga vidya": "yoga vidya",
  "geethanjali - yoga": "geethanjali yoga",
  "tara stiles - yoga that feels like you": "tara stiles"
}
}

///array
export const stringInArray = (array, string) => {
  if (array === undefined || string === undefined) {
    return false
  }
  const boolean = array.some(element => element.toString().toLowerCase() === string.toString().toLowerCase())
  return boolean
}

export const categoryGalleryData = 
  categories.map((category, index) =>  {
    
    let 
      imgExist = true,
      imgFile

    try {
      imgFile = require(`../src/imgs/categories/${category}.png`);
      imgExist = true
    } catch (ex) {
        imgExist = false
        console.log(`no img for category" ${category}`)
    }

    const obj = { 
      id: category, ///note/optimse: superfleous as category ..
      img: imgExist ? imgFile : "",
      category: category,
      extraCategory: categoriesExtraData.extraCategory.hasOwnProperty(category) ?
        categoriesExtraData.extraCategory[category] : null,
      focus: false,
      catchphrase: categoriesExtraData.catchphrases.hasOwnProperty(category) ?
        categoriesExtraData.catchphrases[category] : null,
      //background: "rgba(0,116,217,0.2)",
      //backgroundPreviewImg: colorsLight[`${index > colorsLight.length ? index % colorsLight.length : index}`] ///was: colorsLight[Math.floor(Math.random() * (colorsLight.length - 0 + 1)) + 0] ///note: or index

      }
    return obj
  })

// export const categoryGalleryDatax = 
// [
//     { 
//       // img: pic1,
//       img: vinyasa,
//       id: 1,
//       focus: false,
//       category: "Vinyasa",
//       background: "rgba(0,116,217,0.2)",
//       backgroundPreviewImg: "linear-gradient(to right, rgb(238, 156, 167), rgb(255, 221, 225))" //"linear-gradient(135deg, rgba(0,116,217,1) 0%, rgba(127,219,255,1) 50%)",
//     },
//     { 
//       // img: pic2,
//       img: hatha,
//       id: 2,
//       focus: false,
//       category: "Hatha",
//       background:  "rgba(61,153,112,0.2)",
//       backgroundPreviewImg: "linear-gradient(to right, rgb(168, 255, 120), rgb(120, 255, 214))" //"linear-gradient(135deg, rgba(61,153,112,1) 0%, rgba(46,204,64,1) 50%)",
//     },
//     { 
//       // img: pic4,
//       img: ashtanga,
//       id: 3,
//       focus: false,
//       category: "Ashtanga",
//       background: "rgba(255,133,27,0.2)",
//       backgroundPreviewImg: "linear-gradient(to right, rgb(255, 239, 186), rgb(255, 255, 255))" //linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%)", //"linear-gradient(135deg, rgba(255,133,27,1) 0%, rgba(255,220,0,1) 50%)",
//     },
//     { 
//       // img: pic4,
//       img: yin,
//       id: 4,
//       focus: false,
//       category: "Yin",
//       background: "rgba(255,133,27,0.2)",
//       backgroundPreviewImg: "linear-gradient(to right, rgb(211, 204, 227), rgb(233, 228, 240))" //linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%)", //"linear-gradient(135deg, rgba(255,133,27,1) 0%, rgba(255,220,0,1) 50%)",
//     },
//     { 
//       // img: pic5,
//       img: "data:img/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=",
//       id: 5,
//       focus: false,
//       category: "Iyengar",
//       background: "rgba(177,13,201,0.2)",
//       backgroundPreviewImg: "linear-gradient(135deg, rgba(177,13,201,1) 0%, rgba(240,18,190,1) 50%)",
//     },
//     { 
//       // img: pic7,
//       img: "data:img/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=",
//       id: 6,
//       focus: false,
//       category: "Kundalini",
//       background: "rgba(0,116,217,0.2)",
//       backgroundPreviewImg: "linear-gradient(45deg,  transparent 0%, transparent 57%, #423246 100%),linear-gradient(-45deg, #8f5046 0%,  #A14436 0.3%, #4C2556 112%,  transparent 11% ),linear-gradient(-90deg, #A14436 100%, #A14436 65%, transparent 20% )",
//     },
//     { 
//       // img: pic7,
//       img: "data:img/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=",
//       id: 7,
//       focus: false,
//       category: "Hot",
//       background: "rgba(0,116,217,0.2)",
//       backgroundPreviewImg: "linear-gradient(45deg,  transparent 0%, transparent 57%, #423246 100%),linear-gradient(-45deg, #8f5046 0%,  #A14436 0.3%, #4C2556 112%,  transparent 11% ),linear-gradient(-90deg, #A14436 100%, #A14436 65%, transparent 20% )",
//     },
//     { 
//       // img: pic10,'
//       img: "data:img/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=",
//       id: 8,
//       focus: false,
//       category: "Historie",
//       background: "rgba(0,116,217,0.2)",
//       backgroundPreviewImg: "linear-gradient(70deg, #fff810  30%, rgba(0,0,0,0) 30%), linear-gradient(30deg, #63e89e 60%, #ff7ee3 60%)",
//     },
//   ]
