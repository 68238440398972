//import './icons.css';
// import { useState, useEffect, forwardRef, useMemo, memo, useCallback, useReducer, useRef, createRef, useContext} from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { icon, solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
// import { Wrapper, Button } from './ComponentStandards';
// import { themeContext } from './App';

export const burstIcon = <></>
export const boltIcon = <></>
export const proSign = <></>
export const pesoIcon = <></>
export const clockIcon = <></>
export const personWalkingIcon = <></>
export const lightbulb = <></>
export const handPointerIcon = <i class="fa-solid fa-hand-pointer"></i>
export const trashIcon = <i class="fa-solid fa-trash"></i>
export const xMarkIcon = <i class="fa-solid fa-xmark"></i>

// export const searchIcon = <FontAwesomeIcon icon={solid('search')} />
// export const filterIcon = <FontAwesomeIcon icon={solid('filter')} />
// export const squareListIcon = <FontAwesomeIcon icon={solid('list')} />
// export const gripIcon = <FontAwesomeIcon icon={solid('grip')} />
// export const angleRightIcon = <FontAwesomeIcon icon={solid('angle-right')} className={`font-size-2`}/>
// export const angleLeftIcon = <FontAwesomeIcon icon={solid('angle-left')} className={`font-size-2`}/>
// export const xMarkIcon = <FontAwesomeIcon icon={solid('xmark')} className={`font-size-3`} style={{marginLeft: "1vw"}}/>
// export const trashIcon = <FontAwesomeIcon icon={solid('trash')} className={`font-size-4`} />
// export const handPointerIcon = <FontAwesomeIcon icon={solid('hand-pointer')} className={`font-size-3 color-4`} style={{height: "100%", width: "100%", transform: "rotate(-10deg)"}}/>
// //export const handLove = <FontAwesomeIcon icon={solid('hand-love')} />
// export const pesoIcon = <FontAwesomeIcon icon={solid('peso-sign')} />
// export const burstIcon = <FontAwesomeIcon icon={solid('burst')} />
// export const clockIcon = <FontAwesomeIcon icon={solid('clock')} />
// export const personWalkingIcon = <FontAwesomeIcon icon={solid('person-walking')} />
// export const lightbulb = <FontAwesomeIcon icon={solid('lightbulb')} />
// export const locationPin = <FontAwesomeIcon icon={solid('location-pin')} />
// export const boltIcon = <FontAwesomeIcon icon={solid('bolt')} />
// export const mapMarkerIcon = <FontAwesomeIcon icon={solid('location-dot')} />

// export const proSign = ({ theme, isHighlight }) =>
// 	  // <Wrapper
//     //   name={"pro-sign"}
//     //   height={"auto"}
//     //   width={"auto"}
//     //   display={"inline"}
//     // >
//       <Button
//         show={true}
//         text={"pro"}
//         disabled={true}
//         width={"fit-content"}
//         wrapperDisplay={"inline-flex"}
//         //wrapperClassName={"btn-center"}
//         position={"relative"}
//         classTypes={["classic", "subtle"]}
//         overflowOff={true}
//         //width={"12vw"}
//         //className={`${userPresent && document !== editMediaDocument ? "show fadein-05" : "fade fadeout-05"}`}
//         //positions={["left"]}
//         theme={theme.theme}
//       >
//       {/* <span 
//         className={`
//           pro-sign input-radius-3
//           theme-${theme.theme === 0 ? 1 : 0}
//           ${isHighlight ? `highlight` : ""}
//         `}
//       > 
//         PRO
//       </span> */}
//       </Button>
//     // </Wrapper>
