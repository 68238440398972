// import React, {Component} from 'react'
import { firebaseApp } from './Fire';
import * as generics from './generics';
import { query, where, and, or, orderBy, limit, startAfter, getFirestore, deleteDoc, updateDoc, writeBatch as writeBatch_, getDoc, getDocs, setDoc, addDoc, collection as collection_, doc, serverTimestamp, increment } from 'firebase/firestore';
import { auth } from './Fire';

//import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
//import { setVideolist, uploadPrograms } from '../src/stateChanges';

// const db = firebase.firestore();

import { db } from './Fire';

export const getCollection = async( quereyParams ) => { 

    console.log("getcollection", quereyParams)
  
  let query_

  const getWhereQ = () => { ///optimise: use foreach or map to get whereQ independent on length 

    // const whereQ = quereyParams.wherePath.forEach((path, i) => 
    //   where(quereyParams.wherePath[i], quereyParams.whereOperator[i], quereyParams.whereValue[i]),
    // )
    // return and(whereQ)

    let whereQ 
    switch (true) {
      case quereyParams.wherePath.length === 1:
        whereQ = 
          where(quereyParams.wherePath[0], quereyParams.whereOperator[0], quereyParams.whereValue[0])
        break
      case quereyParams.wherePath.length === 2:
        whereQ = 
          and(where(quereyParams.wherePath[0], quereyParams.whereOperator[0], quereyParams.whereValue[0]),
          where(quereyParams.wherePath[1], quereyParams.whereOperator[1], quereyParams.whereValue[1]))
        break
      case quereyParams.wherePath.length === 3:
        whereQ = 
          and(where(quereyParams.wherePath[0], quereyParams.whereOperator[0], quereyParams.whereValue[0]),
          where(quereyParams.wherePath[1], quereyParams.whereOperator[1], quereyParams.whereValue[1]),
          where(quereyParams.wherePath[2], quereyParams.whereOperator[2], quereyParams.whereValue[2]))
        break
      default:
        break
    }
    return whereQ
  }

  switch(true) {
    case !!quereyParams.wherePath && !!quereyParams.limit:
      query_ = query(
        collection_(db, quereyParams.collection),
        Array.isArray(quereyParams.wherePath) ? 
            getWhereQ() :
            where(quereyParams.wherePath, quereyParams.whereOperator, quereyParams.whereValue)
        ,
        //orderBy(quereyParams.orderBy),
        limit(quereyParams.limit)
      )
      break;
    case !!quereyParams.wherePath && quereyParams.isStartAfter:
      query_ = query(
        collection_(db, quereyParams.collection),
        Array.isArray(quereyParams.wherePath) ? 
            getWhereQ() :
            where(quereyParams.wherePath, quereyParams.whereOperator, quereyParams.whereValue)
        ,
        //orderBy(quereyParams.orderBy),
        startAfter(quereyParams.startAfter) ///pagination via snapshot of last retrived doc
      )
      break;
    case !!quereyParams.wherePath && !quereyParams.limit:
      query_ = query(
        collection_(db, quereyParams.collection),
          Array.isArray(quereyParams.wherePath) ? 
            getWhereQ() :
            where(quereyParams.wherePath, quereyParams.whereOperator, quereyParams.whereValue)
        ,
      )
      break;
    case !quereyParams.wherePath && !!quereyParams.limit: ///note: probably not used
      query_ = query(
        collection_(db, quereyParams.collection),
        //orderBy(quereyParams.orderBy),
        limit(quereyParams.limit)
      )
      break;
    case !quereyParams.wherePath && !quereyParams.limit: 
      query_ = collection_(db, quereyParams.collection)
      break;
    default:
  }

  const 
    q = query_,
    querySnapshot = await getDocs(q) ///note: collection_ or else import collection not read
    let data = []
    querySnapshot.forEach((doc) => data.push(doc.data()))

    let lastDoc = null
    if (!!quereyParams.limit) {
      lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    }

    console.log("getcollection returns ", quereyParams.collection, " returned: ", data)
  
  return  { data: data, lastDoc: lastDoc } 
}

export const getDocIds = async({ collection }) => { 
  const 
    q = collection_(db, collection),
    querySnapshot = await getDocs(q) ///note: collection_ or else import collection not read
  let data = []
  querySnapshot.forEach((doc) => {
      data.push(doc.data().id)
  });

  return data
}

export const getDocument = async({ collection, document }) => {
const 
  q = doc(db, collection, document),
  docSnap = await getDoc(q);
if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
  return docSnap.data()
} else {
  console.log("No such document!");
  return []
}
}

export const writeBatch = async({ collection, dataArray, docIdAttr }) => {
  console.log("writeBatch", collection, dataArray, docIdAttr)
  // Create a batch write
  const batch = writeBatch_(db); ///const batch = db.batch();

// Iterate through the array and create a document for each object
  dataArray.forEach((data) => {
    const document = generics.getDeepValueViaPath({ obj: data, path: docIdAttr }) ///was: getDeepValueViaPath
    // Generate a new document reference
    const docRef = doc(db, collection, document)

    // Add the set operation to the batch write
    batch.set(docRef, data);
  });

  // Commit the batch write
  await batch
    .commit()
    .catch((error) => {
      console.error('Error performing batch write:', error);
    });
    console.log('Batch write successful!');
}

///optimise: consider renaming upload/add to ... write?

// export const getVideoCollection = new Promise((resolve) => {

// export function getVideoCollection() {

//   return new Promise((resolve) => {
//     var docRef = db.collection("videoCollection").doc("jEdSTTegZGJfTlVe2ijr")
//     docRef.get().then((doc) => {
//         if (doc.exists) {
//             let data = doc.get("videoCollection")
//             resolve(data)
//         } else {
//             console.log("No such document!");
//         }
//     })
//     .catch(function(error) {
//         console.log("Error getting document:", error);
//     }) //.then((data) => {return resolve(data), console.log("data", data)});
//   })

//   // const getContent = () => { 
//   //   getContentPromise
//   //       .then((data) => {   
//   //         return resolve(data)
//   //     })
//   }

  // getContent()

// })

// export function getAllVideos() {
//   return new Promise((resolve) => {
//     db.collection("videos")
//       .get()
//       .then((querySnapshot) => {
//         let videos = []
//         querySnapshot.forEach((doc) => {
//           const docVideos = Object.values(doc.data())
//           videos.push(...docVideos)
//         });
//         console.log("videos", videos)
//         setVideolist(videos)
//         resolve(videos)
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   })
// }

// export function getAllVideos() {
//   return new Promise((resolve) => {
//     db.collectionGroup('videos')
//       .get()
//       .then((querySnapshot) => {
//         let videos = []
//         querySnapshot.forEach((doc) => {
//           const docVideos = doc.data().videos // Object.values(doc.data().videos)
//           videos.push(...docVideos)
//         });
//         resolve(videos)
//       })
//       .catch((err) => {
//           console.error(err);
//       });
//   });
// }

// export function downloadRecentVideos() {
//   return new Promise((resolve) => {
//     db.collection('videoCollection').doc(document).collection("videos").doc()
//       .get()
//       .then((querySnapshot) => {
//         let videos = []
//         querySnapshot.forEach((doc) => {
//           const docVideos =  doc.data().videos //Object.values(doc.data())
//             videos.push(...docVideos)
//         });
//         resolve(videos)
//       })
//       .catch((err) => {
//           console.error(err);
//       });
//   });
// }



export function getPrograms() {
  return new Promise((resolve) => {
  db.collection("programs")
    .get()
    .then((querySnapshot) => {
      let programs = []
      querySnapshot.forEach((doc) => {
        const docPrograms = doc.data().programs  //Object.values(doc.data())
        programs.push(...docPrograms)
      });
      // this.setState(uploadPrograms(programs))
      resolve(programs)
    })
  });
}

//   export function getProgramsForChannel({ document }) {
//     return new Promise((resolve) => {
//       db.collection("programs").doc(document)
//         .get()
//         .then((doc) => {
//           let programs = []
//           if (doc.exists) {
//             const docPrograms = doc.data().programs  //Object.values(doc.data())
//             programs.push(...docPrograms)
//             resolve(programs)
//           } else {
//             resolve(programs)
//               console.log("No such document at ", document, "but resolved with:", programs);
//           }
//       })
//       .catch(function(error) {
//             console.log("Error getting document:", error);
//       }) 
//   });
// }

export function uploadPrograms({ document, programs }) {
  console.log("uploadPrograms", document, programs)
  return new Promise((resolve) => {
    db.collection("programs").doc(`${document}`).set(
      { programs } 
    )
    .then((doc) => {
      console.log("document set at ", document, "with programs ", programs);
      resolve()
    })
    .catch((error) => {
      console.error("Error set document at ", document, " with programs ", programs, "error: ", error);
    });
  });
}

export function addProgram({ document, program }) {
  return new Promise((resolve) => {
    db.collection("programs").doc(document).update({
      programs: firebaseApp.firestore.FieldValue.arrayUnion(program)
    })  
    .then((doc) => {
      console.log("program added: ", program);
      resolve()
    })
    .catch((error) => {
      console.error("Error adding program: ", program, error);
    });
  });
}

export function uploadPlaylists({ document, playlists }) {
  return new Promise((resolve) => {
    db.collection("playlists").doc(document).set(
      { playlists } ///...playLists.items
    )
    .then((doc) => {
      console.log("set playlists at ", document, " done with: ", playlists)
      resolve(playlists)
    })
    .catch((error) => {
      console.error("Error set playlists at: ", document, "error: ", error);
    });
  })
}

// export function uploadChannelData({ document, channelData }) { ///note: addDocument used instead
//   return new Promise((resolve) => {
//     const channelsRef = db.collection("channels").doc(document)
//     channelsRef.set(
//       { ...channelData }
//     )
//     .then((doc) => {
//       console.log("channel data uploaded", channelData);
//       resolve()
//     })
//     .catch((error) => {
//       console.error("Error adding document: ", error);
//     });
//   })
// }

// export const getData = async ( collection ) => {
//   console.log("collection", collection)
//   const collection_ = collection === "videos" ? 
//     db.collectionGroup(collection) : db.collection(collection)
//   return new Promise((resolve) => {
//     collection
//       .get()
//       .then((querySnapshot) => {
//         let data = []
//         querySnapshot.forEach((doc) => {
//           let res
//           switch(true) {
//             case collection === "channels": ///optimise: save channels in same format as programs, and align here
//               res = doc.data()
//               data.push(res)
//             break;
//             case collection === "programs" || collection === "videos":
//               res = doc.data()[collection]
//               data.push(...res)
//             break;
//             default:
//           }
//           }
//         );
//         console.log("dataaa", data)
//         ///console.log("bibbber", collection, data)
//         resolve(data)
//       })
//       .catch((err) => {
//         console.error(err);
//         resolve([])
//       });
//   })
// }

export const getChannels = async ({ collection }) => {
  return new Promise((resolve) => {
    db.collection(collection)
      .get()
      .then((querySnapshot) => {
        let data = []
        querySnapshot.forEach((doc) => 
          data.push(doc.data())
        );
        resolve(data)
      })
      .catch((err) => {
        console.error(err);
        resolve([])
      });
  })
}

export function getPlaylists({ channelName }) {
  return new Promise((resolve) => {
    db.collection("playlists").doc(channelName)
      .get()
      .then((doc) => {
        const playlists = doc.data().hasOwnProperty("playlists") ?
          doc.data().playlists : []
        console.log("get playlists at ", channelName, " return: ", playlists)
        resolve(playlists)
      })
      .catch((err) => {
        console.error(err);
      });
  })
}

// const unsub = onSnapshot(doc(db, "cities", "SF"), (doc) => {
//   console.log("Current data: ", doc.data());
// });


// export function getChannelsNames({ collection }) {
//   return new Promise((resolve) => {
//     db.collection(collection)
//       .get()
//       .then((querySnapshot) => {
//         let playlists = []
//         querySnapshot.forEach((doc) => {
//           const document = doc.id
//           playlists.push(document)
//         });
//         resolve(playlists)
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   })
// }

// export function prepareDoc({ collection, document }) {
//   return new Promise((resolve) => {
//     setDoc(doc(db, collection, document), {created: true}, {merge: true}) ///db.collection(collection).doc(document).set({created: true}, {merge: true})
//       .then((doc) => {
//         resolve()
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   })
// }


export function uploadVideos({ document, document_, videos, yearMatrix }) {
  return new Promise((resolve) => {
    db.collection("videoCollection").doc(document).collection("videos").doc(document_).set(
      { videos, years: yearMatrix }
    )
    .then((doc) => {
      console.log("videos uploaded at ", document_, " at ", document, ", videos: ", videos );
      resolve()
    })
    .catch((error) => {
      console.error("Error uploading videos", videos, " at ", document, "error:", error);
    });
  })
}

export function updateVideos({ document_, document, videos }) {
  return new Promise((resolve) => {
    db.collection("videoCollection").doc(document_).collection("videos").doc(document).update(
      { videos }
    )
    .then((doc) => {
      console.log("videos added at ", document_, " at ", document, ", videos: ", videos );
      resolve()
    })
    .catch((error) => {
      console.error("Error adding videos ", videos, "error: ", error);
    });
  })
}

// export function getVideosForChannel({ document }) {
//   return new Promise((resolve) => {
//     db.collection("videoCollection").doc(document).collection("videos")
//       .get()
//         .then((querySnapshot) => {
//           let videos = []
//           querySnapshot.forEach((doc) => {
//             console.log(doc.data())
//             const docVideos = doc.data().videos
//             videos.push(...docVideos)
//           });
//           console.log("get videos at ", document, " returns ", videos)
//           resolve(videos)
//         })
//         .catch((err) => {
//           console.error("get videos ", document, " returns error ", err);
//         });
//   })
// }

// export function getRecentDoc({ document_, year }) {
//   return new Promise((resolve) => {
//     console.log(document_, year)
//     db.collection("videoCollection").doc(document_).collection("videos")
//       .where("years", "array-contains", year)
//       .get()
//       .then((querySnapshot) => {
//         querySnapshot.forEach((doc) => {
//             resolve([doc.data().videos, doc.id])
//         });
//     })
//     .catch((error) => {
//         console.log("Error getting documents: ", error);
//     });
//   })
// }

export const addDocument = async({ collection, document, payload, isAddUid, isAddCreator }) => {
  console.log("addDocument", collection, document, !!document, payload, 'staticData' in payload)
  const date = serverTimestamp()
  let payload_ = 'staticData' in payload ?

    { 
      ...payload,
      ['staticData']: {
        ...payload['staticData'], 
        publishedAt: date, 
        lastChanged: date
      }
    } :
    { 
      ...payload, 
      publishedAt: date, 
      lastChanged: date
    }
  if (isAddUid) {
    const uid = auth.currentUser.uid;

    payload_ = 'staticData' in payload ?
      {
        ...payload_,
        ['staticData']: {
          ...payload_['staticData'], 
          uploaderId: uid 
        }
      } 
      :
      payload_["uploaderId"] = uid
  }

  // let document_ = document
  // console.log("documentid", document)
  // if (!!document) {
  //   console.log("documentid x", document)
  //   document_ = doc(collection_(db, collection)); ///note: firestore generates the ID
  //   console.log("documentid", document_.id)
  //   payload_ = 
  //     payload_ = 
  //       {
  //         ...payload_,
  //         ['staticData']: {
  //           ...payload_['staticData'], 
  //           id: document_.id
  //         }
  //       } 
  // }

  ///const getDocRef = async() => { return doc(collection_(db, collection)) }
  let docRef = !!document ? ///note: if document name not explicit => create docRef
    "" :
    doc(collection_(db, collection))

  const document_ = !!document ?
    document :
    docRef.id

  payload_ = !!document ? ///note: if document name not explicit (i.e. is diamont-list) => add id
      payload_ 
      :
        {
          ...payload_,
          ['staticData']: {
            ...payload_['staticData'], 
            id: docRef.id
          }
        } 

  await setDoc(doc(db, collection, document_), payload_)
  // !!document ? 
  //   await setDoc(doc(db, collection, document), payload_) :
  //   await addDoc(collection_(db, collection), payload_) 
  
  if (isAddCreator) {
    addCreator({ collection, document, date })
  }
}

export const addCreator = async({ collection, document, date }) => { ///note: adds creator info to subcollection ///optimise: consider to make addCreator an instance of addDocument (but nah)
  const 
    payload_ = 
    { 
      creator: auth.currentUser.uid,
      created: date, 
      lastChanged: date
    }  
  await addDoc(collection_(db, collection, document, "auth"), payload_)
}

export const updateDocument = async({ collection, document, param, payload, isIncrement }) => {

  let 
    incrementPayload = {}, 
    nonIncrementPayload = {}
  const mapIncrementPayload = isIncrement ? 
      Object.fromEntries(
        Object.entries(payload).map(
          ([k, v], i) => {
            if (k.includes("Count")) {
              return Object.assign(incrementPayload, { [k]: increment(v) }) 
            } 
            else {
              return Object.assign(nonIncrementPayload, { [k]: v })
            }
          } 
        )
      ) :
    payload ///note: not used

  let payload_
  payload_ = isIncrement ?
          { ...incrementPayload, ...nonIncrementPayload } :
          payload
  
  const lastChanged = { lastChanged: serverTimestamp() }

  payload_ = !!param ? { ///if !!param (nested) add lastchanged at param (and keep primary payload)
    ...payload_, ///note: spread the existing properties of the original data
    [param]: {
        ...payload_[param], ///note: spread the existing properties of the "attr" object
        ...lastChanged ///note: spread the new key-value pair
    }
  } :
  { ...payload_, ...lastChanged }

  const excludedKeys = ["lastChanged"]
  payload_ = generics.convertToDotNotation(payload_, '', {}, excludedKeys) ///note: dot notation is used to enable update of single nested field(s) without overwriting other (nested) fields. ///note: excludedKeys as key "lastUpdated" is excluded from dot notation to mitigate impact on serverTimestamp() 

  const docRef = doc(db, collection, document)

  let isSuccess = true
  await updateDoc(docRef, {
    ...payload_
    }, (error) => { ///note: some failure, like no document, is not captured by error, but by catch
      if (error) {
        console.log("error", error)
        isSuccess = false
      } 
  }).catch(() => {
    console.log("Log, as write failed somehow");
    isSuccess = false
    // return a 500 internal server error occurred
});
  return isSuccess ///note: isSuccess
}


export const readDoc = async({ collection, document }) => {
  console.log("xxx", collection, document)
  const docRef = doc(db, collection, document);
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return docSnap.data()
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

export const deleteDocument = async({ collection, document }) => {
  const 
    docRef = doc(db, collection, document),
    deleteRef = await deleteDoc(docRef)
}

