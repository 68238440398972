import './drop-down.css';
import 'react-dropdown/style.css';
import Dropdown from 'react-dropdown';
import { useContext } from 'react';
import { Wrapper } from './StandardComponents';
import { themeContext } from './App';
import * as generics from './generics';

export const DropDown = ({ highlightWeekday, disabled, height, width, className, options, passedValue, parentCallback } ) => {

  ///context
  const theme = { theme: 1 } ///const { theme, setTheme } = useContext(themeContext);

	
  ///const
  const 
    name = "drop-down",
    defaultOption = options[0],
    buttonTheme = highlightWeekday ? 
      theme.theme === 0 ? 1 : 0
      : theme.theme
  return (
	  <Wrapper
      name={name}
      height={height}
      width={width}
    >
      {/* <div className={`${name}`}> */}
        <Dropdown 
          disabled={disabled}
          className={`
            ${className}
            theme-${buttonTheme}
            input-radius
          `}
          controlClassName={`btn-subtle`}
          menuClassName={`btn-like`}
          // placeholderClassName={``}
          options={options} 
          value={!generics.stringUndefined(passedValue) ? passedValue : defaultOption} 
          placeholder={`Select an option`}
          onChange={(value) => {
            const template = value.value
            parentCallback({ template })
          }}
        />
      {/* </div> */}
    </Wrapper>
  );
}
