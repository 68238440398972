import * as generics from './generics';

///note/risk: not alligned with new data in createVideo in index!
export const createVideo = async({ responseVideo, categoryNameIds }) => {

    const getUriId = ( input ) => { return encodeURI(input.replace(/ /g, "_").trim().toLowerCase()) }

    const youtubeUrl = "https://youtu.be/"

	const videos_ = responseVideo.items.map((video_, i) => {

            const 
                videoId = video_.id,
                { description, channelId, channelTitle, publishedAt, title } = video_.snippet,
                { commentCount, favoriteCount, likeCount, viewCount } = video_.statistics,
                { embeddable, madeForKids, privacyStatus } = video_.status,
                { duration } = video_.contentDetails,

                categoryNameId = getUriId(channelTitle),
                videoUrl = `${youtubeUrl}${video_.id}`,
                thumbnailUrl = video_.snippet.hasOwnProperty('thumbnails') && video_.snippet.thumbnails.hasOwnProperty('high') && video_.snippet.thumbnails.high.hasOwnProperty('url') ? video_.snippet.thumbnails.high.url : "",
                template = "video",
                lastChanged = new Date(),
				//uploaderId = profileId,
                uploaderType = "user",
            
                stats = { commentCount: Number(commentCount), favoriteCount: Number(favoriteCount), likeCount: Number(likeCount), viewCount: Number(viewCount) },
                minutesDuration = generics.iso8601DurationToMinutes(duration),
                durations = { iso8601Duration: duration, minutesDuration: Number(minutesDuration) }

		const video = {
			staticData: {
				lastChanged,
				videoId,
                channelId,
				channelTitle,
				publishedAt,
				title,
                description,
				videoUrl,
				thumbnailUrl,
				template,
                embeddable, 
                madeForKids, 
                privacyStatus,
                ...durations,
				["category name id"]: [categoryNameId],
				//uploaderId,
                uploaderType,
			},
			dynamicData: {	
				lastChanged,
				["category name ids"]: categoryNameIds,
			},
			stats: {
				lastChanged,
                ...stats
			},
			attr: {
				lastChanged,
			}
		  }

		return video
	})

	console.log("create video returns ", videos_)
	return videos_

}