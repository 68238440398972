import { categoriesExtraData, typesCategories } from './CategoryGalleryData';

// export const createPls = async({ channelName, playlists, responsePlaylistItemsCollection }) => {

// 	const createPl = ({ playlistItems, i }) => {
// 		let 
// 			programVideoCollection = [],
// 			counter = 1
// 		playlistItems.items.map((video, index) => {
// 			const 
// 				youtubeUrl = "https://youtu.be/",
// 				thumbnailUrl = playlistItems.items[index].hasOwnProperty('snippet') && playlistItems.items[index].snippet.hasOwnProperty('thumbnails') && playlistItems.items[index].snippet.thumbnails.hasOwnProperty('high') && playlistItems.items[index].snippet.thumbnails.high.hasOwnProperty('url') ? playlistItems.items[index].snippet.thumbnails.high.url : "",
// 				newVideo =
// 					{
// 						videoUrl: `${youtubeUrl}${video.snippet.resourceId.videoId}`,	
// 						episode: counter,  ///note: or use .position ///optimise: episode not offset in realtion to non-included (private) videos, consider to mitigate
// 						season: 1,
// 					}

// 			if (thumbnailUrl !== "") {
// 				programVideoCollection.push(newVideo) ///if no thumbnail video is (probably) private => not included
// 				counter++
// 			}
// 		  })

// 		  //const offsetIndex = i + playListIndex ///note: +playListIndex to offset corretly

// 		  const 
// 			channelTitle = playlistItems.items[0].snippet.channelTitle.toLowerCase(),
// 			thumbnailUrl = playlistItems.items[0].hasOwnProperty('snippet') && playlistItems.items[0].snippet.hasOwnProperty('thumbnails') && playlistItems.items[0].snippet.thumbnails.hasOwnProperty('high') && playlistItems.items[0].snippet.thumbnails.high.hasOwnProperty('url') ? playlistItems.items[0].snippet.thumbnails.high.url : "",
// 			// dateArray = playlistItems["items"].map(e => new Date(e["snippet"]["publishedAt"])),
// 			// maxDate = new Date(Math.max(...playlistItems["items"].map(e => new Date(e["snippet"]["publishedAt"])))),
// 			// indexMaxDate = dateArray.map(Number).indexOf(+maxDate), ///note: serilisation for correct comparison according to https://stackoverflow.com/questions/27450867/how-to-correctly-use-javascript-indexof-in-a-date-array
// 			// publishedAt = playlistItems["items"][indexMaxDate].snippet.publishedAt, ///note: publishedat is maxdate for a video in that playlist
// 			publishedAt = playlists.find(playlist => playlist.id === playlistItems.items[0].snippet.playlistId).snippet.publishedAt,
// 			tagLine = playlists.find(playlist => playlist.id === playlistItems.items[0].snippet.playlistId).snippet.title, //playlistsEssentials.find(playlist => playlist.playListId === playlistItems.items[0].snippet.playlistId).title,
// 			description = playlists.find(playlist => playlist.id === playlistItems.items[0].snippet.playlistId).snippet.description, //playlistsEssentials.find(playlist => playlist.playListId === playlistItems.items[0].snippet.playlistId).description,
// 			playlistId = playlistItems.items[0].snippet.playlistId,
// 			sortId = [100 + i],
// 			getExtraCategories = () => {
// 				for(let i = 0; i < typesCategories.length; i++) {
// 					let typeCategory = typesCategories[i]
// 					//if (tagLine.toLowerCase().includes(typeCategory.toLowerCase())) {
// 					if (new RegExp("\\b"+typeCategory+"\\b").test(tagLine)) {
// 						console.log("info: program ", tagLine, " is created with ", typeCategory)
// 						return [typeCategory]
// 					}
// 				}
// 				return []
// 				//typesCategories.map((typeCategory) => tagLine.toLowerCase().includes(channelTitle.toLowerCase())   //categoriesExtraData.extraCategory[channelTitle],
// 			},
// 			extraCategories = getExtraCategories()
// 			const category = [channelTitle, ...extraCategories] 

// 		  const program = {
// 			programCollectionId: encodeURI(`${channelTitle.toLowerCase().replaceAll(' ', '_')}-${tagLine.toLowerCase().replaceAll(' ', '_').replace(/[\W_]+/g,"_")}`), ///only allow alphanumeric characters and _ in url 
// 			programTitle: channelTitle, 
// 			programTagline: tagLine, 	
// 			programDescriptionShort: description,
// 			programDescriptionLong: description,
// 			programVideoCollection: programVideoCollection,
// 			publishedAt: publishedAt,
// 			thumbnailUrl: thumbnailUrl,
// 			category: category,
// 			sortId: sortId,
// 			playlistId: playlistId,
// 		  }
// 		return program
// 	  }

//     const programs_ = responsePlaylistItemsCollection
//         .map( (playlistItems, i) => {
//   			const program = createPl({ playlistItems, i })
//   			return program
//   		})

//   console.log("create programs at ", channelName, " returned ", programs_.length, " program(s), namely ", programs_)
//   return programs_
// }

export const createPls = async({ channelName, categoryNameId, playlists, responsePlaylistItemsCollection }) => {
	console.log("createPls", responsePlaylistItemsCollection)
	const createPl = ({ playlistItems, i }) => {
		let 
			plVideoCollection = [],
			counter = 1
		playlistItems.items.map((video, index) => {
			const 
				youtubeUrl = "https://youtu.be/",
				thumbnailUrl = playlistItems.items[index].hasOwnProperty('snippet') && playlistItems.items[index].snippet.hasOwnProperty('thumbnails') && playlistItems.items[index].snippet.thumbnails.hasOwnProperty('high') && playlistItems.items[index].snippet.thumbnails.high.hasOwnProperty('url') ? playlistItems.items[index].snippet.thumbnails.high.url : "",
				newVideo =
					{
						videoUrl: video.snippet.resourceId.videoId, ///`${youtubeUrl}${video.snippet.resourceId.videoId}`,	
						episode: counter,  ///note: or use .position ///optimise: episode not offset in realtion to non-included (private) videos, consider to mitigate
						season: 1,
					}

			if (thumbnailUrl !== "") {
				plVideoCollection.push(newVideo) ///if no thumbnail video is (probably) private => not included
				counter++
			}
		  })

		  //const offsetIndex = i + playListIndex ///note: +playListIndex to offset corretly

		  const 
			channelTitle = playlistItems.items[0].snippet.channelTitle.toLowerCase(),
			plThumbnailUrl = playlistItems.items[0].hasOwnProperty('snippet') && playlistItems.items[0].snippet.hasOwnProperty('thumbnails') && playlistItems.items[0].snippet.thumbnails.hasOwnProperty('high') && playlistItems.items[0].snippet.thumbnails.high.hasOwnProperty('url') ? playlistItems.items[0].snippet.thumbnails.high.url : "",
			plPublishedAt = playlists.find(playlist => playlist.id === playlistItems.items[0].snippet.playlistId).snippet.publishedAt,
			plTitle = playlists.find(playlist => playlist.id === playlistItems.items[0].snippet.playlistId).snippet.title, //playlistsEssentials.find(playlist => playlist.playListId === playlistItems.items[0].snippet.playlistId).title,
			plDesc = playlists.find(playlist => playlist.id === playlistItems.items[0].snippet.playlistId).snippet.description, //playlistsEssentials.find(playlist => playlist.playListId === playlistItems.items[0].snippet.playlistId).description,
			plSourceId = playlistItems.items[0].snippet.playlistId, ///only allow alphanumeric characters and _ in url 
			plId = encodeURI(`${channelTitle.toLowerCase().replaceAll(' ', '_')}-${plTitle.toLowerCase().replaceAll(' ', '_').replace(/[\W_]+/g,"_")}-${plSourceId}`),
			plSortId = [100 + i],
			plSource = `youtube`,
			plType = `channel`,
			getExtraCategories = () => {
				for(let i = 0; i < typesCategories.length; i++) {
					let typeCategory = typesCategories[i]
					//if (tagLine.toLowerCase().includes(typeCategory.toLowerCase())) {
					if (new RegExp("\\b"+typeCategory+"\\b").test(plTitle)) {
						console.log("info: program ", plTitle, " is created with ", typeCategory)
						return [typeCategory]
					}
				}
				return []
				//typesCategories.map((typeCategory) => tagLine.toLowerCase().includes(channelTitle.toLowerCase())   //categoriesExtraData.extraCategory[channelTitle],
			},
			extraCategories = getExtraCategories(),
			lastChanged = { lastchanged: new Date() },
			template = "pl"

		  const pl = {
			staticData: {
				...lastChanged,
				plId, 
				channelTitle, 
				plTitle ,
				plPublishedAt,
				plThumbnailUrl,
				plSourceId,
				plSource,
				plType,
				template,
				["category name id"]: [categoryNameId],
			},
			dynamicData: {	
				...lastChanged,
				plDesc, 
				plVideoCollection,
				///["category name ids"]: [...extraCategories],
				plSortId,
				access: "public",
                isApprovedForPublic: true
			},
			stats: {
				...lastChanged,
			},
			attr: {
				///optimise: consider to map attr from templates
				...lastChanged,
			}
		  }
		return pl
	  }

    const programs_ = responsePlaylistItemsCollection
        .map( (playlistItems, i) => {
  			const program = createPl({ playlistItems, i })
  			return program
  		})

  console.log("create pls at ", channelName, " returned ", programs_.length, " pl(s), namely ", programs_)
  console.log("createPls", programs_)
  return programs_
}
