import { apiKeys } from './requestVideos'; 

export const requestVideoStats = async({ videoIds }) => {

    let 
        combinedVideoStatsList = {},
        apiReady = true,
        firstLoop = true,
        isMoreData = false,
        videoIdCounter = 0

    const 
        part = "part=snippet&part=statistics&part=status&part=contentDetails",
        maxResults = 50

    while (isMoreData || firstLoop) {

        let requestStartVideoIdIndex = maxResults * videoIdCounter
        
        if (apiReady) {

            let 
				isFetched = false,
            	counter = 0,
				response,
                requestVideoIds = videoIds.slice(requestStartVideoIdIndex, requestStartVideoIdIndex + maxResults),
                stringifiedRequestVideosIds = requestVideoIds.map(videoId => `id=${videoId}`).join("&")
        		while (!isFetched && counter < apiKeys.length) { 

        			const requests = `https://youtube.googleapis.com/youtube/v3/videos?${part}&${stringifiedRequestVideosIds}&key=${apiKeys[counter]}`

					console.log("attempt fetch with api key index:", counter)
        			
					response = await fetch(requests)

					if (response.status === 403) {
						apiReady = false
						console.log("api index", counter, "ready:", apiReady)
					} else {
						apiReady = true
						isFetched = true
						console.log("isFetched:", isFetched, ", via api index:", counter)
					}
        			counter++
        		}

		if (!apiReady) {
			console.log("apiReady:", apiReady)
			break
		}

        let videoStatsList = await response.json()
        console.log("videoStatsList:", videoStatsList)
        combinedVideoStatsList = isMoreData ?
          {...combinedVideoStatsList, ...combinedVideoStatsList.items.push(...videoStatsList.items)}
          : videoStatsList

        isMoreData = videoIds.length > (maxResults * videoIdCounter) + maxResults - (videoIdCounter > 1 ? 1 : 0)
        console.log(videoIdCounter, isMoreData, videoIds.length, (maxResults * videoIdCounter) + maxResults - (videoIdCounter > 1 ? 1 : 0))

        firstLoop = false
        }
        videoIdCounter++  
	}
    console.log("combinedVideoStatsList", combinedVideoStatsList)
    return combinedVideoStatsList


	// combinedVideoList = []
    
    // console.log("combinedVideoList:", combinedVideoList)
	// const youtubeUrl = "https://youtu.be/"
	// const videos = combinedVideoList.items.map((video, i) => {
	// 	return {
	// 		videoId: video.id.videoId,
	// 		channelTitle: video.snippet.channelTitle,
	// 		description: video.snippet.description,
	// 		publishedAt: video.snippet.publishedAt,
	// 		tagline: video.snippet.title,
	// 		videoUrl:  `${youtubeUrl}${video.id.videoId}`,
	// 		thumbnailUrl: video.snippet.hasOwnProperty('thumbnails') && video.snippet.thumbnails.hasOwnProperty('high') && video.snippet.thumbnails.high.hasOwnProperty('url') ? video.snippet.thumbnails.high.url : "",
	// 		statisticsDate: false,
	// 	}
	// })

	// console.log("videos:", videos)
	// // const document = channelName
	// return [document, videos]

    // })
}