import '../src/video-selector.css';
import React, { useEffect, useContext } from 'react';
import ReactPlayer from 'react-player'
import { videosDataContext, categoriesDataContext, focusElementsContext, focusStatesContext } from './Home2';
import { createContext as selectorContext, useContextSelector } from 'use-context-selector';
import { popularityBadgeInfo } from './PopularityComponents';
import { MemoPlGallery} from './PlGallery';

import {
	//BrowserRouter as Router,
	Link
} from "react-router-dom";
import { getPopularityComponents } from './PopularityComponents'
import { PlComponent } from './PlGallery';

export const VideoSelector2 = (props) => {

    const 
        swrVideos = useContext(videosDataContext),
        swrCategories = useContext(categoriesDataContext)

    const 
        categoryFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "category")),
        plFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "pl")),
        videoFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "video")),
        setFocusStates = useContextSelector(focusStatesContext, v => v.setFocusStates)

        const handleSetFocusStates = ( focusParams ) => {
            setFocusStates((draft) => {
              const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === focusParams.mediaTypeFocus);
              Object.entries(focusParams) ///note: set state for each param (passed via function)
                .filter(([param, v]) => param !== "mediaTypeFocus") ///exclude param value from set state
                .map(([param, v], i) => focusElement[param] = v )
            })
          }
    // const getFocusObj = ({ mediaType }) => { return focusStates.find(focusObj => focusObj.mediaTypeFocus === mediaType) }
    // const 
    //     categoryFocusStates = getFocusObj({ mediaType: "category" }),
    //     plFocusStates = getFocusObj({ mediaType: "pl" })

    // useEffect(() => {
    //     if (props.videoChosen && !props.videoPreviewLoaded) {
    //         props.onVideoPreviewLoaded()
    //     }
    // });

	//let plTitle = props.pls.filter(pl => pl.plCollectionId === props.plCollectionId).map(pl => { return pl.title })[0] //possible to capture more simple?

    // if (props.swr.isError) return <p>Error loading data!</p>;
    // if (props.swr.isLoading) return <p>Loading...</p>;

    if (!plFocusStates.isFocus) { ///note: optimse, based on swr...
       return <div className={`video-selector-c`}></div>
    }
    
    else {

        const videos = swrVideos.isError || swrVideos.isLoading ?
            [] : swrVideos.data

        const videoSelectorcShow = true // props.videoChosen //&& props.videoPreviewLoaded

        const 
            categories = swrCategories.data, ///risk - implement check?
            categoryImg = categories.find(category => category["category name id"] === plFocusStates.elementFocus.staticData["category name id"]?.[0])?.media[0].url,
            background = categories.find(category => category["category name id"].includes(plFocusStates.elementFocus.staticData["category name id"]))?.background?.[0].background, ///optimise: multioption save: "string vs. object" in array 
            re = /(rgba?\(.*?\))/g,
            [backgroundColor] = !!background ? background.match(re) : "white"


        const plFocus = {
            ...plFocusStates.elementFocus,
            id: "videolist-pl",
            staticData: {
                ...plFocusStates.elementFocus.staticData,
                plType: "videolist-pl",
                plThumbnailUrl: categoryImg,
            }
        };

        
        return (
            <>
                {/* {props.videoListReady === true &&  */}
                <div className={`video-selector-c`}
                    
                    // onMouseEnter={(e) => props.onEnterVideoSelector(e)}
                    // onMouseLeave={(e) => props.onEnterVideoSelector(e)}
                >


                    <div className="videolist-pl"
                        onClick={() => handleSetFocusStates({ mediaTypeFocus: "video", isFocus: false })}
                    >
                        {/* <span>You are watching</span> */}
                        <PlComponent 
                            id={"video-selector-pl"}
                            isGallery={false}
                            pl={{...plFocus, id: "video-selector-pl"}}
                            background={background}
                            imgFile={categoryImg}
                            videos={[]}
                            isMuted={true}
                        />
                    </div>

                    <div className="videolist-c"
                        ref={props.refFunction}
                    >
                        <MemoPlGallery id={"video-list"} isMuted={props.isMuted}/>
                        {/* <div className="videolist">
                            {plFocusStates.elementFocus.staticData.plType === "highlight" ? popularityBadgeInfo : null}
                            {
                                plFocusStates.elementFocus.dynamicData.plVideoCollection ////props.pls.find(pl => pl.plCollectionId === props.plCollectionId).plvideos
                                    // && videos
                                    // .filter(video => video.plCollectionId === props.plCollectionId && video.season === props.seasonChosen)
                                    // .sort(( a, b ) => {
                                    // 	const sortLowToHigh = (a, b, parameter) => {
                                    // 	const 
                                    // 	  an = Number(a[parameter]),
                                    // 	  bn = Number(b[parameter])
                                    // 	if(an > bn) return 1;
                                    // 	if(an < bn) return -1;
                                    // 	return 0;
                                    // 	}
                                    // 	return sortLowToHigh(a, b)
                                    // })
                                    .map((video, index) => {

                                        const
                                            isInVideos = !(typeof videos.find(video_ => video_.staticData.videoId === video.videoUrl) === 'undefined'),
                                            tagline = isInVideos ? videos.find(video_ => video_.staticData.videoId === video.videoUrl).staticData.title : "[no title]",
                                            selected = video.videoUrl === videoFocusStates.idFocus,
                                            // isMostWatched = [...videos].filter(video => video.channelTitle === props.activeCategory).length > 0 && video.videoUrl === [...videos].filter(video => video.channelTitle === props.activeCategory).reduce((prev, current) => (prev.viewCount > current.viewCount) ? prev : current).videoUrl ///Math.max(...videos.filter(video => video.channelTitle === props.activeCategory).map(video => video.viewCount )).videoUrl
                                            popularityComponents = getPopularityComponents({ swrVideos, categoryFocus: categoryFocusStates.idFocus, videos: swrVideos.data, plFocus: plFocusStates.elementFocus, videoUrl: video.videoUrl }),
                                            to = !!categoryFocusStates.idFocus ?
                                                `/cat-${categoryFocusStates.idFocus.toLowerCase().replace(/ /g, "_")}/pl-${encodeURI(plFocusStates.elementFocus.staticData.plId).toLowerCase().replace(/ /g, "_")}/lv-${video.season}_${video.episode}` :
                                                `/pl-${encodeURI(plFocusStates.elementFocus.staticData.plId).toLowerCase().replace(/ /g, "_")}/lv-${video.season}_${video.episode}`

                                            return (
                                            <div 
                                                className={`
                                                    videolist-episode-w
                                                    ${selected ? "selected" : "" }
                                                `}
                                                key={`${video.videoUrl}-${index}`}
                                                >
                                                <div className={`videolist-episode-popularity`}>{popularityComponents}</div>
                                                <Link 
                                                    to={to}
                                                    key={`${plFocusStates.elementFocus.plCollectionId}${video.season}${video.episode}`}
                                                    //ref={props.refFunction}
                                                >
                                                    <div className={
                                                        `videolist-episode-c
                                                        ${selected ? "selected" : "" }
                                                    `}
                                                style={{outline: selected ? `5px solid ${backgroundColor}` : ""}} 
                                                onClick={() => {
                                                    const
                                                        mediaTypeFocus = "video",
                                                        actionFocus = "select",
                                                        elementFocus = video,
                                                        idFocus = video.videoUrl,
                                                        isFocus = true
                                                    handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                                                }}
                                                        // data-id={episode}
                                                        // episodeInfo={video}

                                                        // onClick={() => props.onVideoEpisodeChosen(props.plCollectionId, video.season, video.episode)}
                                                        // onMouseEnter={(e) => props.onEpisodeEnter(e, index)}
                                                        // onMouseLeave={(e) => props.onEpisodeEnter(e, index)}
                                                    >

                                                        <div className={`
                                                            videolist-episode-no
                                                            ${selected ? "selected" : "selected-not" }
                                                        `}
                                                        >
                                                            <span>
                                                                {video.episode}
                                                            </span>
                                                        </div>

                                                        <div className={`player-w pointer-events-none`}>
                                                            <ReactPlayer
                                                                className={"react-player videolist-episode-react-player"}
                                                                url={`https://youtu.be/${video.videoUrl}`}
                                                                width='100%'
                                                                height='100%'
                                                                light={true}
                                                                controls={false}
                                                            // onReady={() => this.videoListReady()}
                                                            // onLoad={() => console.log("hejfaesfe")}
                                                            // onError={() => handleVideoChange}
                                                            />
                                                        
                                                        </div>
                                                        <div className={`player-w-background-selected`}></div>
                                                    </div>
                                                    <h3 className={`videolist-episode-title`}>{tagline}</h3>
                                                </Link>
                                            </div>
                                        )
                                    }
                                    )}
                        </div> */}
                    </div>
                </div>
                {/* } */}
            </>
            
        )
    }
}


{/* <div className={`season-picker-c
${props.videoSelectorEntered ? "season-picker-c-brighten" : "season-picker-c-dim"}`}
> */}
{/* {videos.filter(video => video.plCollectionId === props.plCollectionId).map((video, i)  =>   */}

// {videos !== [] && Array(1).fill().map((i, index) => ///props.activeNumberOfSeasons
// <div className={`season-picker
//         ${props.seasonChosen === index + 1 && "season-picker-seleted"}  
//         ${props.seasonChosen !== index + 1 && props.seasonHovered === index + 1 && "season-picker-hovered"}  
//         ${props.videoSelectorEntered ? "video-selector-brighten" : "video-selector-dim"}
//     `}
    // key={index}
    // data-id={index + 1}
    // onClick={(e) => props.onSeasonChosen(e)}
    // onMouseEnter={(e, i) => props.onSeasonEnter(e, i)}
    // onMouseLeave={(e, i) => props.onSeasonEnter(e, i)}
// onClick={() => console.log(Math.max.apply(Math, videos.season.map((season) => season.season )))}
// >
    {/* {props.seasonChosen === index+1 &&  */}
    {/* <span className={`
        season-text
        ${props.seasonChosen === index + 1 ? "season-text-show" : "season-text-hide"}
        `}>
        collection
    </span>

    <span className={`season-no`}>{index + 1}</span>
</div>
)}
</div> */}