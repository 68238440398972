export const createVideos = async({ channelName, categoryNameId, responseVideos }) => {

    const youtubeUrl = "https://youtu.be/"
	const videos_ = responseVideos.items.map((video_, i) => {
		const 
			videoId = video_.id.videoId,
			channelTitle = video_.snippet.channelTitle,
			publishedAt = video_.snippet.publishedAt,
			title = video_.snippet.title,
			videoUrl = `${youtubeUrl}${video_.id.videoId}`,
			thumbnailUrl = video_.snippet.hasOwnProperty('thumbnails') && video_.snippet.thumbnails.hasOwnProperty('high') && video_.snippet.thumbnails.high.hasOwnProperty('url') ? video_.snippet.thumbnails.high.url : "",
			template = "video",
			lastChanged = new Date()

		const video = {
			staticData: {
				lastChanged,
				videoId,
				channelTitle,
				publishedAt,
				title,
				videoUrl,
				thumbnailUrl,
				template,
				["category name id"]: [categoryNameId],
				uploaderId: "auto",
				uploaderType: "backend-channel",
			},
			dynamicData: {	
				lastChanged,
				//["category name ids"]: [],
				access: "public",
				approvedForPublic: true
			},
			stats: {
				lastChanged,
			},
			attr: {
				///optimise: consider to map attr from templates
				lastChanged,
			}
		  }

		return video
			// { 
			// videoId: video.id.videoId,
			// channelTitle: video.snippet.channelTitle.toLowerCase(),
			// publishedAt: video.snippet.publishedAt,
			// title: video.snippet.title,
			// videoUrl:  `${youtubeUrl}${video.id.videoId}`,
			// thumbnailUrl: video.snippet.hasOwnProperty('thumbnails') && video.snippet.thumbnails.hasOwnProperty('high') && video.snippet.thumbnails.high.hasOwnProperty('url') ? video.snippet.thumbnails.high.url : "",
			// template: "video"
			//description: video.snippet.description, ///note: only part description (1000 characters), full description in request video? snippet
			// }
	})

	console.log("create videos at ", channelName, " returns ", videos_)
	return videos_

}