import React, { useState, useEffect, useContext, memo } from 'react';
import logo_text from '../src/imgs/logo/logo_text.png';
import logo from '../src/imgs/logo/logo.png';
import { Button, GridColums } from './StandardComponents';
import { Refine } from './Refine';
import '../src/header.css';
import { authDataContext, focusStatesContext } from './Home2';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { createContext as selectorContext, useContextSelector } from 'use-context-selector';

const Header = (props) => {

  const isInvitation = false

  ///from context
  const 
    plFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "pl")),
    dlcFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list-creator")),
    setFocusStates = useContextSelector(focusStatesContext, v => v.setFocusStates), ///optimise: consider to use callback (as for modal control) and handle setstate in parent (i.e. home)
    swrAuth = useContext(authDataContext),
    authStates = swrAuth.data

  const [isTop, setIsTop] = useState(true) ///optimse: make this scroll listening into reusable component

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    if(window.pageYOffset === 0) {
      setIsTop(true)
    }
    else {
      setIsTop(false)
    }    
  }

  const background = 
    props.isBackground ?
    <div 
      className={`
        background
        ${plFocusStates.isFocus ? "fade-out" : "fade-in"}
      `}
      style={{opacity: isTop && !dlcFocusStates.isFocus ? 0 : 1}}
    /> 
    : null


  const logoComponent = ///alternative: worth your while, be the change, worth watching
    props.isLogo ?
      <div className={`
        logo-c
        ${plFocusStates.isFocus ? "fade-out" : "fade-in"}
        `}>
        <img 
          className="logo-text" 
          src={logo_text} 
          alt="logo"/> 
        <img 
          className="logo" 
          src={logo} 
          alt="logo"/> 
        <p className="slogan">the prime home of yoga</p> 
      </div>
      : <p></p>

  const refine = props.isSearchField ? <Refine className={`${plFocusStates.isFocus || isInvitation ? "fade-out" : "fade-in"}`}/> : <p/>

  const volumeControl =
    props.isVolumeControl ?
      <div className={`volume-c`}> 
      {/* <div className={`volume-c 
      // {props.volumeOff ? "top" : "nottop"}
      `}> */}
        <i className={`fas size-c fa-solid volume-off fa-volume-xmark
          ${props.isMuted ? "hide" : "show"}
        `} 
          aria-hidden="true"
          onClick={() => props.setIsMuted(true)}
        ></i>
        <i className={`fas size-c fa-solid volume-on fa-volume-high
          ${props.isMuted ? "show" : "hide"}
        `} 
          aria-hidden="true"
          onClick={() => props.setIsMuted(false)}
        ></i>    
      </div> 
    : <p></p>

    const newButton = ({ text, className, name }) => 
    props.isAuthControl ?
    <Button
      show={true}
      wrapperClassName={``}
      classTypes={["classical", "min"]}
      className={`
        more
        input-padding-3
        ${className}
      `}
      wrapperStyle={{margin: "auto"}}
      width={"auto"}
      theme={4}
      height={"70%"}
      fontSize={1}
      parentCallback={() => {
        const 
          mediaTypeFocus = "diamont-list-creator",
          actionFocus = "active",
          elementFocus = "x", 
          idFocus = "x", 
          isFocus = true
        handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
    
    }}
    >{text}</Button> : <div></div>

    const modalButton = ({ text, className, name }) => ///optimise: consider to combine modalButton and newButton (=> function call specified at button)
      props.isAuthControl ?
      <Button
        show={true}
				wrapperClassName={``}
				classTypes={["classical", "min"]}
				className={`
					more
          input-padding-3
          ${className}
				`}
        wrapperStyle={{margin: "auto"}}
        width={"auto"}
        theme={4}
        height={"70%"}
				fontSize={1}
        parentCallback={() => props.parentCallback({ modalChildName: name, isShow: true })}
      >{text}</Button> : <div></div>


      const handleSetFocusStates = ({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus }) => {
        setFocusStates((draft) => {
                const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === mediaTypeFocus);
                focusElement.actionFocus = actionFocus
                focusElement.elementFocus = elementFocus
                focusElement.idFocus = idFocus
                focusElement.isFocus = isFocus
        })
      }

    useEffect(() => {
      //console.log("authStates", authStates)
    }, [authStates]);

    const buttons = !authStates?.uid ? ///note: signed in true or false
      <> 
      {modalButton({ name: "filterBox", className: `filter ${plFocusStates.isFocus || isInvitation ? "fade-out" : "fade-in"}`, text: "Filter" })}
      {newButton({ name: "diamont", className: `diamont ${plFocusStates.isFocus || isInvitation ? "fade-out" : "fade-in"}`, text: <i class="fa fa-diamond" aria-hidden="true"></i> })}
      {modalButton({ name: "new", className: `new ${plFocusStates.isFocus || isInvitation ? "fade-out" : "fade-in"}`, text: "New" })}
      {modalButton({ name: "signIn", className: `sign-in ${plFocusStates.isFocus || isInvitation ? "fade-out" : "fade-in"}`, text: "Sign In" })}
      {modalButton({ name: "signUp", className: `sign-up ${plFocusStates.isFocus || isInvitation ? "fade-out" : "fade-in"}`, text: "Sign Up" })}
      </> 
      :
      <> 
       {/* {button({ name: "new", className: "new", text: "New" })} */}
        {isBrowser ? 
            <>
                {modalButton({ name: "filterBox", className: `filter ${plFocusStates.isFocus || isInvitation ? "fade-out" : "fade-in"}`, text: "Filter" })}
                {modalButton({ name: "new", className: `new ${plFocusStates.isFocus || isInvitation ? "fade-out" : "fade-in"}`, text: "New" })}
                {modalButton({ name: "profile", className: `profile ${plFocusStates.isFocus ? "fade-out" : "fade-in"}`, text: <i className="fa-solid fa-user-gear"></i> })}
                {modalButton({ name: "signOut", className: `sign-up ${plFocusStates.isFocus ? "fade-out" : "fade-in"}`, text: "Sign Out" })}
            </>
        : null}
      </> 

    return ( 
        <div className={`header-c`}>
          {/* <GridColums
            name={`header`}
            gridTemplateColumns={"1fr 1fr 1fr" }
            gridTemplateRows={"1fr"}
            height={"100%"}
            width={"100%"}
          > */}
            <div className={`header`}>
              {background}
              {logoComponent}
              {refine}
          {/* <div className="header"> */}
      

            {/* <img className={`header-logo 
              ${props.videoChosen ? "header-logo-hide" : "header-logo-show"}
        `    }
              src={beta}
              alt={""}
              style={{filter: "invert(100%)"}}
            ></img> */}
              
            {/* <div className={`controls-c`}> */}
              {buttons}
              {volumeControl} 
              {/* <div className={`control pip`}>
                <i className={`fas fa-window-maximize
                  ${props.pipActivated ? "pip-show" : "pip-hide"}
                `}
                  onClick={props.onPipClicked}
                ></i>
              </div> */}
      
      
          {/* </GridColums> */}
          </div>
          </div>
  
  
    )
}
        
export default Header;