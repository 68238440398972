// export const colors = ///https://uigradients.com/
//   [ 
//     "linear-gradient(0deg, rgb(189, 195, 199), rgb(44, 62, 80))", //Grade Grey
//     //"linear-gradient(0deg, rgb(0, 0, 0), rgb(15, 155, 15))", //Terminal
//     "linear-gradient(0deg, rgb(0, 0, 0), rgb(231, 76, 60))", //Red Mist
//     "linear-gradient(0deg, rgb(0, 0, 0), rgb(67, 67, 67))", //Deep Space
//     "linear-gradient(0deg, rgb(0, 0, 70), rgb(28, 181, 224))", //Visions of Grandeur
//     "linear-gradient(0deg, rgb(0, 121, 145), rgb(120, 255, 214))", //Chitty Chitty Bang Bang
//     "linear-gradient(0deg, rgb(0, 153, 247), rgb(241, 23, 18))", //Superman
//     "linear-gradient(0deg, rgb(0, 159, 255), rgb(236, 47, 75))", //By Design
//     "linear-gradient(0deg, rgb(0, 176, 155), rgb(150, 201, 61))", //Ohhappiness
//     "linear-gradient(0deg, rgb(0, 180, 219), rgb(0, 131, 176))", //Blue Raspberry
//     "linear-gradient(0deg, rgb(0, 191, 143), rgb(0, 21, 16))", //Vine
//     "linear-gradient(0deg, rgb(0, 195, 255), rgb(255, 255, 28))", //Brady Brady Fun Fun
//     "linear-gradient(0deg, rgb(0, 198, 255), rgb(0, 114, 255))", //Facebook Messenger
//     "linear-gradient(0deg, rgb(0, 201, 255), rgb(146, 254, 157))", //Back To Earth
//     "linear-gradient(0deg, rgb(0, 210, 255), rgb(146, 141, 171))", //Bright Vault
//     "linear-gradient(0deg, rgb(0, 210, 255), rgb(58, 123, 213))", //Reef
//     "linear-gradient(0deg, rgb(0, 242, 96), rgb(5, 117, 230))", //Rainbow Blue
//     "linear-gradient(0deg, rgb(0, 4, 40), rgb(0, 78, 146))", //Frost
//     "linear-gradient(0deg, rgb(0, 57, 115), rgb(229, 229, 190))", //Horizon
//     "linear-gradient(0deg, rgb(0, 65, 106), rgb(121, 159, 12), rgb(255, 224, 0))", //Combi
//     "linear-gradient(0deg, rgb(0, 65, 106), rgb(228, 229, 230))", //Bupe
//     "linear-gradient(0deg, rgb(0, 65, 106), rgb(228, 229, 230))", //Blu
//     "linear-gradient(0deg, rgb(0, 70, 127), rgb(165, 204, 130))", //Sel
//     "linear-gradient(0deg, rgb(0, 79, 249), rgb(255, 249, 76))", //Ukraine
//     "linear-gradient(0deg, rgb(0, 82, 212), rgb(67, 100, 247), rgb(111, 177, 252))", //Bluelagoo
//     "linear-gradient(0deg, rgb(0, 90, 167), rgb(255, 253, 228))", //Evening Night
//     "linear-gradient(0deg, rgb(0, 92, 151), rgb(54, 55, 149))", //Clear Sky
//     "linear-gradient(0deg, rgb(100, 145, 115), rgb(219, 213, 164))", //Earthly
//     "linear-gradient(0deg, rgb(100, 43, 115), rgb(198, 66, 110))", //Crimson Tide
//     "linear-gradient(0deg, rgb(100, 65, 165), rgb(42, 8, 69))", //Twitch
//     "linear-gradient(0deg, rgb(101, 153, 153), rgb(244, 121, 31))", //Metapolis
//     "linear-gradient(0deg, rgb(101, 78, 163), rgb(234, 175, 200))", //Ultra Voilet
//     "linear-gradient(0deg, rgb(102, 102, 0), rgb(153, 153, 102))", //Petrichor
//     "linear-gradient(0deg, rgb(102, 125, 182), rgb(0, 130, 200), rgb(0, 130, 200), rgb(102, 125, 182)", //Hydrogen
//     "linear-gradient(0deg, rgb(103, 178, 111), rgb(76, 162, 205))", //Mild
//     "linear-gradient(0deg, rgb(103, 58, 183), rgb(81, 45, 168))", //Deep Purple
//     "linear-gradient(0deg, rgb(106, 145, 19), rgb(20, 21, 23))", //Green to dark
//     "linear-gradient(0deg, rgb(106, 48, 147), rgb(160, 68, 255))", //Purplin
//     "linear-gradient(0deg, rgb(109, 96, 39), rgb(211, 203, 184))", //Lithium
//     "linear-gradient(0deg, rgb(11, 72, 107), rgb(245, 98, 23))", //Sunset
//     "linear-gradient(0deg, rgb(112, 225, 245), rgb(255, 209, 148))", //Shore
//     "linear-gradient(0deg, rgb(115, 200, 169), rgb(55, 59, 68))", //Between The Clouds
//     "linear-gradient(0deg, rgb(116, 116, 191), rgb(52, 138, 199))", //Stellar
//     "linear-gradient(0deg, rgb(116, 235, 213), rgb(172, 182, 229))", //Digital Water
//     "linear-gradient(0deg, rgb(117, 127, 154), rgb(215, 221, 232))", //Mystic
//     "linear-gradient(0deg, rgb(118, 184, 82), rgb(141, 194, 111))", //Little Leaf
//     "linear-gradient(0deg, rgb(119, 161, 211), rgb(121, 203, 202), rgb(230, 132, 174))", //Hazel
//     "linear-gradient(0deg, rgb(12, 235, 235), rgb(32, 227, 178), rgb(41, 255, 198))", //Subu
//     "linear-gradient(0deg, rgb(120, 2, 6), rgb(6, 17, 97))", //Man of Steel
//     "linear-gradient(0deg, rgb(121, 159, 12), rgb(172, 187, 120))", //Reaqua
//     "linear-gradient(0deg, rgb(123, 67, 151), rgb(220, 36, 48))", //Virgin America
//     "linear-gradient(0deg, rgb(127, 0, 255), rgb(225, 0, 255))", //Purpink
//     "linear-gradient(0deg, rgb(127, 127, 213), rgb(134, 168, 231), rgb(145, 234, 228))", //Azur Lane
//     "linear-gradient(0deg, rgb(128, 0, 128), rgb(255, 192, 203))", //Pink Flavour
//     "linear-gradient(0deg, rgb(128, 128, 128), rgb(63, 173, 168))", //IIIT Delhi
//     "linear-gradient(0deg, rgb(131, 164, 212), rgb(182, 251, 255))", //Friday
//     "linear-gradient(0deg, rgb(131, 58, 180), rgb(253, 29, 29), rgb(252, 176, 69))", //Instagram
//     "linear-gradient(0deg, rgb(131, 77, 155), rgb(208, 78, 214))", //Suzy
//     "linear-gradient(0deg, rgb(131, 96, 195), rgb(46, 191, 145))", //Kye Meh
//     "linear-gradient(0deg, rgb(135, 0, 0), rgb(25, 10, 5))", //The Strain
//     "linear-gradient(0deg, rgb(138, 35, 135), rgb(233, 64, 87), rgb(242, 113, 33))", //Wiretap
//     "linear-gradient(0deg, rgb(142, 14, 0), rgb(31, 28, 24))", //Netflix
//     "linear-gradient(0deg, rgb(142, 158, 171), rgb(238, 242, 243))", //Portrait
//     "linear-gradient(0deg, rgb(142, 45, 226), rgb(74, 0, 224))", //Amin
//     "linear-gradient(0deg, rgb(148, 142, 153), rgb(46, 20, 55))", //Talking To Mice Elf
//     "linear-gradient(0deg, rgb(15, 12, 41), rgb(48, 43, 99), rgb(36, 36, 62))", //Lawrencium
//     "linear-gradient(0deg, rgb(15, 32, 39), rgb(32, 58, 67), rgb(44, 83, 100))", //Moonlit Asteroid
//     "linear-gradient(0deg, rgb(151, 150, 240), rgb(251, 199, 212))", //Anamnisar
//     "linear-gradient(0deg, rgb(156, 236, 251), rgb(101, 199, 247), rgb(0, 82, 212))", //Jodhpur
//     "linear-gradient(0deg, rgb(157, 80, 187), rgb(110, 72, 170))", //Amethyst
//     "linear-gradient(0deg, rgb(16, 141, 199), rgb(239, 142, 56))", //Pun Yeta
//     "linear-gradient(0deg, rgb(161, 255, 206), rgb(250, 255, 209))", //Limeade
//     "linear-gradient(0deg, rgb(167, 112, 239), rgb(207, 139, 243), rgb(253, 185, 155))", //Radar
//     "linear-gradient(0deg, rgb(167, 55, 55), rgb(122, 40, 40))", //Namn
//     "linear-gradient(0deg, rgb(168, 0, 119), rgb(102, 255, 0))", //Shahabi
//     "linear-gradient(0deg, rgb(168, 192, 255), rgb(63, 43, 150))", //Slight Ocean View
//     "linear-gradient(0deg, rgb(168, 255, 120), rgb(120, 255, 214))", //Summer Dog
//     "linear-gradient(0deg, rgb(17, 153, 142), rgb(56, 239, 125))", //Quepal
//     "linear-gradient(0deg, rgb(17, 67, 87), rgb(242, 148, 146))", //Pizelex
//     "linear-gradient(0deg, rgb(170, 255, 169), rgb(17, 255, 189))", //Teal Love
//     "linear-gradient(0deg, rgb(170, 7, 107), rgb(97, 4, 95))", //Aubergine
//     "linear-gradient(0deg, rgb(170, 75, 107), rgb(107, 107, 131), rgb(59, 141, 153))", //Memariani
//     "linear-gradient(0deg, rgb(171, 186, 171), rgb(255, 255, 255))", //Metallic Toad
//     "linear-gradient(0deg, rgb(172, 182, 229), rgb(134, 253, 232))", //Windy
//     "linear-gradient(0deg, rgb(173, 169, 150), rgb(242, 242, 242), rgb(219, 219, 219), rgb(234, 234, ", //Zinc
//     "linear-gradient(0deg, rgb(173, 209, 0), rgb(123, 146, 10))", //Parklife
//     "linear-gradient(0deg, rgb(173, 83, 137), rgb(60, 16, 83))", //eXpresso
//     "linear-gradient(0deg, rgb(178, 254, 250), rgb(14, 210, 247))", //Maldives
//     "linear-gradient(0deg, rgb(178, 69, 146), rgb(241, 95, 121))", //Blush
//     "linear-gradient(0deg, rgb(179, 255, 171), rgb(18, 255, 247))", //Neon Life
//     "linear-gradient(0deg, rgb(18, 194, 233), rgb(196, 113, 237), rgb(246, 79, 89))", //JShine
//     "linear-gradient(0deg, rgb(183, 152, 145), rgb(148, 113, 107))", //Copper
//     "linear-gradient(0deg, rgb(185, 147, 214), rgb(140, 166, 219))", //Dirty Fog
//     "linear-gradient(0deg, rgb(185, 43, 39), rgb(21, 101, 192))", //Evening Sunshine
//     "linear-gradient(0deg, rgb(186, 139, 2), rgb(24, 24, 24))", //Dark Knight
//     "linear-gradient(0deg, rgb(186, 83, 112), rgb(244, 226, 216))", //Purple White
//     "linear-gradient(0deg, rgb(187, 210, 197), rgb(83, 105, 118), rgb(41, 46, 73))", //Royal Blue + Petrol
//     "linear-gradient(0deg, rgb(187, 210, 197), rgb(83, 105, 118))", //Petrol
//     "linear-gradient(0deg, rgb(188, 78, 156), rgb(248, 7, 89))", //Shifter
//     "linear-gradient(0deg, rgb(189, 195, 199), rgb(44, 62, 80))", //50 Shades of Grey
//     "linear-gradient(0deg, rgb(19, 106, 138), rgb(38, 120, 113))", //Turquoise flow
//     "linear-gradient(0deg, rgb(19, 78, 94), rgb(113, 178, 128))", //Moss
//     "linear-gradient(0deg, rgb(190, 147, 197), rgb(123, 198, 204))", //Dania
//     "linear-gradient(0deg, rgb(192, 192, 170), rgb(28, 239, 255))", //Cocoaa Ice
//     "linear-gradient(0deg, rgb(192, 36, 37), rgb(240, 203, 53))", //Back to the Future
//     "linear-gradient(0deg, rgb(192, 57, 43), rgb(142, 68, 173))", //Mello
//     "linear-gradient(0deg, rgb(192, 72, 72), rgb(72, 0, 72))", //Influenza
//     "linear-gradient(0deg, rgb(194, 21, 0), rgb(255, 197, 0))", //Kyoto
//     "linear-gradient(0deg, rgb(194, 229, 156), rgb(100, 179, 244))", //Green and Blue
//     "linear-gradient(0deg, rgb(195, 20, 50), rgb(36, 11, 54))", //Witching Hour
//     "linear-gradient(0deg, rgb(195, 55, 100), rgb(29, 38, 113))", //Celestial
//     "linear-gradient(0deg, rgb(198, 255, 221), rgb(251, 215, 134), rgb(247, 121, 125))", //MegaTron
//     "linear-gradient(0deg, rgb(2, 170, 176), rgb(0, 205, 172))", //Green Beach
//     "linear-gradient(0deg, rgb(20, 136, 204), rgb(43, 50, 178))", //Skyline
//     "linear-gradient(0deg, rgb(20, 30, 48), rgb(36, 59, 85))", //Royal
//     "linear-gradient(0deg, rgb(201, 214, 255), rgb(226, 226, 226))", //Dull
//     "linear-gradient(0deg, rgb(201, 255, 191), rgb(255, 175, 189))", //Virgin
//     "linear-gradient(0deg, rgb(201, 75, 75), rgb(75, 19, 79))", //Bighead
//     "linear-gradient(0deg, rgb(202, 197, 49), rgb(243, 249, 167))", //Sulphur
//     "linear-gradient(0deg, rgb(203, 45, 62), rgb(239, 71, 58))", //Firewatch
//     "linear-gradient(0deg, rgb(203, 53, 107), rgb(189, 63, 50))", //Alive
//     "linear-gradient(0deg, rgb(204, 149, 192), rgb(219, 212, 180), rgb(122, 161, 210))", //Monte Carlo
//     "linear-gradient(0deg, rgb(204, 204, 178), rgb(117, 117, 25))", //Sage Persuasion
//     "linear-gradient(0deg, rgb(204, 43, 94), rgb(117, 58, 136))", //Purple Love
//     "linear-gradient(0deg, rgb(209, 145, 60), rgb(255, 209, 148))", //Koko Caramel
//     "linear-gradient(0deg, rgb(21, 153, 87), rgb(21, 87, 153))", //Crystal Clear
//     "linear-gradient(0deg, rgb(211, 131, 18), rgb(168, 50, 121))", //Crazy Orange I
//     "linear-gradient(0deg, rgb(211, 149, 155), rgb(191, 230, 186))", //Candy
//     "linear-gradient(0deg, rgb(211, 16, 39), rgb(234, 56, 77))", //Playing with Reds
//     "linear-gradient(0deg, rgb(211, 204, 227), rgb(233, 228, 240))", //Delicate
//     "linear-gradient(0deg, rgb(213, 51, 105), rgb(203, 173, 109))", //Blurry Beach
//     "linear-gradient(0deg, rgb(214, 109, 117), rgb(226, 149, 135))", //Sha la la
//     "linear-gradient(0deg, rgb(217, 167, 199), rgb(255, 252, 220))", //Broken Hearts
//     "linear-gradient(0deg, rgb(218, 210, 153), rgb(176, 218, 185))", //Autumn
//     "linear-gradient(0deg, rgb(218, 226, 248), rgb(214, 164, 164))", //Moonrise
//     "linear-gradient(0deg, rgb(218, 34, 255), rgb(151, 51, 238))", //Intuitive Purple
//     "linear-gradient(0deg, rgb(218, 68, 83), rgb(137, 33, 107))", //Vanusa
//     "linear-gradient(0deg, rgb(219, 230, 246), rgb(197, 121, 109))", //Jaipur
//     "linear-gradient(0deg, rgb(22, 160, 133), rgb(244, 208, 63))", //Harmonic Energy
//     "linear-gradient(0deg, rgb(22, 191, 253), rgb(203, 48, 102))", //Transfile
//     "linear-gradient(0deg, rgb(22, 34, 42), rgb(58, 96, 115))", //Mirage
//     "linear-gradient(0deg, rgb(220, 227, 91), rgb(69, 182, 73))", //EasyMed
//     "linear-gradient(0deg, rgb(220, 36, 36), rgb(74, 86, 157))", //Dracula
//     "linear-gradient(0deg, rgb(221, 214, 243), rgb(250, 172, 168))", //Almost
//     "linear-gradient(0deg, rgb(221, 62, 84), rgb(107, 229, 133))", //Kyoo Pal
//     "linear-gradient(0deg, rgb(221, 94, 137), rgb(247, 187, 151))", //Pinky
//     "linear-gradient(0deg, rgb(222, 97, 97), rgb(38, 87, 235))", //Nepal
//     "linear-gradient(0deg, rgb(222, 98, 98), rgb(255, 184, 140))", //A Lost Memory
//     "linear-gradient(0deg, rgb(224, 234, 252), rgb(207, 222, 243))", //Colors Of Sky
//     "linear-gradient(0deg, rgb(225, 238, 195), rgb(240, 80, 83))", //Velvet Sun
//     "linear-gradient(0deg, rgb(228, 58, 21), rgb(230, 82, 69))", //Shrimpy
//     "linear-gradient(0deg, rgb(228, 77, 38), rgb(241, 101, 41))", //Html
//     "linear-gradient(0deg, rgb(229, 45, 39), rgb(179, 18, 23))", //YouTube
//     "linear-gradient(0deg, rgb(229, 57, 53), rgb(227, 93, 91))", //Passion
//     "linear-gradient(0deg, rgb(229, 93, 135), rgb(95, 195, 228))", //Rose Water
//     "linear-gradient(0deg, rgb(230, 218, 218), rgb(39, 64, 70))", //Winter
//     "linear-gradient(0deg, rgb(230, 92, 0), rgb(249, 212, 35))", //Blooker20
//     "linear-gradient(0deg, rgb(232, 203, 192), rgb(99, 111, 164))", //Rose Colored Lenses
//     "linear-gradient(0deg, rgb(233, 100, 67), rgb(144, 78, 149))", //Grapefruit Sunset
//     "linear-gradient(0deg, rgb(233, 211, 98), rgb(51, 51, 51))", //Vasily
//     "linear-gradient(0deg, rgb(234, 205, 163), rgb(214, 174, 123))", //Pale Wood
//     "linear-gradient(0deg, rgb(235, 51, 73), rgb(244, 92, 67))", //Cherry
//     "linear-gradient(0deg, rgb(235, 87, 87), rgb(0, 0, 0))", //Coal
//     "linear-gradient(0deg, rgb(236, 0, 140), rgb(252, 103, 103))", //DIMIGO
//     "linear-gradient(0deg, rgb(236, 111, 102), rgb(243, 161, 131))", //Bourbon
//     "linear-gradient(0deg, rgb(236, 233, 230), rgb(255, 255, 255))", //Clouds
//     "linear-gradient(0deg, rgb(237, 229, 116), rgb(225, 245, 196))", //Sunny Days
//     "linear-gradient(0deg, rgb(237, 33, 58), rgb(147, 41, 30))", //Sin City Red
//     "linear-gradient(0deg, rgb(237, 66, 100), rgb(255, 237, 188))", //Peach
//     "linear-gradient(0deg, rgb(238, 156, 167), rgb(255, 221, 225))", //Piggy Pink
//     "linear-gradient(0deg, rgb(238, 156, 167), rgb(255, 221, 225))", //Piglet
//     "linear-gradient(0deg, rgb(238, 205, 163), rgb(239, 98, 159))", //Tranquil
//     "linear-gradient(0deg, rgb(238, 9, 121), rgb(255, 106, 0))", //Ibiza Sunset
//     "linear-gradient(0deg, rgb(239, 239, 187), rgb(212, 211, 221))", //Sirius Tamed
//     "linear-gradient(0deg, rgb(239, 50, 217), rgb(137, 255, 253))", //Azure Pop
//     "linear-gradient(0deg, rgb(239, 59, 54), rgb(255, 255, 255))", //Compare Now
//     "linear-gradient(0deg, rgb(240, 0, 0), rgb(220, 40, 30))", //Minimal Red
//     "linear-gradient(0deg, rgb(240, 152, 25), rgb(237, 222, 93))", //Mango Pulp
//     "linear-gradient(0deg, rgb(240, 194, 123), rgb(75, 18, 72))", //Starfall
//     "linear-gradient(0deg, rgb(240, 242, 240), rgb(0, 12, 64))", //What lies Beyond
//     "linear-gradient(0deg, rgb(241, 242, 181), rgb(19, 80, 88))", //Fresh Turboscent
//     "linear-gradient(0deg, rgb(241, 39, 17), rgb(245, 175, 25))", //Flare
//     "linear-gradient(0deg, rgb(242, 112, 156), rgb(255, 148, 114))", //Nelson
//     "linear-gradient(0deg, rgb(242, 153, 74), rgb(242, 201, 76))", //Sunkist
//     "linear-gradient(0deg, rgb(243, 144, 79), rgb(59, 67, 113))", //Dawn
//     "linear-gradient(0deg, rgb(244, 107, 69), rgb(238, 168, 73))", //Master Card
//     "linear-gradient(0deg, rgb(244, 196, 243), rgb(252, 103, 250))", //Black Rosé
//     "linear-gradient(0deg, rgb(247, 151, 30), rgb(255, 210, 0))", //Learning and Leading
//     "linear-gradient(0deg, rgb(247, 157, 0), rgb(100, 243, 140))", //Sherbert
//     "linear-gradient(0deg, rgb(247, 248, 248), rgb(172, 187, 120))", //Ver Black
//     "linear-gradient(0deg, rgb(247, 255, 0), rgb(219, 54, 164))", //Alihossein
//     "linear-gradient(0deg, rgb(248, 80, 50), rgb(231, 56, 39))", //Blood Red
//     "linear-gradient(0deg, rgb(248, 87, 166), rgb(255, 88, 88))", //Day Tripper
//     "linear-gradient(0deg, rgb(249, 83, 198), rgb(185, 29, 115))", //Neuromancer
//     "linear-gradient(0deg, rgb(251, 211, 233), rgb(187, 55, 125))", //Cherryblossoms
//     "linear-gradient(0deg, rgb(252, 0, 255), rgb(0, 219, 222))", //Timber
//     "linear-gradient(0deg, rgb(252, 234, 187), rgb(248, 181, 0))", //Sun on the Horizon
//     "linear-gradient(0deg, rgb(252, 53, 76), rgb(10, 191, 188))", //Miaka
//     "linear-gradient(0deg, rgb(252, 70, 107), rgb(63, 94, 251))", //Sublime Vivid
//     "linear-gradient(0deg, rgb(252, 74, 26), rgb(247, 183, 51))", //Orange Fun
//     "linear-gradient(0deg, rgb(252, 92, 125), rgb(106, 130, 251))", //Sublime Light
//     "linear-gradient(0deg, rgb(253, 116, 108), rgb(255, 144, 104))", //Haikus
//     "linear-gradient(0deg, rgb(253, 200, 48), rgb(243, 115, 53))", //Citrus Peel
//     "linear-gradient(0deg, rgb(253, 252, 71), rgb(36, 254, 65))", //Martini
//     "linear-gradient(0deg, rgb(254, 140, 0), rgb(248, 54, 0))", //SoundCloud
//     "linear-gradient(0deg, rgb(254, 172, 94), rgb(199, 121, 208), rgb(75, 192, 200))", //Atlas
//     "linear-gradient(0deg, rgb(255, 0, 132), rgb(51, 0, 27))", //Flickr
//     "linear-gradient(0deg, rgb(255, 0, 153), rgb(73, 50, 64))", //Yoda
//     "linear-gradient(0deg, rgb(255, 0, 204), rgb(51, 51, 153))", //Cosmic Fusion
//     "linear-gradient(0deg, rgb(255, 110, 127), rgb(191, 233, 255))", //Noon to Dusk
//     "linear-gradient(0deg, rgb(255, 126, 95), rgb(254, 180, 123))", //Ed's Sunset Gradient
//     "linear-gradient(0deg, rgb(255, 128, 8), rgb(255, 200, 55))", //Juicy Orange
//     "linear-gradient(0deg, rgb(255, 153, 102), rgb(255, 94, 98))", //Orange Coral
//     "linear-gradient(0deg, rgb(255, 161, 127), rgb(0, 34, 62))", //Predawn
//     "linear-gradient(0deg, rgb(255, 175, 189), rgb(255, 195, 160))", //Roseanna
//     "linear-gradient(0deg, rgb(255, 179, 71), rgb(255, 204, 51))", //Pastel Orange at the Sun
//     "linear-gradient(0deg, rgb(255, 183, 94), rgb(237, 143, 3))", //Light Orange
//     "linear-gradient(0deg, rgb(255, 216, 155), rgb(25, 84, 123))", //Jupiter
//     "linear-gradient(0deg, rgb(255, 224, 0), rgb(121, 159, 12))", //Rea
//     "linear-gradient(0deg, rgb(255, 224, 0), rgb(121, 159, 12))", //Ver
//     "linear-gradient(0deg, rgb(255, 226, 89), rgb(255, 167, 81))", //Mango
//     "linear-gradient(0deg, rgb(255, 238, 238), rgb(221, 239, 187))", //Jonquil
//     "linear-gradient(0deg, rgb(255, 239, 186), rgb(255, 255, 255))", //Margo
//     "linear-gradient(0deg, rgb(255, 251, 213), rgb(178, 10, 44))", //Relaxing red
//     "linear-gradient(0deg, rgb(255, 252, 0), rgb(255, 255, 255))", //Snapchat
//     "linear-gradient(0deg, rgb(255, 65, 108), rgb(255, 75, 43))", //Burning Orange
//     "linear-gradient(0deg, rgb(255, 75, 31), rgb(255, 144, 104))", //Sylvia
//     "linear-gradient(0deg, rgb(255, 75, 31), rgb(31, 221, 255))", //Ali
//     "linear-gradient(0deg, rgb(255, 78, 80), rgb(249, 212, 35))", //Dance To Forget
//     "linear-gradient(0deg, rgb(255, 81, 47), rgb(221, 36, 118))", //Bloody Mary
//     "linear-gradient(0deg, rgb(255, 81, 47), rgb(240, 152, 25))", //Sunrise
//     "linear-gradient(0deg, rgb(255, 95, 109), rgb(255, 195, 113))", //Sweet Morning
//     "linear-gradient(0deg, rgb(26, 41, 128), rgb(38, 208, 206))", //Aqua Marine
//     "linear-gradient(0deg, rgb(26, 42, 108), rgb(178, 31, 31), rgb(253, 187, 45))", //King Yna
//     "linear-gradient(0deg, rgb(28, 146, 210), rgb(242, 252, 254))", //Telegram
//     "linear-gradient(0deg, rgb(28, 216, 210), rgb(147, 237, 199))", //Sea Blizz
//     "linear-gradient(0deg, rgb(29, 151, 108), rgb(147, 249, 185))", //Mojito
//     "linear-gradient(0deg, rgb(29, 43, 100), rgb(248, 205, 218))", //Purple Paradise
//     "linear-gradient(0deg, rgb(29, 67, 80), rgb(164, 57, 49))", //Red Ocean
//     "linear-gradient(0deg, rgb(3, 0, 30), rgb(115, 3, 192), rgb(236, 56, 188), rgb(253, 239, 249))", //Argon
//     "linear-gradient(0deg, rgb(30, 150, 0), rgb(255, 242, 0), rgb(255, 0, 0))", //Rastafari
//     "linear-gradient(0deg, rgb(30, 19, 12), rgb(154, 132, 120))", //Hersheys
//     "linear-gradient(0deg, rgb(30, 60, 114), rgb(42, 82, 152))", //Joomla
//     "linear-gradient(0deg, rgb(31, 162, 255), rgb(18, 216, 250), rgb(166, 255, 203))", //Stripe
//     "linear-gradient(0deg, rgb(31, 28, 44), rgb(146, 141, 171))", //Steel Gray
//     "linear-gradient(0deg, rgb(31, 64, 55), rgb(153, 242, 200))", //Harvey
//     "linear-gradient(0deg, rgb(32, 0, 44), rgb(203, 180, 212))", //Purplepine
//     "linear-gradient(0deg, rgb(32, 1, 34), rgb(111, 0, 0))", //Love and Liberty
//     "linear-gradient(0deg, rgb(33, 147, 176), rgb(109, 213, 237))", //Cool Blues
//     "linear-gradient(0deg, rgb(33, 147, 176), rgb(109, 213, 237))", //Sexy Blue
//     "linear-gradient(0deg, rgb(33, 150, 243), rgb(244, 67, 54))", //Politics
//     "linear-gradient(0deg, rgb(33, 95, 0), rgb(228, 228, 217))", //Misty Meadow
//     "linear-gradient(0deg, rgb(34, 193, 195), rgb(253, 187, 45))", //Summer
//     "linear-gradient(0deg, rgb(35, 37, 38), rgb(65, 67, 69))", //Midnight City
//     "linear-gradient(0deg, rgb(35, 7, 77), rgb(204, 83, 51))", //Taran Tado
//     "linear-gradient(0deg, rgb(36, 198, 220), rgb(81, 74, 157))", //Mantle
//     "linear-gradient(0deg, rgb(40, 48, 72), rgb(133, 147, 152))", //Titanium
//     "linear-gradient(0deg, rgb(40, 60, 134), rgb(69, 162, 71))", //Meridian
//     "linear-gradient(0deg, rgb(41, 128, 185), rgb(109, 213, 250), rgb(255, 255, 255))", //Cool Sky
//     "linear-gradient(0deg, rgb(41, 128, 185), rgb(44, 62, 80))", //Nighthawk
//     "linear-gradient(0deg, rgb(43, 192, 228), rgb(234, 236, 198))", //Bora Bora
//     "linear-gradient(0deg, rgb(43, 88, 118), rgb(78, 67, 118))", //Sea Blue
//     "linear-gradient(0deg, rgb(44, 62, 80), rgb(253, 116, 108))", //Dusk
//     "linear-gradient(0deg, rgb(44, 62, 80), rgb(52, 152, 219))", //Between Night and Day
//     "linear-gradient(0deg, rgb(44, 62, 80), rgb(76, 161, 175))", //Deep Sea Space
//     "linear-gradient(0deg, rgb(47, 115, 54), rgb(170, 58, 56))", //Christmas
//     "linear-gradient(0deg, rgb(48, 232, 191), rgb(255, 130, 53))", //Mini
//     "linear-gradient(0deg, rgb(48, 67, 82), rgb(215, 210, 204))", //Lizard
//     "linear-gradient(0deg, rgb(49, 71, 85), rgb(38, 160, 218))", //Nimvelo
//     "linear-gradient(0deg, rgb(5, 117, 230), rgb(2, 27, 121))", //Very Blue
//     "linear-gradient(0deg, rgb(51, 51, 51), rgb(221, 24, 24))", //Pure Lust
//     "linear-gradient(0deg, rgb(51, 77, 80), rgb(203, 202, 165))", //Anwar
//     "linear-gradient(0deg, rgb(52, 143, 80), rgb(86, 180, 211))", //Emerald Water
//     "linear-gradient(0deg, rgb(52, 148, 230), rgb(236, 110, 173))", //Vice City
//     "linear-gradient(0deg, rgb(52, 232, 158), rgb(15, 52, 67))", //Pacific Dream
//     "linear-gradient(0deg, rgb(53, 92, 125), rgb(108, 91, 123), rgb(192, 108, 132))", //Red Sunset
//     "linear-gradient(0deg, rgb(54, 0, 51), rgb(11, 135, 147))", //Purple Bliss
//     "linear-gradient(0deg, rgb(54, 209, 220), rgb(91, 134, 229))", //Scooter
//     "linear-gradient(0deg, rgb(55, 59, 68), rgb(66, 134, 244))", //Dark Ocean
//     "linear-gradient(0deg, rgb(57, 106, 252), rgb(41, 72, 255))", //Kimoby Is The New Blue
//     "linear-gradient(0deg, rgb(58, 123, 213), rgb(58, 96, 115))", //Solid Vault
//     "linear-gradient(0deg, rgb(58, 28, 113), rgb(215, 109, 119), rgb(255, 175, 123))", //Relay
//     "linear-gradient(0deg, rgb(58, 97, 134), rgb(137, 37, 62))", //Love Couple
//     "linear-gradient(0deg, rgb(6, 190, 182), rgb(72, 177, 191))", //Socialive
//     "linear-gradient(0deg, rgb(60, 165, 92), rgb(181, 172, 73))", //Lemon Twist
//     "linear-gradient(0deg, rgb(60, 59, 63), rgb(96, 92, 60))", //Selenium
//     "linear-gradient(0deg, rgb(61, 126, 170), rgb(255, 228, 122))", //Opa
//     "linear-gradient(0deg, rgb(62, 81, 81), rgb(222, 203, 164))", //Sand to Blue
//     "linear-gradient(0deg, rgb(64, 224, 208), rgb(255, 140, 0), rgb(255, 0, 128))", //Wedding Day Blues
//     "linear-gradient(0deg, rgb(64, 58, 62), rgb(190, 88, 105))", //Poncho
//     "linear-gradient(0deg, rgb(64, 59, 74), rgb(231, 233, 187))", //Frozen
//     "linear-gradient(0deg, rgb(65, 41, 90), rgb(47, 7, 67))", //80's Purple
//     "linear-gradient(0deg, rgb(65, 77, 11), rgb(114, 122, 23))", //Army
//     "linear-gradient(0deg, rgb(66, 39, 90), rgb(115, 75, 109))", //Mauve
//     "linear-gradient(0deg, rgb(67, 198, 172), rgb(248, 255, 174))", //Honey Dew
//     "linear-gradient(0deg, rgb(67, 198, 172), rgb(25, 22, 84))", //The Blue Lagoon
//     "linear-gradient(0deg, rgb(67, 206, 162), rgb(24, 90, 157))", //Endless River
//     "linear-gradient(0deg, rgb(68, 160, 141), rgb(9, 54, 55))", //Orca
//     "linear-gradient(0deg, rgb(69, 104, 220), rgb(176, 106, 179))", //Can You Feel The Love Tonight
//     "linear-gradient(0deg, rgb(69, 127, 202), rgb(86, 145, 200))", //Inbox
//     "linear-gradient(0deg, rgb(7, 101, 133), rgb(255, 255, 255))", //Sky
//     "linear-gradient(0deg, rgb(71, 118, 230), rgb(142, 84, 233))", //Electric Violet
//     "linear-gradient(0deg, rgb(72, 85, 99), rgb(41, 50, 60))", //ServQuick
//     "linear-gradient(0deg, rgb(74, 194, 154), rgb(189, 255, 243))", //Cinnamint
//     "linear-gradient(0deg, rgb(75, 108, 183), rgb(24, 40, 72))", //Pinot Noir
//     "linear-gradient(0deg, rgb(75, 121, 161), rgb(40, 62, 81))", //Dark Skies
//     "linear-gradient(0deg, rgb(76, 161, 175), rgb(196, 224, 229))", //Decent
//     "linear-gradient(0deg, rgb(76, 184, 196), rgb(60, 211, 173))", //Sea Weed
//     "linear-gradient(0deg, rgb(77, 160, 176), rgb(211, 157, 56))", //Miami Dolphins
//     "linear-gradient(0deg, rgb(78, 205, 196), rgb(85, 98, 112))", //Disco
//     "linear-gradient(0deg, rgb(78, 84, 200), rgb(143, 148, 251))", //Moon Purple
//     "linear-gradient(0deg, rgb(8, 80, 120), rgb(133, 216, 206))", //Venice Blue
//     "linear-gradient(0deg, rgb(80, 201, 195), rgb(150, 222, 218))", //Aqualicious
//     "linear-gradient(0deg, rgb(82, 194, 52), rgb(6, 23, 0))", //Behongo
//     "linear-gradient(0deg, rgb(82, 82, 82), rgb(61, 114, 180))", //Curiosity blue
//     "linear-gradient(0deg, rgb(83, 105, 118), rgb(41, 46, 73))", //Royal Blue
//     "linear-gradient(0deg, rgb(84, 51, 255), rgb(32, 189, 255), rgb(165, 254, 203))", //Lunada
//     "linear-gradient(0deg, rgb(84, 74, 125), rgb(255, 212, 82))", //Kyoo Tah
//     "linear-gradient(0deg, rgb(85, 98, 112), rgb(255, 107, 107))", //Cheer Up Emo Kid
//     "linear-gradient(0deg, rgb(86, 171, 47), rgb(168, 224, 99))", //Lush
//     "linear-gradient(0deg, rgb(86, 20, 176), rgb(219, 214, 92))", //Minnesota Vikings
//     "linear-gradient(0deg, rgb(86, 204, 242), rgb(47, 128, 237))", //Blue Skies
//     "linear-gradient(0deg, rgb(89, 193, 115), rgb(161, 127, 224), rgb(93, 38, 193))", //Magic
//     "linear-gradient(0deg, rgb(9, 48, 40), rgb(35, 122, 87))", //Under the Lake
//     "linear-gradient(0deg, rgb(90, 63, 55), rgb(44, 119, 68))", //Forest
//     "linear-gradient(0deg, rgb(92, 37, 141), rgb(67, 137, 162))", //Shroom Haze
//     "linear-gradient(0deg, rgb(93, 65, 87), rgb(168, 202, 186))", //Forever Lost
//     "linear-gradient(0deg, rgb(95, 44, 130), rgb(73, 160, 157))", //Calm Darya
//     "linear-gradient(0deg, rgb(96, 108, 136), rgb(63, 76, 107))", //Ash
//     "linear-gradient(0deg, rgb(96, 56, 19), rgb(178, 159, 148))", //Cool Brown
//     "linear-gradient(0deg, rgb(97, 144, 232), rgb(167, 191, 232))", //Venice
//     "linear-gradient(0deg, rgb(97, 67, 133), rgb(81, 99, 149))", //Kashmir
//     "linear-gradient(0deg, rgb(97, 97, 97), rgb(155, 197, 195))", //Moor
//     "linear-gradient(0deg, rgb(99, 99, 99), rgb(162, 171, 88))", //Shifty
//   ]

  export const colorsLight = ({ isRadial }) => {
    const 
      prefix = isRadial ? "radial-gradient(" : "linear-gradient(0deg, ",   
      colorMap = colors.map(color => `${prefix}${color})`)
    return colorMap
}

export const attrColors = [
  "rgb(239, 239, 187)",
  "rgb(131, 164, 212)",
  "rgb(204, 196, 243)",
  "rgb(217, 167, 199)"
]

const colors = [
  "rgb(251, 223, 132), rgb(255, 239, 186), rgb(251, 223, 132)",
  "#84fab0, #8fd3f4", //"linear-gradient(to right, rgb(168, 255, 120), rgb(120, 255, 214)",
  "rgb(238, 156, 167), rgb(255, 221, 225)", //"linear-gradient(to right, rgb(238, 156, 167), rgb(255, 221, 225)",
  //"linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)",
  "rgb(134, 168, 231), rgb(145, 234, 228), rgb(127, 127, 213)", //Azur Lane
  "#4CCEB2, #2F8FD8",
  // "rgb(127, 127, 213), rgb(134, 168, 231), rgb(145, 234, 228)", //Azur Lane
  // "linear-gradient(to right, rgb(211, 204, 227), rgb(233, 228, 240)",
  "rgb(254, 186, 131), rgb(255, 232, 173)", 
  "rgb(172, 182, 229), rgb(134, 253, 232)", //Windy
  "rgb(178, 254, 250), rgb(14, 210, 247)", //Maldives
  "rgb(237, 66, 100),  rgb(255, 237, 188)", //Peach
  "rgb(116, 235, 213), rgb(172, 182, 229)", //Digital Water
  "rgb(239, 239, 187), rgb(212, 211, 221)", //Sirius Tamed
  "rgb(28, 146, 210), rgb(242, 252, 254)", //Telegram
  "rgb(29, 43, 100), rgb(248, 205, 218)", //Purple Paradise
  "rgb(117, 127, 154), rgb(215, 221, 232)", //Mystic
  "rgb(131, 164, 212), rgb(182, 251, 255)", //Friday
  "rgb(161, 255, 206), rgb(250, 255, 209)", //Limeade
  "rgb(168, 255, 120), rgb(120, 255, 214)", //Summer Dog
  "rgb(255, 175, 189), rgb(255, 195, 160)", //Roseanna
  "rgb(255, 238, 238), rgb(221, 239, 187)", //Jonquil
  "rgb(151, 150, 240), rgb(251, 199, 212)", //Anamnisar
  "rgb(255, 239, 186), rgb(255, 255, 255)", //Margo
  "rgb(43, 192, 228), rgb(234, 236, 198)", //Bora Bora
  "rgb(74, 194, 154), rgb(189, 255, 243)", //Cinnamint
  "rgb(76, 161, 175), rgb(196, 224, 229)", //Decent
  "rgb(204, 196, 243), rgb(252, 133, 250)", //Black Rosé
  "rgb(67, 198, 172), rgb(248, 255, 174)", //Honey Dew
  "rgb(253, 116, 108), rgb(255, 144, 104)", //Haikus
  "rgb(201, 214, 255), rgb(226, 226, 226)", //Dull
  "rgb(0, 180, 219), rgb(0, 131, 176)", //Blue Raspberry
  "rgb(0, 210, 255), rgb(146, 141, 171)", //Bright Vault
  "rgb(100, 145, 115), rgb(219, 213, 164)", //Earthly
  "rgb(142, 158, 171), rgb(238, 242, 243)", //Portrait
  "rgb(186, 83, 112), rgb(244, 226, 216)", //Purple White
  "rgb(192, 192, 170), rgb(28, 239, 255)", //Cocoaa Ice
  "rgb(194, 229, 156), rgb(100, 179, 244)", //Green and Blue
  "rgb(202, 197, 49), rgb(243, 249, 167)", //Sulphur
  "rgb(209, 145, 60), rgb(255, 209, 148)", //Koko Caramel
  "rgb(211, 149, 155), rgb(191, 230, 186)", //Candy
  "rgb(211, 204, 227), rgb(233, 228, 240)", //Delicate
  "rgb(217, 167, 199), rgb(255, 252, 220)", //Broken Hearts
  "rgb(218, 210, 153), rgb(176, 218, 185)", //Autumn
  "rgb(218, 226, 248), rgb(214, 164, 164)", //Moonrise
  "rgb(234, 205, 163), rgb(214, 174, 123)", //Pale Wood
  "rgb(236, 233, 230), rgb(255, 255, 255)", //Clouds
  "rgb(237, 229, 116), rgb(225, 245, 196)", //Sunny Days
  "rgb(41, 128, 185), rgb(109, 213, 250), rgb(255, 255, 255)", //Cool Sky
  "rgb(86, 204, 242), rgb(47, 128, 237)", //Blue Skies
  "rgb(97, 144, 232), rgb(167, 191, 232)", //Venice
  "rgb(253, 200, 48), rgb(243, 115, 53)" //Citrus Peel
]
