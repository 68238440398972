import { apiKeys } from './requestVideos'; 

export const requestPlaylistItemsCollection = async ({ channelName, playlists, playlistsTitles, playlistTitle }) => {

    const 
      playlistIndex = playlistsTitles.indexOf(playlistTitle)
    
    let 
      maxResults = 50,
      part = "snippet",
      apiReady = true,
      playlistItems = {},
      combinedPlaylistItems = {},
      playlistItemsCollection = []

    for (let i = playlistIndex; i < playlistsTitles.length; i++) { 

      let 
        firstLoop = true,
        isMoreData = false,
        nextPageToken = ""

      do {

        if (apiReady) {

          let 
            isFetched = false,
            counter = 0,
            response

          while (!isFetched && counter < apiKeys.length) { 
     
            const 
                playlistId = playlists.find(playlists => playlists.snippet.title === playlistsTitles[i]).id  //playlistsEssentials.find(playlists => playlists.title === playlistsTitles[i]).playListId //"PLui6Eyny-UzwawO75byJ_7Wh3hXXFUczo"
            const 
                requests = firstLoop ?
                  `https://www.googleapis.com/youtube/v3/playlistItems?part=${part},contentDetails&maxResults=${maxResults}&playlistId=${playlistId}&key=${apiKeys[counter]}` :
                  `https://www.googleapis.com/youtube/v3/playlistItems?part=${part},contentDetails&maxResults=${maxResults}&playlistId=${playlistId}&key=${apiKeys[counter]}&pageToken=${nextPageToken}`
                
            response = await fetch(requests)

            if (response.status === 403) {
              apiReady = false
              console.log("api index", counter, "ready:", apiReady, " at ", playlistsTitles[i])
            } else {
              apiReady = true
              isFetched = true
              console.log("isFetched:", isFetched, " via api index:", counter)
            }

            counter++
          }
        
        if (!apiReady) {
          console.log("apiReady:", apiReady, "nextPageToken:", nextPageToken)
          break
        }
        
        let playlistItems = await response.json()

        combinedPlaylistItems = isMoreData ?
          {...combinedPlaylistItems, ...combinedPlaylistItems.items.push(...playlistItems.items)}
          : playlistItems

        isMoreData = playlistItems.hasOwnProperty("nextPageToken")
        nextPageToken = isMoreData ? 
          playlistItems.nextPageToken : ""
        
        firstLoop = false
        }
      }
      while (isMoreData || firstLoop)

      playlistItems = combinedPlaylistItems
      console.log("playlistitems response at ", channelName, " index ", i,  " is ", playlistItems)
      playlistItemsCollection.push(playlistItems)
    }
      
    console.log("request playlist-items-collection at ", channelName, " returns ", playlistItemsCollection )
    return playlistItemsCollection
}