//https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCX32D3gKXENrhOXdZjWWtMA&maxResults=2&order=date&type=video&key=[YOUR_API_KEY]
//https://youtube.googleapis.com/youtube/v3/videos?part=statistics&id=tIJrHBIUkYM&id=tIJrHBIUkYM&key=[YOUR_API_KEY] ///+ contentdetails?? => https://developers.google.com/youtube/v3/docs/videos#resource 

export const apiKeys =
[
	"AIzaSyDVRyd2eZzXy8sgPAlxGAg1tIS5Me636ig",
	"AIzaSyAy1OfrBnjSMn0V5ENtYtBAqtIQMx0N5Dc" ///yoga curated api
]

export const requestYouTube = async({ resource, channelName, channelId, requestAll }) => {

    let parameters = {
        videos: {
            part: "snippet",
            type: "video", ///note: or playlist
            order: "date",
            maxResults: requestAll ? 50 : 5,
        },
        playlists: {
            part: "snippet",
            type: "", ///note: not used
            order: "date",
            maxResults: requestAll ? 50 : 3,
        }
    }

    const { part, type, order, maxResults } = parameters[resource]

    let 
		apiReady = true,
		firstLoop = true,
		isMoreData = false,
		nextPageToken = "",
        combinedResponseData = {}

    do {
        if (apiReady) {

            let 
				isFetched = false,
            	counter = 0,
                response
				
        		while (!isFetched && counter < apiKeys.length) { 

                    let requestUrl = {
                        videos: `https://youtube.googleapis.com/youtube/v3/search?part=${part}&channelId=${channelId}&maxResults=${maxResults}&order=${order}&type=${type}&key=${apiKeys[counter]}`,
                        playlists: `https://www.googleapis.com/youtube/v3/playlists?part=${part},contentDetails&channelId=${channelId}&maxResults=${maxResults}&order=${order}&key=${apiKeys[counter]}`
                    }

        			const requests = firstLoop ?
                        requestUrl[resource] :
						`${requestUrl[resource]}&pageToken=${nextPageToken}`
        
					console.log("attempt fetch with api key index:", counter)
        			
					response = await fetch(requests)

					if (response.status === 403) {
						apiReady = false
						console.log("api index", counter, "ready:", apiReady)
					} else {
						apiReady = true
						isFetched = true
						console.log("isFetched:", isFetched, ", via api index:", counter)
					}
        			counter++
        		}

		if (!apiReady) {
			console.log("apiReady:", apiReady, "nextPageToken:", nextPageToken)
			break
		}

        let responseData = await response.json()
        combinedResponseData = isMoreData ?
          {...combinedResponseData, ...combinedResponseData.items.push(...responseData.items)}
          : responseData

        isMoreData = responseData.hasOwnProperty("nextPageToken")
        nextPageToken = isMoreData ? 
            responseData.nextPageToken : ""
        
        firstLoop = false
        }
	}
	while ((isMoreData || firstLoop) && requestAll)

	const returnData = combinedResponseData

	console.log("request ",  type, " at ", channelName, " returns ", returnData, " with items ", returnData.items)
	return returnData
}