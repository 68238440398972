import React from 'react';
import { Home } from './Home2';
import UploadNewPrograms from './UploadNewPrograms';
import UploadNewVideoCollection from './UploadNewVideoCollection';
import {   
  //BrowserRouter as Router,
  Switch,
  Route } from "react-router-dom";

// export default function App() {
const App = () => {
  // let { path, url } = useRouteMatch();

    return ( ///note: Home placed outside Switch to mitigate Home aqquires prop location => would cause it to rerender
      <div>
        <Home/> 
        <Switch>
          <Route 
            exact={true} 
            path="/uploadnewprograms" 
            component={UploadNewPrograms}
            //render={(props) => <UploadNewPrograms/>} 
            />
          {/* <Route 
            exact={false} 
            path="/" 
            component={Home}
            //render={(props) => <Home {...props}/>}
         
            //{path} //"/" 
            //render={(props) => <Home {...props}/>} 
            // render={(props) => <Home {...props}/>}
             /> */}
            {/* <Route 
            exact={true} 
            path="/uploadnewvideocollection" 
            // component={Home}
            render={(props) => <UploadNewVideoCollection {...props}/>} 
            /> */}
            {/* <Route 
              exact={true} 
              path="/:category" 
              render={(props) => <Home {...props}/>}
              //render={(props) => <Home {...props}/>} 
              // params={key: 'foo'}
            />
            <Route 
            exact={true} 
            path="/:programCollectionId/:seasonepisode" 
            render={(props) => <Home {...props}/>}
            // component={Home}
            // render={(props) => <Home2 {...props}/>} 
            />  */}
            {/* <Route
              exact={false}
              path="/*" 
              //component={Home} 
              render={(props) => <Home />}
            />
             
            
            {/* <Route path="/" component={Home} /> */}
            {/* <Route path="/category" component={Category} />
            <Route path="/products" component={Products} /> */}

        {/* <Route path="/" 
            //{path} //"/" 
            component={Home} 
            // render={(props) => <Home {...props}/>}
             /> */}
        </Switch>
      </div>
    );
}

export default App;

///prerender:
        // "/",
        // "/ashtanga", 
        // "/vinyasa", 
        // "/hatha", 
        // "/yin", 
        // "/iyengar", 
        // "/kundalini", 
        // "/hot", 
        // "/aerial", 
        // "/prenatal", 
        // "/power", 
        // "/acro",
        // "/yoga with adriene", 
        // "/psychetruth", 
        // "/boho beautiful yoga", 
        // "/yoga with kassandra", 
        // "/sarahbethyoga", 
        // "/abiola akanni", 
        // "/move with nicole", 
        // "/shilpa's yoga", 
        // "/kinoyoga", 
        // "/brettlarkinyoga", 
        // "/breatheandflow", 
        // "/ventuno yoga", 
        // "/yoga with bird", 
        // "/jessica richburg", 
        // "/tara stiles - yoga that feels like you", 
        // "/candace cabrera tavino", 
        // "/yoga with tim", 
        // "/yoginimelbourne", 
        // "/ekhartyoga", 
        // "/youaligned", 
        // "/travis eliot", 
        // "/the bare female", 
        // "/live fertile", 
        // "/yoga with roberta", 
        // "/yoga with zelinda", 
        // "/ann swanson wellness", 
        // "/faith hunter", 
        // "/sarah wes",
        // "/arianna elizabeth",
        // "/seanviguefitness",
        // "/yoga practice videos - yoga vidya",
        // "/body positive yoga",
        // "/geethanjali - yoga",
        // "/suzie ray",
        // "/marlingyoga",
        // "/yoga with amit",
        // "/purple valley ashtanga yoga"