
import '../src/list.css';
import update from 'immutability-helper'
import { memo, useCallback, useState, useEffect } from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { Box } from './Box.js'
import { Dustbin } from './Dustbin.js'
import { Card } from './Card.js'
import { ItemTypes } from './ItemTypes.js'
import * as generics from './generics';
import { usePrevious } from './StateOptions';

export const Container = memo(function Container({ dataCallback }) {

  const dustbin = {
    accepts: [ItemTypes.CATEGORY, ItemTypes.PL, ItemTypes.VIDEO, NativeTypes.FILE],
    lastDroppedItem: null,
  }

  const 
    [dustbins, setDustbins] = useState([dustbin])
    // [diamontList, setDiamontList] = useState([])

  const [isDragging, setIsDragging] = useState(false) ///note: boolean at card
  const prevIsDragging = usePrevious(isDragging)
  const [boxes] = useState([
    { item: 'Bottle', type: ItemTypes.CATEGORY },
    { item: 'Banana', type: ItemTypes.CATEGORY },
    { item: 'Magazine', type: ItemTypes.CATEGORY },
  ])
  // const [droppedBoxNames, setDroppedBoxNames] = useState([])
  // function isDropped(boxName) {
  //   return droppedBoxNames.indexOf(boxName) > -1
  // }
  const handleDrop = useCallback(
    (index, item) => {
      // const { item } = item
      // setDroppedBoxNames(
      //   update(droppedBoxNames, item ? { $push: [item] } : { $push: [] }),
      // )
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item,
            },
            // id: {
            //   $set: index,
            // },
          },
        }),
      )
    },
    [dustbins], ///droppedBoxNames
  )

  const handleExclude = ({ index }) => {
    setDustbins(
      update(dustbins, 
        { $splice: [[index, 1]] } 
        )
     )
  }

  const handleDropzones = ({ isSortEnd }) => {

    if (!isDragging) { ///only insert dropzones if isDragging (card) false

      let dustbins_ = []

      dustbins.map((dustbin_, i) => {

        if (
          !dustbin_.lastDroppedItem && ///if no content
          i + 1 <= dustbins.length - 1 && ///and if next not last
          !dustbins[i + 1]?.lastDroppedItem ///and if next no context
          ) {
          return dustbins_ /// => nothing (dont add)
        }
        if (
          !!dustbin_.lastDroppedItem && ///if content
          i === 0 && i === dustbins.length - 1 ///and first and last
          ) {
          return dustbins_.push(dustbin, dustbin_, dustbin); /// => add dropzone before
        }
        if (
          !!dustbin_.lastDroppedItem && ///if content
          i === 0 && ///and first
          !!dustbins[i + 1]?.lastDroppedItem ///and if next content
          ) { 
          return dustbins_.push(dustbin, dustbin_, dustbin); /// => add dropzone before
        }
        if (!dustbin_.lastDroppedItem) { ///if empty 
          return dustbins_.push(dustbin_); /// => keep dropzone
        }
        if (
          !!dustbin_.lastDroppedItem && ///if content 
          !!dustbins[i + 1]?.lastDroppedItem ///and if next content
          ) {
          return dustbins_.push(dustbin_, dustbin); /// => keep content and add dropzone after
        }
        if (
          !!dustbin_.lastDroppedItem && ///if content 
          i !== dustbins.length - 1 && ///and if not last
          !dustbins[i + 1]?.lastDroppedItem ///and if next empty

          ) {
          return dustbins_.push(dustbin_); /// => keep content
        }
        if (
          !!dustbin_.lastDroppedItem && ///if content 
          i === dustbins.length - 1 ///and if last
          ) {
          return dustbins_.push(dustbin_, dustbin); /// => keek content and and add dropzone after
        }
      })

      if (
          !generics.isEqualArrays(dustbins, dustbins_) || ///if new item added
          isSortEnd && !isDragging ///or after (potentially) sorting ///note: isSortEnd included to mitigate infinite loop
          ) { ///note: only set if not equal (mitigate infinite loop)
        setDustbins(dustbins_)
      }
    }
  }

  useEffect(() => { 
    console.log("dustbins", dustbins)
    handleDropzones({ isSortEnd: false })
    prepareDiamontList({})
  }, [dustbins])

  useEffect(() => {
    if (!isDragging) {
      handleDropzones({ isSortEnd: true })
    }
  }, [isDragging])

  const prepareDiamontList = ({}) => { 
    const data = dustbins
      .filter(dustbin => !!dustbin.lastDroppedItem)
      .map(dustbin => dustbin.lastDroppedItem.draggedItem)
    dataCallback( data )
  }

  //   const initialCards = dustbins.map((dustbin, i) => { 
  //     return {
  //       id: i,
  //       text: 'Write a cool JS library',
  //     }
  // } )

  //console.log("initialCards", initialCards)

  // const [cards, setCards] = useState(initialCards)

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    console.log("no drop move")
    setDustbins((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])
  // const renderCard = useCallback((card, index) => {
  //   return (
  //     <Card
  //       key={card.id}
  //       index={index}
  //       id={card.id}
  //       text={card.text}
  //       moveCard={moveCard}
  //     />
  //   )
  // }, [])

  // const ConditionalCard = memo(({ condition, index, children }) => {
  //   return condition ? 
  //   <Card 
  //     key={index} //`${lastDroppedItem?.draggedItem?.["category name id"]}-${index}`}
  //     index={index}
  //     id={index}
  //     text={""}
  //     moveCard={moveCard}
  //     isDraggingCallback={(isDragging) => setIsDragging(isDragging)}
  //   >
  //     { children }
  //   </Card> : <>{ children }</>
  // })

  return (
    <div>
      <div className={`
      list-container
      ${dustbins.length === 1 && !dustbins.lastDroppedItem ? "dropzone-only" : ""}
      `
    }>
        {dustbins.map(({ accepts, lastDroppedItem }, index) => (
          <Card
            key={index} //`${lastDroppedItem?.draggedItem?.["category name id"]}-${index}`}
            index={index}
            id={index}
            text={""}
            moveCard={moveCard}
            isDraggingCallback={setIsDragging}
            noDropCallback={handleExclude}
          > 
            <Dustbin
              isShow={!isDragging && !lastDroppedItem}
              accept={accepts}
              lastDroppedItem={lastDroppedItem}
              onDrop={(item) => handleDrop(index, item)}
              key={index}
            />
          </Card>
        ))}
      </div>

      {/* <div style={{ overflow: 'hidden', clear: 'both' }}>
        {boxes.map(({ item, type }, index) => (
          <Box
            item={item}
            type={type}
            isDropped={isDropped(item)}
            key={index}
          />
        ))}
      </div> */}
    </div>
  )
})



// import { Box } from './Box.js'
// import { Dustbin } from './Dustbin.js'
// export const Container = () => (
//   <div style={{ zIndex: 100000000000000 }}>
//     <div style={{ overflow: 'hidden', clear: 'both' }}>
//       <Dustbin allowedDropEffect="copy" />
//       {/* <Dustbin allowedDropEffect="copy" />
//       <Dustbin allowedDropEffect="move" /> */}
//     </div>
//     <div style={{ overflow: 'hidden', clear: 'both' }}>
//       <Box item="Glass" />
//       <Box item="Banana" />
//       <Box item="Paper" />
//     </div>
//   </div>
// )
