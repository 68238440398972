import { db, storageRef } from './Fire'; 
import { collection, addDoc } from 'firebase/firestore';
import { serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, uploadBytes, getDownloadURL } from "firebase/storage";

export const prepareImage = async(file) => {
    //const blob = await fetch(file).then(resp=>resp.blob()) //optional: Convert to blob

    // Create the file metadata
    /** @type {any} */
    const metadata = {
      contentType: 'image/jpeg'
    };

    return (metadata)
  } 

export const writeImage = async(file, metadata, activeTemplate) => {
    const promise = new Promise((resolve, reject) => {
    // Upload file and metadata to the object 'images/mountains.jpg'
        const imageStorageRef = ref(storageRef, activeTemplate + '/' + file.name);
        const uploadTask = uploadBytesResumable(imageStorageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
            (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                console.log('Upload is paused');
                break;
                case 'running':
                console.log('Upload is running');
                break;
            }
            }, 
            (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
                case 'storage/canceled':
                // User canceled the upload
                break;

                // ...

                case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
            }, 
            () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log('File available at', downloadURL);
                resolve(downloadURL)
            });
            }
        );
  	})
		return promise
}

export const WriteArtwork = async(newArtwork, activeTemplate) => {
    const promise = new Promise(async(resolve, reject) => {
        const timeStamp = serverTimestamp()
        let newArtworkMeta = {
            created: timeStamp,
            sortId: timeStamp, 
            template: activeTemplate
        }
        let galleryItem = {...newArtwork, ...newArtworkMeta }
        try {
            const docRef = await addDoc(collection(db, activeTemplate), galleryItem);
            console.log("Document written with ID: ", docRef.id);
            resolve()
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    })
    return promise
}
