import { apiKeys } from './requestVideos';

export const requestVideo = async ({ videoId }) => {
    const 
        part = "part=snippet&part=statistics&part=status&part=contentDetails",
        apiKey = "AIzaSyDVRyd2eZzXy8sgPAlxGAg1tIS5Me636ig" //apiKeys[0]
    const 
        requests = `https://www.googleapis.com/youtube/v3/videos?${part}&id=${videoId}&key=${apiKey}`,
        response = await fetch(requests),
        video = await response.json()
    console.log("request video response is ", video)
    console.log("highres thumbnail :", video.items[0].snippet.thumbnails.maxres.url)
    return video
}