
import React, { useRef, useEffect, memo, useContext, useCallback, createContext as reactCreateContext, useContext as reactUseContext, Fragment, useState, useMemo, Dispatch, SetStateAction } from 'react';
import { GridColums, Symbol } from './StandardComponents';
import produce from "immer"
import * as generics from './generics';
import '../src/modal.css';
import { Auth } from './Auth';
///import { templates } from './ItemForm';
import { NewArtwork } from './NewArtwork';
import { MultiOptions } from './MultiOptions';
import { usePrevious } from './StateOptions';
import { templatesContext } from './DataProvider';
import FilterBox from './FilterBox';

///export const showContext = reactCreateContext([,() => {}])

const Controls = ({ parentCallback, showControl }) => { ///classNameModalControls
  const symbols = ["minus", "check", "times"] 
  return (
    <div className={`modal-controls-wrapper`}> 
      <GridColums ///${classNameModalControls}
        className={`modal-controls`}
        gridTemplateColumns={`repeat(3, 1fr)`} 
      >
        {symbols.map((symbol) => 
          <Symbol
            key={symbol}
            symbol={symbol}
            showControl={showControl}
            parentCallback={() => parentCallback({ symbol })}
          ></Symbol>
        )}
      </GridColums>
    </div>
  )
}

// const Modal = ( { theme, modalMinWidth, show, children, parentCallback, classNameModalControls, showControl }) => { ///not used: classNameModalControls
//   //const [auth, setAuth] = useState({show: false, modalChild: null }), ///optimise: probably rename variable ..
//   console.log("modal rerender")

//   return (
//     <dialog className={`
//       modal-wrapper
//       ${show ? "fade-in" : "fade-out"}
//     `}
//     >
//       <div className={`modal
//       input-radius input-border-default
//       ${show ? "show slide-up-into-place" : "hide slide-down-out-of-place"}
//       `}
//       style={{minWidth: modalMinWidth}}
//       >
//         <Controls
//           classNameModalControls={classNameModalControls} 
//           showControl={showControl}
//           parentCallback={parentCallback}
//         />
//         { children } 
//       </div>
//     </dialog>
//   );
// }
// const MemoModal = memo(Modal)

export const Modal = ({ children, templatesData, setTemplatesDataCallback }) => {

  ///ref
  const modalRef = useRef(null)

  const { templates } = useContext(templatesContext)

  const initialValueModal = {  ///optimise: move individual values to specific components? 
      action: "new", ///note: state "new" or "update" used for modalOption "multiOptions" ///note: currently a default value have to be set initially, and "new" is chosen
      isShow: false, 
      modalChildName: null, 
      symbol: null, 
      selectedData: null,
      isAcceptEnabl: false,
      isClearAllEnabl: false,
  } 

  const [modal, setModal] = useState(initialValueModal) ///consider: to rename to modal or similar

  useEffect(() => {
    if (modal.modalChildName === "multiOptions") {
      const 
        isAcceptEnabl = !generics.arrayOfObjectsIsEqual(modal.selectedData, templatesData[modal.action].values[templatesData[modal.action].activeTemplate][templatesData[modal.action].label]),
        isClearAllEnabl = generics.isArrayLengthAbove(modal.selectedData, 0)
        handleSetModal({ isAcceptEnabl: isAcceptEnabl, isClearAllEnabl: isClearAllEnabl })
    }
  }, [modal.modalChildName, modal.selectedData])

    ///options for modal
    const modalOptions = {
      null: null,
      new: <NewArtwork templatesData={templatesData} setTemplatesDataCallback={(templatesParam_) => setTemplatesDataCallback({ ...templatesParam_, action: "new" })} />, ///note: all setstate from modalOption "new" has action: "new", thus action defined once and for all here
      multiOptions: <MultiOptions action={modal.action} templatesData={templatesData} symbol={modal.symbol} selectedDataCallback={({ data }) => handleSetModal({ selectedData: data })} setTemplatesDataCallback={setTemplatesDataCallback} />, ///note: setstate from modalOption "multiOptions" has either action "new" or "update", thus action defined upstream (in contrast to modalOption "new")
      signUp: <Auth modalChild={"signUp"}/>,
      signIn: <Auth modalChild={"signIn"}/>,
      signOut: <Auth modalChild={"signOut"}/>,
      profile: <Auth modalChild={"profile"} setModalCallback={() => handleSetModal(initialValueModal)} />,
      filterBox: <FilterBox templatesData={templatesData}/>,
      // signIn: <Auth modalChild={"signIn"}/>
    }

    const showModalControls = { ///optimise: move values to specific component
      null: null,
      new: {
        minus: false,
        check: false,
        times: true,
      },
      signUp: {
        minus: false,
        check: false,
        times: true,
      },
      signIn: {
        minus: false,
        check: false,
        times: true,
      },
      signOut: {
        minus: false,
        check: false,
        times: true,
      },
      profile: {
        minus: false,
        check: false,
        times: true,
      },
      multiOptions: {
        minus: true && modal.isClearAllEnabl,
        check: true && modal.isAcceptEnabl,
        times: true,
      },
      filterBox: {
        minus: true,
        check: true,
        times: true,
      },
    }

    useEffect(() => {
      const actionModal = modal.symbol
      //if (modal.modalChildName.includes("sign") || modal.modalChildName === "profile") { ///note: signup/signin
        switch (actionModal) { 
          case "times": ///note: notice fall-through
            //setTemplatesDataCallback({label: ""})
          case "check":
              if (modal.modalChildName === "multiOptions" && modal.action === "new" ) { ///if multiOptions and action new => set modalOptions as "new" (user experience: "go back" from modalOptions to "new")
                return handleSetModal({ modalChildName: modal.action, isShow: true, symbol: null })
              } else {
                handleSetModal(initialValueModal); ///reset/close
              }
            break;
          case "minus":
            break;
          default:
            break;
       // };
        //setModal(initialValueShow); ///reset/close 
        }
        
    }, [modal.symbol]);

    const handleSetModal = ( modalParam ) => {
      setModal(
        produce((draft) => { 
            Object.entries(modalParam).map(
              ([k, v], i) => draft[k] = v )
          })
      )
    }

    const getModalWidth = ({ modalChildName }) => { ///note: implemented to control min-width of modal at all times, i.e. to mitigate => close modal event => removal of child => close modal animation with no child => modal width zero at animation
      let width
      switch (true) {
        case modalChildName === "signUp":
          width = "50%";
          break;
        case modalChildName === "signIn":
          width = "40%";
          break;
        case modalChildName === "signOut":
          width = "50%";
          break
        case modalChildName === "profile":
          width = "50%";
          break
        case modalChildName === "new":
          width = "70%";
          break
        default:
          width = "70%";
        break;
      };
      return width
    }

    ///isUseModal => open modal
    useEffect(() => {
      if (templatesData.new.isUseModal) {
        openModal({ action: "new" })
      }
    }, [templatesData.new.isUseModal]); 

    useEffect(() => {
      if (templatesData.update.isUseModal) {
        openModal({ action: "update" })
      }
    }, [templatesData.update.isUseModal]); 

    const openModal = ({ action }) => {
      if (templatesData[action].isUseModal) {  
        if (templates[templatesData[action].activeTemplate][templatesData[action].label].type === "multiOptions") ///optimise: combine if ..
          {
          handleSetModal({ modalChildName: "multiOptions", isShow: true, action: action })
          }
        if (
          templates[templatesData[action].activeTemplate][templatesData[action].label].type === "textualObjects" &&
          generics.arrayEmpty(templates[templatesData[action].activeTemplate][templatesData[action].label].value) ///note: if options not predefined
          ) 
          {
          handleSetModal({ modalChildName: "multiOptions", isShow: true, action: action })
        }
        setTemplatesDataCallback({ action, isUseModal: false })
    }
    }

    ///toogle body scroll on modal open/close
    useEffect(() => {
      modal.isShow ? 
        document.body.classList.add('modal-open') : 
        document.body.classList.remove('modal-open')
    }, [modal.isShow]);

    useEffect(() => { ///note: scroll to top of modal at modalChildName change
      modalRef.current.scrollTo({
        top: 0,
        behavior: 'smooth', 
      });
    }, [modal.modalChildName]);

    const
      modalWidth = getModalWidth({ modalChildName: modal.modalChildName }),
      memoChildren = useMemo(() =>  modalOptions[modal.modalChildName], [modal, templatesData, templatesData.new.activeTemplate, templatesData.new.values]), ///note: templatesData.new.activeTemplate added to ensure update of UI at template change. note: templatesData.new.values added to ensure update of UI at values change
      memoShowControl = useMemo(() =>  !!modal.modalChildName && showModalControls[modal.modalChildName], [modal.modalChildName, modal.isAcceptEnabl, modal.isClearAllEnabl]  )
 
    return (
      // <showContext.Provider value={modalValue}>
      <>
        <dialog className={`
          modal-wrapper
          ${modal.isShow ? "fade-in" : "fade-out"}
        `}
        >
          <div className={`modal
            input-radius input-border-default
            ${modal.isShow ? "show slide-up-into-place" : "hide slide-down-out-of-place"}
            `}
            style={{width: modalWidth}}
            ref={modalRef}
          >
            <Controls
              //classNameModalControls={`modal-controls-wrapper-${show.modal.modalChildName}`} 
              showControl={memoShowControl}
              parentCallback={({ symbol }) => handleSetModal({ modalChildName: modal.modalChildName, isShow: true, symbol: symbol })}
            />
              { memoChildren }
          </div>
        </dialog>
          { children(modal, setModal) } 
      </>
    )
}


          {/* <MemoModal
            modalMinWidth={modalMinWidth}
          //classNameModalControls={`modal-controls-wrapper-${show.modal.modalChildName}`} ///not used
            show={modal.isShow}
            showControl={memoShowControl} //!!modal.modalChildName && generics.getValueFromKey(showModalControls, modal.modalChildName)}
            children={memoChildren}
            parentCallback={memoHandleModal} ///optimise: current choice: modal controls is reused. consider to move each control and action into specific componets (using the modal)
        /> {children(modal, setModal)}
        </> 
    //  </showContext.Provider>*/}
