import './diamont-list.css';
import './styling-standards.css';
import { useState, useEffect } from 'react';
import { Wrapper } from './StandardComponents';
import { Dustbin } from './Dustbin.js'
import { Container } from './Container.js'
import { Button } from './StandardComponents';

export const DiamontList = ( props ) => {

  const [data, setData] = useState({})

  console.log("hhh", data)
	
  const name = "diamont-list"

  const saveButton = 
  <Button
    show={true}
    wrapperClassName={``}
    classTypes={["classical", "min"]}
    className={`
      more
      input-padding-3
    `}
    wrapperStyle={{margin: "auto"}}
    width={"auto"}
    theme={4}
    height={"70%"}
    fontSize={1}
    parentCallback={() => props.parentCallback({ 
      action: "new", ///note: for setTemplatesData
      label: "data", ///note: for setTemplatesData
      value: data, ///note: for setTemplatesData
      template: "diamont-list", ///note: for setTemplatesData
      modalChildName: "new", ///note: for setModal
      isShow: true } ///note: for setModal
      )}
    >{"Save"}
  </Button> 

  return (
    <div className={`${name}-container`}>
      <div className={`${name}`}>
      {saveButton}
       <Container
          dataCallback={( data ) => setData( data )}
       ></Container>
        {/* <div style={{ overflow: 'hidden', clear: 'both' }}>
          <Dustbin allowedDropEffect="any" />
          <Dustbin allowedDropEffect="copy" />
          <Dustbin allowedDropEffect="move" />
        </div> */}
      </div>
    </div>
  );
}