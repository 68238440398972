
import './gallery.css';
import './item-text.css';
import { useState, Fragment, useEffect, createRef, useContext, useRef, useCallback } from 'react';
import * as generics from './generics';
///import { UserPresent }from './Generics'; ///reduced away
import { themeContext, itemsContext, refineContext, scrollToContext, showContext, templatesDataContext } from './App';
///import { ConditionalLinkWrapper, WithLink } from './Links'; ///reduced away (moved here)
import ContentEditable from 'react-contenteditable'
///import { artistData } from './artist_data'; ///reduced away
///import * as Gallery_r from './Gallery_r'; ///reduced away
import { GridColums, Button, Wrapper, ButtonWithLogo, ConditionalElementWrapper, SvgComponent } from './StandardComponents';
import { MultiStateButton } from './MultiStateButton';
import { weekdays, hoursTypes, OpeningHours, TextualArray, TextualObjects, templates, templatesNames, templatesShortcut, templatesSetup, options, handleContentEditableChange, multiOptionsValues, multiStateButtons, multiOptions, textualContent } from './ItemForm'; ///reduced away: had contentEditableKeyDown, contentRestrictions, contentEditablePaste
import produce from "immer";
///import { unitLengthIndexContext, unitLength } from './Gallery';
///import { MultiOptionButton } from './MultiOptions'; ///reduced away
//import { scrollToName } from './Scroll'; ///reduced away (moved to this jsx)
//import { DateTime, Interval } from "luxon"; 
import * as icons from './Icons';
import { Outlet, Routes, Route, Link, NavLink, useSearchParams, useLocation, useNavigate} from 'react-router-dom'; //v6 ///included due to reduced away, ie. ConditionalLinkWrapper moved here
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll' ///included due to reduced away, i.e. scrolltoname included here
import { templatesContext } from './DataProvider';

const contentRestrictions = (input, label) => { ///note: input is e or text from paste
  const keydown = input.type === "keydown"
  ///test
  if (keydown && !generics.isAllowedGeneral(input)) { ///general restrictions 
    return 
  }
  ///test
  let restrictions = { ///specific restrictions
    dimensions: keydown ? generics.inputKeyIsNumber(input) : generics.isNumber(input),
    price: keydown ? generics.inputKeyIsNumber(input) : generics.isNumber(input),
  }
  return generics.keyExist(restrictions, label) ? restrictions[label] : true ///optimise: consider other structure... 
}

const contentEditablePaste = (e, label, currentRef, refIndex, anchorOffset) => {
  e.preventDefault()
  const pastedText = generics.getPasteText(e).trim() ///get pasted text
  const pastedTextToNumber = parseInt(pastedText) ///convert to number or "nan" ///optimise: consider to allow/implement floating points
  const pastedTextModified = generics.isNumber(pastedTextToNumber) ?  ///if pasted is number (can be sucessfully converted to a integer)
    pastedTextToNumber : ///number
    pastedText ///text
  const contentAllowed = contentRestrictions(pastedTextModified, label) ///check if pasted text (modified to number if relevant) is allowed
  const existingText = currentRef[refIndex].innerText ///get existing text in field
  const combinedText = generics.isNumber(pastedTextToNumber) ? ///if pasted is number (can be sucessfully converted to a integer)
    generics.addStr(existingText, anchorOffset, pastedText, "").trim() : ///premise: is number, and must return a integer (with no spaces)
    generics.addStr(existingText, anchorOffset, pastedText, " "); ///optimise: at end of text + a space + paste => content inserted with an additional space => consdier to mitigate
  const emptyString = ""
  if (!contentAllowed) { return emptyString }
  const result = existingText === "" ? pastedText : combinedText ///return pasted text or combined string (including use of cursur position at paste)
  return result
}
///on key down =>
const contentEditableKeyDown = (e, label, currentRefs, refIndex, gotoNext) => { ///optimise: consider to move all contentediable functions to generics or to a seperate file
  if (
    e.key === "Enter" ||
    e.key === "Tab" 
    ) {
      e.preventDefault()
      currentRefs[refIndex].blur(); ///blur
      if (gotoNext) {
        setTimeout(function() { ///optimise:risk: does not work properly. null ref are created continlously on render. impacts this function. prevent how?
            const focusIndex = refIndex <= currentRefs.length - 1 ? ///next ref => circle refs, plus mitigate focus on non-existing ref/dom element
              refIndex + 1 : 
              currentRefs.length
            currentRefs[focusIndex].focus() ///note: focus (next) ///bug/optimise: on copy into video, every tab/enter in other fields focus video. why? mitigate? hint: its indendent on this codeblock...
        }, 100);
      }
      return
    }
  const contentAllowed = contentRestrictions(e, label)
  if (!contentAllowed) { e.preventDefault() }
}

export const WithLink = ({ underline, to, text, preTree, state, children }) => { //////included due to ConditionalLinkWrapper moved here
	to = !generics.stringUndefined(preTree) ? `${preTree}/${to}` : to
	return (
		<nav>
			<Link
				to={generics.stringToUrl(to)} //.toLowerCase()}
				className={`
					link
				`}
				style={{color: "inherit", display: "block", textDecoration: `${underline ? "underline" : " none"}`}}
				state={state}
				underline={{}}
			> { text } { children }
			</Link>
		</nav>
	)
}

export const ConditionalLinkWrapper = ( { underline, wrapIt, to, text, preTree, state, children } ) => 
wrapIt ? WithLink({ underline, to, text, preTree, state, children }) : children;

export const HomeLink = ({ children }) => {
    return (

			// <nav>
				<Link to="/"> { children } </Link>
			// </nav>
    )
}

export const scrollToName = (name, containerId, horizontal, instant, offset) => {

  scroller.scrollTo(name, { //scroll-to-HyxJobn6B1JhmkeJk22F-true-2
     duration: `${instant ? 0 : 300}`,
     delay: `${instant ? 0 : 0 }`,
     horizontal: horizontal, 
     smooth: true,
     containerId: containerId, ///scroll-container-id-HyxJobn6B1JhmkeJk22F-true
     ignoreCancelEvents: true,
     offset: offset
  })
}

export const ItemText = ({ background, data, isEditDocument, documentIndex, setTemplatesDataCallback }) => { ///reduced away: data, document
  
  // const data =
  // {
  //   "document": "6SDd5IoDObxe8o8bXUsK",
  //   "sortId": {
  //       "seconds": 1669785452,
  //       "nanoseconds": 946000000
  //   },
  //   "location": [],
  //   "webpage": "df",
  //   "contact": [
  //       {
  //           "text | smart": "9690323517"
  //       },
  //       {
  //           "text | globe": ""
  //       },
  //       {
  //           "call | smart": "9690323517"
  //       },
  //       {
  //           "call | globe": "9690323517"
  //       },
  //       {
  //           "whatsapp": "+639690323517"
  //       },
  //       {
  //           "messenger": "ricobpn"
  //       },
  //       {
  //           "instagram": "rico_borg"
  //       },
  //       {
  //           "email": "ricobpn@gmail.com"
  //       }
  //   ],
  //   "social": [
  //       {
  //           "instagram": ""
  //       },
  //       {
  //           "facebook": ""
  //       }
  //   ],
  //   "fees": [
  //       {
  //           "parking": "4"
  //       },
  //       {
  //           "entry": ""
  //       },
  //       {
  //           "environmental": ""
  //       },
  //       {
  //           "other": ""
  //       }
  //   ],
  //   "template": "attractions",
  //   "emotions": [],
  //   "media": [
  //       {
  //           "nameId": "PB150065m_1_02.jpg",
  //           "id": 0,
  //           "file": "",
  //           "url": "https://firebasestorage.googleapis.com/v0/b/simple-easy-gallery.appspot.com/o/undefined%2FPB150065m_1_02.jpg?alt=media&token=d0cfd3d7-207a-4710-bfc7-b949e0e62d7e",
  //           "mediaType": "image",
  //           "mediaText": ""
  //       }
  //   ],
  //   "address": "df",
  //   "time to visit": "df",
  //   "phone": "df",
  //   "what to do": [
  //       {
  //           "consumption": ""
  //       }
  //   ],
  //   "activities": [],
  //   "open for business": "true",
  //   "created": {
  //       "seconds": 1669785452,
  //       "nanoseconds": 946000000
  //   },
  //   "how to get there": "df",
  //   "type": [],
  //   "opening hours": {
  //       "tuesday": {
  //           "closing": "16:30",
  //           "opening": "6:00"
  //       },
  //       "wednesday": {
  //           "closing": "16:30",
  //           "opening": "6:00"
  //       },
  //       "saturday": {
  //           "closing": "16:30",
  //           "opening": "6:30"
  //       },
  //       "sunday": {
  //           "closing": "17:30",
  //           "opening": "6:30"
  //       },
  //       "thursday": {
  //           "closing": "16:30",
  //           "opening": "6:00"
  //       },
  //       "friday": {
  //           "opening": "6:00",
  //           "closing": "16:30"
  //       },
  //       "monday": {
  //           "opening": "6:00",
  //           "closing": "16:30"
  //       }
  //   },
  //   "practical": "df",
  //   "info": "dfd",
  //   "title": "HESA",
  //   "protip": [
  //       {
  //           "consumption": ""
  //       }
  //   ],
  //   "description": "bla bla",
  //   "highlights": [
  //       "efdfdf"
  //   ],
  //   "forceRender": true
  // }
  const document = "6SDd5IoDObxe8o8bXUsK"
  
  ///ref
  
  const contentEditableRef = useRef([]); //const contentEditableRef = useRef('');
  const multiStateButtonRef = useRef([]); 

  ///context
  const { templates } = useContext(templatesContext)

  const 
    { items, setItems } = {items: [data]}, ///reduced away: useContext(itemsContext),
    theme = {view: 1} ///reduced away: { theme, setTheme } = useContext(themeContext),
    ///{ refine, setRefine } = useContext(refineContext),  ///reduced away
    //{ unitLengthIndex, setUnitLengthIndex } = useContext(unitLengthIndexContext),
    const [scrollTo, setScrollTo] = useState({ galleryElementScrollBoxPair: {boxId_0: {}, boxId_1: {}}, wasRef: {ref: {}, component: "itemform", index: 10, anchorOffset: "", component: ""} }) ///optimise: rename to moveto ///reduced away, was: useContext(null) ///note: utilise wasref at scrolltocontext
    ///{ show, setShow } = useContext(showContext),  ///reduced away
    ///{ templatesData, setTemplatesData } = useContext(templatesDataContext); ///reduced away
  ///state
  ///const [updatedProperty, setUpdatedProperty] = useState([]) ///reduced away
  const [scrollToMenuIndex, setScrollToMenuIndex] = useState(0)

  ///const from context
  const 
    showDocumentMoreGridViewExtra = true ///reduced away: theme.view === 1 && generics.checkRemainder(documentIndex + 1, refine.gallery.itemsPrRow + 1, true)
  ///const
  const 
    userPresent = true, ///reduced away: UserPresent() 
    activeTemplate = data["template"], ///optimise: rename to template or itemtemplate 
    itemValues = Object.entries(data), ///optimise pass { data } to itemtext and rewrite activetemplate, itemvalues and document
    //document = data["document"], //question: move..
    isDocumentMore = generics.stringsAreIdentical(document, "6SDd5IoDObxe8o8bXUsK"), ///refined away: generics.stringsAreIdentical(document, refine.documentMore.document), ///optimise: called showdocumentmore in other component, align
    mappingIndex = () => {
      let index
      switch (true) {
        case theme.view === 0 && !isDocumentMore:
          index = 0
          break;
        case (theme.view === 0 && isDocumentMore) || (theme.view === 1 && showDocumentMoreGridViewExtra):
          index = 1
          break;
        case theme.view === 1:
          index = 2
          break;
        default:
        break;
      };
      return index
    },
  numberOfColums = 3,
  {media, video, ...template_} = templates[activeTemplate], ///note: template_ is without media and video, i.e. not mapped in itemtext
  templetEntries = Object.entries(template_) ///note: is without media and video, i.e. not mapped in itemtext
  const classes = {
    1: {
      default: `column-span-${numberOfColums}`,
      medium: ``,
      dimensions: `suffix`,
    },
    2: {
      default: "font-size-1",
      title: "font-size-3",
      //artist: `${theme.view === 0 ? "font-size-2 notranslate" : "font-size-0 notranslate" }`,
      //title: `${theme.view === 0 ? "font-size-4 italic notranslate" : "font-size-2 italic notranslate" }`,
      dimensions: "font-size-2 notranslate",
      description: "font-size-1 text-align-justify",
      price: "font-size-2 notranslate",
      tags: "font-size-1",
      cooltags: "font-size-1",
    }
  }

  ///compute refindex
  const getRefIndex = (function(n) { ///optimise: move to generics. note: did try, but not working as expected, try to solve
    return function() {
      n += 1;
      return n;
    }
  }(-1));

  const getSuffix = (label) => {
    const suffix = { ///optimise: move suffix outside function?
      //dimensions: Object.keys(unitLength)[unitLengthIndex], ///reduced away
    }
    return generics.keyExist(suffix, label) ? suffix[label] : ""
  }

  const getBulletIcon = ({ label }) => {
    const bullet = { ///optimise: move suffix outside function?
      "highlights": icons.burstIcon,
      "what to do": icons.boltIcon,
    }
    return generics.keyExist(bullet, label) ? bullet[label] : ""
  }

  const getBulletIconAtText = ({ label, theme, isHighlight }) => {
    const bullet = { ///optimise: move suffix outside function?
      "protip": icons.proSign, ///reduced away, was: icons.proSign({theme, isHighlight})
    }
    return generics.keyExist(bullet, label) ? bullet[label] : ""
  }

  const getFormatting = (label, value) => {
    const formatting = { ///optimise: move formatting outside function?
      ///dimensions: generics.zip(value.split(" "), ["h", "w", "d"]).join(" "), ///redueded away (but func not used)
    }
    return generics.keyExist(formatting, label) ? formatting[label] : value
  }

  const getModifiedValue = (label, value) => {
    const modifier = { ///optimise: move formatting outside function?
      //dimensions: (value / Object.values(unitLength)[unitLengthIndex]).toFixed(unitLengthIndex) ///unitLengthIndex concides with desired decimals for cm and ln, and thus used ///reduced away
    }
    return generics.keyExist(modifier, label) ? modifier[label] : value
  }

  const handleTextClicked = (label) => {
    const actions = { ///optimise: move formatting outside function?
      ///dimensions: setUnitLengthIndex((unitLengthIndex + 1) % 2), ///toogle unitlength ///reduced away
      tags: console.log("fuck year")
    }
    return generics.keyExist(actions, label) ? actions[label] : null
  }

  const handleContentEditableBlur = async(label, document, value, index, s) => { ///optimse: update of text, sequence and rendering. dont use s?
    const stateValue = [...items].find((item) => item.document === document)[label]
    let newValue = ""
    switch (true) {
      case value === "": ///no text => default to stateValue
        newValue = stateValue; 
        //return ///optimise: return here?
        break;
      case Array.isArray(templates[activeTemplate][label].value): ///property contains array ///was case Array.isArray(templet[label]):
        newValue = generics.replaceAtIndexReturnArray(stateValue, s, contentEditableRef.current[index].innerText); ///construct newValue
          if (generics.containsAnyObject(newValue)) return ///abort if property contains object - to mitigate right click on text from array => will return an object (contentEditable), not a string
        break;
      default:
        newValue = value;
        if (generics.isObject(newValue)) return ///abort if property is object
    }
    if (
      newValue !== stateValue && 
      newValue !== "" 
      //contentEditableRef.current != "" ///if change
      ) { 
      handleUpdateItemText(label, newValue, document, index)
    }
    ///contentEditableRef.current = "" ///reset - to mitigate "select other, no change, offocus, but update"
  }

  const handleUpdateItemText = async(label, value, document, index) => {
    const template = activeTemplate
    ///await Gallery_r.updateItemText({ label, value, document, template }) ///optimise: implement abortcontroller or similar for calls with multistatebuttons - to avoid write at every change, see fx https://stackoverflow.com/questions/67034451/react-cancel-async-functions-in-useeffect-that-depends-on-other-variables or https://javascript.plainenglish.io/how-to-add-a-timeout-limit-to-asynchronous-javascript-functions-3676d89c186d ///reduced away
    handleSetItems(label, document, value)
    ///setUpdatedProperty([document, index]) ///note: for styling usage ///reduced away
  }

  const handleSetItems = useCallback((label, document, newValue) => {
    // setItems(
    //   produce((draft) => {
    //     const item = draft.find((item) => item.document === document);
    //     item[label] = newValue;
    //     item.forceRender = !item.forceRender; //new state ensures render when contentEditableRef.current = "" (at no change in 'items' prop via use of prevText)
    //   })
    // );
    
  },[]); 

  const handleSetScrollTo = (wasRef, wasRefIndex, anchorOffset, component) => {
    console.log("jjj", wasRef, wasRefIndex, anchorOffset, component)
    setScrollTo( 
      produce((draft) => {
        const item = draft["wasRef"]
        item["ref"] = wasRef
        item["index"] = wasRefIndex
        item["anchorOffset"] = anchorOffset
        item["component"] = component
      })
    )
  }

  ///reduced away
  // useEffect(() => {
  // if (isEditDocument) {
  //   if (show.modal.requester === "itemText") {
  //     if (show.multiOptions.action === "check") {
  //       if (show.textualObjects.document !== "") { ///optimise!!!!: update requested to specifc element, and use action instead of requester to distingush between "new" and "update existing"
  //         console.log("jjjjjjjjj", show.textualObjects.document)
  //         const
  //           trigger = "textual-array--change",
  //           document = show.textualObjects.document,
  //           label = show.textualObjects.label,
  //           index = null, ///note: not used, but specified for completeness
  //           newItem = [{[show.multiOptions.dataSelected[0][label]]: ""}],
  //           value = [...templatesData.update.values[activeTemplate][label], ...newItem],
  //           startUpload = false,
  //           reset = false 
  //           console.log("WHAT!!!", document)
  //           setTemplatesDataCallback({ trigger, document, label, index, value, startUpload, reset }) 
  //     }
  //   }
  //   }
  //   if (show.modal.requester === "itemText") {
  //     if (show.multiOptions.action === "check") {
  //       if (show.textualObjects.document === "") {
  //       const 
  //         //path = show.multiOptions.payloadInnerPathViaArray, ///question: use document instead?
  //         label = show.multiOptions.label,
  //         value = show.multiOptions.dataSelected,
  //         document = show.multiOptions.document
  //         handleUpdateItemText(label, value, document)
  //       //handleSetItems2(path, value)
  //       }
  //     }
  //   }	
  //   if (
  //     show.modal.modalChild === "textualArray" || 
  //     show.modal.modalChild === "textualObjects" ||
  //     show.modal.modalChild === "openingHours"
  //     ) {
  //     if (show[show.modal.modalChild].startUpload === true) {
  //       const 
  //         trigger = "textual-array--change",
  //         document = show[show.modal.modalChild].document,
  //         label = show[show.modal.modalChild].label,
  //         index = null, ///note: not used, but specified for completeness
  //         value = templatesData.update.values[activeTemplate][label],
  //         startUpload = true,
  //         reset = false 
  //         console.log("pik", show.modal.modalChild, show, trigger, document, label, index, value, startUpload, reset)
  //       setTemplatesDataCallback({ trigger, document, label, index, value, startUpload, reset }) 
  //     }
  //   }	
  // }
  // }, [show]);



  ///reduced away
//   useEffect(() => {
//   if (isEditDocument) {
//     if (show.modal.modalChild === "multiOptions" && show.textualObjects.document !== "") {
//       console.log("what the fuck", document)
//     const 
//       label = show.textualObjects.label,
//       modalChildName = "textualObjects"

//     const modalChildComponent = 
//       <TextualObjects
//         requester={"itemText"}
//         className={""}
//         placeholder={`Enter ${label}`}
//         label={label}
//         activeTemplate={activeTemplate}
//         //value={value}
//         currentRefs={contentEditableRef.current}
//         refIndex={1}
//         stateData={templatesData.update.values[activeTemplate][label]} ///was: value}
//         setTemplatesDataCallback={({ label, value }) => {
//           const
//             trigger = "text--change",
//             document = show.textualObjects.document,
//             index = null, ///note: not used, but specified for completeness
//             startUpload = false,
//             reset = false 
//           setTemplatesDataCallback({ trigger, document, label, index, value, startUpload, reset }) 
//         }} // handleSetTemplatesData({ label, value })}
//       ></TextualObjects> 
//   toggleModalShow({ label, modalChildComponent, modalChildName})
//   }
// }
//   }, [templatesData.update.values[activeTemplate]]);

  useEffect(() => {
    console.log("jjj useeffect", scrollTo.wasRef)
    const { index, anchorOffset, component } = scrollTo.wasRef
    if (component !== "itemtext") { return }
    setTimeout(function() {
      contentEditableRef.current[index].focus() ///note: scrollto.wasref.ref does not work => also, thus: each component must have their own version of this useeffect
      //contentEditableRef.current[index].selectionStart = anchorOffset ///optimise: not working
    }, 50);
  }, [scrollTo]);


  ///reduced away
  // const toggleModalShow = ({ label, modalChildComponent, modalChildName }) => { ///optimise: move most to modal in app
  //   console.log("h", label, modalChildComponent, modalChildName)
  //   const 
  //     component = "modal", //label
  //     action = "show", showValue = true,
  //     child = "modalChild", 
  //     childValue = modalChildName ///note/risk: not correct - should be dynamic, based on who calls (textialarray or textualobjecs)
  //   const 
  //     modalChild = modalChildName,
  //     parameter = "label", labelValue = label
  //     //data = "data", dataValue = { ["bib"]: "hi" }
  //     console.log("modalChildName", label, modalChildName)
  //   setShow(
  //     produce((draft) => { 
  //       draft[component][action] = showValue
  //       draft[component][child] = childValue
  //       draft[component]["requester"] = "itemText"
  //       draft[modalChild][parameter] = labelValue
  //       //draft[modalChild][data] = dataValue
  //       draft[modalChild]["child"] = modalChildComponent
  //       // draft[modalChild]["payloadObjectName"] = payloadObjectName
  //       // draft[modalChild]["payloadInnerPathViaArray"] = payloadInnerPathViaArray ///note: can be used with immer
  //       // draft[modalChild]["preSelectedOptions"] = preSelectedOptions
  //       draft[modalChild]["document"] = document
  //     })
  //   );
  // }

  const ScrollToMenu = ({ show, indexOffset, containerIndex, showDocumentMoreGridViewExtra }) => {

    const 
      name = "scrollto-menu",
      shortcutEntries =
      Object.entries(templates[activeTemplate])
        .filter(([label]) => templates[activeTemplate][label].mapping[1])
        .map(([label]) =>  [label, templates[activeTemplate][label].shortcut ]), ///note: to exclude elements/labels not mapped at more-view (ensure scroll to correct index) ///was: templatesMapping[activeTemplate][label][1]

      scrollToContainerId = `scroll-container-id-text-${document}-${containerIndex}-${showDocumentMoreGridViewExtra}`
  
    const component = 
        
        <Wrapper
          className={`
            ${show ? "show fadeout-5" : "hide fadeout-05"}
            input-padding-3 input-radius
          `}
          name={name}
          height={"auto"}
          style={{background: background}}
          //wrapperStyle={{width: "auto", background: "blue"}}
          width={"auto"}
         // style={{backgroundColor: `${templatesSetup[activeTemplate].color}` }}
          //borderBottom: `1px solid ${templatesSetup[activeTemplate]}`
          
        >
        <GridColums
        gridAutoFlow={"column"}
    
      > 					
        {shortcutEntries
          //.filter(([isShortcut, icon]) => isShortcut)
          .map(([label, value], index) => {
            const 
              [ isShortcut, icon ] = value,
              scrollTo = `scroll-to-text-${document}-${index - indexOffset}-${containerIndex}`
                return (
                  isShortcut ?
                  <div 
                    className={`tab theme-color-${index - indexOffset === scrollToMenuIndex ? 1 : 0}`}
                    //style={{color: `${index - indexOffset === scrollToMenuIndex ? "green"  : ""}`}}
                    key={index}
                    onClick={() => {
              
                      setScrollToMenuIndex(index - indexOffset)
                      scrollToName(scrollTo, scrollToContainerId, false, false, 0)
                    }}
                  >{ icon }
                  </div> :
                  null
                )		
            })
          }
          </GridColums>
      </Wrapper>

    return (
      <Fragment 
        //key={index}
      >
      {component}
      </Fragment>
    );
  }

  ///reduced away (note: was not used prior)
  // const ConditionalGridWrapper = ( { wrapIt, children } ) => {
  //   let component = <></>
  //   switch (true) {
  //     case wrapIt:
  //       component = 
  //         <GridColums
  //           gridTemplateColumns={"1fr"}
  //         > { children }
  //         </GridColums>
  //       break
  //     case !wrapIt:
  //       <div 
  //           style={{cursor: "pointer", height: "100%"}}
  //         >
  //           { children } 
  //       </div> 
  //     default:
  //       break
  //   }
  //   return (
  //   <div  
  //   > { component }
  //   </div>
  //   )
  // }


  ///reduced away
  // const ContactComponent = ({ value, parentCallback }) => { //({ provider, contactValue, style }) => {

  // const [showContactValueIndex, setShowContactValueIndex] = useState(null)

  // const contactComponent = ({ provider, contactValue, index }) => {
  //   const 
  //     buttonHeight = "2.5em",
  //     iconScaleFactor = 0.7,
  //     showContactValue = index === showContactValueIndex && contactValue !== ""
  //   let component = <></>
  //   let preLink, preFilledMessage, href, type, textValue, icon
    
  //   switch (true) {
  //     // case contactValue === "": 
  //     // 	return <></>
  //     // 	// href = ""
  //     // 	// icon = ""
  //     // 	// text = ""
  //     // 	break;
  //     case provider === 'whatsapp': 
  //       preLink = "https://wa.me/"
  //       preFilledMessage = "?text=I'm%20interested%20in%20your%20car%20for%20sale"
  //       href = `${preLink}${contactValue}${preFilledMessage}`
  //       icon = icons.whatsAppLogo
  //       type = "Chat"
  //       textValue = contactValue
  //     break
  //     case generics.stringIncludes(provider, "globe") || generics.stringIncludes(provider, "smart"):
  //       const contactType = generics.stringIncludes(provider, "text") ?
  //       "sms:" : "tel:",
  //       areaCode = "+63"
  //       icon = generics.stringIncludes(provider, "globe") ?
  //         icons.globeLogo :
  //         icons.smartLogo
  //       type = generics.stringIncludes(provider, "text") ? 
  //         "SMS" : "Call"
  //       preLink = `${contactType}${areaCode}`
  //       href = `${preLink}${contactValue}`
  //       textValue = contactValue.replace(/^\s*([0-9]{3})\s*\-?([0-9]{3})\s*\-?([0-9]{4})$/, '$1 $2 $3') //.replace(/(.{2})/g,"$1 ")
  //     break
  //     case provider === 'email':
  //       preLink = "mailto:"
  //       href = `${preLink}${contactValue}`
  //       icon = ""
  //       type = "Email"
  //       textValue = contactValue
  //     break
  //     case provider === 'messenger':
  //       preLink = "https://m.me/"
  //       href = `${preLink}${contactValue}`
  //       icon = icons.facebookMessengerLogo
  //       type = "Chat"
  //       textValue = contactValue
  //     break
  //     case provider === 'instagram':
  //       preLink = "https://www.instagram.com/"
  //       href = `${preLink}${contactValue}`
  //       icon = icons.instagramLogoWithText
  //       type = "Instagram"
  //       textValue = contactValue
  //     break
  //     default:
  //       //component = `${provider} ${contactValue}`
  //     break
  //   }
  //   component =	
  //     <div
  //     onMouseEnter={() => setShowContactValueIndex(index)}
  //     onMouseLeave={() => setShowContactValueIndex(null)}
  //     >
  //     <ButtonWithLogo
  //       isOneColumn={showContactValue}
  //       href={href} 
  //       buttonHeight={buttonHeight} 
  //       theme={theme === 0 ? 1 : 0} 
  //       iconScaleFactor={iconScaleFactor}
  //       icon={showContactValue ? "" : icon}
  //       text={showContactValue ? 
  //         `${type} ${textValue}` : 
  //         type 
  //       }
  //       disabled={contactValue === ""}
  //       parentCallback={(e) => parentCallback(e)}
  //     >
  //     </ButtonWithLogo>
  //     </div>
  //   return component
  // }
  //   return (
  //   <GridColums
  //     gridTemplateColumns={"1fr 1fr"}
  //     gridAutoFlow={"row dense"}
  //     columnGap={"1em"}
  //     rowGap={"1em"}
  //   >
  //     {value.map((contact, index) => { ///optimise: map over itemform, and match value in data, if exist => will ensure rendering and sort order can be controlled/aligned
  //       const 
  //         provider = Object.keys(contact)[0],
  //         contactValue = Object.values(contact)[0]
  //       const component = contactComponent({ provider, contactValue, index })
  //         return (
  //           <div
  //             style={{	
  //               //opacity: `${contactValue === "" ? 0.3 : 1}`,
  //               gridColumnStart: `${index <= 3 ? 1 : 2}` ///note: the first 4 elements are mapped to the left (vertical) in the grid, the remaining to the right
  //             }}
  //           >{component}
  //           </div>
  //         )
  //       }
  //     )}
  //   </GridColums>
  //     )
  //   }

  ///reduced away
  // const OpeningHoursComponent = ({ value, className, parentCallback }) => {
  //   const addZero = (string) => {
  //     return string.length === 1 ? `0${string}` : string
  //     // const hoursString  = string.substr(0, string.indexOf(':')) ///optimise: move to genenerics?
  //     // return hoursString.length === 1
  //   }
  //   // const getTimeComponent = (string, componentType) => {
  //   // 	const str = "05:01:06"
  //   // 	const timeComponentArray = str.split(':')
  //   // 	let timeComponent = "00"
  //   // 	switch (true) {
  //   // 		case componentType === "hh":
  //   // 			timeComponent = string.substr(0, string.indexOf(':'))
  //   // 			break;
  //   // 			case componentType === "mm":
  //   // 				timeComponent = 1
  //   // 			break;
  //   // 		case componentType === "ss":
  //   // 			timeComponent = 1
  //   // 			break;
  //   // 		default:
  //   // 		break;
  //   // 	};
  //   // }
  //   const 
  //     zone = "Asia/Manila",
  //     currentTime = DateTime.now().setZone(zone), //.plus({day: -1}),
  //     weekdayIndex = currentTime.weekday - 1,
  //     currentWeekday = weekdays[weekdayIndex], ///note: or weekdays[today.getDay()],
  //     mapWeekdayArray = ///note: weekday array starting from todays weekday, and ending at same day (same day as last entry: to encompass only one opening day)
  //       [...[...weekdays].slice(weekdayIndex), 
  //       ...[...weekdays].slice(0, weekdayIndex), 
  //       //...[...weekdays].slice(weekdayIndex, weekdayIndex + 1) ///note: repeat last weekday
  //       ] 		
  //     let 
  //       searchSwitch = false,
  //       todayOpeningHours, todayClosingHours,
  //       nextOpeningHours = null, nextClosingHours = null,
  //       nextOpeningWeekday = null,
  //       daysToNextOpening = 0
  //     ///risk/optimise: include senario where all days is closed...
  //       mapWeekdayArray.map((weekday, index) => { ///map over weekday array (starting from todays weekday)
  //         //hoursTypes.map((hoursType) => {
  //           if (!searchSwitch && weekday === currentWeekday) { ///capture todays opening hour (including "closed")
  //             todayOpeningHours = value[weekday].opening
  //             todayClosingHours = value[weekday].closing
  //             searchSwitch = true
  //           }
  //           if (
  //             searchSwitch && 
  //             weekday !== currentWeekday && 
  //             value[weekday].opening !== "closed") 
  //           { ///capture next opening weekday and hours
  //             nextOpeningHours = value[weekday].opening
  //             nextClosingHours = value[weekday].closing
  //             nextOpeningWeekday = weekday
  //             daysToNextOpening = index
  //             searchSwitch = false
  //           }
  //         //})
  //       })
  //     let todayOpeningTime = "closed", todayClosingTime = "closed", nextOpeningTime = "closed", nextClosingTime = "closed"
  //     const isOpenToday = todayOpeningHours !== "closed"
  //     if (isOpenToday) {
  //       const
  //         [todayOpeningHour, todayOpeningMinute] = value[currentWeekday].opening.split(':'),
  //         [todayClosingHour, todayClosingMinute] = value[currentWeekday].closing.split(':'),
  //         todayClosingDay = `${addZero(todayOpeningHour)}:${addZero(todayOpeningMinute)}` < `${addZero(todayClosingHour)}:${addZero(todayClosingMinute)}` ? ///if closing time is past midnight (premise for calc: closing hour the next day is before the opening hour the current day)
  //           currentTime.day :
  //           (currentTime.day + 1) % (currentTime.day + 1 === currentTime.daysInMonth ? currentTime.daysInMonth + 1 : currentTime.daysInMonth),  ///nextday equal to current day plus next opening day, but max days in month (else restart from day 1)
  //         todayClosingMonth = todayClosingDay >= currentTime.day ? ///if today closing time is today and
  //           currentTime.month : 
  //           (currentTime.month + 1) % 13 ///if nextday is < currenttime.day, add one month (but max 12, else restart from month 1)
  //       todayOpeningTime = DateTime.fromObject({hour: todayOpeningHour, minute: todayOpeningMinute }, { zone: zone})
  //       todayClosingTime = DateTime.fromObject({month: todayClosingMonth, day: todayClosingDay, hour: todayClosingHour, minute: todayClosingMinute }, { zone: zone})
  //     }
  //     const nextOpeningWeekdayExist = nextOpeningWeekday !== null
  //     if (nextOpeningWeekdayExist) {
  //       const
  //         [nextOpeningHour, nextOpeningMinute] = value[nextOpeningWeekday].opening.split(':'),
  //         [nextClosingHour, nextClosingMinute] = value[nextOpeningWeekday].closing.split(':'),
  //         nextDay = ///nextday equal to current day plus next opening day, but max days in month (else restart from day 1). 
  //           (currentTime.day + daysToNextOpening) % 
  //           (currentTime.day + daysToNextOpening === currentTime.daysInMonth ? currentTime.daysInMonth + 1 : currentTime.daysInMonth), ///note: plus 1 at condition to mitigate nextday is 0
  //         nextMonth = nextDay > currentTime.day ? ///default (most cases)
  //           currentTime.month : 
  //           (currentTime.month + 1) % 13 ///if nextday is < currenttime.day, add one month (but max 12, else restart from month 1)
  //       nextOpeningTime = DateTime.fromObject({month: nextMonth, day: nextDay, hour: nextOpeningHour, minute: nextOpeningMinute }, { zone: zone})
  //       nextClosingTime = DateTime.fromObject({month: nextMonth, day: nextDay, hour: nextClosingHour, minute: nextClosingMinute }, { zone: zone})
  //     }
  //     const 
  //       isOpen = isOpenToday && todayOpeningTime <= currentTime && currentTime <= todayClosingTime,
  //       isClosed = todayOpeningTime > currentTime && currentTime > todayClosingTime,
  //       durationObjToNextOpening = currentTime < todayOpeningTime || (currentTime < todayOpeningTime && todayOpeningTime !== "closed") ? ///if not open today yet or closed today
  //         todayOpeningTime.diff(currentTime, ["hours", "minutes"]) : //Interval.fromDateTimes(currentTime, todayOpeningTime) : 
  //         nextOpeningTime.diff(currentTime, ["hours", "minutes"]), //Interval.fromDateTimes(currentTime, nextOpeningTime),
  //       durationObjToNextClosing = currentTime < todayOpeningTime || isOpen ?
  //         todayClosingTime.diff(currentTime, ["hours", "minutes"]) : //Interval.fromDateTimes(currentTime, todayClosingTime) :
  //         nextClosingTime.diff(currentTime, ["hours", "minutes"]), //Interval.fromDateTimes(currentTime, nextClosingTime),
  //       openingSoon = durationObjToNextOpening.values.hours === 0 && durationObjToNextOpening.values.minutes < 60,
  //       closingSoon = durationObjToNextClosing.values.hours === 0 && durationObjToNextClosing.values.minutes < 60,
  //       openingHoursMessage = todayOpeningTime !== "closed" ?
  //         `${value[currentWeekday]["opening"]}-${value[currentWeekday]["closing"]}` :
  //         "closed today",
  //       statusMessage = isOpen ? 
  //         `closing in ${durationObjToNextClosing.values.hours}:${Math.floor(durationObjToNextClosing.values.minutes)} ${closingSoon ? "(closing soon)" : ""}` : ///${durationObjToNextClosing.length('hour')}` : 
  //         `closed. opening in ${durationObjToNextOpening.values.hours}:${Math.floor(durationObjToNextClosing.values.minutes)} ${openingSoon ? "(opening soon)" : ""}` //${durationObjToNextOpening.length('hour')}`
  //   const componnet = 
  //     <span 
  //       className={className}
  //       onClick={() => parentCallback()}
  //     >
  //         {openingHoursMessage} {statusMessage}
  //       </span>

  //   return componnet
  // }
  
  const Component = ( { className, label, index, updatedDocument, updatedIndex, isLastScrollableElement, children }) => {
    const name = label
    const classes1 = generics.getClassesFromType(classes, 1, [label])
    const suffix = getSuffix(label)
    const button = 
      <Button		
        show={true}
        wrapperClassName={classes1}
      
        //wrapperStyle={{display: "inline-grid"}}
        classTypes={["classical", "subtleExtra"]}
        className={`
          ${className}
          gallery-text
          gallery-text-${label} 
        `}
        style={{paddingBottom: `${isLastScrollableElement ? "100%" : "0vh"}`}} ///optimise: note at 100% content can be scrollable out of view, fix or ok?
        fontSize={1}
        theme={1} ///reduced away (was theme.theme)
        suffix={suffix}
        parentCallback={() => !userPresent && handleTextClicked(label)}
      > { children }
      </Button>
    const components = {
      default:
        <div  
          ///reduced away:        
          // ${document === updatedDocument && index === updatedIndex ?
          // "gallery-text--updated" : 
          // ""}
          className={`
          ${className}
            gallery-text 
            input-radius
            gallery-text-${name} 
            ${classes1}
        `}
        style={{
          paddingBottom: `${isLastScrollableElement ? "100%" : "0vh"}`,
          width: `${label === "contact" ? "100%" : ""}`
        }} ///optimise: 100% - risk of scrolling out of view ///note: padding on last item to enable scrollto
        > 
          { children }
        </div>
        ,
      dimensions: <>{ button }</>,
      // multioption: <>{ button }</>,
      }
      // const isMultiOptions = generics.stringInArray(multiOptions, label)
      //label = isMultiOptions ? "multioption" : label
      const result = generics.keyExist(components, label) ? 
        components[label] : 
        components.default
      return result
  }

  let fixedTempletEntries = []
  let scrollableTempletEntries = []
  templetEntries.map(([ label_], index) => { ///note: map templetEntries in two groups, ie. fixed and scrollable
    const isFixed = templates[activeTemplate][label_].isfixed ///was: templatesIsFixed[activeTemplate][label_]
    isFixed ? 
      fixedTempletEntries.push(templetEntries[index]) :
      scrollableTempletEntries.push(templetEntries[index])
  })

  const splitTempletEntries = [fixedTempletEntries, ["scrollToMenu"], scrollableTempletEntries]

  return (
    <Wrapper
      name="tab-box scroll-box"
      label={`gallery-text`}
      show={true}
      height={`${isDocumentMore ? "100%" : "fit-content"}`}
      overflow={"hidden"}
      display={"flex"}
      style={{overflow: "hidden", flexDirection: "column"}}
    >
      {splitTempletEntries.map((templetEntry, containerIndex) => {
        const scrollableContainerIndex = 2
        return (
        templetEntry[0] === "scrollToMenu" ? ///if element is scrolltomenu
          true ? ///if ... => exclude gridview 1 non-selected ///reduced away: theme.view === 0 || theme.view === 1 && showDocumentMoreGridViewExtra
            <ScrollToMenu 
              show={isDocumentMore || showDocumentMoreGridViewExtra} ///optimise: fadein does not occur, due to rerendering of parent component, consider to fix, if possible...
              indexOffset={fixedTempletEntries.length}
              containerIndex={scrollableContainerIndex} ///note: fixed is 0, scrolltomenu is 1, scrollable is 2
              showDocumentMoreGridViewExtra={showDocumentMoreGridViewExtra}
            /> 
            : null
        : 
      <GridColums
        //style={{paddingBottom: "10vh"}}
        className={`
          content 
          gallery-text-container
          type-${containerIndex}
          `}
        gridTemplateColumns={`repeat(3, minmax(0, 1fr)`} 
        gridTemplateRows={`1fr`} 
        justifyItems={`${containerIndex === 0 ? "center" : "center"}`}
        wrapperId={`scroll-container-id-text-${document}-${containerIndex}-${showDocumentMoreGridViewExtra}`}
        //columnGap={`1vw`}
        rowGap={`1.5vw`} ///note: index 2 equals scrollable entries
        justifyContent={theme.view === 0 || showDocumentMoreGridViewExtra ? "left" : "center"}
        alignItems={"center"}
        height={"100%"}
        wrapperStyle={{
            //scrollSnapType: "y proximity", 
            overflow: `${containerIndex === 0 ? "" : "auto"}`
          }} ///note: scroll at view 1
      >
      {templetEntry
        .filter(([label,]) => templates[activeTemplate][label].mapping[mappingIndex()]) ///note: stringundefined check to exclude that data not including in the templatae, like metadata like crateddate (is undefined) ///was: filter(([a,]) => includedNames.includes(a)) was: templatesMapping[activeTemplate][label][mappingIndex()]
        .map(([label,], index) => {
          let 
            value = data[label],
          // let 
          // 	[ label, value ] = Object.values(data)[0][a],
            mapArrayDummy = [],
            noOfColumns = 1
          const
           html = {
            default: "span",
            title: "h1",
            description: "p",
            },
          style = {
            title: {},
            default: {},
            },
          isOpeningHours = templates[activeTemplate][label].type === "openingHours",
          isTextualArray = templates[activeTemplate][label].type === "textualArray",
          isTextualObjects = templates[activeTemplate][label].type === "textualObjects", //&& label !== "contact" && false,
          isTextualContent = templates[activeTemplate][label].type === "textual", /// was: generics.stringInArray(textualContent, label),
          isMultiStateButtons = templates[activeTemplate][label].type === "multiState", /// was: generics.stringInArray(multiStateButtons, label),
          isPreDefinedArray = templates[activeTemplate][label].type === "preDefinedArray", ///optimse/question: or include in textualarray? was: generics.isArrayLengthAbove(templates[activeTemplate][label].value, 0), /// was: generics.isArrayLengthAbove1(templet[label]),
          isMultiOptions = templates[activeTemplate][label].type === "multiOptions", /// was: generics.stringInArray(multiOptions, label),
          isInstagramEmbed = templates[activeTemplate][label].type === "instagramEmbed"
          //isContact = templates[activeTemplate][label].type === "textualObjects" && label === "contact" && false
          
          switch (true) {
            case isPreDefinedArray: ///note: must be before istextualcontent, as dimension in also included within
              mapArrayDummy = templates[activeTemplate][label].value /// was: templet[label]
              noOfColumns = templates[activeTemplate][label].value.length /// was: templet[label].length
              break;
            case isTextualArray || isTextualObjects: 
              console.log("isTextualObjects", label)
              mapArrayDummy = Array(value.length).fill("")  // userPresent ? ["one"] : Array(value.length).fill("") 
              noOfColumns = value.length /// was: templet[label].length
              if (label === "contact") {
                mapArrayDummy = ["one"]
                noOfColumns = 1
              }
            break;
            case (isTextualContent || isMultiStateButtons || isInstagramEmbed ): ///reduced away: || isOpeningHours
              mapArrayDummy = ["one"]
              noOfColumns = 1
              break;
            case isMultiOptions:
              value = generics.arrayOfObjectGetValuesByKey(value, label)
              mapArrayDummy = userPresent && value.length === 0 ? ["dummy"] : Array(value.length).fill("") ///note: "dummy" to ensure map of multioption button, if user present
              noOfColumns = value.length
              break;
            default:
            break;
          }
          const htmlTag = generics.keyExist(html, label) ? html[label] : html["default"]
          const contentStyle = generics.keyExist(style, label) ? style[label] : style["default"]
          ///const [updatedDocument, updatedIndex] = updatedProperty ///optimise: add i ///reduced away
          const wrapIt = false ///reduced away, see below
            // label === "artist" ? ///optimise: use switch
            // !userPresent && generics.entityExist(artistData, "firstName", value) 
            // : false
          //const classes2 = generics.getClassesFromType(classes, 2, ["not used", label])
          const templateClassName = templates[activeTemplate][label].className ///was: templatesClassName[activeTemplate][label]
          const isHighlight = containerIndex === scrollableContainerIndex && index === scrollToMenuIndex
          const bulletIcon = getBulletIcon({ label })
          const bullletIconText = getBulletIconAtText({ label, theme, isHighlight })

        return ( ///optimise: move key to component, not outer div...

          
          <Component //includedNames.includes(label) && //exclude rendering of document
            key={label}
            label={label}
            index={index}
            ///updatedDocument={updatedDocument} ///reduced away
            //updatedIndex={updatedIndex}
            //setTemplatesDataCallback={(label, value) => setTemplatesDataCallback({label, value})}
            isLastScrollableElement={index + 1 === scrollableTempletEntries.length && containerIndex === 2} ///risk: correct with +-?
          >
        <Element
        name={`scroll-to-text-${document}-${index}-${containerIndex}`} 
        >
            <ConditionalLinkWrapper
              wrapIt={wrapIt}
              to={`${label}s/${value}`} 
              text={value}
              underline={true} ///note: true implicit aldready
            >
              {/* <GridColums */}

              <div
                className={`
                  flex-text 
                  ${containerIndex === 2 ? "input-padding-2" : ""}
                  ${label === "title" ? "uppercase" : ""}
                  theme-${theme.theme}
                  ${isHighlight ? `highlight--true` : "highlight--false"}
                  `}
                style={{
                  flexDirection: `${templates[activeTemplate][label].vertical ? "column" : "row"}`, ///was: templatesVertical[activeTemplate][label] 
                }} 
              >
              {containerIndex === 2  && false?
                <div>
                  {label}
                </div>
                :
                null
              }
              
              {mapArrayDummy.map((x, s) => {
                // const suffix = generics.lastIndex(mapArrayDummy, s) ? getSuffix(label) : "";
                let modifiedValue ///legacy: const modifiedValue = isSplitContent ? getModifiedValue(label, value[s]) : getModifiedValue(label, value)
                switch (true) {
                  case (isPreDefinedArray || isMultiOptions || isTextualArray): ///note: must be before istextualcontent, as dimension in also included within
                    modifiedValue = getModifiedValue(label, value[s])
                    break;
                  case (isTextualContent || isMultiStateButtons):
                    modifiedValue = getModifiedValue(label, value)
                    break;
                  default:
                  break;
                };
                let refIndex = getRefIndex()
                let component = null
                let modalChildComponent = null 
                switch(true) {
                  case isInstagramEmbed:
                    component =
                      <></>
                      //<InstagramPost></InstagramPost>
            
                    break;
                  case isMultiStateButtons && userPresent: /// was: generics.stringInArray(multiStateButtons, label) && userPresent:
                    component = 
                      // MultiState = useCallback(({ label, value }) => {
                      // 	return (
                          <MultiStateButton
                            key={label}
                            wrapperClassName={`multi-state-button-wrapper-${label}`}
                            className={`${templateClassName} multi-state-button-${label}`}
                            classTypes={["classical", "simple"]}
                            //minWidth={minWidthButton}
                            theme={theme.theme}
                            //style={{minWidth: minWidthButton}}
                            label={label}
                            options={templates[activeTemplate][label].options} 
                            value={value}
                            ref={multiStateButtonRef}
                            parentCallback={(label, value) => handleContentEditableBlur(label, document, value, index, s)}
                        ></MultiStateButton>
                      // 	)
                      // }, [itemElements])
                    break;
                    case isMultiOptions: /// was: generics.stringInArray(multiOptions, label): //&& userPresent:
                      component = <></> ///reduced away
                        // <MultiOptionButton
                        //   className={`${templateClassName}`}
                        //   key={label}
                        //   disabled={!userPresent}
                        //   classTypes={["classical", "mini"]}
                        //   label={label} 
                        //   multiOptionsValues={templates[activeTemplate][label].options}
                        //   requester={`itemText`} ///optimise: use name
                        //   preSelectedOptions={items[items.findIndex((item) => item.document === document)][label]}
                        //   payloadInnerPathViaArray={`${items.findIndex((item) => item.document === document)},${label}`.split(",")}
                        //   payloadObjectName={`items`}
                        //   document={document}
                        //   text={generics.arrayEmpty(value) ? `select ${label}?` : value[s]}
                        //   parentCallback={() => console.log("multi")} ///note: parentcallback only at not disabled (!userpresent)
                        // ></MultiOptionButton>
                      break;
                      case isOpeningHours: ///reduced away (replaced with fragment)
                        modalChildComponent = <></>
                        //   <OpeningHours
                        //     stateData={value} //templatesData.update.values[activeTemplate][label]}
                        //     label={label}
                        //     setStateCallback={({ label, value }) => {
                        //       const 
                        //         trigger = "opening-hours--change",
                        //         //document = show.openingHours.document,
                        //         index = null, ///note: not used, but specified for completeness
                        //         startUpload = false,
                        //         reset = false 
                        //     setTemplatesDataCallback({ trigger, document, label, index, value, startUpload, reset }) 
                        //     }}
                        // ></OpeningHours>
                        // component = 
                        //   <OpeningHoursComponent
                      
                        //     value={value}
                        //     className={`${templateClassName}`}
                        //     parentCallback={() => {
                        //       const modalChildName = "openingHours"
                        //       toggleModalShow({ label, modalChildComponent, modalChildName})
                        //     }}
                        //   >
                        //   </OpeningHoursComponent>
                        break;
                      case isTextualArray: /// was: generics.stringInArray(multiOptions, label): //&& userPresent:
                        refIndex = getRefIndex()
                        modalChildComponent = 
                            <TextualArray
                              className={""}
                              placeholder={`Enter ${label}`}
                              label={label}
                              //value={value}
                              currentRefs={contentEditableRef.current}
                              refIndex={refIndex}
                              stateData={value} ///was: value}
                              setTemplatesDataCallback={({ label, value }) => {
                                const
                                  trigger = "text--change",
                                  index = null, ///note: not used, but specified for completeness
                                  startUpload = false,
                                  reset = false 
                                setTemplatesDataCallback({ trigger, document, label, index, value, startUpload, reset }) 
                              }} // handleSetTemplatesData({ label, value })}
                          ></TextualArray> 
                    
                        component = 
                          <span
                             className={`${templateClassName}`}
                             //style={{display: "block"}}
                            onClick={() => {
                              // const
                              // action = "update",
                              // startUpload = false,
                              // reset = false 
                              // setTemplatesDataCallback({ action, label, value, startUpload, reset }) 
                              const modalChildName = "textualArray"
                              //toggleModalShow({ label, modalChildComponent, modalChildName}) ///reduced away
                            }}
                           >
                              {bulletIcon} {modifiedValue}
                           </span>
                        break;
                      case isTextualObjects && userPresent: /// was: generics.stringInArray(multiOptions, label): //&& userPresent:
                
                        refIndex = getRefIndex()
                        let 
                          protips = [],
                          protipExist = false
                        if ( ///specific rendering/styling for label "what to do"
                          label === "what to do" //&&
                          //generics.keyExist(items[items.findIndex((item) => item.document === document)]["protip"], Object.keys(value[s])[0]) ///note: if protip exist (key exist in passed data/value)
                        ) {
                          protips = generics.arrayOfObjectGetValuesByKey(items[items.findIndex((item) => item.document === document)]["protip"], Object.keys(value[s])) ///note: array with any protip for given entry ("what to do" entry)
                           //protip = items[items.findIndex((item) => item.document === document)]["protip"][0][Object.keys(value[s])[0]] ///collect protip for given key ("what to do" item)
                          protipExist = protips.length > 0 ///if any protips (for given key, ie. "what to do" item)
                        }
                        const modalChildName = "textualObjects"
                        modalChildComponent = 
                            <TextualObjects
                              requester={"itemText"}
                              className={""}
                          
                              placeholder={`Enter ${label}`}
                              label={label}
                              activeTemplate={activeTemplate}
                              //value={value}
                              currentRefs={contentEditableRef.current}
                              refIndex={refIndex}
                              stateData={value} ///was: value}
                              setTemplatesDataCallback={({ label, value }) => {
                                
                                const
                                  trigger = "text--change",
                                  index = null, ///note: not used, but specified for completeness
                                  startUpload = false,
                                  reset = false 
                                setTemplatesDataCallback({ trigger, document, label, index, value, startUpload, reset }) 
                              }} // handleSetTemplatesData({ label, value })}
                          ></TextualObjects> 
                  
                        const textualObjectsComponents = {
                            default:
                              <GridColums
                              gridAutoFlow={"row"}
                              rowGap={"0.5em"}				
                              justifyItems={"center"}
                            >
                              <span> </span> 
                              <span className={"bold"}>{bulletIcon} {Object.keys(value[s])[0]} </span>
                              <span>{bullletIconText} {Object.values(value[s])[0]}</span>
                              {false && protipExist ? ///note: disabled via false for now
                              protips.map((protip) => 
                                <span 
                                  onClick={(e) => {
                                    label = "protip"
                                    modalChildComponent = 
                                      <TextualObjects
                                        requester={"itemText"}
                                        className={""}
                                        placeholder={`Enter ${label}`}
                                        label={"protip"}
                                        activeTemplate={activeTemplate}
                                        //value={value}
                                  
                                        currentRefs={contentEditableRef.current}
                                        refIndex={`${refIndex}${label}`}
                                        stateData={items[items.findIndex((item) => item.document === document)]["protip"]} ///was: value}
                                        setTemplatesDataCallback={({ label, value }) => {
                                          label = "protip"
                                          const
                                            trigger = "text--change",
                                            index = null, ///note: not used, but specified for completeness
                                            startUpload = false,
                                            reset = false 
                                          setTemplatesDataCallback({ trigger, document, label, index, value, startUpload, reset }) 
                                        }} // handleSetTemplatesData({ label, value })}
                                    ></TextualObjects> 
                                    e.stopPropagation() ///note: prevent boobling, ie. firing of parent onclick
                                    ///toggleModalShow({ label, modalChildComponent, modalChildName}) ///reduced away
                                  }}
                                >	{icons.proSign} {protip} 
                                </span> ///reduced away: was {icons.proSign({theme, isHighlight})} {protip} 
                                )
                                : null
                              }
                            </GridColums>
                            ,
                            contact: <></> ///reduced away (contactcomponent replaced by fragment)
                              // <ContactComponent
                              //   value={value}
                              //   parentCallback={(e) => {
                              //     e.stopPropagation() //////note: prevent boobling, ie. firing of parent onclick
                              //   }}
                              //   // style={{gridColumnStart: `${s >= 4 ? "1" : "2"}`}}
                              //   // provider={Object.keys(value[s])[0]} //{Object.keys(value[s])[0]}
                              //   // contactValue={Object.values(value[s])[0]}
                              // >
                              // </ContactComponent>,
                          }
                            // const isMultiOptions = generics.stringInArray(multiOptions, label)
                            //label = isMultiOptions ? "multioption" : label
            

                        component = 
                          <div
                              className={`${templateClassName}`}
                            onClick={() => {
                              ///toggleModalShow({ label, modalChildComponent, modalChildName}) ///reduced away
                            }}
                            >
                              {generics.keyExist(textualObjectsComponents, label) ? 
                              textualObjectsComponents[label] : 
                              textualObjectsComponents.default}
                            </div>
                          break;
                  default:
                    refIndex = getRefIndex()
                    component =
                    <ContentEditable ///optimise: implement contenteditable default
                      key={`${label}${s}`}
                      className={`bib ${templateClassName}`}
                      tagName={htmlTag}
                      html={modifiedValue}
                      placeholder={userPresent ? `add ${label}?` : null}
                      style={contentStyle}
                      disabled={!userPresent}
                      innerRef={(el) => (contentEditableRef.current[refIndex] = el)}
                      //onClick={() => !userPresent && handleTextClicked(label)} ///reactivate
                      onKeyDown={(e) => {
                        const gotoNext = false
                        contentEditableKeyDown(e, label, contentEditableRef.current, refIndex, gotoNext)} ///note: refindex due to nested data like dimensions
                      } 
                      onPaste={(e) => {
                        const anchorOffset = window.document.getSelection()["anchorOffset"]
                        const value = contentEditablePaste(e, label, contentEditableRef.current, refIndex, anchorOffset) ///if text is not restricted => return text, else empty string
                        handleSetItems(label, document, value, s) ///note: handlecontenteditableblur not used: to mitigate upgrade at backend directly after paste. instead re-focus via setscrollto/wasref ensures update at blur
                        const component = "itemtext"
                        handleSetScrollTo(contentEditableRef.current[refIndex], refIndex, anchorOffset, component)
                        }} 
                      ///note: onchange not used => to ensure correct focus/blur at custom keydown events
                      onBlur={() => {
                        const value = contentEditableRef.current[refIndex].innerText
                        handleContentEditableBlur(label, document, value, index, s)
                        }
                      }
                      > 
                    </ContentEditable>
                    break;
                }   
                const key = `${document}-${label}-${index}-${s}`
                return (
                  <Fragment
                    key={key}
                  >
                    {component} 
                  </Fragment>
                )
              })}
              {/* </GridColums> */}
              </div>
      
            </ConditionalLinkWrapper>  
            </Element>
          </Component>


        )
          })
      }
      {/* <ScrollToMenu></ScrollToMenu> */}
      </GridColums>
            )}
      )}

    </Wrapper>
    )
}