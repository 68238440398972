import './app.css';
import './styling-standards.css';
import './refine.css';
import ContentEditable from 'react-contenteditable'
import { useState, useEffect, createRef, useRef, useCallback, useContext, memo } from 'react';
import * as generics from './generics';
import { MultiStateButton } from './MultiStateButton';
import { templatesDataContext } from './App'; ///reduced away: efineContext, urlInfoContext, themeContext, itemsContext, itemsRefinedContext,
import produce from "immer";
import { GridColums, SquareIcon, Wrapper } from './StandardComponents';
import { searchIcon, filterIcon } from './Icons';
import { templatesFilterableAttr } from './ItemForm';
import { search } from './AlgoliaSearch';

export const ItemCount = ({ color, activeTemplate, showTemplateName, showOnlySummary, show, fontSize }) => {

  ///context
  // const { theme, setTheme } = useContext(themeContext);
  //const { items, setItems } = useContext(itemsContext);
  ///const { itemsRefined, setItemsRefined } = useContext(itemsRefinedContext); ///reduced away

  const 
    name = `item-count`,
    itemsCount = 1, ///reduced away: items.filter(item => item.template === activeTemplate).length,
    itemsRefinedCount = 1
        // showOnlySummary ? ///reduced away
        //   itemsRefined.length :
        //   itemsRefined.filter(item => item.template === activeTemplate).length
  let itemCountText
  // switch (true) { ///reduced away
  //   case showOnlySummary:
  //     itemCountText = `showing ${itemsRefinedCount} results`;
  //     break;
  //   // case (itemsCount && itemsRefinedCount) === 0:
  //   //   itemCountText = "";
  //   //   break;
  //   case itemsRefined.length === 1:
  //     //alert(`test: ${1 === 2 ? 'hello' : 'world'}`);
  //     itemCountText = `showing ${itemsRefinedCount} ${showTemplateName ? activeTemplate : ""} (of ${itemsCount})`;
  //     break;
  //   case itemsCount === itemsRefinedCount:
  //     itemCountText = `showing all ${showTemplateName ? activeTemplate + "s": ""} (${itemsRefinedCount})`
  //     break;
  //   default:
  //     itemCountText = `showing ${itemsRefinedCount} ${showTemplateName ? activeTemplate + "s" : ""} (of ${itemsCount})`
  //   break;
  // };

  return (   
    <Wrapper
      name={name}
      height={`auto`}
      width={`auto`}
      show={show}
    >
    {/* <div className={`
      item-count-wrapper
      padding-top-2
      padding-bottom-2
    `}> */}
      <div className={`
        ${name}
        font-size-${fontSize}
        `}>
        <span className={`
          item-count-text
          no-select
        `}
        style={{color: color}}
          >{itemCountText}
        </span>
      </div>
    {/* </div> */}
    </Wrapper>
  )
}

export function Refine({ className }) {

  ///ref
  const searchRef = useRef("")
  const searchFocus = createRef("")
  const multiStateButtonRef = useRef("")

  ///context
	///const { refine, setRefine } = useContext(refineContext); ///reduced away
  ///const { urlInfo, setUrlInfo } = useContext(urlInfoContext);
  const theme = { theme: 4} /// reduced away { theme, setTheme } = useContext(themeContext);
  //const { items, setItems } = useContext(itemsContext);
  //const { itemsRefined, setItemsRefined } = useContext(itemsRefinedContext); ///reduced away
  ///const { templatesData, setTemplatesData } = useContext(templatesDataContext); ///reduced away

  ///const from context
  // const activeTemplate = templatesData.show.activeTemplates[templatesData.show.activeTemplates.length - 1] ///reduced away
  // const activeTemplates = templatesData.show.activeTemplates ///reduced away
  ///state
  const [ showSearch, setShowSearch ] = useState(false);

  ///const
  const show = true /// urlInfo.location.pathname === "/" || generics.stringIncludes(urlInfo.location.pathname, "/gallery/") ? true : false
  const activeAttr = [] ///[...new Set(activeTemplates.map((activeTemplate => templatesFilterableAttr[activeTemplate])).flat())] ///was: [activeTemplate].sort()  ///optimise: defined in serveral jsx. define once, and geneate generic ///reduced away
  const categoryOptions = ["all"].concat(activeAttr)

  const label = "category"
  const dimension = "5vh"
  const height = "70%"
  //const widthMultiStateButton = {minWidth: `10vw`}

  ///TOOGLE SHOW SEARCH
  const toogleShowSearch = () => {
    setShowSearch(true)
  }

  // useEffect(() => {
  //   if (!generics.arrayEmpty(refine.filterOptions)) { 
  //     if (!generics.arrayEmpty(Object.values(refine.filterOptions[0])[0])) { ///optimise/risk: check only first filter, like artist, if non-empty. implement more robust solution
  //     const show = true /// (activeTemplate !== "live" || activeTemplate !== "special events") ? true : false
  //     const delayFunc = () => toogleShowFilterBox({ show })
  //     setTimeout(delayFunc, 10); ///optimise: timerfunc??
  //     }
  //   }
  // }, [refine.filterOptions]);

  useEffect(() => {
    if (showSearch) { ///if => to prevent focus on render (load of page)
    searchFocus.current.focus()
    }
  }, [showSearch]);

  ///HANDLE SEARCH
  ///handle search field change
  const handleChange = (e) => {
    const parameter = "lookup"
    if (showSearch) {
      const lookup = (generics.removeHtmlTypeStrings(e.target.value)).trim()
      searchRef.current = lookup
      //search({})
      ///handleSetRefine(parameter, lookup) ///reduced away
    } else { /// in case of typing directly after rendering/load, first keystroke reveal search-field (typing deferred)
      setShowSearch(true) 
    }
  }

  ///handle search field blur
  const handleBlur = () => {
    //searchRef.current = "" ///not working
  }

  ///handle category change
  const callbackMultiStateButton = useCallback((label, value) => {
    const parameter = "category"
    ///handleSetRefine(parameter, value)  ///reduced away
  }, []);

  ///toogle show filterbox 
  // const toogleShowFilterBox = ({ show }) => { ///reduced away
  //   const parameter = "show" ///optimise: combine serefine with handlesetrefine
  //   const target = "filterbox"
  //   const value = true ///reduced away: refine[parameter][target] ? false : true
  //   setRefine(
  //     produce((draft) => {
  //       const item = draft
  //       item[parameter][target] = show; ///value
  //     })
  //   );
  // }

  ///HANDLE SET REFINE
  // const handleSetRefine = useCallback((parameter, lookup) => { ///reduced away
  //   setRefine(
  //     produce((draft) => {
  //       const item = draft
  //       item[parameter] = lookup;
  //     })
  //   );
  // },[]); 

  const CategoryOptions = () => {
    const 
      className = `search-category-options`
    return (   
      <>
      <MultiStateButton
        key={label}
        wrapperClassName={`${className}-wrapper`}
        //classNameWrapper={`${className}-wrapper`} ///note: redundant
        className={className}
        classTypes={["classic", "left"]}
        //wrapperStyle={{height: "100%"}}
        minHeight={"100%"}
        height={height}
        fontSize={0}
        theme={theme.theme}
        label={label}
        options={categoryOptions} 
        value= {"all"} ///reduced away{refine[label]}
        ref={multiStateButtonRef}
        parentCallback={callbackMultiStateButton}
    ></MultiStateButton>
    </> 
    )
  }

  const SearchField = useCallback(() => {
    const style = {minWidth: `10vw`}
    return (    
      <div className={`search-field-wrapper`}
        style={{height: height}}
      >
        <div 
          className={`
            search-field
            ${showSearch ? "hide-pseudo-after fadeout-05" : "show-pseudo-after fadein-05"}
          `}
          search-icon={`\uf002`}
          onClick={() => toogleShowSearch()} ///optimise: whole-field is clickable. should only be search icon
        >
          <ContentEditable 
            key={"search"}
            placeholder={showSearch ? "search" : ""}
            className={`input input-height-full input-radius-right input-padding-2 input-justify-content-left input-border-default lowercase`}
            style={style}
            html={searchRef.current} 
            innerRef={searchFocus}
            onChange={(e) => handleChange(e)}
            onBlur={(e) => handleBlur(e)}
          />
        </div>
      </div>
    )
  }, [showSearch])

  const Search =  useCallback(({ showTip, tip }) => {
    //const tip = generics.arrayToString(categoryOptions.slice(1), ", ")
    //const showTip = refine.lookup !== "" && refine.category === "all"
    return (    
      <div className={`search-wrapper`}>
      <div  
        className={`
          search-container
        ${showTip ?
          "tip-below" :
          ""}
        `}
        //tip={`searching within: ${tip}`}
        // search-icon={`\uf002`}
        // onClick={() => toogleShowSearch()} ///optimise: whole-field is clickable. should only be search icon
      >
        <GridColums
          className={`
            search 
            ${true ? "show fadeout-05" : "hide--clickable fadein-05"}`} ///alternative: showsearch
          gridTemplateColumns={"5em 1fr"}
        >
            <CategoryOptions/>
            <SearchField/>
        </GridColums> 
      </div>
    </div>
    )
  }, [showSearch, theme]) ///reduced away: refine.category ///note: usecallback to mitigate loss of focus. optimise: searchfield lose focus on refine.category change, ok or?

  // const ItemCount = useCallback(() => {
  //   const itemCount = itemsRefined.length === 0 ? "" : `${itemsRefined.length} \uf03e`
  //   return (    
  //     <div className={`item-count-wrapper
  //       ${showSearch ? "item-count-wrapper-pos1" : "item-count-wrapper-pos2"}
  //     `}>
  //       <div className={`item-count`}>
  //         <span className={`
  //           item-count-text 
  //           text-gradient-1
  //           input input-radius input-padding-2
  //         `}
  //           >{itemCount}
  //         </span>
  //       </div>
  //     </div>
  //   )
  // }, [showSearch, itemsRefined])

	
  const Filtering = () => {
    const width = dimension
    const focus = true ///reduced away: refine.show.filterbox
    return (  <></>
        // <SquareIcon ///reduced away
        //   className={`filterbox-toggle`}
        //   focus={focus}
        //   icon={filterIcon}
        //   width={width}
        //   parentCallback={() => toogleShowFilterBox()}
        // >
        // </SquareIcon>
    )
  }

  return (
    <div className={`
      refine-wrapper ${className}
      ${show ? "show fadein-01" : "hide fadeout-05"}
    `}>
      <GridColums
        className={"refine"}
        gridTemplateColumns={`1fr`} //{`1fr fit-content(100%)`}
        justifyContent="end"
        //justifyItems="end"
        //gridTemplateRows={`5vh`} //{`3fr 0.5fr`}
        // gap={`0px 2vw`}
      > 
        {/* <ItemCount
          itemsRefined={itemsRefined}
          className={`${true ? "item-count-wrapper-pos1" : "item-count-wrapper-pos2"}`} //alternative: showsearch
        /> */}
        <Search
          tip={generics.arrayToString(categoryOptions.slice(1), " ")}
          showTip={true} ///reduced away: {refine.lookup !== "" && refine.category === "all"}
        />
          {/* <Filtering/> */}
        {/* <ItemCount/> */}
      </GridColums>
    </div>
  );
}