import React, { useEffect, useRef, memo, createContext as reactCreateContext, useContext as reactUseContext, Fragment, useState, useMemo, Dispatch, SetStateAction } from 'react';
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import produce from "immer"
import { useImmer } from "use-immer";
import { getAllVideos, getPrograms, getChannels, getData, getCollection } from './FirebaseFunctions';
import { CategoryGallery2 } from './CategoryGallery2';
import { MemoPlGallery} from './PlGallery';
import { VideoSelector2 } from './VideoSelector2';
import * as generics from './generics';
import { useCallback } from 'react';
import { BackgroundComponent, GridColums, Blanket } from './StandardComponents';
import { createContext as selectorContext, useContextSelector } from 'use-context-selector';
import { useContext } from 'use-context-selector';
import { Modal } from './Modal';
import Header from './Header';
import Footer from './Footer';
import { checkMarkHTML } from './templates';
import { useLocation } from "react-router-dom";
import { signInWithEmail } from './AuthFunctions';
import { NewArtwork } from './NewArtwork';
import { DataProvider } from './DataProvider';
import { Invitation } from './Invitation';
import * as emailSetups from './emailSetups';
import { addDocument, updateDocument, readDoc, updateUserStats } from './FirebaseFunctions';
import { TextualArray, templet, templatesType, templates, templatesNames, handleMediaAddition, templatesValue } from './ItemForm';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { DiamontList } from './DiamontList';
import { XListComponents } from './XListComponents';

const 
    focusStatesContext = selectorContext([,() => {}]),
    categoryGroupsDataContext = reactCreateContext([,() => {}]),
    categoryGroupsAPIContext = reactCreateContext( () => undefined),
    categoriesDataContext = reactCreateContext([,() => {}]),
    categoriesAPIContext = reactCreateContext( () => undefined),
    channelsDataContext = reactCreateContext([,() => {}]),
    channelsAPIContext = reactCreateContext( () => undefined),
    authDataContext = reactCreateContext([,() => {}]),
    authAPIContext = reactCreateContext( () => undefined),
    //templatesDataContext = reactCreateContext([,() => {}]),
    ///showContext = reactCreateContext([,() => {}]),
    //categoriesContext = createContext([,() => {}]),
    //plsContext = createContext([,() => {}]),
    plsDataContext = reactCreateContext([,() => {}]),
    plsAPIContext = reactCreateContext([,() => {}]),
    dlsDataContext = reactCreateContext([,() => {}]),
    dlsAPIContext = reactCreateContext([,() => {}]),
    videosDataContext = reactCreateContext([,() => {}]),
    videosAPIContext = reactCreateContext([,() => {}]),
    authContext = reactCreateContext([,() => {}])
    //focusElementsContext = createContext([,() => {}])
export {
    focusStatesContext,
    videosAPIContext,
    videosDataContext,
    authContext,
    authDataContext,
    authAPIContext,
    // showContext,
    // templatesDataContext,
    categoryGroupsDataContext,
    categoriesDataContext,
    channelsDataContext,
    channelsAPIContext,
    plsDataContext,
    plsAPIContext,
    dlsDataContext
    // focusElementsContext,
    }

const useData = ( queryParams ) => { 

    const offset = 1 ///note: offset of 1 utilsed as fetchCount 0 as key in useSWR is improper
    const 
        [fetchCount, setFetchCount] = useState(offset), ///note: fetchCount implemented as part of pagination: fetchCount used as key for useSWRImmutable and to pick particular queryParams for each fectch (if multiple)
        [lastDoc, setLastDoc] = useState(null) ///note: lastDoc returned from pagination with !!limit to be used for startAfter at next fetch

    let queryParams_ = Array.isArray(queryParams.collection) ? 
        {   ...queryParams, 
            collection: queryParams.collection[fetchCount - offset], 
            wherePath: queryParams.wherePath[fetchCount - offset],
            whereOperator: queryParams.whereOperator[fetchCount - offset],
            whereValue: queryParams.whereValue[fetchCount - offset],
            limit: queryParams.limit[fetchCount - offset],
            isStartAfter: queryParams.isStartAfter[fetchCount - offset],  
            startAfter: lastDoc ///pagination via snapshot of last retrived doc
        }
        : queryParams
 
        const { data, error, isLoading } = useSWRImmutable( fetchCount ? queryParams_ : null, getCollection) ///note: useSWRImmutable to mitigate additional requests (i.e. only get data once (for each key/fetchCount)) ///note: note data is now an object with { data: data, lastDoc: lastDoc}, where lastDoc is used for pagination (at next fetch)

        useEffect(() => {
            if (Array.isArray(queryParams.collection) && !!data?.data) { ///note: if collection is array => pagination, and if data (data.data) returned from useSWR
                setFetchCount((fetchCount + 1) % (queryParams["collection"].length + offset)) ///note: increment of fecthCount with cap coordinated with amount of pagination requests (as specified in .collection array)
            }
            if (!!data?.lastDoc) { ///note: if pagination with !!limit return lastDoc
                setLastDoc(data.lastDoc)
            }

        }, [data])

    return { 
               data: data?.data,
               isLoading: isLoading,
               isError: error
           }
}

const CategoryGroupsStatesProvider = ({ children }) => {
    const Provider = ({ children }) => { 
        const [swr, setSwr] = useState({ data: undefined, isLoading: true, isError: undefined });
        return (
            <categoryGroupsDataContext.Provider value={swr}>
                <categoryGroupsAPIContext.Provider value={setSwr}>
                    { children }
                </categoryGroupsAPIContext.Provider>
            </categoryGroupsDataContext.Provider>
        );
    }

    const useApi = () => reactUseContext(categoryGroupsAPIContext);
    const APIChild = ({ }) => {
        const api = useApi() ///note: setter function
        const swr = useData({ collection: "category groups" }) 
        api(swr) ///note: set state    
    }

    return (
        <Provider>
                <APIChild/>
                { children }
        </Provider>
    )
}

const CategoriesStatesProvider = ({ children }) => {
    const Provider = ({ children }) => { 
        const [swr, setSwr] = useState({ data: undefined, isLoading: true, isError: undefined });
        return (
            <categoriesDataContext.Provider value={swr}>
                <categoriesAPIContext.Provider value={setSwr}>
                    { children }
                </categoriesAPIContext.Provider>
            </categoriesDataContext.Provider>
        );
    }

    const useApi = () => reactUseContext(categoriesAPIContext);
    const APIChild = ({ }) => {
        const api = useApi() ///note: setter function
        const swr = useData({ collection: "categoriesx" }) ///optimise: consider to rename to categoryCollections (as each document includes "categories"...)
        api(swr) ///note: set state    
    }

    return (
        <Provider>
                <APIChild/>
                { children }
        </Provider>
    )
}


const Test = (props) => {
    return (
        <button
            onClick={() => props.parentCallback({value: !props.newData})}
        > 
            bibber
        </button>
    )
}

const ChannelsStatesProvider = ({ children }) => {

    const Provider = ({ children }) => { 
        const [swr, setSwr] = useState({ data: undefined, isLoading: true, isError: undefined });
        return (
            <channelsDataContext.Provider value={swr}>
                <channelsAPIContext.Provider value={setSwr}>
                    { children }
                </channelsAPIContext.Provider>
            </channelsDataContext.Provider>
        );
    }

    const useApi = () => reactUseContext(channelsAPIContext);
    const APIChild = ({ }) => {
        const api = useApi() ///note: setter function
        const swr = useData({ collection: "channels" })
        api(swr) ///note: set state    
    }

    return (
        <Provider>
                <APIChild/>
                { children }
        </Provider>
    )
}

const CategoryGalleryWrapper = (parentCallback) => {
    // ///note: splitting of api and data providers in same compoent as in ref => useData/API 
    // ///https://www.developerway.com/posts/react-re-renders-guide
    // ///https://codesandbox.io/s/part-7-2-split-context-data-and-api-r8lsws?file=/src/App.tsx:632-783
    // const Provider = ({ children }) => { 
    //     const [swr, setSwr] = useState({swrChannels: {data: undefined, isLoading: true, isError: undefined}});
    //     return (
    //         <channelsDataContext.Provider value={swr}>
    //             <channelsAPIContext.Provider value={setSwr}>
    //                 { children }
    //             </channelsAPIContext.Provider>
    //         </channelsDataContext.Provider>
    //     );
    // }

    // const useApi = () => reactUseContext(channelsAPIContext);
    // const APIChild = ({ }) => {
    //     const api = useApi() ///note: setter function
    //     const swr = useData("channels")
    //     api(swr) ///note: set state    
    // }

    return (
        //  <Provider>
        //         <APIChild/>
                <CategoryGallery2/>
               // {/* <CategoryGallery2
                    //focusStateControl={{focusStates, setFocusStatesCb: handleSetFocusStates}}
                    //channels={swrChannels.data}
                    //swr={swrChannels}
               // /> */}
       // </Provider>
    )
}
const MemoCategoryGalleryWrapper = memo(CategoryGalleryWrapper) 

// const ProgramGalleryComponent = (props) => {
//     const { swrPrograms } = useData("pls")
//     return (
//         //<plsContext.Provider value={swrPrograms}>
//             <ProgramGallery2
//                 focusProvider={props.focusProvider}
//                 // pls={swrPrograms.data}
//                 swr={swrPrograms}
//         />
//         //</plsContext.Provider>
//     )
// }

const PlStatesProvider = ({ children }) => {
    ///note: splitting of api and data providers in same compoent as in ref => useData/API 
    ///https://www.developerway.com/posts/react-re-renders-guide
    ///https://codesandbox.io/s/part-7-2-split-context-data-and-api-r8lsws?file=/src/App.tsx:632-783
    const Provider = ({ children }) => { 
        const [swr, setSwr] = useState({ data: [], isLoading: true, isError: undefined });

        const handlesSetSWR = useCallback((swr) => {
            if (swr.data === undefined && swr.isLoading) { return } ///note: to mitigtate set of data (with undefined) at second load at pagination ///optimise/risk: consider to implement more root cause based solution, this can impact senario where swr not set fx at error
            setSwr(
              produce((draft) => {
                draft.data = !!swr.data ? draft.data.concat(swr.data) : draft.data ///note: to enable pagination
                draft.isLoading = swr.isLoading
                draft.isError = swr.isError
              })
            );
        }, [])

        return (
            <plsDataContext.Provider value={swr}>
                <plsAPIContext.Provider value={handlesSetSWR}>
                    { children }
                </plsAPIContext.Provider>
            </plsDataContext.Provider>
        );
    }

    const useApi = () => reactUseContext(plsAPIContext);
    const APIChild = ({ }) => {
        const api = useApi() ///note: setter function
        const swr = useData({ 
            collection: ["pls", "pls"], ///note: array => fetch via pagination 
            limit: [8, 0], ///note: limit 0 means no limit
            isStartAfter: [false, true], ///note: isStartAfter must be true for a fetch following a fetch with !!limit 
            orderBy: ["staticData.plPublishedAt", "staticData.plPublishedAt"], 
            wherePath: ["dynamicData.access", "dynamicData.access"],
            whereOperator: ["==", "=="],
            whereValue: ["public", "public"],
            })
        api(swr) ///note: set state 

    }

    return (
        <Provider>
            <APIChild/>
                { children }
        </Provider>
    )
}
const MemoPlStatesProvider = memo(PlStatesProvider) ///note: memo used to prevent re-render at url change (at pl select)


const DlStatesProvider = ({ children }) => {
    ///note: splitting of api and data providers in same compoent as in ref => useData/API 
    ///https://www.developerway.com/posts/react-re-renders-guide
    ///https://codesandbox.io/s/part-7-2-split-context-data-and-api-r8lsws?file=/src/App.tsx:632-783
    const Provider = ({ children }) => { 
        const [swr, setSwr] = useState({ data: [], isLoading: true, isError: undefined });

        const handlesSetSWR = useCallback((swr) => {
            if (swr.data === undefined && swr.isLoading) { return } ///note: to mitigtate set of data (with undefined) at second load at pagination ///optimise/risk: consider to implement more root cause based solution, this can impact senario where swr not set fx at error
            setSwr(
              produce((draft) => {
                draft.data = !!swr.data ? draft.data.concat(swr.data) : draft.data ///note: to enable pagination
                draft.isLoading = swr.isLoading
                draft.isError = swr.isError
              })
            );
        }, [])

        return (
            <dlsDataContext.Provider value={swr}>
                <dlsAPIContext.Provider value={handlesSetSWR}>
                    { children }
                </dlsAPIContext.Provider>
            </dlsDataContext.Provider>
        );
    }

    const useApi = () => reactUseContext(dlsAPIContext);
    const APIChild = ({ }) => {
        const api = useApi() ///note: setter function
        const swr = useData({ 
            collection: ["lists"], ///note: array => fetch via pagination 
            limit: [0], ///note: limit 0 means no limit
            isStartAfter: [false], ///note: isStartAfter must be true for a fetch following a fetch with !!limit 
            orderBy: ["staticData.lastChanged"], 
            wherePath: ["dynamicData.access"],
            whereOperator: ["=="],
            whereValue: ["public"],
            })
        api(swr) ///note: set state 

    }

    return (
        <Provider>
            <APIChild/>
                { children }
        </Provider>
    )
}

const VideosStatesProvider = ({ children }) => {
    const Provider = ({ children }) => { 
        const [swr, setSwr] = useState({ data: [], isLoading: true, isError: undefined, isUpdate: false });

        const handlesSetSWR = useCallback((swr) => {
            if (swr.data === undefined && swr.isLoading) { return } ///note: to mitigtate set of data (with undefined) at second load at pagination ///optimise/risk: consider to implement more root cause based solution, this can impact senario where swr not set fx at error
            if (!swr.isUpdate) {
                setSwr(
                    produce((draft) => {
                        draft.data = !!swr.data ? draft.data.concat(swr.data) : draft.data ///note: to enable pagination
                        draft.isLoading = swr.isLoading
                        draft.isError = swr.isError
                    })
                );
            }
        }, [])

        const handleSetSWRUpdate = useCallback(({ param, label, value, identifier }) => {
                setSwr(
                    produce((draft) => {
                        const index = draft.data.findIndex(video => video.staticData.videoId === identifier)
                        if (index !== -1) draft.data[index][param][label] = value
                    })
                )
        }, [])

        const videosAPIValue = useMemo(
            () => ({ handlesSetSWR, handleSetSWRUpdate }), 
            []
          );

        return (
            <videosDataContext.Provider value={swr}>
                <videosAPIContext.Provider value={videosAPIValue}>
                    { children }
                </videosAPIContext.Provider>
            </videosDataContext.Provider>
        );
    }

    const APIChild = ({ }) => {
        const { handlesSetSWR } = reactUseContext(videosAPIContext); ///note: setter function
        const swr = useData({ 
            collection: ["videos", "videos", "videos"], ///note: array => fetch via pagination
            limit: [8, 0, 0], ///note: limit 0 means no limit
            isStartAfter: [false, true, false], ///note: isStartAfter must be true for a fetch following a fetch with !!limit 
            orderBy: ["staticData.publishedAt", "staticData.publishedAt", "staticData.publishedAt"], 
            wherePath: [
                "dynamicData.access", 
                ["dynamicData.access", "staticData.uploaderType"], 
                ["dynamicData.access", "staticData.uploaderType"], 
            ], 
            whereOperator: [
                "==",
                ["==", "=="],
                ["==", "=="]
            ],
            whereValue: [
                "public",
                ["public", "backend-channel"],
                ["public", "user"]
            ],
            }) 
        handlesSetSWR(swr) ///note: set state    
    }

    return (
        <Provider>
                <APIChild/>
                { children }
        </Provider>
    )
}

const VideoSelector = () => {
    return (
        <VideoSelector2/>
    )
}
const MemoVideoSelector = memo(VideoSelector)

const HeaderComponet = memo((props) => ///optimise: move to categorygallery?
    <Header
        isSearchField={true}
        isLogo={true}
        isBackground={true}
        isAuthControl={true}
        isVolumeControl={true}
        isMuted={props.isMuted}
        setIsMuted={props.setIsMuted}
        parentCallback={props.parentCallback}
        // topOfPage={this.state.topOfPage}
        // videoChosen={this.state.videoChosen}
        // audioOff={this.state.audioOff}
        // pipActivated={this.state.pipActivated}
        // onAudioSymbolClicked={this.handleAudioSymbolClicked}
        // onPipClicked={this.handlePipClicked}
        // onGoHome={this.handleGoHome}
    ></Header>
)

const FooterComponent = () =>
    <Footer
    ></Footer>



const FocusStatesProvider = ({ children }) => {

    ///from context
    const 
        swrCategories = reactUseContext(categoriesDataContext),
        swrPls = reactUseContext(plsDataContext),
        swrAuth = reactUseContext(authDataContext),
        swrDls = reactUseContext(dlsDataContext),
        authStates = swrAuth.data

    let location = useLocation()

    const initialState = [
        {
            mediaTypeFocus: "mainComponents",
            actionFocus: null, ///note: not used
            elementFocus: null, ///note: not used
            idFocus: ["banner", "categoryGallery", "pl"], ///note: array as multiple mainComponents in focus similtanously
            isFocus: false ///note: not used,
        },
        {
            mediaTypeFocus: "category",
            actionFocus: "",
            elementFocus: {},
            idFocus: "",
            isFocus: false, ///note: isFocus is implemented for ease, identical to !!idFocus 
        },
        {
            mediaTypeFocus: "pl",
            actionFocus: "",
            elementFocus: {},
            idFocus: "",
            isFocus: false,
        },
        {
            mediaTypeFocus: "video",
            actionFocus: "",
            elementFocus: {},
            idFocus: "",
            isFocus: false,
        },
        {
            mediaTypeFocus: "diamont-list-creator",
            actionFocus: "",
            elementFocus: {},
            idFocus: "",
            isFocus: false,
        },
        {
            mediaTypeFocus: "diamont-list",
            actionFocus: "",
            elementFocus: {},
            idFocus: "",
            isFocus: false,
        }
        ],
    [focusStates, setFocusStates] = useImmer(initialState);
    const focusValue = useMemo(
        () => ({ focusStates, setFocusStates }), 
        [focusStates]
      );
    // focusStates = useContextSelector(focusStatesContext, v => v[0]), ///was: { focusStates, setFocusStates } = useContextSelector(focusStatesContext)
    // setFocusStates = useContextSelector(focusStatesContext, v => v[1])

  const ///optimse: export to other components, instead of restating them?
      mainComponentsFocusObj = generics.getFocusObj({ focusStates, mediaType: "mainComponents" }),
      categoryFocusObj = generics.getFocusObj({ focusStates, mediaType: "category" }),
      plFocusObj = generics.getFocusObj({ focusStates, mediaType: "pl" }),
      dlFocusObj = generics.getFocusObj({ focusStates, mediaType: "diamont-list" }),
      videoFocusObj = generics.getFocusObj({ focusStates, mediaType: "video" }),
      dlcFocusObj = generics.getFocusObj({ focusStates, mediaType: "diamont-list-creator" })
      
//     plFocusObj = generics.getFocusObj({ focusStates, mediaType: "pl" }),
//     videoFocusObj = generics.getFocusObj({ focusStates, mediaType: "video" })

useEffect(() => {
    console.log("videoFocusObj", videoFocusObj)
}, [videoFocusObj])

///purpose: to centrally control focus state of each mainComponent (=> impact on hide/view) based on state of focusStates and location (url changes). ///potential drawback: casasade based (userinteraction => state update => useeffect => ...) => slow compared to implementation with no useeffect, but set concurretly with setstate (locally)? (prilimarly assumption: not in practice) ///note: alternative includes controlled locally (=> but less oversight) or via url changes. note: 
useEffect(() => { 
  const 
    pathname = location.pathname,
    pathLength = pathname.split("/").filter(Boolean).length
//   let idFocus = mainComponentsFocusObj.idFocus

    //focusStates.map((focusState) =>
    //     focusState.isFocus && idFocus.push(focusState.mediaTypeFocus)
    //   )

    console.log("pathname", pathname,  pathname.includes("vid-"))

  let idFocus = ["header", "banner", "footer"] ///note: default


    if (!pathname.includes("cat-") && !pathname.includes("xl-")) {
        idFocus = idFocus.concat(["invitation", "pl-gallery-invitation", "x-lists", "category-gallery", "pl-gallery-newest", "pl-gallery-user-videos"])
    }
    if (pathname.includes("xl-") && !pathname.includes("cat-")) {
        idFocus = idFocus.concat(["x-list"])
    }
    if (pathname.includes("cat-")) {
        idFocus = idFocus.concat(["pl-gallery-category"])
    }
    if (pathname.includes("pl-") || pathname.includes("vid-")) {
        idFocus = idFocus.concat(["video-selector"])
    }
    if (dlcFocusObj.isFocus) {
        idFocus.push("diamont-list-creator") ///note: for dlc only push seems to work, while the remaining seems to only work at concat...
    }

  const 
      mediaTypeFocus = "mainComponents",
      actionFocus = null, ///not used
      elementFocus = null, ///not used
      isFocus = null ///not used
  handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })

}, [
    location,
    dlcFocusObj ///note: dl/dlcFocusStates is not assoicated with impact on url, thus dlcFocusObj is included explicit for useEffect
    ]
) 

useEffect(() => { 

    const 
        [xlPath, categoryPath, plPath, lvPath] = generics.getElementsFromPath({ pathname: location.pathname }), ///note: func returns: plPath = !!vidPath["vid-"] ? vidPath : plPath (OK as plPath and vidPath never occuring simultaneously (note: vidPath is just a subtype of plPath)
        focusNameObjIds = [ xlPath, categoryPath, plPath, lvPath ]

    if (!swrCategories?.data || !swrPls?.data) { return }
    
    focusNameObjIds
        .map((focusNameObjId) =>
            Object.entries(focusNameObjId)///note: k is identifer like "cat-" and v is "value"
                //.filter(([k, v]) => !!v) ///for each path element that is defined (not "")
                .map(([k, v]) => {
                    let mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus,
                        isMediaFound
                    switch(true) {
                        case k === "cat-":
                            if (!!v) {
                                isMediaFound = !!swrCategories.data.find(categoryObj => categoryObj["category name id"] === v)
                                if (isMediaFound) {
                                    mediaTypeFocus = "category"
                                    actionFocus = "select"
                                    elementFocus = swrCategories.data.find(categoryObj => categoryObj["category name id"] === v)
                                    idFocus = elementFocus?.["category name id"]
                                    isFocus = true
                                }
                            }
                            if (!v) {
                                mediaTypeFocus = "category"
                                actionFocus = ""
                                elementFocus = ""
                                idFocus = ""
                                isFocus = false
                            }
                            handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                        break
                        case k === "pl-": ///
                            if (!!v) {
                                isMediaFound = !!swrPls.data.find(pl => pl.staticData["plId"] === [...Object.values(plPath)][0])
                                if (isMediaFound) {
                                    mediaTypeFocus = "pl"
                                    actionFocus = "select"
                                    elementFocus = swrPls.data.find(pl => pl.staticData["plId"] === [...Object.values(plPath)][0])
                                    idFocus = elementFocus.staticData.plId
                                    isFocus = true
                                    handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                                }
                            }
                            if (!v) {
                                mediaTypeFocus = "pl"
                                actionFocus = ""
                                elementFocus = ""
                                idFocus = ""
                                isFocus = false
                                handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                            }
                        break
                        case k === "lv-":
                            if (!!v) {
                                const 
                                    [season, episode] = v.split("_"),
                                    [pathSeason, pathEpisode] = [season, episode].map(e => parseInt(e)),
                                    focusSeason = videoFocusObj.elementFocus.season,
                                    focusEpisode = videoFocusObj.elementFocus.episode
                                if (pathSeason !== focusSeason || pathEpisode !== focusEpisode) {
                                    const isMediaFound = !!swrPls.data.find(pl => pl.staticData["plId"] === [...Object.values(plPath)][0])
                                    if (isMediaFound) {
                                        mediaTypeFocus = "video"
                                        actionFocus = "select"
                                        elementFocus = swrPls.data.find(pl => pl.staticData["plId"] === [...Object.values(plPath)][0]).dynamicData.plVideoCollection?.find(video => video.season === pathSeason && video.episode === pathEpisode) 
                                        idFocus = elementFocus.videoUrl
                                        isFocus = true
                                        console.log("elementxocusx", elementFocus)
                                        handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                                    }
                                }
                            }
                            if (!v) {
                                mediaTypeFocus = "video"
                                actionFocus = ""
                                elementFocus = ""
                                idFocus = ""
                                isFocus = false
                                handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                            }
                        break;
                        case k === "xl-":
                            if (!!v) {
                                isMediaFound = !!swrDls.data.find(dl => dl.staticData.id === v)
                                if (isMediaFound) {
                                    mediaTypeFocus = "diamont-list"
                                    elementFocus = swrDls.data.find(dl => dl.staticData.id === v)
                                    idFocus = elementFocus.staticData.id
                                    isFocus = true
                                    handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                                }
                            }
                            if (!v) {
                                mediaTypeFocus = "diamont-list"
                                actionFocus = ""
                                elementFocus = ""
                                idFocus = ""
                                isFocus = false
                                handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
                            }
                            break;
                    default:
                    }
                })
        )
  }, [location, swrCategories.data, swrPls.data]) ///useeffect at a change, but change in swr.. in addition as swr.data undefined at load
  

    //   useEffect(() => { ///direct load => set category
    //     const 
    //       pathname = location.pathname,
    //       pathLength = pathname.split("/").filter(Boolean).length
    //       console.log("location pathname", location.pathname, pathLength)
    //     if (
    //         pathLength > 0 && ///(pathLength === 1 || pathLength === 2) {
    //         pathname.split("/")[1].startsWith("cat-")
    //         ) { 
            
    //         const categoryId = pathname.split("/")[1].replace(/cat-/g, '').replace(/\//g, '')
    //             // categoryId = pathLength === 1 ?
    //             //     pathname.split("/")[1].replace(/\//g, '') :
    //             //     pathname.split("/")[1].replace(/\//g, '') ///.split("-")[0] ///experiment/risk: this action at pathLength === 2, consider removing
    //         console.log("location", categoryId, categoryId)
    //         const 
    //             mediaTypeFocus = "category",
    //             actionFocus = "select",
    //             elementFocus = categoriesMap.find(categoryObj => encodeURI(categoryObj["category name"].toLowerCase().replace(/ /g, "_")) === categoryId)
    //         if (!!elementFocus) {
    //             const idFocus = elementFocus["category name"],
    //             isFocus = true
    //             handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
    //         }
    //     }
    //   }, [location]); 

        //   useEffect(() => { ///direct load => set category
    //     const 
    //       pathname = location.pathname,
    //       pathLength = pathname.split("/").filter(Boolean).length
    //       console.log("location pathname", location.pathname, pathLength)
    //     if (
    //         pathLength > 0 && ///(pathLength === 1 || pathLength === 2) {
    //         pathname.split("/")[1].startsWith("cat-")
    //         ) { 
            
    //         const categoryId = pathname.split("/")[1].replace(/cat-/g, '').replace(/\//g, '')
    //             // categoryId = pathLength === 1 ?
    //             //     pathname.split("/")[1].replace(/\//g, '') :
    //             //     pathname.split("/")[1].replace(/\//g, '') ///.split("-")[0] ///experiment/risk: this action at pathLength === 2, consider removing
    //         console.log("location", categoryId, categoryId)
    //         const 
    //             mediaTypeFocus = "category",
    //             actionFocus = "select",
    //             elementFocus = categoriesMap.find(categoryObj => encodeURI(categoryObj["category name"].toLowerCase().replace(/ /g, "_")) === categoryId)
    //         if (!!elementFocus) {
    //             const idFocus = elementFocus["category name"],
    //             isFocus = true
    //             handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
    //         }
    //     }
    //   }, [location]); 


useEffect(() => { ///direct load??? => reset category, pl and video
    const 
      pathname = location.pathname,
      pathLength = pathname.split("/").filter(Boolean).length
    if (pathLength === 0) { ///was 1?
        const focusElements = ["diamont-list", "category", "pl", "video"]
        focusElements.map((focusElement) => {
            const { mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus } = initialState.find(el => el.mediaTypeFocus === focusElement)
            handleSetFocusStates({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus })
        })
    }
  }, [location]); 

const handleSetFocusStates = useCallback(({ mediaTypeFocus, actionFocus, elementFocus, idFocus, isFocus }) => {
    setFocusStates((draft) => {
            const focusElement = draft.find((focusElement) => focusElement.mediaTypeFocus === mediaTypeFocus);
            focusElement.actionFocus = actionFocus
            focusElement.elementFocus = elementFocus
            focusElement.idFocus = idFocus
            focusElement.isFocus = isFocus
    })
  },[])
      return (
        <focusStatesContext.Provider value={focusValue}>
        { children }
        </focusStatesContext.Provider>
      )
};


// const AuthStatesProvider = ({ children }) => {

   

//       return (
//         <authContext.Provider value={authValue}>
//         { children }
//         </authContext.Provider>
//       )
// };



// const isSignInWithEmail = location.search.includes("mode=signIn")
// if (isSignInWithEmail) {
//     handleSignInWithEmail()
// }

const useAuth = ({ key }) => { ///note: the use of this format, useChannels, is meant for reusable components... but used for consistency 
    let location = useLocation()
    const isSignInWithEmail = location.search.includes("mode=signIn")
    const { data, error, isLoading } = useSWRImmutable(true ? { key, isSignInWithEmail } : null, handleSignInWithEmail) ///note: useSWRImmutable to mitigate additional requests (i.e. only get data once)
    return { 
               data: data,
               isLoading: isLoading,
               isError: error
           }
}

// const LocationProvider = ({ children }) => {
//     const 
//         currentLocation = useLocation(),
//         [location, setLocation] = useState(currentLocation)

//     useEffect(() => {
//         setLocation(location)
//     }, [currentLocation])

//     return <>{ children(location) }</>
// }

const handleSignInWithEmail = async({ isSignInWithEmail }) => {
    let 
        uid = "", 
        resultUser = {},
        isNewUser = false,
        isFromLink = false
    if (isSignInWithEmail) {
        console.log("isSignInWithEmail")
        resultUser = await signInWithEmail() ///sign in/up
        uid = resultUser.uid
        isNewUser = resultUser.metadata.creationTime === resultUser.metadata.lastSignInTime ///note/premise: if datestamps are identical => new user
        isFromLink = true
            // handleSetAuthStates({ parameter: "uid", value: resultUser.uid })
            // handleSetAuthStates({ parameter: "isNewUser", value: isNewUser })
        // if (isNewUser) { ///if new user => write user doc to users
        //     await handleCreateNewUser({ resultUser, subsc })
        //     console.log("sign in with email: new user added and signed-in")
        // }
        // else { ///if existing user => update user stats
        //     console.log("sign in with email: existing user", resultUser ," signed-in")
        //     const payload = { signInCount: 1 } 
        //     await updateDoc({ collection: "usersStats", document: resultUser.uid, payload: payload, isIncrement: true }) ///optimise: make function generic, part of updateDoc
        // }
    }
    const authStates = { 
        uid: uid, 
        isNewUser: isNewUser, 
        isLinkSend: false, 
        resultUser: resultUser, 
        user: {}, 
        userStats: {}, 
        isFromLink: isFromLink,
        /// userRoles: ["user"] ///note: initially set via cloudfunction upon creation of user
    }
    return authStates
}

const handleCreateNewUser = async({ resultUser, subsc }) => {

    const
        { email, uid } = resultUser, ///consider: at new user, consider retreving created-date from resultUser instead of creating (slightly later) timestamp in addDocument 
        userInfo =  { email, uid, subsc }
    await addDocument({ collection: "users", document: uid, payload: userInfo })
    const userStats = { signInCount: 1 }
    addDocument({ collection: "usersStats", document: uid, payload: userStats })
    const 
        emailSetup = emailSetups.emailSetupSubsc({ template: "signup", email, subsc }),
        sendEmail = await addDocument({ collection: "mail", document: uid, payload: emailSetup })
    console.log("sign in with email: new user added and signed-in")
}

const AuthStatesProvider = ({ location, children }) => {

    const history = useHistory();

    console.log("AuthStatesProvider rerender")

    const 
        initialState = 
            {
                uid: "",
                isLinkSend: false,
                isFromLink: false,
                isNewUser: false,
                user: {},
                userStats: {},
                /// userRoles: ["user"] ///note: initially set via cloudfunction upon creation of user
            }

        // [authStates, setAuthStates] = useImmer(initialState),
        // authValue = useMemo(
        //     () => ({ authStates, setAuthStates }), 
        //     [authStates]
        // )

    //   useEffect(() => { ///if location ... => handleSignInWithEmail
    //     const isSignInWithEmail = location.search.includes("mode=signIn") ///optimise/risk: or use "oob" to be specific with signin method?
    //     setIsSignInWithEmail(isSignInWithEmail)
    //     // if (isSignInWithEmail) {
    //     //     handleSignInWithEmail()  
    //     //     return () => {
    //     //         // this now gets called when the component unmounts
    //     //     };
    //     // }
    // }, [location]);

    // useEffect(() => {
    //     const isSignInWithEmail = location.search.includes("mode=signIn")
    //     if (isSignInWithEmail) {
    //         const isInitiateSignInWithEmail = useInitiate({ key: "signin" })
    //         setIsInitiateSignInWithEmail(isInitiateSignInWithEmail)
    //     }
    // }, [location])
     
    // const handleSignInWithEmail = useCallback(async() => {
    //     console.log("handlesigninwithemail")
    //     const   
    //         resultUser = await signInWithEmail(), ///sign in/up
    //         isNewUser = resultUser.metadata.creationTime === resultUser.metadata.lastSignInTime ///note/premise: if datestamps are identical => new user
    //         handleSetAuthStates({ parameter: "uid", value: resultUser.uid })
    //         handleSetAuthStates({ parameter: "isNewUser", value: isNewUser })
    //         console.log("resultuser", resultUser.uid)
    //     if (isNewUser) { ///if new user => write user doc to users
    //         await handleCreateNewUser({resultUser})
    //         console.log("sign in with email: new user added and signed-in")
    //     }
    //     else { ///if existing user => update user stats
    //         console.log("sign in with email: existing user", resultUser ," signed-in")
    //         const payload = { signInCount: 1 } 
    //         await updateDoc({ collection: "usersStats", document: resultUser.uid, payload: payload, isIncrement: true }) ///optimise: make function generic, part of updateDoc
    //     }
    //     return true
    // }, [])

    // const handleCreateNewUser = useCallback(async({resultUser}) => {
    //     const
    //         { email, uid } = resultUser, ///consider: at new user, consider retreving created-date from resultUser instead of creating (slightly later) timestamp in addDocument 
    //         subsc = {
    //             isAccount: location.search.includes("isaccountsubsc=true"),
    //             isEmail: location.search.includes("isemailsubsc=true"),
    //         },
    //         userInfo =  { email, uid, subsc }
    //     await addDocument({ collection: "users", document: uid, payload: userInfo })
    //     const userStats = { signInCount: 1 }
    //     addDocument({ collection: "usersStats", document: uid, payload: userStats })
    //     const emailSetup = {
    //         to: [email],
    //         // from: 'YogaTube.ai <contact@YogaTube.ai>',
    //         // message: {
    //         // subject: 'Hello from Firebase!',
    //         // text: 'This is the plaintext section of the email body.',
    //         // html: 'This is the <code>HTML</code> section of the email body.',
    //         // },
    //         template: {
    //             name: "signup",
    //             data: {
    //                 subscriptions: `
    //                     <p
    //                         style="
    //                             font-weight: 400;
    //                             font-size: 15px;
    //                             margin: 10px;
    //                             "
    //                     > 
    //                     <table cellpadding="0px" cellspacing="10pz" border="0">
    //                         <tr>
    //                             <td align="center" valign="middle" bgcolor=${subsc.isAccount ? "#007bff" : "#808080"} width="25" height="25" style="font-size: 15px; color: #fff; line-height: 1;">
    //                                 ${subsc.isAccount ? "\u2713" : ""}
    //                             </td>
    //                             <td align="left" valign="middle" bgcolor="" height="25" style="font-size: 15px; color: white; line-height: 1; opacity: ${subsc.isAccount ? 1 : 0.3}">
    //                                 Free Membership
    //                             </td>
    //                         </tr>
    //                         <tr>
    //                             <td align="center" valign="middle" bgcolor=${subsc.isEmail ? "#007bff" : "#808080"} width="25" height="25" style="font-size: 15px; color: #fff; line-height: 1;">
    //                                 ${subsc.isEmail ? "\u2713" : ""}
    //                             </td>
    //                             <td align="left" valign="middle" bgcolor="" height="25" style="font-size: 15px; color: white; line-height: 1; opacity: ${subsc.isEmail ? 1 : 0.3}">
    //                                 Free Newsletter
    //                             </td>
    //                         </tr>
    //                     </table>
    //                     </p>   
    //                     `,
    //             },
    //         }
    //     }    
    //     addDocument({ collection: "mail", document: uid, payload: emailSetup })
    // }, [])

    // const handleUpdateDoc = useCallback(async({ collection, document, payload, isIncrement }) => {
    //     await updateDoc({ collection, document, payload, isIncrement })
    // }, [])

    // const handleSetAuthStates = ({ parameter, value }) => {
    //     setAuthStates(
    //         produce((draft) => {
    //             draft[parameter] = value;
    //         })
    //     )
    // }


    const Provider = ({ children }) => {
        
        let location = useLocation()

        const [swr, setSwr] = useState({ data: initialState, isLoading: true, isError: undefined });

        const handleSetAuthStates = ({ parameter, value }) => {
           
            setSwr(
                produce((draft) => {
                    draft.data[parameter] = value;
                })
            )
        }

        useEffect(() => {
        
            if (swr?.data?.isFromLink) { ///if swr change is from emaillink =>
                const { user, resultUser, isNewUser } = swr.data
                if (isNewUser) { ///if new user => write user doc to users
                    const subsc = {
                        isAccount: location.search.includes("isaccountsubsc=true"),
                        isEmail: location.search.includes("isemailsubsc=true"),
                    }
                    handleCreateNewUser({ resultUser, subsc })
                }
                else { ///if existing user => update user stats
                    console.log("sign in with email: existing user", user ," signed-in", swr, swr?.data?.isFromLink)
                    const payload = { signInCount: 1 } 
                    updateDocument({ collection: "usersStats", document: user.uid, payload: payload, isIncrement: true }) ///optimise: make function generic, part of updateDoc
                }
                handleSetAuthStates({ parameter: "isFromLink", value: false }) ///reset

                // let timeout;
                // timeout = setTimeout(reset, 15000);
                
                // function reset() {
                //     //history.push("/");
                //     console.log("history push")
                //     history.push('/');
                //     // const newUrl = '/new-url';
                //     // history.pushState(null, '', newUrl);
                // }

            }
        }, [swr])

        return (
            <authDataContext.Provider value={swr}>
                <authAPIContext.Provider value={setSwr}>
                    { children }
                </authAPIContext.Provider>
            </authDataContext.Provider>
        );
    }

    const useApi = () => reactUseContext(authAPIContext);
    const APIChild = ({ }) => {
        const api = useApi() ///note: setter function
        const swr = useAuth({ key: "signinlink" })
        api(swr) ///note: set state 
    }

    return (
        <Provider>
                <APIChild/>
                { children }
        </Provider>
    )
}

const GlobalControls = ({ children }) => {
    ///state
    const [isMuted, setIsMuted] = useState(true)
    return <> { children(isMuted, setIsMuted) } </>
}

export const ChildrenWrapper = ({ children }) => {
    const 
        mainComponentsFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "mainComponents")), ///optimise: move func to parent?
        diamontListFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "diamont-list")),
        plFocusStates = useContextSelector(focusStatesContext, v => v.focusStates.find(focusObj => focusObj.mediaTypeFocus === "pl"))
    return (
        children.map((child) => {
            const 
                classNameIn = child.props.id === "video-selector" ? (diamontListFocusStates.isFocus ? "slide-in-more" : "slide-in") : "fade-in", ///note: or alternatively specify at each child
                classNameOut = child.props.id === "video-selector" ? "slide-out" : "fade-out-no-height",
                isPlSelected =  plFocusStates.isFocus,
                isPlSelectedInGallery = plFocusStates.elementFocus.id === child.props.id
                console.log("isSelectedInGallery", child.props.id, plFocusStates, isPlSelected && !isPlSelectedInGallery)
            return (
                <div 
                    className={` 
                        ${mainComponentsFocusStates.idFocus.includes(child.props.id) ? classNameIn : classNameOut}
                        ${child.props.id.includes("gallery") && isPlSelected && !isPlSelectedInGallery ? "select--false-blur--children" : "stay-back-awhile"} 
                        ${isPlSelectedInGallery ? "move-forwards" : ""} 
                    `}
                    key={child.props.id}
                    style={{
                        display: "grid", 
                        position: "relative", ///note: support position of diamont-list (at top) 
                    }} 
                >
                        { child }
                </div>
            )
        })
    )
}


export const Home = () => {

    return ( ///optimise: consider to align videosDataContext.Provider format with ChannelsStatesProvider
            <CategoryGroupsStatesProvider>
            <CategoriesStatesProvider>
            <ChannelsStatesProvider>
            <PlStatesProvider>
            <VideosStatesProvider>
            <DlStatesProvider>
            {/* <LocationProvider>
            {(location) => ( */}
            <AuthStatesProvider> 
            <FocusStatesProvider> 
            <GlobalControls>
            {(isMuted, setIsMuted) => (
            <DataProvider>
            {(templatesData, setTemplatesData, handleSetTemplatesData) => ( /////parentCallback={({ modalChild, value }) => handleSetAuth({ modalChild, value })} }
            <Modal  
                templatesData={templatesData}
                setTemplatesDataCallback={handleSetTemplatesData}
            >
            {(modal, setModal) => ( /////parentCallback={({ modalChild, value }) => handleSetAuth({ modalChild, value })} 
              <>
              <div className={`main-columns`}>
                     <div className={"x"} style={{position: "absolute"}}>
                     <BackgroundComponent id={"banner"}/>
                        {/* <Blanket id={"blanket"}/> */}
                        <HeaderComponet id={"header"} isMuted={isMuted} setIsMuted={setIsMuted} parentCallback={setModal}/>
                        <FooterComponent id={"footer"} show={true}/>
                     </div>
                     {/* <div className={"x"} style={{position: "relative"}}> */}
                    {/* <ChildrenWrapper>  
                        <DiamontList id={"diamont-list"}/>
                         <div id={"dummy"}></div>  {/* ///note: fragmented included to enable children.map */}
                    {/* </ChildrenWrapper> */} 
                    {/* </div> */}
                    <div className={"x"} style={{position: "relative"}}>
                    <ChildrenWrapper>   
                        <DiamontList id={"diamont-list-creator"} 
                            parentCallback={({ action, label, value, template, modalChildName, isShow }) => {
                            setModal({ modalChildName, isShow })
                            handleSetTemplatesData({ action, label, value, activeTemplate: template })
                        }}
                        />
                        {/* <Invitation id={"invitation"} parentCallback={({ modalChildName, isShow }) => setModal({ modalChildName: modalChildName, isShow: isShow })}/>
                        <MemoPlGallery id={"pl-gallery-invitation"} isMuted={isMuted}/> */}
                        <MemoCategoryGalleryWrapper id={"category-gallery"}/>
                        <XListComponents id={"x-lists"} isMuted={isMuted}/>
                        <XListComponents id={"x-list"} isMuted={isMuted}/>
                        <MemoPlGallery id={"pl-gallery-category"} isMuted={isMuted}/>
                        <MemoPlGallery id={"pl-gallery-newest"} isMuted={isMuted}/> 
                        <MemoPlGallery id={"pl-gallery-user-videos"} isMuted={isMuted}/> 
                        <MemoVideoSelector id={"video-selector"} isMuted={isMuted}/>

                    </ChildrenWrapper>
                    </div>
                </div>
                </>
            )}
            </Modal>
            )}
            </DataProvider>
            )}
            </GlobalControls>
            </FocusStatesProvider>
            </AuthStatesProvider>
            {/* </LocationProvider> */}
            </DlStatesProvider>
            </VideosStatesProvider>
            </PlStatesProvider>
            </ChannelsStatesProvider>
            </CategoriesStatesProvider>
            </CategoryGroupsStatesProvider>
    )
}

//useData only called once for each component 
//but called without calling update of other componnets
//thus controlled in proximity by compotnents, an api provider?

//a staic home wrapper that never changes after first mount/render - just holds first load content like a background - that would be app, right?

