import './multi-state-button.css';
//import './app.css';
import './styling-standards.css';
import { useState, useEffect, forwardRef, useMemo, memo, useCallback, useContext, useReducer } from 'react';
import * as generics from './generics';
import { Button } from './StandardComponents';

export const MultiStateButton = memo(forwardRef(({ options, paddingAndMargin,  value, label, wrapperClassName, wrapperStyle, position, className, classTypes, minHeight, wrapperHeight, height, width, minWidth, text, fontSize, theme, isSelected, suffix, message, children, parentCallback }, ref) => {
  ///toogle value
  const toggleValue = (value) => {
    let index
    const isHtml = value.type === "i" || value.type === "div" ///optimise: include all html types
    switch(true) {
      case isHtml: 
        const id = value.props.id ///html elements is defined with props id
        const idOptions = options.map((option) => option.props.id)
        index = generics.indexInArray(idOptions, id)
        break;
      case !isHtml:
        index = generics.indexInArray(options, value)
        break;
      default:
    }
    const nextIndex = (index + 1) % options.length
    const nextValue = options[nextIndex]
    parentCallback(label, nextValue);
  };

  return (
      <Button
        show={true}
        wrapperClassName={`
          multi-state-button-wrapper
          ${wrapperClassName}
        `}
        wrapperStyle={wrapperStyle}
				classTypes={classTypes}
				className={`
          multi-state-button
					${className}
				`}
        paddingAndMargin={paddingAndMargin}
        minHeight={minHeight}
        minWidth={minWidth}
        height={height}
        width={width}
        position={position}
				theme={theme}
        fontSize={fontSize}
        parentCallback={() => toggleValue(value)}
			>
        <div
          key={label}
          ref={ref}
          //type={"button"} //to mitigate submit => parms '?' to url
          className={`multi-state-button-button ${className}`}
          placeholder={value === "" ? "Select" : ""}
        > { typeof value == "boolean" ? value.toString() : value }
        </div>
        </Button>
  );
}))

///was export default memo(forwardRef(MultiStateButton))

  ///reducer
  // const initialState = {counter: 0};

  // function reducer(state, action) {
  // switch (action.type) {
  //   case 'increment':
  //     return {counter: (state.counter + 1) % stateOptions.length};
  //   default:
  //     throw new Error();
  //     }
  //   }

  // const [state, dispatch] = useReducer(reducer, initialState);

   //onClick={() => dispatch({type: 'increment'})}